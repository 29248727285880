export type RecipientSectionType = {
  slug: string;
  title: string;
  homecare?: boolean;
  disabled?: boolean;
};

export const RecipientPageSections: RecipientSectionType[] = [
  {
    title: "Details",
    slug: "details",
  },
  {
    title: "Bookings",
    slug: "bookings",
  },
  {
    title: "Providers",
    slug: "providers",
  },
  {
    title: "Addresses",
    slug: "addresses",
  },
  {
    title: "Inbox",
    slug: "inbox",
    disabled: true,
  },
  {
    title: "Invoices",
    slug: "invoices",
    homecare: true,
  },
  {
    title: "Documents",
    slug: "documents",
    homecare: true,
    // disabled: true,
  },

  {
    title: "Reviews",
    slug: "reviews",
  },
  {
    title: "Treatment notes",
    slug: "treatment-notes",
  },
];
