import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import braintree from "braintree-web";
import { isEmpty, isNil, isEqual } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import "react-day-picker/lib/style.css";
import { useHistory, useLocation } from "react-router-dom";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import LandingWrapper from "../../components/LandingWrapper";
import LogoLink from "../../components/LogoLink";
import PaymentFrame, { PaymentFrameType } from "../../components/PaymentFrame/PaymentFrame";
import TextField from "../../components/TextField";
import MetaTags from "../../components/Voucher/MetaTags";
import { PaymentType } from "../../constants/payment";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import AfterPayHandler from "../../services/payment/afterpay.payment";
import { parseApiError, parseApiV2Error } from "../../helpers/error";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { Coupon, PaymentMethod } from "../../models";
import dataLayerService from "../../services/gtm/dataLayer.service";
import * as segmentTrackService from "../../services/segment/track.service";
import AfterPayVoucherService from "../../services/voucher/afterPayVoucher.service";
import { useAlertStore } from "../../stores/alert";
import { usePaymentStore } from "../../stores/booking";
import { useBraintreeStore } from "../../stores/braintree";
import { useUserStore } from "../../stores/user";
import {
  fetchVoucherPrice,
  preselectLastPaymentMethod,
  submitVoucherOrder,
  useVoucherStore,
} from "../../stores/voucher";
import { checkIfEmpty, getValue } from "../../utils/object";
import SummaryRow from "./PurchaseSummaryRow";
import InfoModal from "../../components/Modals/InfoModal/InfoModal";
import { getFormatRespectToCountry } from "../../utils/date.util";
import { VoucherDetailsItemTypes } from "../../data/enums";

import RecipientIcon from "../../images/one-person-outline.svg";
import GiftVoucherIcon from "../../images/giftVoucher/gift-voucher.svg";
import DeliveredTo from "../../images/one-person-outline.svg";
import TimeIcon from "../../images/review-calendar-time.svg";
import CurrencyConversionInfo from "../../components/CurrencyConversionInfo";
import { formatToPrice } from "../../helpers/number";
import { COUNTRIES_CURRENCY } from "../../constants/countries";
import VoucherPaymentDeclined from "../../components/Voucher/VoucherPaymentDeclined";
import { VOUCHER_DELIVERY_STATUS } from "../../constants/voucher";
import { DEFAULT_BOOKING_DATE_FORMAT } from "../../constants/date";
import VoucherCard from "./VoucherCard/VoucherCard";
import { AlignItems, Display, FlexDirection, FontWeight, JustifyContent, Spacing } from "../../components/v2/Styled/enum";
import TextButton from "../../components/TextButton/TextButton";
import Divider from "../../components/Divider";
import pixelsService from "../../services/pixels/pixels.service";

function PaymentSection() {
  const { setVoucherPrice, voucherPrice, voucher, selectedCountry } = useVoucherStore();
  const isMobile = useMobile();

  const {
    couponCode: appliedCouponCode,
    setCouponCode: setAppliedCouponCode,
    paymentType,
    setPaymentType,
  } = usePaymentStore();

  const applied = !isEmpty(appliedCouponCode);

  const [couponCode, setCouponCode] = React.useState("");
  const { setErrorMessage } = useAlertStore();

  const getAfterpayInstallment = useCallback(() => {
    const installment = (voucherPrice?.price || 0) / 4;
    return installment.toPriceString(voucher?.currency);
  }, [voucherPrice]);

  const onCouponApply = () => {
    if (!isEmpty(appliedCouponCode)) {
      setAppliedCouponCode("");
    } else {
      if (!couponCode) {
        return setErrorMessage("Please enter coupon code.");
      }
      const accessToken = localStorage.getItem("token");
      const params = {
        country: selectedCountry,
      };
      axios
        .get(`/coupons/code/${couponCode}?accessToken=${accessToken}`, {
          params,
        })
        .then((response) => {
          const coupon = response.data as Coupon;

          if (!isNil(coupon?.id)) {
            console.debug("coupon:", coupon);
            setVoucherPrice(null);
            setAppliedCouponCode(couponCode);
          } else {
            if (response?.data.error) {
              setErrorMessage(response?.data?.error);
            }
          }
        })
        .catch((error) => {
          setErrorMessage(parseApiError(error));
        });
    }
  };

  return (
    <Box display="flex" flexDirection="column" flex={1} mt={isMobile ? "16px" : "0px"}>
      <PaymentFrame
        title="Payment"
        type={PaymentFrameType.voucher}
        selectedType={paymentType}
        onSelectedType={(type) => setPaymentType(type)}
        afterpayInstallmentAmount={getAfterpayInstallment()}
        isGiftVoucherPayment={true}
      />
      <Box height="16px" />
      <TextField
        title="Have a coupon?"
        placeholder="Enter coupon code"
        value={couponCode}
        onChange={(text = "") => setCouponCode(text.replaceAll(" ", ""))}
        actionTitle={applied ? "Remove" : "Apply"}
        actionDestructive={applied}
        onActionClicked={onCouponApply}
        disabled={applied}
      />
    </Box>
  );
}

interface RowProps {
  title: string;
  value: string;
  type: VoucherDetailsItemTypes;
  onChange?: () => unknown;
  wrapperStyle?: any;
}

const getImageSrc = (type: VoucherDetailsItemTypes) => {
  switch (type) {
  case VoucherDetailsItemTypes.giftVoucher:
    return GiftVoucherIcon;
  case VoucherDetailsItemTypes.from:
    return RecipientIcon;
  case VoucherDetailsItemTypes.deliveredTo:
    return DeliveredTo;
  case VoucherDetailsItemTypes.deliveredDate:
    return TimeIcon;
  }
};
function Row({ title, value, type, onChange, wrapperStyle = {} }: RowProps) {
  const image = getImageSrc(type);
  const isMobile = useMobile();

  const content = {
    titleFontWeight: "500",
    seperator: ": ",
    titleColor: Colors.Grey,
    imageWidth: "24px",
    imageHeight: "24px",
  };

  if (type === VoucherDetailsItemTypes.giftVoucher) {
    content.seperator = " | ";
    content.titleColor = Colors.Dusk;
    content.titleFontWeight = "500";
  } else if (type === VoucherDetailsItemTypes.deliveredDate) {
    content.imageHeight = "18px";
    content.imageWidth = "18px";
  }

  const allowChange = !!onChange;

  const valueStyles: any = {};
  if (type === VoucherDetailsItemTypes.giftVoucher) {
    valueStyles.maxWidth = isMobile ? "200px" : "290px";
  }

  return (
    <Box
      fontFamily="Museo"
      fontSize="16px"
      fontWeight={FontWeight.Regular}
      color={Colors.Dusk}
      width="100%"
      display="flex"
      alignItems="start"
      marginTop="6px"
      justifyContent={JustifyContent.spaceBetween}
      style={{ overflowWrap: "break-word", ...wrapperStyle }}
    >
      <Box display={Display.Flex} alignItems={AlignItems.start}>
        <Box
          style={{ minWidth: "24px", height: "24px" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={image}
            alt="icon"
            style={{
              width: content.imageWidth,
              height: content.imageWidth,
            }}
          />
        </Box>
        <Box width="16px" />
        <Box display="flex" flex={1} style={{ ...valueStyles }}>
          <Typography
            style={{
              fontSize: 16,
              fontFamily: "Museo",
              fontWeight: content.titleFontWeight,
            }}
          >
            <span
              style={{
                color: content.titleColor,
              }}
            >
              {title}
              {content.seperator}
            </span>
            {value}
          </Typography>
        </Box>
      </Box>
      {allowChange && <TextButton onClick={onChange} text="Change" type="primary" textStyle={{ padding: 0 }} />}
    </Box>
  );
}

function SummarySection({ voucherData }: { voucherData: object }) {
  const {
    voucher,
    recipientName,
    recipientEmail,
    yourName,
    notes,
    sendTo,
    sendASAP,
    sendOn,
    selectedCountry,
    voucherPrice,
    cardType,
  } = useVoucherStore();
  const history = useHistory();
  const isMobile = useMobile();

  const { user, currency: userCurrency, currencyCode: userCurrencyCode } = useUserStore();
  const { paymentType } = usePaymentStore();

  const voucherDeliveryStatus = getValue(voucherData, "deliveryStatus");

  const [infoModalContent, setInfoContent] = useState({
    visible: false,
    content: {
      title: "",
      description: "",
    },
  });

  const getAfterpayInstallment = useCallback(() => {
    const installment = (voucherPrice?.price || 0) / 4;
    return installment.toPriceString(voucher?.currency);
  }, [voucherPrice]);

  const getSubTotal = () => {
    const clientPlatformFee = getValue(voucherPrice, "clientPlatformFee");
    let subTotal = voucherPrice?.priceWithoutDiscount || 0;

    if (clientPlatformFee && clientPlatformFee.amount) {
      subTotal = subTotal - clientPlatformFee.amount;
    }
    return subTotal;
  };

  const { couponCode: appliedCouponCode } = usePaymentStore();

  const handleInfoClose = () => {
    setInfoContent({ ...infoModalContent, visible: false });
  };

  const showInfoModal = (title: string, description: string) => {
    setInfoContent({
      visible: true,
      content: { title, description },
    });
  };

  const platformFeeTitle = voucherPrice?.clientPlatformFee?.title || "Processing Fee";
  const platformFeeDesc = voucherPrice?.clientPlatformFee?.remarks || "";
  const subTotal = getValue(voucherPrice, "subTotal", 0);
  const convertedSubTotal = getValue(voucherPrice, "convertedSubTotal", 0);

  const onVoucherChange = () => {
    history.push(Paths.PurchaseVoucher);
  };

  const summaryWrapperStyle = {
    border: `solid 1px ${Colors.PaleLilac}`,
    padding: "16px",
  };
  const wrapperStyle = isMobile ? {} : summaryWrapperStyle;

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="16px"
        lineHeight="24px"
        color={Colors.Dusk}
        mb="16px"
      >
        Voucher summary
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        borderRadius="6px"
        bgcolor="white"
        justifyContent="center"
        style={{ ...wrapperStyle }}
      >
        <Box display={"flex"} flexDirection={"column"} padding={isMobile ? 0 : "16px"} gridGap={Spacing.S4}>
          <Box display={"flex"} flexDirection={"column"} gridGap={Spacing.S3}>
            <Row
              title={"Gift voucher"}
              value={
                voucher?.type === "custom"
                  ? voucher?.currency + voucher?.voucherValue
                  : voucher?.giftText || ""
              }
              type={VoucherDetailsItemTypes.giftVoucher}
              onChange={onVoucherChange}
            />
            <Box marginLeft={isMobile ? 0 : Spacing.S10}>
              <VoucherCard cardType={cardType} fromName={yourName} toName={recipientName} note={notes} />
            </Box>
          </Box>
          {isMobile && <Divider />}
          <Row
            title={"Deliver to"}
            value={sendTo === "recipient" ? recipientEmail : `${user?.email}`}
            type={VoucherDetailsItemTypes.deliveredTo}
          />
          {isMobile && <Divider />}

          <Row
            title={"Delivery date"}
            value={
              sendOn && sendASAP === "specificDate"
                ? moment(sendOn).format(DEFAULT_BOOKING_DATE_FORMAT)
                : "Now"
            }
            type={VoucherDetailsItemTypes.deliveredDate}
          />

          {isMobile && <Box height="1px" width="100%" bgcolor={Colors.VeryLightBlue} mt={isMobile ? 0 : 3} />}
        </Box>

        <Box display={"flex"} flexDirection={"column"} padding={isMobile ? "16px 0px" : "16px"} gridGap={"12px"}>
          <Typography style={{ color: Colors.Dusk, fontWeight: 700 }}>Summary</Typography>
          <Box>
            <SummaryRow title="Subtotal" value={`${voucher?.currency}${subTotal}`} />
            <Box style={{ marginTop: "5px" }} />
            <CurrencyConversionInfo
              convertedToCurrencyCode={userCurrencyCode}
              convertedFromCurrencyCode={COUNTRIES_CURRENCY[selectedCountry]}
              convertedCurrency={formatToPrice(convertedSubTotal, userCurrency)}
            />
          </Box>

          {(voucherPrice?.credit || 0) > 0 && (
            <SummaryRow
              title="Credit"
              value={`-${voucher?.currency}${voucherPrice?.credit.toFixed(2)}`}
            />
          )}

          {!isNil(voucherPrice?.discount) && !isEmpty(appliedCouponCode) && (
            <SummaryRow
              title={`Coupon (${appliedCouponCode})`}
              value={`-${voucher?.currency}${voucherPrice?.discount.toFixed(2)}`}
            />
          )}

          {!isEqual(voucherPrice?.clientPlatformFee?.amount, 0) &&
            !isNil(voucherPrice?.clientPlatformFee) && (
            <Box>
              <SummaryRow
                handleOnClick={() => showInfoModal(platformFeeTitle, platformFeeDesc)}
                title={platformFeeTitle}
                value={`${voucher?.currency}${
                  voucherPrice?.clientPlatformFee?.amount.toFixed(2) || `${voucher?.currency}0.0`
                }`}
              />
            </Box>
          )}
        </Box>

        <Box
          fontFamily="Museo"
          fontSize="18px"
          fontWeight={700}
          color={Colors.Dusk}
          bgcolor={Colors.PaleGrey}
          borderRadius="6px"
          padding={"0 16px"}
          mt={"8px"}
        >
          <Box display="flex" flexDirection="row" alignItems="center" height="54px">
            <Box flex={1}>Total</Box>
            <Box>
              {voucher?.currency}
              {voucherPrice?.price.toFixed(2)}
            </Box>
          </Box>
          {paymentType === PaymentType.afterpay && (
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Box
                fontFamily="Open Sans"
                fontSize="16px"
                fontWeight={400}
                lineHeight="24px"
                mr="16px"
                mb="16px"
              >
                4 payments of {getAfterpayInstallment()}
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {voucherDeliveryStatus === VOUCHER_DELIVERY_STATUS.DECLINED && (
        <Box
          border={`solid 1px ${Colors.PaleLilac}`}
          borderRadius="8px"
          padding="24px"
          mt={"26px"}
          mb={7}
        >
          <VoucherPaymentDeclined voucher={voucherData} buttonWrapper={{ marginTop: "20px" }} />
        </Box>
      )}
      <InfoModal
        handleClose={handleInfoClose}
        visible={infoModalContent.visible}
        title={infoModalContent.content.title}
        description={infoModalContent.content.description}
        divider={false}
      />
    </Box>
  );
}

export default function PurchaseVoucherPayment() {
  const location = useLocation();
  const accessToken = useAccessToken();
  const history = useHistory();
  const {
    user,
    fetchMe,
    fetchCreditBalance,
    currency: userCurrency,
    currencyCode: userCurrencyCode,
  } = useUserStore();

  const isMobile = useMobile();

  useEffect(() => {
    segmentTrackService.trackVoucherPaymentViewed();
    fetchCreditBalance();
  }, []);

  const {
    voucher,
    voucherPrice,
    recipientName,
    recipientEmail,
    yourName,
    notes,
    sendTo,
    sendASAP,
    sendOn,
    cardType,
    selectedCountry,
  } = useVoucherStore();

  const {
    paymentMethod,
    couponCode: appliedCouponCode,
    setCouponCode: setAppliedCouponCode,
  } = usePaymentStore();

  const [couponCode, setCouponCode] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [paymentSuccess, setPaymentSuccess] = React.useState(false);
  const [hasInitiatedCheckoutStarted, setHasInitiatedCheckoutStarted] = useState(false);

  const { paymentType, firstTimeUserCardRecaptchaSuccess, setPaymentMethod } = usePaymentStore();
  const { hostedFieldsInstance } = useBraintreeStore();

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  // Apple pay
  const [canMakeApplePayment, setCanMakeApplePayment] = React.useState(false);
  const [applePayInstance, setApplePayInstance] = React.useState<any>(null);
  const [initingApplePay, setInitingApplePay] = React.useState(false);

  const [voucherData, setVoucherData] = React.useState({});

  React.useEffect(() => {
    fetchMe();
    fetchVoucherPrice();

    preselectLastPaymentMethod();

    AfterPayHandler.initialize();
    initApplePay();
  }, []);

  React.useEffect(() => {
    fetchVoucherPrice();

    appliedCouponCode && setCouponCode(appliedCouponCode);
  }, [appliedCouponCode]);

  // NOTE: afterpay-voucher-success and afterpay-voucher-error routes uses this same page as well
  React.useEffect(() => {
    handleAfterPayRoutes();
  }, [location]);

  React.useEffect(() => {
    if (!isEmpty(voucherPrice) && !hasInitiatedCheckoutStarted) {
      const values = {
        value: voucherPrice?.priceWithoutDiscount,
        subtotal: voucherPrice?.price,
        currency: COUNTRIES_CURRENCY[selectedCountry] || "AUD",
      };

      pixelsService.trackInitiateCheckout(values);
      setHasInitiatedCheckoutStarted(true);
    }
  }, [voucherPrice, hasInitiatedCheckoutStarted]);

  const handleAfterPayRoutes = async () => {
    if (location.pathname.includes("/afterpay-voucher-success")) {
      setPaymentSuccess(true);
      AfterPayVoucherService.successPurchase(location.search)
        .then((resp) => {
          setAppliedCouponCode("");
          history.push(Paths.Purchases);
        })
        .catch((err) => {
          setErrorMessage(parseApiV2Error(err));
        });
    } else if (location.pathname.includes("/afterpay-voucher-error")) {
      AfterPayVoucherService.failedPurchase(location.search).catch((err) => {
        setErrorMessage(parseApiV2Error(err));
      });
    }
  };

  const initApplePay = () => {
    if (!window.ApplePaySession) {
      return;
    }

    setInitingApplePay(true);

    let path = "/paymentmethods/clientToken";

    axios
      .get(path, {
        params: {
          accessToken,
        },
      })
      .then((response) => {
        if (response.data && response.data.clientToken) {
          let token = response.data.clientToken;

          braintree.client.create(
            {
              authorization: token,
            },
            (clientErr, clientInstance) => {
              braintree.applePay
                .create({
                  client: clientInstance,
                })
                .then((applePayInstance) => {
                  setApplePayInstance(applePayInstance);

                  console.debug("merchant id: ", applePayInstance.merchantIdentifier);

                  window.ApplePaySession.canMakePaymentsWithActiveCard(
                    applePayInstance.merchantIdentifier,
                  )
                    .then((canMakePaymentsWithActiveCard: boolean) => {
                      console.debug(
                        "canMakePaymentsWithActiveCard: ",
                        canMakePaymentsWithActiveCard,
                      );

                      setCanMakeApplePayment(canMakePaymentsWithActiveCard);

                      setInitingApplePay(false);
                    })
                    .catch((error: any) => {
                      setInitingApplePay(false);

                      console.debug("canMakePaymentsWithActiveCard error", error);
                    });
                });
            },
          );
        }
      });
  };

  const onPurchase = () => {
    setSubmitting(true);

    // Credit card - first time user
    if (paymentType === PaymentType.card) {
      if (hostedFieldsInstance && !paymentMethod) {
        if (!firstTimeUserCardRecaptchaSuccess) {
          setSubmitting(false);
          setErrorMessage("Please complete reCAPTCHA");
          return;
        }

        // console.debug("instance: ", hostedFieldsInstance);
        hostedFieldsInstance
          .tokenize()
          .then((payload) => {
            const { nonce, details } = payload;
            const { lastFour, expirationMonth, expirationYear, cardType } = details;

            axios
              .post("/paymentmethods", {
                accessToken,
                nonce,
                last4: lastFour,
                expiryMonth: expirationMonth,
                expiryYear: expirationYear,
                cardType,
              })
              .then((response) => {
                const paymentMethod = response.data as PaymentMethod;

                actuallyMakePurchase(paymentMethod.id);
              })
              .catch((error) => {
                setSubmitting(false);

                console.debug("add payment method error: ", error);

                setErrorMessage(parseApiError(error));
              });
          })
          .catch((error) => {
            setSubmitting(false);

            console.debug("tokenize error: ", error);
            setErrorMessage("Error with your card");
          });
      } else {
        actuallyMakePurchase();
      }

      return;
    }

    // Apple pay
    if (paymentType === PaymentType.applepay) {
      if (!canMakeApplePayment) {
        setSubmitting(false);
        setErrorMessage("You don't have any cards linked with Apple Pay");
        return;
      }

      let label = "Get Blys Pty Ltd";

      let paymentRequest = applePayInstance.createPaymentRequest({
        total: {
          label: label,
          amount: voucherPrice?.price,
        },

        // We recommend collecting billing address information, at minimum
        // billing postal code, and passing that billing postal code with
        // all Apple Pay transactions as a best practice.
        //requiredBillingContactFields: ['postalAddress']
      });
      console.log(paymentRequest.countryCode);
      console.log(paymentRequest.currencyCode);
      console.log(paymentRequest.merchantCapabilities);
      console.log(paymentRequest.supportedNetworks);

      let session = new window.ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = (event: any) => {
        applePayInstance
          .performValidation({
            validationURL: event.validationURL,
            displayName: label,
          })
          .then((merchantSession: any) => {
            console.debug("merchantSession:", merchantSession);

            session.completeMerchantValidation(merchantSession);
          })
          .catch((validationErr: any) => {
            // You should show an error to the user, e.g. 'Apple Pay failed to load.'
            console.error("Error validating merchant:", validationErr);
            setSubmitting(false);
            session.abort();
          });
      };

      session.onpaymentauthorized = (event: any) => {
        console.log("Your shipping address is:", event.payment.shippingContact);

        applePayInstance
          .tokenize({
            token: event.payment.token,
          })
          .then((payload: any) => {
            // Send payload.nonce to your server.
            console.log("nonce:", payload.nonce);

            // If requested, address information is accessible in event.payment
            // and may also be sent to your server.
            // console.log('billingPostalCode:', event.payment.billingContact.postalCode);

            // After you have transacted with the payload.nonce,
            // call `completePayment` to dismiss the Apple Pay sheet.
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);

            // window.location.href = `${APPLE_PAY_SUCCESS_APP_REDIRECT_URL}?nonce=${payload.nonce}`;

            // alert("nonce: " + payload.nonce);

            let path = "/paymentmethods";

            axios
              .post(path, {
                nonce: payload.nonce,
                accessToken: accessToken,
                type: "apple-pay",
              })
              .then((response) => {
                let paymentMethod = response.data as PaymentMethod;

                setPaymentMethod(paymentMethod);

                actuallyMakePurchase(paymentMethod.id);
              });
          })
          .catch((tokenizeErr: any) => {
            console.error("Error tokenizing Apple Pay:", tokenizeErr);
            setSubmitting(false);
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
          });
      };

      session.begin();
      return;
    } else if (paymentType === PaymentType.afterpay && (voucherPrice?.price || 0) > 0) {
      handleAfterPayVoucherPurchase()
        .then(() => {
          setSubmitting(false);
        })
        .catch((err) => {
          setErrorMessage(parseApiV2Error(err));
          setSubmitting(false);
        });

      return;
    }

    // Others - just proceed
    actuallyMakePurchase();
  };

  const handleAfterPayVoucherPurchase = async () => {
    let payload = {
      selectedVoucher: voucher,
      couponCode: couponCode ? couponCode : null,
      currency: "AUD",
      fromName: yourName,
      toName: recipientName,
      massageDuration: voucher?.massageDuration,
      note: notes,
      recipientEmail: recipientEmail,
      sendASAP: sendASAP,
      sendOnDate: sendOn,
      sendTo: sendTo,
      sessionType: voucher?.sessionType,
      voucherType: voucher?.voucherValue,
      cardType,
    };

    let purchaseOrderResponse = await AfterPayVoucherService.purchase(payload);
    let { token } = purchaseOrderResponse.data;

    AfterPayHandler.redirectToAfterPay("AU", token);
  };

  const actuallyMakePurchase = (overridePaymentMethodId?: number) => {
    submitVoucherOrder(overridePaymentMethodId)
      .then((purchase: any) => {
        dataLayerService.trackVoucherOrderCompleted(purchase);
        setSubmitting(false);

        const subtotal = voucherPrice?.priceWithoutDiscount.toFixed(2);
        const total = voucherPrice?.priceWithoutDiscount.toFixed(2);

        segmentTrackService.trackVoucherPaymentCompleted(
          subtotal,
          total,
          voucher?.text,
          purchase.couponId,
          purchase.price,
          voucher?.maxNumberOfUseForAllUsers,
        );

        // Facebook Pixel - Purchase
        const purchaseData = {
          value: purchase.priceWithoutDiscount,
          subtotal: purchase.price,
          currency: COUNTRIES_CURRENCY[selectedCountry] || "AUD",
        };

        pixelsService.trackPurchase(purchaseData);

        if (!checkIfEmpty(purchase)) {
          setVoucherData(purchase);
          setAppliedCouponCode("");

          if (purchase.deliveryStatus === VOUCHER_DELIVERY_STATUS.DECLINED) {
            setErrorMessage("The payment for the voucher purchase has failed.");
            return;
          }

          setSuccessMessage("The payment for the voucher purchase has been successful.");
          history.push(Paths.Purchases);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setErrorMessage(error);
      });
  };

  const deliveryStatus = getValue(voucherData, "deliveryStatus", "");

  return (
    <>
      <LandingWrapper showPromoImages={false}>
        <MetaTags />
        <Box width={isMobile ? undefined : "1024px"}>
          <LogoLink />
          <Typography
            variant="h1"
            style={{
              color: Colors.NightBlue,
              marginTop: "48px",
              marginBottom: "32px",
            }}
          >
            Blys voucher
          </Typography>
          {isMobile && (
            <>
              <SummarySection voucherData={voucherData} />
              <PaymentSection />
              {paymentSuccess ? (
                <p>Payment Successful, Redirecting to Purchases shortly</p>
              ) : (
                <>
                  {deliveryStatus !== VOUCHER_DELIVERY_STATUS.DECLINED && (
                    <Box
                      ml="auto"
                      mr="auto"
                      maxWidth="500px"
                      paddingTop={Spacing.S6}
                      paddingBottom={Spacing.S6}
                    >
                      <Button
                        loading={submitting}
                        width="100%"
                        type={ButtonType.secondary}
                        size={ButtonSize.large}
                        title="Purchase"
                        onClick={onPurchase}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          )}

          {!isMobile && (
            <Box display="flex" flexDirection="row">
              <PaymentSection />
              <Box width="24px" />
              <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S6}>
                <SummarySection voucherData={voucherData} />
                {paymentSuccess ? (
                  <p>Payment Successful, Redirecting to Purchases shortly</p>
                ) : (
                  <Box>
                    {deliveryStatus !== VOUCHER_DELIVERY_STATUS.DECLINED && (
                      <Box maxWidth="500px" ml="auto" mr="auto">
                        <Button
                          loading={submitting}
                          width="100%"
                          type={ButtonType.secondary}
                          size={ButtonSize.large}
                          title="Purchase"
                          onClick={onPurchase}
                        />

                        <Box mt="8px" mb="55px">
                          <Button
                            type={ButtonType.outlined}
                            title={"Back"}
                            onClick={() => {
                              history.push(Paths.PurchaseVoucher);
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box height="24px" />
            </Box>
          )}
        </Box>
      </LandingWrapper>
    </>
  );
}
