import React from "react";
import queryString from "query-string";
import { Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { Recipient } from "../../../models";
import { useMobile } from "../../../hooks/mobile";
import SegmentedControl from "../../../components/SegmentedControl";
import RecipientPastBookings from "../components/RecipientPastBookings";
import RecipientUpcomingBookings from "../components/RecipientUpcomingBookings";

interface RecipientBookingsSectionProps {
  recipient: Recipient;
}
const bookingSegments = [
  { title: "Upcoming", value: "upcoming" },
  { title: "Past booking", value: "past" },
];

const getSegmentIndex = (value: string) => {
  const index = bookingSegments.findIndex((i) => value === i.value);

  return index < 1 ? 0 : index;
};

const RecipientBookingsSection: React.FC<RecipientBookingsSectionProps> = ({ recipient }) => {
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search) as Record<
    string,
    string | number | undefined | string[]
  >;

  const currentSegment = query.section || "upcoming";
  const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState(
    getSegmentIndex(currentSegment as string)
  );

  if (!recipient) {
    return null;
  }

  return (
    <Box
      marginTop="43px"
      paddingLeft={isMobile ? "16px" : "80px"}
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
    >
      <SegmentedControl
        segments={bookingSegments.map((i) => i.title)}
        selectedSegmentIndex={selectedSegmentIndex}
        onSelectedIndex={(index) => {
          history.push(`?section=${bookingSegments[index].value}`);
          setSelectedSegmentIndex(index);
        }}
      />
      {selectedSegmentIndex === 0 && <RecipientUpcomingBookings recipient={recipient} />}
      {selectedSegmentIndex === 1 && <RecipientPastBookings recipient={recipient} />}
    </Box>
  );
};

export default RecipientBookingsSection;
