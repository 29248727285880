import { checkIfEqual, getValue } from "../../utils/object";
import { IFormData } from "../../pages/ProDashboard/Business/Payouts/Forms/model";
import { getPayloadForAccountUpdate } from "./transformer/stripeTransformer.service";

const checkIfDetailsUpdated = ({
  data,
  payoutDetails,
  checkExplicitlyForIDDocument = false,
}: {
  data: IFormData;
  payoutDetails: IFormData;
  checkExplicitlyForIDDocument?: boolean;
}) => {
  const dataPayload = getPayloadForAccountUpdate(data);
  const payoutDetailsPayload = getPayloadForAccountUpdate(payoutDetails);

  const hasDataChanged = !checkIfEqual(dataPayload, payoutDetailsPayload);

  return checkExplicitlyForIDDocument
    ? hasDataChanged || !!getValue(data, "identityVerification.documentPath")
    : hasDataChanged;
};
export { checkIfDetailsUpdated };
