import { PaymentType } from "../constants/payment";
import { PaymentMethod } from "../models";
import AfterPayIcon from "../images/afterpay.png";
import PayWithNdisIcon from "../images/pay-with-ndis.svg";
import PayWithInvoiceIcon from "../images/pay-with-invoice.svg";
import ApplePayIcon from "../images/payment-section-applepay.svg";

export const getPaymentMethodDetails = ({
  paymentType,
  paymentMethod,
}: {
  paymentType: string | undefined;
  paymentMethod: PaymentMethod;
}) => {

  switch (paymentType) {
  case "applepay" as PaymentType: // "applepay for old data"
  case  PaymentType.applepay:
    return {
      icon: ApplePayIcon,
      width: "40px",
      height: "24px",
    };
  case PaymentType.afterpay:
    return {
      icon: AfterPayIcon,
      width: "88px",
      height: "24px",
    };
  case PaymentType.payWithNDISFunding:
    return {
      icon: PayWithNdisIcon,
      width: "40px",
      height: "28px",
    };

  case PaymentType.payWithInvoice:
    return {
      icon: PayWithInvoiceIcon,
      width: "24px",
      height: "28px",
    };

  case PaymentType.googlepay:
  case PaymentType.card:
  case PaymentType.paypal:
    return {
      icon: PaymentMethod.iconFor(paymentMethod),
      width: "37.5px",
      height: "24px",
    };

  default:
    return {
      icon: PayWithNdisIcon,
      width: "37.5px",
      height: "24px",
    };
  }
};
