import React from "react";
import { Box, IconButton } from "@material-ui/core";

import CloseIcon from "../../../images/modal-close.svg";
import { Colors } from "../../../constants/colors";

interface Props {
  title: string;
  subtitle?: string;
  onClose: () => unknown;
}

const ProviderInfoModalHeader = ({ title, onClose }: Props) => {
  return (
    <Box>
      <Box>
        <Box
          height="70px"
          alignItems="center"
          justifyContent="center"
          display="flex"
          position="relative"
          paddingLeft="20px"
          paddingRight="20px"
        >
          <Box position="absolute" right="10px" top="10px">
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt="closeIcon" />
            </IconButton>
          </Box>

          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="16px"
            color={Colors.NightBlue}
            alignSelf="center"
            width="fit-content"
          >
            {title}
          </Box>
        </Box>
      </Box>
      <Box width="100%" height="1px" bgcolor={Colors.PaleLilac} />
    </Box>
  );
};

export default ProviderInfoModalHeader;
