import React, { useEffect, useState } from "react";
import { Box, BoxProps } from "@material-ui/core";
import { FormStepsName, IFormData } from "./model";
import Each from "../../../../../components/Each";
import Button from "../../../../../components/Button";
import { getValue } from "../../../../../utils/object";
import { useMobile } from "../../../../../hooks/mobile";
import { Colors } from "../../../../../constants/colors";
import { Text } from "../../../../../components/v2/Styled";
import StatusPill from "../../../../../components/StatusPill";
import * as Styled from "../../../../../components/v2/Styled/enum";
import TextButton from "../../../../../components/TextButton/TextButton";
import Row from "../../../../../components/ProDashboard/Business/Payouts/utils/Row";
import CardWrapper from "../../../../../components/BookingDetails/BookingDetailCard";
import { getBtnTextForPayoutReview } from "../../../../../services/proDashboard/payouts.service";
import StripeTos from "../../../../../components/ProDashboard/Business/Payouts/CoBranding/StripeTOS";
import { checkIfDetailsUpdated } from "../../../../../services/proDashboard/stripeDetailCheck.service";
import PayoutAccountCard from "../../../../../components/ProDashboard/Business/Payouts/PayoutAccountCard";
import { CoBrandingActionModal } from "../../../../../components/ProDashboard/Business/Payouts/CoBranding";
import PayoutIdVerificationCard from "../../../../../components/ProDashboard/Business/Payouts/PayoutIDVerificationCard";
import {
  transformAddress,
  transformIdentityVerification,
  transformPayoutAccount,
  transformPersonalDetails,
} from "../../../../../services/proDashboard/transformer/payoutReviewTransformer.service";

interface Props {
  data: IFormData;
  isUpdating: boolean;
  isForUpdate: boolean;
  payoutDetails: IFormData;
  highlights: FormStepsName[];
  invalidateData: () => unknown;
  onFormSubmit: () => unknown;
  onChange: (s: FormStepsName) => unknown;
  isInitialOnboardingCompleted: boolean;
}

const PayoutReviewForm = ({
  data,
  onChange,
  highlights,
  isUpdating,
  isForUpdate,
  onFormSubmit,
  payoutDetails,
  invalidateData = () => {},
  isInitialOnboardingCompleted,
}: Props): JSX.Element => {
  const isMobile = useMobile();
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState<boolean>(false);

  const { personalDetails, address, identityVerification, accountForPayout } = data;

  useEffect(() => {
    invalidateData();
  }, []);

  const transformedAddress = transformAddress(address);
  const transformedPayoutAccount = transformPayoutAccount(accountForPayout);
  const transformedPersonalDetail = transformPersonalDetails(personalDetails);
  const transformedIdentityVerification = transformIdentityVerification(identityVerification);
  const hasProvidedIDDocument = getValue(identityVerification, "documentProvided");

  const handleSubmit = () =>
    isInitialOnboardingCompleted ? setShowConfirmSubmitModal(true) : onFormSubmit();

  const Title = (props: BoxProps) => (
    <Box
      color={Colors.Dusk}
      fontSize={Styled.FontSize.F16}
      fontFamily={Styled.FontFamily.Museo}
      fontWeight={Styled.FontWeight.Bold}
      lineHeight={isMobile ? Styled.LineHeight.L16 : Styled.LineHeight.L24}
      {...props}
    />
  );

  const TBox = (props: BoxProps) => (
    <Box display={Styled.Display.Flex} flexDirection={Styled.FlexDirection.Column} {...props} />
  );

  const ChangeButton = (props: any) => <TextButton type={"primary"} {...props} />;

  const buildPersonalDetail = (item: { label: string; value: string }) => (
    <Row label={item.label} value={item.value} />
  );

  const cardDefaultStyle = { width: "auto", margin: Styled.Spacing.S0, padding: Styled.Spacing.S4 };

  const COMPONENT_MAP: Record<FormStepsName, Record<string, any>> = {
    [FormStepsName.PersonalDetails]: {
      hideFromUI: false,
      title: "Bank account holder details",
      canChange: true,
      Component: (
        <TBox gridGap={Styled.Spacing.S1}>
          <Each of={transformedPersonalDetail} render={buildPersonalDetail} />
        </TBox>
      ),
    },
    [FormStepsName.Address]: {
      hideFromUI: false,
      title: "Address",
      canChange: true,
      Component: (
        <Text color={Colors.Grey} size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21}>
          {transformedAddress || "No address added"}
        </Text>
      ),
    },
    [FormStepsName.IdentityVerification]: {
      hideFromUI: false,
      title: "Identity verification",
      canChange: !hasProvidedIDDocument,
      Component: (
        <PayoutIdVerificationCard
          details={transformedIdentityVerification}
          hasProvidedIDDocument={hasProvidedIDDocument}
        />
      ),
    },
    [FormStepsName.AccountForPayout]: {
      hideFromUI: false,
      canChange: true,
      title: "Account details",
      Component: (
        <PayoutAccountCard
          details={transformedPayoutAccount.details}
          account={transformedPayoutAccount}
        />
      ),
    },
    [FormStepsName.ReviewAndConfirm]: {
      canChange: false,
      hideFromUI: true,
      title: null,
      Component: null,
    },
  };

  const buildRow = (step: FormStepsName) => {
    const title = COMPONENT_MAP[step].title;
    const Component = COMPONENT_MAP[step].Component;
    const hideFromUI = getValue(COMPONENT_MAP[step], "hideFromUI", false);
    const canChange = getValue(COMPONENT_MAP[step], "canChange", false);

    const showHighlight = (highlights || []).includes(step);
    const actionBtnText = getBtnTextForPayoutReview({ data, formStepName: step });

    if (hideFromUI) {
      return <></>;
    }

    return (
      <TBox gridGap={Styled.Spacing.S2}>
        <Box display={Styled.Display.Flex} gridGap={Styled.Spacing.S1}>
          <Box
            flex={1}
            gridGap={Styled.Spacing.S2}
            display={Styled.Display.Flex}
            flexWrap={Styled.FlexWrap.Wrap}
            alignItems={Styled.AlignItems.center}
          >
            <Title>{title}</Title>
            {showHighlight ? <StatusPill text={"Action required"} /> : <></>}
          </Box>
          {canChange ? (
            <Box justifyContent={Styled.JustifyContent.end}>
              <ChangeButton
                text={actionBtnText}
                onClick={() => onChange(step)}
                textStyle={{ fontWeight: Styled.FontWeight.Medium }}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <CardWrapper style={cardDefaultStyle}>{Component}</CardWrapper>
      </TBox>
    );
  };

  const hasDetailsChanged = checkIfDetailsUpdated({
    data,
    payoutDetails,
    checkExplicitlyForIDDocument: true,
  });

  return (
    <TBox gridGap={Styled.Spacing.S4}>
      <Each of={Object.keys(COMPONENT_MAP)} render={buildRow} />
      <StripeTos />
      <Box>
        <Button
          loading={isUpdating}
          onClick={handleSubmit}
          title={"Confirm & submit"}
          width={isMobile ? "100%" : "auto"}
          disabled={!hasDetailsChanged}
          style={{
            minWidth: "184px",
            marginTop: Styled.Spacing.S4,
            padding: `${Styled.Spacing.S3} ${Styled.Spacing.S6}`,
          }}
        />
      </Box>
      <CoBrandingActionModal
        open={showConfirmSubmitModal}
        onClose={() => setShowConfirmSubmitModal(false)}
        onConfirm={() => {
          onFormSubmit();
          setShowConfirmSubmitModal(false);
        }}
        title={"Changing information may require an account verification. Proceed anyways?"}
        description={
          "Some information updates trigger account verifications and introduce new account requirements. If additional information is requested, make sure to provide it to avoid any disruptions to charges and payouts."
        }
      />
    </TBox>
  );
};

export default PayoutReviewForm;
