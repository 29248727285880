import { Box } from "@material-ui/core";
import { OfferedAlternativeDate } from "../../../models";
import Button, { ButtonType } from "../../../components/Button";
import { useEffect, useState } from "react";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { formatDateWithTimezone } from "../../../utils/date.util";
import { Colors } from "../../../constants/colors";
import DateTimeIcon from "../../../images/review-calendar-time.svg";
import { DEFAULT_BOOKING_DATETIME_FORMAT } from "../../../constants/date";

interface Props {
  offer: OfferedAlternativeDate;
  timezone: string;
  onRemoveOffer: ({ offerId }: { offerId: number }) => unknown;
  onUpdateOffer: ({ offerId }: { offerId: number }) => unknown;
  currency: string;
}

const OfferedAlternative = ({ offer, timezone, onRemoveOffer, onUpdateOffer, currency }: Props) => {
  const [offeredDate, setOfferedDate] = useState<null | string>(null);
  useEffect(() => {
    if (checkIfEmpty(offer)) return;

    let offeredTime = offer.time;
    offeredTime = formatDateWithTimezone(offeredTime, timezone, DEFAULT_BOOKING_DATETIME_FORMAT);
    setOfferedDate(offeredTime);
  }, [offer, timezone]);

  const handleRemoveOffer = () => onRemoveOffer({ offerId: offer.id });

  const handleUpdateOffer = () => onUpdateOffer({ offerId: offer.id });
  const preferredPayout = getValue(offer, "preferredPayout");

  if (!offeredDate) return <></>;

  return (
    <Box style={{ ...styles.wrapper, flexDirection: "column" }}>
      <Box>
        <Box style={styles.headerWrapper}>
          <Box>
            <img src={DateTimeIcon} alt="Date icon" />
          </Box>

          <Box>
            <Box style={styles.header}>Alternative date and time you offered</Box>
            <Box style={styles.dateTime}>{offeredDate}</Box>
          </Box>
        </Box>
      </Box>
      {preferredPayout && (
        <Box>
          <Box style={styles.headerWrapper}>
            <Box sx={{ width: "16px" }}></Box>
            {/* 
            to be finalized fro icon
            <Box>
              <img src={DateTimeIcon} alt="Date icon" />
            </Box> */}

            <Box>
              <Box style={styles.header}>Rate you offered</Box>
              <Box style={styles.dateTime}>
                {currency}&nbsp;
                {preferredPayout}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box style={styles.info}>
        Your alternative date and time has been offered to the client. If the client accepts your
        offer, the booking will be confirmed and added to your Upcoming bookings.
      </Box>
      <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        <Button type={ButtonType.outlined} title="Update offer" onClick={handleUpdateOffer} />
        <Button type={ButtonType.outlined} title="Remove offer" onClick={handleRemoveOffer} />
      </Box>
    </Box>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    gap: "10px",
  },
  prodInfo: {
    color: Colors.Grey,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  info: {
    color: Colors.Grey,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "5px",
    marginBottom: "15px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "start",
    gap: "15px",
  },
  header: {
    color: Colors.Grey,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 500,
  },
  dateTime: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Museo",
    color: Colors.Dusk,
  },
};

export default OfferedAlternative;
