import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, BoxProps } from "@material-ui/core";
import { UseQueryResult } from "@tanstack/react-query";
import Each from "../../../../Each";
import Divider from "../../../../Divider";
import StatusPill from "../../../../StatusPill";
import StripeCoBranding from "./StripeCoBranding";
import ActionModal from "../../../../ActionModal";
import * as Styled from "../../../../v2/Styled/enum";
import { Paths } from "../../../../../constants/paths";
import Button, { ButtonType } from "../../../../Button";
import AdditionalInfoModal from "./AdditionalInfoModal";
import { Colors } from "../../../../../constants/colors";
import { useUserStore } from "../../../../../stores/user";
import { getFeedbackURL } from "../../../../../services/feedback";
import { checkIfEmpty, getValue } from "../../../../../utils/object";
import CardWrapper from "../../../../BookingDetails/BookingDetailCard";
import { AdditionalInformation } from "../../../../../pages/ProDashboard/Business/Payouts/Forms/model";
import { useStripeAccountRequirements } from "../../../../../hooks/stripe/connect/stripeConnect.hooks";

interface Props {
  accountStatus?: {
    status: string;
    accountStatus: any;
  };
  accountRequirements: UseQueryResult;
}

const UpdateInfoCoBranding = ({ accountStatus, accountRequirements }: Props): JSX.Element => {
  const history = useHistory();
  const { user } = useUserStore();

  const [infoModalData, setInfoModalData] = useState({
    isVisible: false,
    title: "Contact the admin team",
    description:
      "Certain information cannot be collected through the app due to privacy concerns. Please contact the admin team to securely submit it.",
  });

  const [additionalInfoData, setAdditionalInfoData] = useState({
    key: "",
    name: "",
    title: "",
    description: "",
    isVisible: false,
  });

  const { isLoading: isRequirementsLoading, data: accountRequirementsData } = accountRequirements;

  const toCollect = getValue(accountRequirements, "data.toCollect", []);
  const isRequirementEmpty = checkIfEmpty(toCollect);

  const TBox = (props: BoxProps) => (
    <Box display={Styled.Display.Flex} flexDirection={Styled.FlexDirection.Column} {...props} />
  );

  const resetInfoModalData = () =>
    setAdditionalInfoData({
      key: "",
      title: "",
      name: "",
      description: "",
      isVisible: false,
    });

  const handleInfoModalAction = (action = "") => {
    if (action !== "close") {
      const mailURL = getFeedbackURL(user, "Contact Admin: Additional Stripe Info Needed");
      window.open(mailURL, "_blank");
    }
    setInfoModalData({
      ...infoModalData,
      isVisible: false,
    });
  };

  const buildRow = (item: any, idx: number, arr: Array<any>) => {
    const key = getValue(item, "key");
    const name = getValue(item, "name", "");
    const title = getValue(item, "title", "");
    const dueText = getValue(item, "dueText", "");
    const description = getValue(item, "description", "");

    const handleClick = () => {
      if (
        [
          AdditionalInformation.ExternalAccount,
          AdditionalInformation.PersonalDetails,
          AdditionalInformation.AddressVerification,
        ].includes(name)
      ) {
        history.push(Paths.ProDashboardBusinessPayoutInformation, {
          accountStatus,
          accountRequirementsData,
        });
      } else if (name === AdditionalInformation.Other) {
        setInfoModalData({
          ...infoModalData,
          isVisible: true,
        });
      } else {
        setAdditionalInfoData({
          key,
          title,
          name,
          description,
          isVisible: true,
        });
      }
    };

    return (
      <TBox gridGap={Styled.Spacing.S6}>
        <TBox gridGap={Styled.Spacing.S4}>
          <TBox gridGap={Styled.Spacing.S2}>
            <TBox gridGap={Styled.Spacing.S2}>
              <Box
                display={Styled.Display.Flex}
                justifyContent={Styled.JustifyContent.spaceBetween}
              >
                <Box
                  color={Colors.Dusk}
                  fontSize={Styled.FontSize.F18}
                  lineHeight={Styled.LineHeight.L27}
                  fontWeight={Styled.FontWeight.Bold}
                  fontFamily={Styled.FontFamily.Museo}
                >
                  {title}
                </Box>
                <Box>
                  <StatusPill
                    text={dueText}
                    bgColor={Colors.BrightGray}
                    fontColor={Colors.Tomato}
                  />
                </Box>
              </Box>
            </TBox>
            {description ? (
              <Box
                color={Colors.Dusk}
                fontSize={Styled.FontSize.F14}
                lineHeight={Styled.LineHeight.L21}
                fontFamily={Styled.FontFamily.OpenSans}
              >
                {description}
              </Box>
            ) : (
              <></>
            )}
          </TBox>
          <Box>
            <Button
              onClick={handleClick}
              title={"Add information"}
              type={ButtonType.secondary}
              style={{ width: "auto", padding: "8px 16px" }}
            />
          </Box>
        </TBox>
        {arr.length - 1 !== idx && arr.length !== 1 ? <Divider /> : <></>}
      </TBox>
    );
  };

  return (
    <CardWrapper
      style={{
        gap: 0,
        margin: 0,
        padding: 0,
        width: "100%",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      {!isRequirementsLoading && !isRequirementEmpty ? (
        <TBox gridGap={Styled.Spacing.S6} padding={`${Styled.Spacing.S4} ${Styled.Spacing.S6}`}>
          <Each of={toCollect} render={buildRow} />
        </TBox>
      ) : (
        <></>
      )}
      <Box
        bgcolor={isRequirementEmpty ? Colors.White : Colors.PaleGrey}
        padding={`${Styled.Spacing.S4} ${Styled.Spacing.S6}`}
        borderTop={isRequirementEmpty ? "" : `1px solid ${Colors.LightPeriwinkle}`}
      >
        <StripeCoBranding />
      </Box>
      <AdditionalInfoModal
        data={additionalInfoData}
        onClose={resetInfoModalData}
        open={additionalInfoData.isVisible}
      />
      <ActionModal
        confirmText={"Contact"}
        title={infoModalData.title}
        open={infoModalData.isVisible}
        onConfirm={handleInfoModalAction}
        description={infoModalData.description}
        onCancel={() => handleInfoModalAction("close")}
      />
    </CardWrapper>
  );
};

export default UpdateInfoCoBranding;
