import { BASE_UPLOADS_URL } from "../constants/common";

const formatUrl = (url: string) => {
  if (!url) return "";
  if (url.startsWith("https://") || url.startsWith("http://")) {
    return url;
  }
  return `https://${url}`;
};

const getFullUploadsUrl = (path: string) => {
  if (path.startsWith(BASE_UPLOADS_URL)) {
    return path;
  }
  return `${BASE_UPLOADS_URL}/${path}`;
};

const isAUDomain = () => window.location.hostname.endsWith(".au");

const urlPattern =
  /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|me|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

const whiteListedUrlsForChat = [
  /^((https?:\/\/)?([a-zA-Z0-9-]+\.)?getblys\.com(\/.*)?)$/,
  /^((https?:\/\/)?([a-zA-Z0-9-]+\.)?getblys\.com.au(\/.*)?)$/,
  /^((https?:\/\/)?(www\.)?)?google\.(com|com\.au|ca|co\.uk|[a-z]{2,3})(\/.*)?$/,
  /^((https?:\/\/)?(www\.)?)?meet\.google\.com(\/.*)?$/,
  /^((https?:\/\/)?(www\.)?)?maps\.google\.com(\/.*)?$/,
  /^((https?:\/\/)?(www\.)?)?apple\.com\/maps(\/.*)?$/,
  /^((https?:\/\/)?(www\.)?)?maps\.apple\.com(\/.*)?$/,
  /^((https?:\/\/)?(www\.)?)?waze\.com(\/.*)?$/,
  /^((https?:\/\/)?(www\.)?)?youtube\.com(\/.*)?$/,
  /^((https?:\/\/)?([a-zA-Z0-9-]+\.)?)?spotify\.com(\/.*)?$/,
  /^((https?:\/\/)?(www\.)?)?maps.app\.goo\.gl(\/.*)?$/,
  /^((https?:\/\/)?(www\.)?)?yes\.no(\/.*)?$/i,
];

function getUrlsFromText(text: string) {
  return text.match(urlPattern) || [];
}
export {
  formatUrl,
  getFullUploadsUrl,
  isAUDomain,
  whiteListedUrlsForChat,
  urlPattern,
  getUrlsFromText,
};
