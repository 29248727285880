import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useBookingStore } from "../../stores/booking";
import { parseQueryString } from "../../helpers/string";
import { checkIfEmpty, getValue } from "../../utils/object";
import { transformSourceDetails } from "../../stores/V2/booking/booking.transformer";

const TrackBookingSource = () => {
  const { search } = useLocation();
  const { setSourceDetails } = useBookingStore();

  useEffect(() => {
    setNewSourceDetails();
  }, []);

  const setNewSourceDetails = () => {
    try {
      const params = parseQueryString(search);
      if (!checkIfEmpty(params)) {
        const source = transformSourceDetails(params);
        setSourceDetails(source);
        const trackSource = getValue(source, "source");
        // use localstorage to access the bookingSource in pixel services
        localStorage.setItem("bookingSource", trackSource);
      }
    } catch (e) {
      console.error("Error in setting source details", e);
    }
  };

  return null;
};

export default TrackBookingSource;
