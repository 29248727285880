import React, { useState } from "react";

import Each from "../../../components/Each";
import { useMobile } from "../../../hooks/mobile";
import Plus from "../../../images/plus-white.svg";
import { Colors } from "../../../constants/colors";
import { useAlertStore } from "../../../stores/alert";
import { parseApiV2Error } from "../../../helpers/error";
import { Preferences, Recipient } from "../../../models";
import { Box, Text } from "../../../components/v2/Styled";
import { checkIfEmpty, getValue } from "../../../utils/object";
import RecipientPreferenceModals from "./modals/RecipientPreferenceModal";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import { FilterButton } from "../../../components/FilterButton/FilterButton";
import NoNotesPlaceholder, { NoNotesPlaceholderType } from "./NoNotesPlaceholder";
import ClientTreatmentNote from "../../../components/Cards/TreatmentNoteCard/ClientTreatmentNoteCard";
import { FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../../../components/v2/Styled/enum";
import { useAddRecipientPreference, useDeleteRecipientPreference, useGetRecipientClientNotes } from "../../../hooks/recipient/recipient.hooks";
import { FilterNoteValue, NoteFilterModal } from "./modals/SortAndFilterNoteModal";

interface Props {
  recipient: Recipient;
  switchView: (index: number) => unknown;
}

const RecipientClientNotes = ({ recipient, switchView }: Props) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const [selectedInstruction, setSelectedInstruction] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState<FilterNoteValue>({});

  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const isMobile = useMobile();

  const { data, isLoading } = useGetRecipientClientNotes(recipient.id, { search: searchQuery });

  const { mutate: addPreferences, isLoading: addingPreference } = useAddRecipientPreference({
    onSuccess: () => {
      setShowAddModal(false);
      setSuccessMessage("Added preference for the recipient");
    },
    onError: (err: any) => {
      setErrorMessage(parseApiV2Error(err) || "Failed to add preference.");
    },
  });

  const { mutate: deletePreferences, isLoading: deletingPreference } = useDeleteRecipientPreference({
    onSuccess: () => {
      setSuccessMessage("Deleted preference for the recipient");
    },
    onError: (err: any) => {
      setErrorMessage(parseApiV2Error(err) || "Failed to delete preference.");
    },
  });

  const clientNotes = getValue(data, "data", []);
  
  const onNoteEdit = (preference: any) => {
    setSelectedInstruction(preference);
    setShowAddModal(true);
  };

  const buildNotes = (preferences: Preferences) => {
    return (
      <ClientTreatmentNote
        preferences={preferences}
        onEditClicked={onNoteEdit}
        onDeleteNote={deletePreferences}
        fullwidth
        showDivider
      />
    );
  };

  const getFilterCount = () => {
    let keys = Object.keys(searchQuery);
    if (searchQuery.sortBy === "asc") {
      return keys.length;
    } else {
      return keys.filter((val) => val !== "sortBy").length;
    }
  };

  return (
    <Box gap={Spacing.S6} marginY={Spacing.S11} direction={FlexDirection.Column} width="936px">
      <Text
        font={FontFamily.Museo}
        weight={FontWeight.Bold}
        size={FontSize.F24}
        color={Colors.NightBlue}
      >
        Client notes
      </Text>
      <Text
        font={FontFamily.Museo}
        weight={FontWeight.Medium}
        size={FontSize.F16}
        color={Colors.Dusk}
      >
        Share preferences, allergies, injuries, or any relevant details for each service type of the recipients. This information is included in every future booking to help providers tailor treatments to the recipient's specific needs.
      </Text>
      <Box gap={isMobile ? Spacing.S2 : Spacing.S4} justifyContent={"flex-end"}>
        <Button
          type={ButtonType.secondary}
          size={ButtonSize.small}
          onClick={() => setShowAddModal(true)}
          style={{
            borderRadius: "100px",
            maxWidth: "122px",
          }}
          icon={Plus}
          title="Add note"
        />
        <div style={{ gap: 0, width: "160px" }}>
          <FilterButton
            title={isMobile ? "" : "Filter and sort"}
            onClick={() => setShowFilterModal(true)}
            filterCount={getFilterCount()}
          />
        </div>
      </Box>

      {!checkIfEmpty(clientNotes) && !isLoading ? (
        <Box direction={FlexDirection.Column}>
          <Each of={clientNotes} render={buildNotes} />
        </Box>
      ) : (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          height={isMobile ? "400px" : "75%"}
          marginY={isMobile ? Spacing.S0 : Spacing.S20}
        >
          <div
            style={{
              marginTop: isMobile ? 0 : "8vh",
            }}>
            <NoNotesPlaceholder type={NoNotesPlaceholderType.clientnote} onAddNoteClicked={()=>{setShowAddModal(true);}} onViewProviderNotes={() => switchView(1)} />
          </div>
        </Box>
      )}

      <NoteFilterModal
        open={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        handleFilterApplied={setSearchQuery}
        forClientNotes
      />

      <RecipientPreferenceModals
        recipient={recipient}
        open={showAddModal}
        isLoading={addingPreference}
        onClose={() => setShowAddModal(false)}
        onSave={addPreferences}
        selectedInstruction={selectedInstruction}
        setSelectedInstruction={setSelectedInstruction}
      />
    </Box>
  );
};

export default RecipientClientNotes;
