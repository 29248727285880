import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { getValue } from "../../../../utils/object";
import { StripeConnectAccountStatus } from "../../../../constants/stripe";
import { StripeConnectCustomV2 } from "../../../../components/Stripe/Connect";
import { PayoutCoBranding } from "../../../../components/ProDashboard/Business/Payouts/CoBranding";
import StripeTos from "../../../../components/ProDashboard/Business/Payouts/CoBranding/StripeTOS";

interface Props {
  accountStatus: UseQueryResult;
  accountRequirements: UseQueryResult;
}

const StripeConnectCustom = ({ accountStatus, accountRequirements }: Props): JSX.Element => {
  const accountStatusData = getValue(accountStatus, "data");
  const payoutAccountStatus = getValue(accountStatus, "data.status");
  const isAccountStatusLoading = getValue(accountStatus, "isLoading");

  return (
    <React.Fragment>
      <PayoutCoBranding
        accountStatus={accountStatusData}
        accountRequirements={accountRequirements}
      />
      {payoutAccountStatus === StripeConnectAccountStatus.NOT_CONNECTED ? (
        <StripeTos preText={"By setting up your payout account"} />
      ) : (
        <></>
      )}
      <StripeConnectCustomV2
        accountStatus={accountStatusData}
        isLoading={isAccountStatusLoading}
        accountRequirements={accountRequirements}
      />
    </React.Fragment>
  );
};

export default StripeConnectCustom;
