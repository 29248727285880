import { Box, Grid, GridProps, Popover } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import PopoverItem, { PopoverItemType } from "../components/PopoverItem";
import PopoverItemSeparator from "../components/PopoverItemSeparator";
import SectionPageHeader from "../components/SectionPageHeader";
import BlockedTherapistItem from "../components/Therapists/BlockedTherapistItem";
import FavTherapistItem from "../components/Therapists/FavTherapistItem";
import TherapistsPlaceholder, {
  TherapistsPlaceholderType,
} from "../components/TherapistsPlaceholder";
import Wrapper from "../components/Wrapper";
import { Paths } from "../constants/paths";
import { TrackingEvents } from "../constants/tracking";
import { withVersion } from "../helpers/tracking";
import { useMobile } from "../hooks/mobile";
import { invalidateQueries } from "../services/query";
import { BlockedTherapist, FavoriteTherapist, RecentlyBookedPro } from "../models";
import { trackEvent } from "../services/segment/track.service";
import { PRO_KEYS } from "../hooks/booking/preferredProviders.hooks";
import { getProfileLink } from "../services/therapist/therapist.service";
import BrowseTherapists from "./Therapists/Browse/BrowseTherapists";
import RecentTherapist from "../components/Therapists/RecentTherapist";
import { isAUDomain } from "../utils/url";
import { getAccessToken } from "../helpers/accessToken";
import pixelsService from "../services/pixels/pixels.service";

const sections = ["Favourite", "Blocked", "Recent", "Browse"];

export default function Therapists(): JSX.Element {
  const [accessToken, setAccessToken] = React.useState(localStorage.getItem("token"));

  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState<number>(0);

  const [favPopoverAnchorEl, setFavPopoverAnchorEl] = React.useState<HTMLElement | null>(null);

  const [blockedPopoverAnchorEl, setBlockedPopoverAnchorEl] = React.useState<HTMLElement | null>(
    null,
  );

  const [recentlyBookedTherapistPopoverAnchorEl, setRecentlyBookedTherapistPopoverAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const [favTherapistForMore, setFavTherapistForMore] = React.useState<FavoriteTherapist | null>(
    null,
  );

  const [blockedTherapistForMore, setBlockedTherapistForMore] =
    React.useState<BlockedTherapist | null>(null);

  const [recentlyBookedTherapistForMore, setRecentlyBookedTherapistForMore] =
    React.useState<RecentlyBookedPro | null>(null);

  const { data: favTherapistsData, mutate: favTherapistsMutate } = useSWR(
    `/favorite/me?accessToken=${accessToken}`,
  );

  const favTherapists = favTherapistsData
    ? (favTherapistsData.favoritetherapists as [FavoriteTherapist])
    : [];

  const { data: blockedTherapistsData, mutate: blockedTherapistsMutate } = useSWR(
    `/blockTherapist/me?accessToken=${accessToken}`,
  );

  const invalidatRecentlyBookedProQuery = () => {
    invalidateQueries([PRO_KEYS.RECENT_PROS]);
  };

  const blockedTherapists = blockedTherapistsData
    ? (blockedTherapistsData as [BlockedTherapist])
    : [];

  const gridProps = {
    container: true,
    spacing: 3,
    style: {
      marginTop: isMobile ? "16px" : "40px",
      marginLeft: isMobile ? undefined : "80px",
      marginBottom: "16px",
      width: "auto",
      marginRight: "0px",
    },
    direction: isMobile ? "column" : undefined,
    alignItems: isMobile ? "center" : undefined,
  } as GridProps;

  React.useEffect(() => {
    if (location.pathname === Paths.BlockedTherapists) {
      setSelectedSectionIndex(1);
    } else if (location.pathname === Paths.RecentTherapists) {
      setSelectedSectionIndex(2);
    } else if (location.pathname === Paths.BrowseTherapists) {
      setSelectedSectionIndex(3);
    } else {
      setSelectedSectionIndex(0);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    pixelsService.trackPageView();
  }, []);

  const handleFavouriteTherapistClick = (therapistId: number | undefined) => {
    if (!therapistId) return;
    trackEvent(
      TrackingEvents.TherapistInteracted,
      withVersion({
        action: "unfavourite",
        therapistId: therapistId,
      }),
    );

    setRecentlyBookedTherapistPopoverAnchorEl(null);

    axios
      .post(`/favorite/${therapistId}`, { accessToken })
      .then((response) => {
        favTherapistsMutate();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleBlockTherapistClick = (therapistId: number | undefined) => {
    if (!therapistId) return;

    trackEvent(
      TrackingEvents.TherapistInteracted,
      withVersion({
        action: "block",
        therapistId: therapistId,
      }),
    );

    setRecentlyBookedTherapistPopoverAnchorEl(null);

    axios
      .post(`/blockTherapist/${therapistId}`, {
        accessToken,
        blockReason: "Blocked", // TODO: Provide input for reason
      })
      .then((response) => {
        blockedTherapistsMutate();
        invalidatRecentlyBookedProQuery();
        favTherapistsMutate();
      })
      .catch((error) => {
        console.debug("error: ", error);

        alert(error);
      });
  };

  const handleUnBlockTherapistClick = (therapistId: number | undefined) => {
    if (!therapistId) return;

    trackEvent(
      TrackingEvents.TherapistInteracted,
      withVersion({
        action: "unblock",
        therapistId,
      }),
    );

    setBlockedPopoverAnchorEl(null);

    axios
      .delete(`/blockTherapist/${therapistId}`, {
        data: {
          accessToken,
        },
      })
      .then((response) => {
        blockedTherapistsMutate();
        invalidatRecentlyBookedProQuery();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleUnFavouriteTherapistClick = (therapistId: number | undefined) => {
    if (!therapistId) return;

    trackEvent(
      TrackingEvents.TherapistInteracted,
      withVersion({
        action: "unfavourite",
        therapistId: therapistId,
      }),
    );

    setFavPopoverAnchorEl(null);

    axios
      .delete(`/favorite/${therapistId}`, {
        data: {
          accessToken,
        },
      })
      .then((response) => {
        favTherapistsMutate();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleFavouriteTherapistDemoteDown = (therapistId: number | undefined) => {
    if (!therapistId) return;

    trackEvent(
      TrackingEvents.TherapistInteracted,
      withVersion({
        action: "demote",
        therapistId: therapistId,
      }),
    );

    setFavPopoverAnchorEl(null);

    axios
      .put(`/favorite/${therapistId}/moveDown`, {
        accessToken,
      })
      .then((response) => {
        favTherapistsMutate();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleFavouriteTherapistPromoteUp = (therapistId: number | undefined) => {
    if (!therapistId) return;

    trackEvent(
      TrackingEvents.TherapistInteracted,
      withVersion({
        action: "promote",
        therapistId: therapistId,
      }),
    );

    setFavPopoverAnchorEl(null);

    axios
      .put(`/favorite/${therapistId}/moveUp`, {
        accessToken,
      })
      .then((response) => {
        favTherapistsMutate();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onProfileClicked = (therapist: FavoriteTherapist | BlockedTherapist) => {
    let publicProfileURL = getProfileLink({
      id: therapist.therapistId,
      firstName: therapist.therapist?.firstName || "",
    });
    const userToken = getAccessToken();
    publicProfileURL += `?token=${userToken}`;

    if (isAUDomain()) publicProfileURL += "&country=AU";

    window.open(publicProfileURL, "_blank");
  };

  return (
    <>
      <Wrapper
        header={
          <SectionPageHeader
            title="Providers"
            sections={sections}
            selectedSectionIndex={selectedSectionIndex}
            onSelectedSectionIndex={(index) => {
              if (index === 1) {
                history.push(Paths.BlockedTherapists);
              } else if (index === 2) {
                history.push(Paths.RecentTherapists);
              } else if (index === 3) {
                history.push(Paths.BrowseTherapists);
              } else {
                history.push(Paths.Therapists);
              }
            }}
          />
        }
        onLoggedIn={() => {
          setAccessToken(localStorage.getItem("token"));
        }}
      >
        {selectedSectionIndex === 0 && !isNil(favTherapistsData) && isEmpty(favTherapists) && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={isMobile ? "400px" : "75%"}
          >
            <TherapistsPlaceholder type={TherapistsPlaceholderType.favourite} />
          </Box>
        )}

        {selectedSectionIndex === 0 && (
          <Grid {...gridProps}>
            {favTherapists &&
              favTherapists.map((therapist: FavoriteTherapist) => (
                <Grid item>
                  <FavTherapistItem
                    therapist={therapist}
                    onMoreClicked={(event) => {
                      setFavTherapistForMore(therapist);
                      setFavPopoverAnchorEl(event.currentTarget as HTMLElement);
                    }}
                    onClick={() => onProfileClicked(therapist)}
                  />
                </Grid>
              ))}
          </Grid>
        )}

        {selectedSectionIndex === 1 &&
          !isNil(blockedTherapistsData) &&
          isEmpty(blockedTherapists) && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={isMobile ? "400px" : "75%"}
          >
            <TherapistsPlaceholder type={TherapistsPlaceholderType.blocked} />
          </Box>
        )}

        {selectedSectionIndex === 1 && (
          <Grid {...gridProps}>
            {blockedTherapists &&
              blockedTherapists.map((therapist: BlockedTherapist) => (
                <Grid item>
                  <BlockedTherapistItem
                    therapist={therapist}
                    onMoreClicked={(event) => {
                      setBlockedTherapistForMore(therapist);
                      setBlockedPopoverAnchorEl(event.currentTarget as HTMLElement);
                    }}
                    onClick={() => onProfileClicked(therapist)}
                  />
                </Grid>
              ))}
          </Grid>
        )}

        {selectedSectionIndex === 2 && (
          <RecentTherapist
            gridProps={gridProps}
            onMoreClicked={(event, therapist: RecentlyBookedPro) => {
              setRecentlyBookedTherapistForMore(therapist);
              setRecentlyBookedTherapistPopoverAnchorEl(event.currentTarget as HTMLElement);
            }}
            selectedSectionIndex={selectedSectionIndex}
            favouriteTherapistList={favTherapists || []}
          />
        )}

        {selectedSectionIndex === 3 && <BrowseTherapists />}
      </Wrapper>
      <Popover
        id={"fav-therapist-popover"}
        open={favPopoverAnchorEl !== null}
        anchorEl={favPopoverAnchorEl}
        onClose={() => setFavPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" width="300px">
          <PopoverItem
            type={PopoverItemType.default}
            title="Promote up"
            onClick={() => handleFavouriteTherapistPromoteUp(favTherapistForMore?.therapistId)}
          />
          <PopoverItemSeparator />
          <PopoverItem
            type={PopoverItemType.default}
            title="Demote down"
            onClick={() => handleFavouriteTherapistDemoteDown(favTherapistForMore?.therapistId)}
          />
          <PopoverItemSeparator />

          <PopoverItem
            type={PopoverItemType.default}
            title="Unfavourite therapist"
            onClick={() => handleUnFavouriteTherapistClick(favTherapistForMore?.therapistId)}
          />
          <PopoverItemSeparator />
          <PopoverItem
            type={PopoverItemType.destructive}
            title={`Block ${favTherapistForMore?.therapist?.firstName || "therapist"}`}
            onClick={() => handleBlockTherapistClick(favTherapistForMore?.therapistId)}
          />
        </Box>
      </Popover>
      <Popover
        id={"blocked-therapist-popover"}
        open={blockedPopoverAnchorEl !== null}
        anchorEl={blockedPopoverAnchorEl}
        onClose={() => setBlockedPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" width="300px">
          <PopoverItem
            type={PopoverItemType.default}
            title="Unblock"
            onClick={() => handleUnBlockTherapistClick(blockedTherapistForMore?.therapistId)}
          />
        </Box>
      </Popover>

      <Popover
        id={"recently-booked-therapist-popover"}
        open={recentlyBookedTherapistPopoverAnchorEl !== null}
        anchorEl={recentlyBookedTherapistPopoverAnchorEl}
        onClose={() => setRecentlyBookedTherapistPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" width="300px">
          <PopoverItemSeparator />

          {favTherapists?.every(
            (data: FavoriteTherapist) => data.therapistId !== recentlyBookedTherapistForMore?.id,
          ) && (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title="Favourite therapist"
                onClick={() => handleFavouriteTherapistClick(recentlyBookedTherapistForMore?.id)}
              />
              <PopoverItemSeparator />
            </>
          )}
          <PopoverItem
            type={PopoverItemType.destructive}
            title={`Block ${recentlyBookedTherapistForMore?.firstName || "therapist"}`}
            onClick={() => handleBlockTherapistClick(recentlyBookedTherapistForMore?.id)}
          />
        </Box>
      </Popover>
    </>
  );
}
