import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import TextField from "../TextField";
import MobileTextField from "../MobileTextField";
import { PointOfContactInfo } from "../PointOfContactInfo/PointOfContactInfo";
import { Spacing } from "../v2/Styled/enum";
interface GuardianProps {
  guardianFirstName: string;
  guardianLastName: string;
  guardianEmail: string;
  guardianMobile: string;
  isMobile: boolean;
  guardianCountryCode: string;
  isGuardianPOC: boolean;
  setGuardianFirstName: (val: string) => unknown;
  setGuardianLastName: (val: string) => unknown;
  setGuardianEmail: (val: string) => unknown;
  setGuardianMobile: (val: string) => unknown;
  setGuardianCountryCode: (val: string) => unknown;
  setIsGuardianPOC: (val: boolean) => unknown;
  setIsRecipientPOC: (val: boolean) => unknown;
}

export default function GuardianForm(props: GuardianProps) {
  const {
    guardianEmail,
    guardianMobile,
    guardianFirstName,
    guardianLastName,
    isMobile,
    guardianCountryCode,
    isGuardianPOC,
    setGuardianEmail,
    setGuardianFirstName,
    setGuardianLastName,
    setGuardianMobile,
    setGuardianCountryCode,
    setIsGuardianPOC,
    setIsRecipientPOC,
  } = props;
  return (
    <Box display="flex" flexDirection="column" mb={Spacing.S3} mt={Spacing.S3}>
      <Box
        fontFamily="Museo"
        fontSize="24px"
        color={Colors.Dusk}
        fontWeight={600}
        lineHeight="21px"
        mb={Spacing.S5}
      >
        Guardian / Carer / Next of kin
      </Box>

      <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
        <TextField
          title="First name"
          value={guardianFirstName}
          onChange={(text) => setGuardianFirstName(text)}
        />
        <Box width="60px" />
        <TextField
          title="Last name"
          value={guardianLastName}
          onChange={(text) => setGuardianLastName(text)}
        />
      </Box>

      <TextField
        title="Email"
        placeholder="your@email.com"
        value={guardianEmail}
        onChange={(text) => setGuardianEmail(text)}
        type="email"
      />

      <MobileTextField
        countryCode={guardianCountryCode}
        mobile={guardianMobile}
        mobileFieldTitle="Mobile"
        onCountryCodeChange={(code) => setGuardianCountryCode(code)}
        onMobileChange={(text) => setGuardianMobile(text)}
      />
      <PointOfContactInfo
        isPointOfContact={isGuardianPOC}
        onCheckBoxChange={(isChecked) => {
          setIsGuardianPOC(isChecked);
          setIsRecipientPOC(false);
        }}
        firstName={guardianFirstName}
      />
    </Box>
  );
}
