import React from "react";
import { Recipient } from "../../../models";
import { Box, CircularProgress } from "@material-ui/core";
import { useMobile } from "../../../hooks/mobile";
import { Text } from "../../../components/v2/Styled";
import { FontFamily, FontSize, FontWeight } from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import TermsAndPolicies from "../../../components/Users/TermsAndPolicies/TermsAndPolicies";
import { useAlertStore } from "../../../stores/alert";
import {
  useDocumentTypeOptions,
  useExistingUserDocuments,
  useRemoveDocument,
} from "../../../hooks/userTerms.hook";
import TermsAndPoliciesUploadedFiles from "../../../components/Users/TermsAndPolicies/TermsPoliciesUploadFiles";
import TermsAndPoliciesAddbutton from "../../../components/Users/TermsAndPolicies/TermsAndPoliciesAddbutton";
import { RECIPIENT_KEYS, useGetRecipientDocuments } from "../../../hooks/recipient.hook";
import { invalidateQueries } from "../../../services/query";

interface RecipientDocumentsSectionProps {
  recipient: Recipient;
}

const RecipientDocumentsSection: React.FC<RecipientDocumentsSectionProps> = ({ recipient }) => {
  const isMobile = useMobile();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const onComplete = {
    remove: (success: boolean) => {
      if (success) {
        invalidateQueries([RECIPIENT_KEYS.documents, recipient.id]);
        setSuccessMessage("Your terms and policies have been removed.");
      } else {
        setErrorMessage("Error occur while removing document.");
      }
    },
  };

  const { isLoading: isDocumentOptionsLoading, data: documentOptions } = useDocumentTypeOptions();
  const { isLoading: isExistingDocsLoading, data: existingDocs } = useGetRecipientDocuments({
    recipientId: recipient.id,
  });
  const { isLoading: isRemoving, mutate: removeTermsDocument } = useRemoveDocument(
    onComplete.remove
  );

  const handleRemoveDocs = (documentId: number) => {
    if (!isRemoving) {
      removeTermsDocument(documentId);
    }
  };

  const onSuccess = () => {
    invalidateQueries([RECIPIENT_KEYS.documents, recipient.id]);
  };

  if (isDocumentOptionsLoading || isExistingDocsLoading) {
    return (
      <Box display={"flex"} justifyContent={"center"}>
        <CircularProgress
          variant="indeterminate"
          style={{ color: Colors.Dusk }}
          size="25px"
          thickness={4}
        />
      </Box>
    );
  }

  return (
    <Box
      marginTop="40px"
      paddingLeft={isMobile ? "16px" : "80px"}
      paddingRight={isMobile ? "16px" : "200px"}
      paddingBottom="88px"
      maxWidth={"632px"}
    >
      <Box
        flex={1}
        style={{ gap: "24px" }}
        alignItems="center"
        justifyContent={isMobile ? "flex-end" : "space-between"}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Text
            font={FontFamily.Museo}
            weight={FontWeight.Bold}
            size={FontSize.F24}
            color={Colors.NightBlue}
          >
            Documents
          </Text>
          <Text
            font={FontFamily.Museo}
            weight={FontWeight.Medium}
            size={FontSize.F16}
            color={Colors.Dusk}
          >
            All relevant documents related to the recipient will be saved to streamline future
            bookings. Accepted file formats include PDF, JPG, and PNG.
          </Text>
          {(existingDocs || []).length > 0 ? (
            <TermsAndPoliciesUploadedFiles
              uploadedFilesData={existingDocs}
              docsType={documentOptions}
              removeAlreadyUploadedDocs={handleRemoveDocs}
              styles={{ marginTop: "16px" }}
            />
          ) : (
            <Box
              style={{
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
              }}
            >
              No documents uploaded
            </Box>
          )}
        </div>
      </Box>
      <Box style={{ marginTop: "40px" }}>
        <TermsAndPoliciesAddbutton
          recipientId={recipient.id}
          onSuccess={onSuccess}
          isTermsDocument
        />
      </Box>
    </Box>
  );
};

export default RecipientDocumentsSection;
