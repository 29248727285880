import React from "react";
import Button from "../../components/Button";
import { useMobile, useTab } from "../../hooks/mobile";
import Wrapper from "../../components/Wrapper";
import { Colors } from "../../constants/colors";
import { Box, Text } from "../../components/v2/Styled";
import ReferralsContent from "./Referrals/ReferralsContent";
import SignupAsProImage from "../../images/signup-as-a-provider.jpg";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from "../../components/v2/Styled/enum";
import { HOME_PAGE } from "../../constants/links";
import { getCountryCode } from "../../utils/country";
import { getValue } from "../../utils/object";
import pixelsService from "../../services/pixels/pixels.service";

const ProDashboard = () => {
  const isMobile = useMobile();
  const isTab = useTab();

  React.useEffect(() => {
    pixelsService.trackPageView();
  }, []);

  const handleSignupPressed = async () => {
    const countryCode = await getCountryCode();
    const baseHomeURL = getValue(HOME_PAGE, countryCode, HOME_PAGE.DEFAULT);

    window.open(`${baseHomeURL}/provider`, "_blank");
  };

  return (
    <Wrapper>
      <ProDashboardHeader section={""} hideTabs />

      <Box
        maxWidth={"766px"}
        marginY={Spacing.S11}
        direction={FlexDirection.Column}
        paddingX={isMobile ? Spacing.S4 : Spacing.S20}
        gap={Spacing.S12}
      >
        <Box
          direction={isMobile || isTab ? FlexDirection.Column : FlexDirection.Row}
          gap={Spacing.S10}
        >
          <Box direction={FlexDirection.Column} gap={Spacing.S6}>
            <Box direction={FlexDirection.Column} gap={Spacing.S4}>
              <Box>
                <Text
                  color={Colors.Dusk}
                  font={FontFamily.Museo}
                  size={FontSize.F24}
                  weight={FontWeight.Bold}
                >
                  Become a Blys provider
                </Text>
              </Box>
              <Box>
                <Text
                  color={Colors.Dusk}
                  font={FontFamily.Museo}
                  size={FontSize.F16}
                  weight={FontWeight.Medium}
                  lineHeight={LineHeight.L24}
                >
                  Are you a professional wellness services provider? Join Blys today, the leading
                  booking platform for in-home massage, beauty and wellness. Enjoy working when and
                  where you want and earn up to 2 to 3 times more than spas and salons!
                </Text>
              </Box>
            </Box>

            <Box>
              <Button
                title={"Sign up as a provider"}
                width={isMobile ? "100%" : "217px"}
                onClick={handleSignupPressed}
              />
            </Box>
          </Box>

          <Box>
            <img
              src={SignupAsProImage}
              style={{ width: isMobile ? "100%" : "359px", height: isMobile ? "auto" : "332px" }}
            />
          </Box>
        </Box>

        <ReferralsContent forClientUser={true} />
      </Box>
    </Wrapper>
  );
};
export default ProDashboard;
