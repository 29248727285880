import { FormStepsName, IFormData } from "../../pages/ProDashboard/Business/Payouts/Forms/model";

const getBtnTextForPayoutReview = ({
  data,
  formStepName,
}: {
  data: IFormData;
  formStepName: FormStepsName;
}): string => {
  const { personalDetails, address, identityVerification, accountForPayout } = data;

  const stepConfig: Record<FormStepsName, { data: Record<string, any>; excludes: string[] }> = {
    [FormStepsName.PersonalDetails]: { data: personalDetails, excludes: [] },
    [FormStepsName.Address]: { data: address, excludes: ["country"] },
    [FormStepsName.IdentityVerification]: {
      data: identityVerification,
      excludes: ["documentProvided"],
    },
    [FormStepsName.AccountForPayout]: { data: accountForPayout, excludes: ["details"] },
    [FormStepsName.ReviewAndConfirm]: { data: {}, excludes: [] },
  };

  const config = stepConfig[formStepName];
  // If any of the non excluded key have some value return "Change"
  return config
    ? Object.keys(config.data)
      .filter((key) => !config.excludes.includes(key))
      .some((key) => ![null, undefined].includes(config.data[key]))
      ? "Change"
      : "Add"
    : "Change";
};

export { getBtnTextForPayoutReview };
