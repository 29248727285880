import React from "react";
import queryString from "query-string";
import { Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { Recipient } from "../../../models";
import { useMobile } from "../../../hooks/mobile";
import SegmentedControl from "../../../components/SegmentedControl";
import RecipientClientNotes from "../components/RecipientClientNotes";
import RecipientProNotes from "../components/RecipientProNotes";

interface RecipientTreatmentNoteSectionProps {
  recipient: Recipient;
}
const noteSegments = [
  { title: "Client notes", value: "client-notes" },
  { title: "Provider notes", value: "provider-notes" },
];

const getSegmentIndex = (value: string) => {
  const index = noteSegments.findIndex((i) => value === i.value);

  return index < 1 ? 0 : index;
};

const RecipientTreatmentNoteSection: React.FC<RecipientTreatmentNoteSectionProps> = ({ recipient }) => {
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search) as Record<
    string,
    string | number | undefined | string[]
  >;

  const currentSegment = query.section || "upcoming";
  const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState(
    getSegmentIndex(currentSegment as string)
  );

  const handleViewProviderOrClientNotes = (index: number) => {
    history.push(`?section=${noteSegments[index].value}`);
    setSelectedSegmentIndex(index);
  };

  if (!recipient) {
    return null;
  }

  return (
    <Box
      marginTop="43px"
      paddingLeft={isMobile ? "16px" : "80px"}
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
    >
      <SegmentedControl
        segments={noteSegments.map((i) => i.title)}
        selectedSegmentIndex={selectedSegmentIndex}
        onSelectedIndex={(index) => {
          history.push(`?section=${noteSegments[index].value}`);
          setSelectedSegmentIndex(index);
        }}
      />
      {selectedSegmentIndex === 0 && <RecipientClientNotes recipient={recipient} switchView={handleViewProviderOrClientNotes} />}
      {selectedSegmentIndex === 1 && <RecipientProNotes recipient={recipient} />}
    </Box>
  );
};

export default RecipientTreatmentNoteSection;
