import { Box } from "@material-ui/core";
import {useMobile} from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";

interface INoteEditOrDelete {
  handleDeleteNote: () => void;
  handleEditNote: (data: any) => void;
}

const NoteEditOrDelete = ({handleDeleteNote, handleEditNote}: INoteEditOrDelete) => {
  const isMobile = useMobile();

  const boxWidth = isMobile ? 0 : 8;
  const boxHeight = isMobile ? 8 : 0;
  const buttonWidth = isMobile ? "100%" : "160px";
  const flexDirection = isMobile ? "column-reverse" : "row";
  // styles.badge.backgroundColor = "#DC3545";

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection={flexDirection}
        justifyContent="center"
        marginTop={"24px"}
        alignItems="flex-start"
      >
        <Button
          type={ButtonType.outlined}
          size={ButtonSize.small}
          width={buttonWidth}
          height={48}
          colorTitle={Colors.Dusk}
          title="Delete"
          fontStyle={{
            fontFamily: "Museo",
            fontWeight: 700,
            fontSize: 16,
          }}
          style={{ borderRadius: 4, flexGrow: 1 }}
          onClick={() => {
            handleDeleteNote();
          }}
        />
        <Box width={boxWidth} height={boxHeight} />
        <Button
          type={ButtonType.outlined}
          size={ButtonSize.small}
          width={buttonWidth}
          height={48}
          colorTitle={Colors.Dusk}
          title="Edit"
          fontStyle={{
            fontFamily: "Museo",
            fontWeight: 700,
            fontSize: 16,
          }}
          style={{ borderRadius: 4, flexGrow: 1 }}
          onClick={handleEditNote}
        />
      </Box>
    </Box>
  );
};

export default NoteEditOrDelete;