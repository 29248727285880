import React from "react";
import { Box } from "@material-ui/core";

import TextLink from "../TextLink";
import { Address } from "../../models";
import RadioButton from "../RadioButton";
import { Spacing } from "../v2/Styled/enum";
import Divider from "../../components/Divider";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import { useUserStore } from "../../stores/user";
import { stringForAddress } from "../../helpers/address";
import { checkIfEmpty, checkIfNil } from "../../utils/object";
import { labelForLocationType, useTypesStore } from "../../stores/types";

interface Props {
  address: Address;
  selected?: boolean;
  onEditClicked?: () => unknown;
  onDeleteClicked?: (address: Address) => unknown;
  onSelected?: () => unknown;
}

export default function AddressItem({
  address,
  selected,
  onEditClicked,
  onDeleteClicked,
  onSelected,
}: Props): JSX.Element {
  const { locationTypes } = useTypesStore();
  const isMobile = useMobile();
  const loggedInUserId = useUserStore(state => state?.user?.id);


  const [typeLabel, setTypeLabel] = React.useState("");

  React.useEffect(() => {
    setTypeLabel(labelForLocationType(address.type) || address.type);
  }, [address, locationTypes]);

  return (
    <>
      <Box display="flex" flexDirection="row" mt={2} mb={2} alignItems="center">
        {!checkIfNil(selected) && (
          <Box ml={isMobile ? undefined : Spacing.S0} mr={Spacing.S1}>
            <RadioButton selected={selected} onSelected={onSelected} />
          </Box>
        )}

        <Box display="flex" flex={1} flexDirection="column">
          <Box
            fontFamily="Museo"
            fontSize="14px"
            fontWeight={700}
            color={Colors.Dusk}
            mt={1}
          >
            {typeLabel}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            paddingRight={isMobile ? 0 : 0}
            alignItems="center"
          >
            <Box
              fontFamily="Museo"
              fontSize="18px"
              fontWeight={500}
              color={Colors.NightBlue}
              mt={1}
              mr={4}
              flex={1}
            >
              {stringForAddress(address)}
            </Box>
            {(address.userId === loggedInUserId && onEditClicked) && <TextLink title="Edit" onClick={onEditClicked} />}
            {(address.userId === loggedInUserId && onDeleteClicked) && (
              <>
                <Box width="16px" />
                <TextLink title="Delete" onClick={() => onDeleteClicked(address)} destructive />
              </>
            )}
          </Box>

          {!checkIfEmpty(address.instructions) && (
            <Box
              fontFamily="Open Sans"
              fontSize="14px"
              fontWeight={500}
              color={Colors.BlueyGrey}
              mt={0.35}
            >
              Notes: {address.instructions}
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
    </>
  );
}
