import { Box, Typography } from "@material-ui/core";
import ContentModal from "../ContentModal/contentModal";
import Button, { ButtonType } from "../../Button";
import { FontFamily, Spacing } from "../../v2/Styled/enum";
import { formatToPrice } from "../../../helpers/number";
import { Colors } from "../../../constants/colors";
import { PartialJobForTip, TipPerJob } from "../../../types/tip.types";

type Props = {
  currency: string;
  jobs: PartialJobForTip[];
  tipPerJobs: TipPerJob[];
  onClose: () => void;
  onConfirm: () => void;
};

export default function TippingConfirmModal(props: Props) {
  const getConfirmDescription = () => {
    if (props.jobs.length === 1) {
      const formattedTipAmount = formatToPrice(props.tipPerJobs[0].tipAmount, props.currency);

      return `The booking has already been confirmed and will incur additional ${formattedTipAmount} as a tip for your provider.`;
    }

    let description = "The booking has already been confirmed and will incur additional ";
    props.tipPerJobs.map((tip, index) => {
      const formattedTipAmount = formatToPrice(tip.tipAmount, props.currency);

      if (index > 0) {
        description += " and ";
      }

      description += `${formattedTipAmount} as a tip for ${
        props.jobs.find((job) => job.id === tip.jobId)?.user.firstName
      }`;
    });

    description += ".";

    return description;
  };

  return (
    <ContentModal
      actions={[
        <Box display={"flex"} gridGap={Spacing.S4} width={"100%"}>
          <Button
            type={ButtonType.outlined}
            onClick={() => {
              props.onClose();
            }}
            title="Cancel"
          />
          <Button
            type={ButtonType.secondary}
            onClick={() => {
              props.onConfirm();
              props.onClose();
            }}
            title="Confirm"
          />
        </Box>,
      ]}
      actionStyle={{ paddingBottom: "32px" }}
      title="Confirm tip"
      titleDivider={false}
      divider={false}
      onClose={() => {
        props.onClose();
      }}
      visible={true}
      PaperProps={{
        style: {
          boxSizing: "border-box",
        },
      }}
    >
      <Typography>
        <Box
          sx={{
            fontFamily: FontFamily.OpenSans,
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: Colors.BlueyGrey,
            textAlign: "center",
          }}
        >
          {getConfirmDescription()}
        </Box>
      </Typography>
    </ContentModal>
  );
}
