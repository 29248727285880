import React from "react";
import { Booking } from "../../../models";
import { getValue } from "../../../utils/object";
import { Box, Text } from "../../../components/v2/Styled";
import RecurringIcon from "../../../images/booking-recurrency.png";
import InfoIcon from "../../../images/info-icon.png";
import { Box as MUIBox } from "@material-ui/core";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";

interface Props {
  booking: Booking;
}

const RecurringInfo = ({ booking }: Props): JSX.Element => {
  const recurringInfo = getValue(booking, "recurring");
  const recurringDisclaimerText = getValue(recurringInfo, "recurringData.pro.nextBookingText", "");
  const recurringFrequency = getValue(recurringInfo, "recurringData.frequency", 0);
  const recurringText = getValue(recurringInfo, "recurringData.pro.nextBookingDateText", "");
  const recurringLabel = getValue(recurringInfo, "recurringData.pro.recurringLabel", "");

  if (recurringFrequency === 0) {
    return <React.Fragment />;
  }

  return (
    <>
      <Box gap={Spacing.S4}>
        <Box width={"24px"} height={"24px"}>
          <img src={RecurringIcon} style={{ width: 24, height: 24 }} />
        </Box>
        <Box direction={FlexDirection.Column}>
          <Box>
            <Text
              font={FontFamily.Museo}
              size={FontSize.F16}
              lineHeight={LineHeight.L24}
              color={Colors.Dusk}
              weight={FontWeight.Medium}
            >
              {recurringLabel}
            </Text>
          </Box>
          <Box>
            <Text
              font={FontFamily.OpenSans}
              weight={FontWeight.Regular}
              size={FontSize.F14}
              color={Colors.Grey}
            >
              {recurringText}
            </Text>
          </Box>
        </Box>
      </Box>
      {recurringFrequency && (
        <MUIBox
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: Colors.CornflowerBlue,
            gap: Spacing.S2,
            padding: Spacing.S3,
            borderRadius: Spacing.S2,
          }}
        >
          <Box width={"16px"} height={"16px"}>
            <img
              src={InfoIcon}
              style={{ width: 16, height: 16, marginTop: 2, fill: Colors.Blue2 }}
            />
          </Box>
          <Box direction={FlexDirection.Column}>
            <Box>
              <Text
                font={FontFamily.OpenSans}
                size={FontSize.F14}
                lineHeight={LineHeight.L21}
                color={Colors.Blue2}
                weight={FontWeight.Medium}
              >
                {recurringDisclaimerText}
              </Text>
            </Box>
          </Box>
        </MUIBox>
      )}
    </>
  );
};

export default RecurringInfo;
