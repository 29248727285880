import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { REGEX } from "../../data/enums";
import Button, { ButtonType } from "../../components/Button";
import GoogleReCaptcha from "../../components/GoogleReCaptcha";
import LandingWrapper from "../../components/LandingWrapper";
import LoginModal from "../../components/LoginModal";
import LogoLink from "../../components/LogoLink";
import MobileTextField from "../../components/MobileTextField";
import TextField from "../../components/TextField";
import MetaTags from "../../components/Voucher/MetaTags";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { parseApiError } from "../../helpers/error";
import { useMobile } from "../../hooks/mobile";
import * as segmentTrackService from "../../services/segment/track.service";
import { useAlertStore } from "../../stores/alert";
import { getCountryCode } from "../../utils/country";
import { resetPaymentStore } from "../../stores/booking";

export default function SignUp(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();

  useEffect(() => segmentTrackService.trackVoucherNewUserStepViewed(), []);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState("");
  const [mobile, setMobile] = React.useState("");

  const [showLoginModal, setShowLoginModal] = React.useState(false);

  const [recaptchaSuccess, setRecaptchaSuccess] = React.useState(false);

  const { setErrorMessage } = useAlertStore();

  const onSignUp = async () => {
    if (isEmpty(firstName)) {
      setErrorMessage("Please enter your first name");
      return;
    }

    if (isEmpty(lastName)) {
      setErrorMessage("Please enter your last name");
      return;
    }

    if (isEmpty(email)) {
      setErrorMessage("Please enter your email");
      return;
    }

    if (!REGEX.EMAIL.test(email.toLowerCase())) {
      setErrorMessage("Please enter valid email address");
      return;
    }

    if (isEmpty(countryCode) || isEmpty(mobile)) {
      setErrorMessage("Please enter your mobile");
      return;
    }

    if (!recaptchaSuccess) {
      setErrorMessage("Please complete reCAPTCHA");
      return;
    }

    setLoading(true);

    let data = {
      firstName,
      lastName,
      email,
      mobile: `${countryCode}${mobile}`,
      country: await getCountryCode(),
    };

    axios
      .post("/users/signup", data)
      .then((response) => {
        setLoading(false);

        console.debug("sign up success: ", response);

        const { accessToken } = response.data;

        if (accessToken) {
          resetPaymentStore(); // hotfix
          localStorage.setItem("token", accessToken);

          history.push(Paths.PurchaseVoucherPayment);
        }

        segmentTrackService.trackVoucherNewUserStepCompleted();
      })
      .catch((error) => {
        console.debug("error: ", error);

        setLoading(false);

        if (error.response?.status === 490) {
          setShowLoginModal(true);
        } else {
          setErrorMessage(parseApiError(error));
        }
      });
  };

  return (
    <>
      <LandingWrapper showPromoImages={false}>
        <MetaTags />
        <Box width={isMobile ? undefined : "400px"}>
          <LogoLink />
          <Typography
            variant="h1"
            style={{ color: Colors.NightBlue, marginTop: "48px" }}
          >
            Blys voucher
          </Typography>
          {/* <Typography
          variant="h3"
          style={{ color: Colors.Dusk, marginTop: "18px" }}
        >
          Super glad to have you with us!
        </Typography> */}

          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            mt={3}
          >
            <TextField
              title="Your first name"
              placeholder="Name"
              value={firstName}
              onChange={(text) => setFirstName(text)}
            />
            <Box width="20px" />
            <TextField
              title="Your last name"
              placeholder="Name"
              value={lastName}
              onChange={(text) => setLastName(text)}
            />
          </Box>

          <TextField
            title="Your email"
            placeholder="youremail@mail.com"
            value={email}
            onChange={(text) => setEmail(text)}
            type="email"
          />

          <MobileTextField
            countryCode={countryCode}
            mobile={mobile}
            onCountryCodeChange={(code) => setCountryCode(code)}
            onMobileChange={(mobile) => setMobile(mobile)}
            mobileFieldTitle="Your mobile"
          />

          <GoogleReCaptcha
            elementID="voucher-signup"
            verifyCallback={() => setRecaptchaSuccess(true)}
          />

          <Box mt="20px">
            <Button
              loading={loading}
              title="Continue"
              type={ButtonType.secondary}
              onClick={() => onSignUp()}
            />
            <Box height="8px" />
            <Button
              type={ButtonType.outlined}
              title={"Back"}
              onClick={() => {
                history.push(Paths.PurchaseVoucher);
              }}
            />
          </Box>
        </Box>
      </LandingWrapper>

      <LoginModal
        open={showLoginModal}
        email={email}
        onClose={() => setShowLoginModal(false)}
        onSuccess={(accessToken) => {
          setShowLoginModal(false);

          localStorage.setItem("token", accessToken);

          history.push(Paths.PurchaseVoucherPayment);
        }}
      />
    </>
  );
}
