import { useEffect, useState } from "react";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing
} from "../../../../components/v2/Styled/enum";
import { Colors } from "../../../../constants/colors";
import { Box } from "../../../../components/v2/Styled";
import { checkIfEmpty } from "../../../../utils/object";
import TextField from "../../../../components/TextField";
import Button, { ButtonType } from "../../../../components/Button";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";


interface Props {
  open: boolean;
  onClose: () => unknown;
  onBlockClicked: (data: any) => unknown;
  blockData: object;
};

const BlockModal = ({
  open,
  onClose,
  onBlockClicked,
  blockData
}: Props) => {

  const [reason, setReason] = useState("");

  useEffect(() => {
    if (checkIfEmpty(blockData) && open) {
      setReason("");
    }
  }, [blockData, open]);


  const blockInfo = "Please provide reason for blocking this client. (Your note will remain confidential with Blys)";

  const handlBlockClient = () => {
    const data = {
      reason,
    };
    onBlockClicked(data);
  };

  const actions = [
    <Button
      onClick={onClose}
      type={ButtonType.outlined}
      title={"Cancel"}
    />,
    <Button
      disabled={!reason}
      onClick={handlBlockClient}
      type={ButtonType.secondary}
      title={"Add"}
    />,
  ];

  return (
    <ContentModal
      fullWidth
      maxWidth={"sm"}
      divider={false}
      visible={open}
      onClose={onClose}
      actionStyle={{ padding: "0px 48px 48px 48px" }}
      contentStyle={{ padding: "48px 48px 40px 48px" }}
      childrenStyle={{ marginTop: 0 }}
      actions={actions}
    >
      <Box display={Display.Flex} direction={FlexDirection.Column} gap={Spacing.S7} >
        <Box display={Display.Flex} direction={FlexDirection.Column} alignItems={AlignItems.start} gap={Spacing.S3}>
          <Box
            display={Display.Flex}
            fontSize={FontSize.F22}
            color={Colors.NightBlue}
            fontWeight={FontWeight.Bold}
            fontFamily={FontFamily.Museo}
            justifyContent={JustifyContent.center}
          >
            Block client
          </Box>
          <Box
            fontSize={FontSize.F16}
            fontFamily={FontFamily.Museo}
            fontWeight={FontWeight.Medium}
            color={Colors.Grey}
          >
            {blockInfo}
          </Box>
        </Box>
        <TextField
          multiline
          title={"Reason"}
          value={reason}
          paddingTop="0px"
          paddingBottom="0px"
          onChange={setReason}
          placeholder="Add your reason"
          maxLength={2000}
        />
      </Box>
    </ContentModal >
  );
};

export default BlockModal;