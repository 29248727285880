import { Box } from "@material-ui/core";
import React, { useCallback } from "react";
import { Colors } from "../../constants/colors";
import { PaymentType } from "../../constants/payment";
import { applePaySupported } from "../../helpers/applepay";
import { useAllowedPayments } from "../../hooks/payment.hooks";
import { useUserRole } from "../../hooks/user.hooks";
import AfterPayIcon from "../../images/payment-section-afterpay.svg";
import ApplePayIcon from "../../images/payment-section-applepay.svg";
import { getAfterpayInstalment, usePaymentStore } from "../../stores/booking";
import { useUserStore } from "../../stores/user";
import AfterpayInfoModal from "../Afterpay/AfterpayInfoModal";
import RadioButton from "../RadioButton";
import TextLink from "../TextLink";
import PaymentFrameCards from "./PaymentFrameCards";
import PaymentFramePaypal from "./PaymentFramePaypal";
import PaymentSplit from "./PaymentSplit";
import PayWithNdisIcon from "../../images/pay-with-ndis.svg";
import PayWithInvoiceIcon from "../../images/pay-with-invoice.svg";
import { AlignItems, Display } from "../v2/Styled/enum";

export enum PaymentFrameType {
  booking,
  voucher,
}

interface Props {
  title?: string;
  type?: PaymentFrameType;
  selectedType: PaymentType;
  onSelectedType: (type: PaymentType) => unknown;
  afterpayInstallmentAmount?: number | string;
  allowSkipPayment?: boolean;
  showPaymentSplit?: boolean;
  hidePayWithInvoice?: boolean;
  hidePayWithNDIS?: boolean;
  isGiftVoucherPayment?: boolean;
  onlyCard?: boolean;
}

interface SectionProps {
  image?: string;
  title?: string;
  selected: boolean;
  onSelected: () => unknown;
  selectedContent?: React.ReactNode;
}

function Section({ image, title, selected, onSelected, selectedContent }: SectionProps) {
  return (
    <Box border="1px solid #C5CBD6" borderRadius="8px" overflow="hidden" mt="16px">
      <Box
        height="56px"
        alignItems="center"
        bgcolor={Colors.PaleGrey}
        display="flex"
        flexDirection="row"
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between" flex={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box width="8px" />
            <RadioButton selected={selected} onSelected={onSelected} />
            <Box display={Display.Flex} alignItems={AlignItems.center}>
              {image && <img src={image} alt={"radio image"} style={{ marginRight: 10 }} />}
              {!!title && (
                <Box
                  fontFamily="Museo"
                  fontWeight={600}
                  fontSize="16px"
                  lineHeight="24px"
                  color={Colors.Dusk}
                >
                  {title}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {selected && selectedContent}
    </Box>
  );
}

export default function PaymentFrame({
  title,
  type = PaymentFrameType.booking,
  selectedType,
  onSelectedType,
  afterpayInstallmentAmount,
  onlyCard = false,
  allowSkipPayment = false,
  showPaymentSplit = false,
  hidePayWithInvoice = false,
  hidePayWithNDIS = false,
  isGiftVoucherPayment = false,
}: Props) {
  const { user } = useUserStore();
  const { isAdmin, isHomeCareClient, isB2BUser } = useUserRole();
  const [afterpayInfoOpen, setAfterpayInfoOpen] = React.useState(false);
  const { isPaypalAllowed, isAfterpayAllowed, isBraintreeApplePayAllowed } =
    useAllowedPayments(user);

  const { setSkipPayment } = usePaymentStore();

  const afterpayInstallment = useCallback(() => {
    if (afterpayInstallmentAmount) {
      return afterpayInstallmentAmount;
    }

    return getAfterpayInstalment().toPriceString();
  }, [afterpayInstallmentAmount]);

  let CTA_Name = "Request to book";
  if (type === PaymentFrameType.voucher) CTA_Name = "Purchase";

  const handleOptionSelected = (type: PaymentType) => {
    onSelectedType(type);
    setSkipPayment(false);
  };

  if (onlyCard) {
    return (
      <PaymentFrameCards
        type={type}
        selected={true}
        onSelected={() => handleOptionSelected(PaymentType.card)}
      />
    );
  }

  return (
    <>
      <Box>
        {title && (
          <Box
            fontFamily="Museo"
            fontWeight={600}
            fontSize="16px"
            lineHeight="24px"
            color={Colors.Dusk}
            mb="16px"
          >
            {title}
          </Box>
        )}

        {showPaymentSplit && <PaymentSplit />}

        <PaymentFrameCards
          type={type}
          selected={selectedType === PaymentType.card}
          onSelected={() => handleOptionSelected(PaymentType.card)}
        />

        {isPaypalAllowed ? (
          <PaymentFramePaypal
            selected={selectedType === PaymentType.paypal}
            onSelected={() => handleOptionSelected(PaymentType.paypal)}
          />
        ) : null}

        {/* Apple Pay */}
        {applePaySupported() && isBraintreeApplePayAllowed && (
          <Section
            image={ApplePayIcon}
            selected={selectedType === PaymentType.applepay}
            onSelected={() => handleOptionSelected(PaymentType.applepay)}
          />
        )}

        {/* Afterpay */}
        {isAfterpayAllowed ? (
          (type === PaymentFrameType.booking || type === PaymentFrameType.voucher) && (
            <Section
              image={AfterPayIcon}
              selected={selectedType === PaymentType.afterpay}
              onSelected={() => handleOptionSelected(PaymentType.afterpay)}
              selectedContent={
                <Box p="16px" style={{ background: Colors.White }} color={Colors.Dusk}>
                  <Box fontFamily="Open Sans" fontWeight={700} fontSize="16px" lineHeight="24px">
                    4 payments of {afterpayInstallment()}
                  </Box>

                  <Box
                    fontFamily="Open Sans"
                    fontWeight={400}
                    fontSize="16px"
                    lineHeight="24px"
                    mt="8px"
                    mb="16px"
                  >
                    With Afterpay you can book now and pay in 4 equal fortnightly payments with no
                    interest. Available to customers in Australia and New Zealand with a debit or
                    credit card. <br />
                    <br />
                    When your booking is confirmed, you’ll be sent a link to complete your payment.
                  </Box>

                  <TextLink title="Learn more" onClick={() => setAfterpayInfoOpen(true)} />
                </Box>
              }
            />
          )
        ) : (
          <></>
        )}

        {!isGiftVoucherPayment && (isB2BUser || isAdmin) && !hidePayWithInvoice && (
          <Section
            title={"Pay with invoice"}
            image={PayWithInvoiceIcon}
            selected={selectedType === PaymentType.payWithInvoice}
            onSelected={() => handleOptionSelected(PaymentType.payWithInvoice)}
          />
        )}

        {!isGiftVoucherPayment && (isHomeCareClient || isAdmin) && !hidePayWithNDIS && (
          <Section
            title={"Pay with NDIS funding"}
            image={PayWithNdisIcon}
            selected={selectedType === PaymentType.payWithNDISFunding}
            onSelected={() => handleOptionSelected(PaymentType.payWithNDISFunding)}
          />
        )}
      </Box>

      {selectedType === PaymentType.afterpay && (
        <AfterpayInfoModal open={afterpayInfoOpen} onClose={() => setAfterpayInfoOpen(false)} />
      )}
    </>
  );
}
