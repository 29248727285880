import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";

import Button, { ButtonType } from "../../../../components/Button";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import { Colors } from "../../../../constants/colors";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing
} from "../../../../components/v2/Styled/enum";
import TextField from "../../../../components/TextField";
import CloseButton from "../../../../components/CloseButton/CloseButton";

import CrossIcon from "../../../../images/cross-large.svg";
import { useCreateTreatmentNote, useEditTreatmentNote, useUploadNoteFile } from "../../../../hooks/user/userTreatmentNote.hooks";
import { useAlertStore } from "../../../../stores/alert";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import RoundedImage from "../../../../components/Image/RoundedImage";

interface Props {
  note?: any;
  jobId?: number,
  title?: string;
  visible: boolean;
  refreshNotes: () => unknown;
  onClose: () => unknown;
}

const AddTreatmentNoteModal = ({
  note,
  jobId,
  visible,
  title = "Add client treatment note",
  onClose,
  refreshNotes,
}: Props) => {

  const noteFileUploadRef = useRef<HTMLInputElement>(null);;

  const [noteText, setNoteText] = useState("");
  const [files, setNoteFiles] = useState<any>([]);
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  useEffect(() => {
    const noteText = getValue(note, "data.note") || "";
    const noteFiles = getValue(note, "data.files") || "";

    setNoteText(noteText);
    setNoteFiles(noteFiles);
  }, [note]);

  const resetForm = () => {
    setNoteText("");
    setNoteFiles([]);
  };

  const createNoteCB = {
    onSuccess: (response: any) => {
      const successMsg = getValue(response, "response.data.message") || "Treatment note added.";
      setSuccessMessage(successMsg);
      resetForm();
      onClose();
      if (!!refreshNotes) refreshNotes();
    },
    onError: () => setErrorMessage("Unable to add treatment note. Please try again."),
  };

  const updateNoteCB = {
    onSuccess: (response: any) => {
      const successMsg = getValue(response, "response.data.message") || "Treatment note updated.";
      setSuccessMessage(successMsg);
      resetForm();
      onClose();
      if (!!refreshNotes) refreshNotes();
    },
    onError: () => setErrorMessage("Unable to update treatment note. Please try again."),
  };

  const uploadNoteFileCB = {
    onSuccess: (response: any) => {
      const uploadedFiles = getValue(response, "data.files") || [];
      const newFiles = [...files, ...uploadedFiles];
      setNoteFiles(newFiles);
    },
    onError: () => setErrorMessage("Unable to upload file. Please try again."),
  };

  const { mutate: updateNote, isLoading: isNoteUpdating } = useEditTreatmentNote(updateNoteCB);
  const { mutate: createNewNote, isLoading: isAddingNote } = useCreateTreatmentNote(createNoteCB);
  const { mutate: uploadNoteFile, isLoading: isUploading } = useUploadNoteFile(uploadNoteFileCB);

  const onTextChange = (text: string) => setNoteText(text);

  const onUploadFileClick = () => {
    if (noteFileUploadRef.current){ 
      noteFileUploadRef.current.click();
    }
  };

  const onNoteUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = getValue(event, "target.files[0]");
    if (!file) return;

    const form = new FormData();
    form.append("files", file);
    
    uploadNoteFile(form);
  };

  const getTransformedNoteData = () => {
    const noteData = {
      jobId,
      files,
      text: noteText,
    };
    return noteData;
  };

  const handleNoteAdd = () => {
    const notePayload = getTransformedNoteData();
    
    const { files, text } = notePayload;
    if (checkIfEmpty(text) && checkIfEmpty(files)) {
      setErrorMessage("Note cannot be empty.");
      return;
    }
    createNewNote(notePayload);
  };

  const removeNoteFile = (index: number) => {
    const uploadedFiles = [...files];
    uploadedFiles.splice(index, 1);
    setNoteFiles(uploadedFiles);
  };

  const handleNoteEdit = () => {
    const notePayload = getTransformedNoteData();

    const noteId = getValue(note, "id");
    if (!noteId) {
      setErrorMessage("Unable to update note. Please try again.");
      return;
    }

    const { files, text } = notePayload;
    if (checkIfEmpty(text) && checkIfEmpty(files)) {
      setErrorMessage("Note cannot be empty.");
      return;
    }

    updateNote({ ...notePayload, id: noteId });
  };

  const actions = [
    note ? <Button
      title="Save changes"
      loading={isNoteUpdating}
      style={{ width: "none" }}
      type={ButtonType.primary}
      onClick={handleNoteEdit}
    /> :
      <Button
        title="Add note"
        loading={isAddingNote}
        style={{ width: "none" }}
        type={ButtonType.primary}
        onClick={handleNoteAdd}
      /> 
  ];
  
  const openImageURL = (url: string) => window.open(url, "_blank");

  return <ContentModal
    visible={visible}
    divider={false}
    onClose={onClose}
    maxWidth={"md"}
    fixButtonsWithDivider
    contentStyle={{ padding: "8px 20px" }}
    actions={actions}
  >
    <input
      type="file"
      id="note-file-upload"
      ref={noteFileUploadRef}
      style={{ display: "none" }}
      onChange={onNoteUploadChange}
    />
    <Box display={Display.Flex} flexDirection={FlexDirection.Row} justifyContent={JustifyContent.end}>
      <CloseButton size="sm" icon={CrossIcon} onClick={onClose} wrapperStyle={{ marginBottom: 0 }}/>
    </Box>
    <Box display={Display.Flex} flexDirection={FlexDirection.Column} paddingX={Spacing.S5}>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} alignItems={AlignItems.start} gridGap={Spacing.S4}>
        <Box
          display={Display.Flex}
          fontSize={FontSize.F30}
          color={Colors.NightBlue}
          fontWeight={FontWeight.Bold}
          fontFamily={FontFamily.Museo}
          alignItems={AlignItems.center}
          justifyContent={JustifyContent.center}
        >
          {title}
        </Box>
        <Box fontSize={FontSize.F18} fontFamily={FontFamily.OpenSans} fontWeight={FontWeight.Medium} color={Colors.Grey}>
          These notes are visible to the client and any future providers they book.
        </Box>
      </Box>

      <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={"24px"} marginTop={Spacing.S8} marginBottom={Spacing.S6}>
        <TextField
          multiline
          title="Note"
          value={noteText}
          paddingTop="0px"
          paddingBottom="0px"
          onChange={onTextChange}
          placeholder="Add your note"
          maxLength={2000}
        />
        {(files || []).length ? (
          <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3} >
            {files.map((file: string, index:number) => {
              return <RoundedImage
                clickable
                allowRemove
                fileUrl={file}
                onClick={() => openImageURL(file)}
                onRemove={() => removeNoteFile(index)}
              />;
            })}
          </Box>
        ): <></>}

        <Button
          width="132px"
          loading={isUploading}
          title="Upload file"
          type={ButtonType.outlined}
          onClick={onUploadFileClick}
        />
      </Box>
    </Box>
  </ContentModal>;
};

export default AddTreatmentNoteModal;
