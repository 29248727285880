import React from "react";
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import CancellationPolicyText from "../../../Util/CancellationPolicyText";
import { BOOKING_STATUS } from "../../../../constants/booking";
import { PaymentType } from "../../../../constants/payment";
import { getAfterpayInstalment } from "../../../../stores/booking";
import { BookingPrice } from "../../../../models";
import { usePeakHourFixed } from "../../../../hooks/booking/peakHour.hooks";

interface Props {
  currency?: string;
  totalPrice?: number;
  hasBackup?: boolean;
  paymentType?: string;
  bookingStatus?: string;
  showCancellationPolicyText?: boolean;
  width?: string;
  isActuallyPaid?: boolean;
  bookingPrice: BookingPrice | null;
  setShowTotalDueUpToModal?: (showModal: boolean) => unknown;
  showUnderLine?: boolean;
  closePriceBreakDownModal?: () => unknown;
}

interface AccordionDetailProps {
  title?: string;
  lastItem?: boolean;
  description: string;
}

const AccordionDetailRow = ({ title, description, lastItem = false }: AccordionDetailProps) => {
  return (
    <Box paddingY={"12px"} borderBottom={lastItem ? "0px" : "1px solid #DCDFE6"} lineHeight="18px">
      {title ? <Box style={styles.detailTitle}>{title}</Box> : <Box />}
      {description}
    </Box>
  );
};

const TotalDueAccordion = ({
  hasBackup,
  currency = "A$",
  totalPrice,
  paymentType,
  bookingStatus,
  showCancellationPolicyText = true,
  width = "auto",
  isActuallyPaid,
  bookingPrice,
  setShowTotalDueUpToModal,
  showUnderLine = true,
  closePriceBreakDownModal,
}: Props) => {
  const getTotalText = () => {
    if (BOOKING_STATUS.NEW === bookingStatus || !isActuallyPaid) {
      return "Total due";
    }
    return "Total paid";
  };

  const { upToText, isPeakHourFixed } = usePeakHourFixed({
    lateNightSurcharge: bookingPrice?.lateNightSurcharge,
    lateNightSurchargeApplied: bookingPrice?.lateNightSurchargeApplied,
  });

  const onTotalDueClick = () => {
    if (setShowTotalDueUpToModal && !isPeakHourFixed && totalPrice) {
      setShowTotalDueUpToModal(true);
      if (closePriceBreakDownModal) {
        closePriceBreakDownModal();
      }
    }
  };

  return (
    <Box
      fontWeight={700}
      borderRadius="6px"
      color={Colors.Dusk}
      bgcolor={Colors.PaleGrey}
      width={width}
    >
      <Accordion
        expanded={true}
        style={{
          fontSize: "16px",
          boxShadow: "none",
          fontFamily: "Museo",
        }}
      >
        <Box bgcolor={Colors.PaleGrey} paddingX={2} paddingY={1} borderRadius={"8px"}>
          <Box
            fontSize="18px"
            color={"#41506F"}
            lineHeight={"27px"}
            display="flex"
            marginY={"4px"}
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box flex={1}>{getTotalText()}</Box>
            <Box display="flex" flexDirection="column" alignItems={"flex-end"}>
              <Box
                style={!isPeakHourFixed && showUnderLine && totalPrice ? styles.clickable : {}}
                onClick={onTotalDueClick}
              >
                {totalPrice ? upToText : ""}
                {currency}
                {totalPrice?.toFixed(2)}
              </Box>
              <Box>
                {paymentType === PaymentType.afterpay && (
                  <Box>
                    <Box
                      fontSize="12px"
                      color="#8B96AD"
                      fontWeight={600}
                      lineHeight={"18px"}
                      fontFamily={"Open Sans"}
                    >
                      4 payment of {getAfterpayInstalment().toPriceString()}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <AccordionDetails
          style={{
            display: "flex",
            color: "#8B96AD",
            fontSize: "12px",
            fontWeight: 600,
            padding: "0px 16px",
            fontFamily: "Open Sans",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: " 0px 0px 8px 8px",
          }}
        >
          {bookingStatus === BOOKING_STATUS.NEW ? (
            <AccordionDetailRow description="You will not be charged until your booking request has been accepted by a pro." />
          ) : (
            <Box />
          )}
          {hasBackup && bookingStatus === BOOKING_STATUS.NEW ? (
            <AccordionDetailRow description="Price may vary if pro selects backup date and time." />
          ) : (
            <Box />
          )}
          {showCancellationPolicyText && (
            <CancellationPolicyText
              textStyle={{ margin: "0px" }}
              headerStyle={styles.detailTitle}
              wrapperStyle={{ padding: "12px 0px", margin: "0px" }}
              actionStyle={{ fontWeight: 400, fontSize: "12px", fontFamily: "Open Sans" }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const styles = {
  detailTitle: {
    color: "#41506F",
    fontWeight: 600,
    marginBottom: "2px",
    fontFamily: "Open Sans",
  },
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};

export default TotalDueAccordion;
