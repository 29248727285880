import { Box, CircularProgress, InputBase, withStyles } from "@material-ui/core";
import React from "react";

import { isEmpty } from "lodash";
import { Colors } from "../constants/colors";
import { LockAttributePayload } from "../hooks/userProfileLock.hooks";
import errorInfo from "../images/error-info.png";
import notView from "../images/notview.png";
import view from "../images/view.png";
import { useAlertStore } from "../stores/alert";
import LockButton from "./LockButton";
import TextButton from "./TextButton/TextButton";
import TextLink from "./TextLink";
import { AlignItems, Display, JustifyContent } from "./v2/Styled/enum";

interface Props {
  title?: string;
  subtitle?: string;
  subtitleElement?: JSX.Element;
  placeholder?: string;
  onChange?: (text: string) => unknown;
  width?: string | number;
  value?: string | null;
  type?: string;
  onFocus?: () => unknown;
  isPassword?: boolean;
  onBlur?: () => unknown;
  isActionLoading?: boolean;
  actionTitle?: string | null;
  actionDestructive?: boolean;
  onActionClicked?: () => unknown;
  multiline?: boolean;
  maxLength?: number;
  disabled?: boolean;
  useInputTextColor?: boolean;
  inputTextColor?: string;
  subtitleColor?: string;
  showErrorImage?: boolean;
  minValue?: number;
  maxValue?: number;
  style?: React.CSSProperties;
  isLocked?: boolean;
  isAdminLoggedInAsClient?: boolean;
  handleToggleLock?: (data: LockAttributePayload) => any;
  lockFieldName?: string;
  showLock?: boolean;
  inputTextLineHeight?: string | number | undefined;
  paddingTop?: string;
  paddingBottom?: string;
  readOnly?: boolean;
  clear?: boolean;
  titleStyle?: object;
  closeIcon?: any;
  borderWidth?: string;
  titleFontSize?: string;
  textWrapperStyle?: React.CSSProperties;
  inputRef?: React.RefObject<HTMLInputElement>;
  startAdornment?: React.ReactNode;
  enableShowPassword?: boolean;
  inputStyle?: React.CSSProperties;
}

export const StyledInputBase = withStyles({
  input: {
    "&::placeholder": {
      color: Colors.LightBlueGrey,
      opacity: 1.0,
    },
  },
})(InputBase);

const HoveredBox = withStyles({
  root: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.65,
    },
  },
})(Box);

export default function TextField({
  title,
  subtitle,
  subtitleElement,
  placeholder,
  onChange,
  width = "100%",
  value,
  type,
  onFocus,
  onBlur,
  actionTitle,
  isActionLoading = false,
  actionDestructive = false,
  onActionClicked,
  multiline = false,
  maxLength,
  disabled = false,
  useInputTextColor = false,
  inputTextColor = Colors.TurquoiseBlue,
  subtitleColor = Colors.Dusk,
  showErrorImage = false,
  isPassword = false,
  minValue = 0,
  maxValue,
  style = {},
  isLocked,
  isAdminLoggedInAsClient,
  handleToggleLock,
  lockFieldName,
  showLock,
  inputTextLineHeight = undefined,
  paddingTop,
  paddingBottom,
  readOnly,
  closeIcon,
  borderWidth,
  clear = false,
  titleStyle = {},
  titleFontSize = "14px",
  textWrapperStyle,
  inputRef,
  startAdornment,
  enableShowPassword = true,
  inputStyle = {},
}: Props): JSX.Element {
  const [showPassword, setShowPassword] = React.useState(false);
  const { setErrorMessage } = useAlertStore();

  const onShowPasswordClicked = () => {
    setShowPassword(!showPassword);
  };

  const handleInputClick = () => {
    if (isLocked) {
      setErrorMessage("Your profile is locked.");
    }
  };

  const handleLockPress = (fieldName: string | undefined) => {
    if (!handleToggleLock || !fieldName) return;
    const payload = {
      fieldName,
    };
    handleToggleLock(payload);
  };

  return (
    <Box
      width={width}
      paddingTop={paddingTop ? paddingTop : "16px"}
      paddingBottom={paddingBottom ? paddingBottom : "16px"}
      style={{ ...style }}
    >
      {title && (
        <Box
          fontFamily="Museo"
          fontSize={titleFontSize}
          color={Colors.Dusk}
          fontWeight={700}
          style={titleStyle}
        >
          {title}
        </Box>
      )}

      {subtitle && (
        <Box
          fontFamily="Open Sans"
          fontSize="14px"
          fontWeight={400}
          lineHeight="21px"
          color={subtitleColor ? subtitleColor : Colors.Dusk}
          mt={1}
        >
          {subtitle}
        </Box>
      )}

      {subtitleElement && <Box mt={1}>{subtitleElement}</Box>}

      <Box
        display="flex"
        flexDirection="row"
        mt={1}
        mb={1}
        style={{ ...(textWrapperStyle || {}) }}
      >
        <StyledInputBase
          disabled={disabled || isLocked}
          placeholder={placeholder}
          onChange={(event) => onChange && onChange(event.target.value)}
          onClick={() => handleInputClick()}
          fullWidth
          value={value}
          type={!isPassword ? type : showPassword ? "text" : "password"}
          onFocus={onFocus}
          onBlur={onBlur}
          style={{
            color:
              (disabled && !useInputTextColor) || isLocked ? Colors.LightBlueGrey : inputTextColor,
            flex: isPassword ? 0.9 : 1,
            lineHeight: inputTextLineHeight,
            ...inputStyle,
          }}
          multiline={multiline}
          inputProps={{
            maxLength,
            ...(type && type === "number" ? { min: minValue, max: maxValue } : {}),
          }}
          readOnly={readOnly || false}
          inputRef={inputRef}
          startAdornment={startAdornment}
        />
        {isPassword && enableShowPassword ? (
          <div style={{ display: "block", flex: 0.1 }}>
            <img
              style={{
                position: "relative",
                right: "13px",
                width: "14px",
                float: "right",
              }}
              src={showPassword ? view : notView}
              onClick={onShowPasswordClicked}
            />
          </div>
        ) : (
          <></>
        )}

        {isActionLoading ? (
          <Box>
            <CircularProgress size={22}/>
          </Box>
        ) : (
          <>
            {actionTitle && (
              <TextLink
                title={actionTitle}
                onClick={onActionClicked}
                destructive={actionDestructive}
              />
            )}
          </>
        )}

        {/* show lock icon if admin login as specific user form NAP */}

        {showLock && isAdminLoggedInAsClient ? (
          <LockButton
            isLocked={!!isLocked}
            handlePress={() => handleLockPress(lockFieldName)}
            size={16}
          />
        ) : (
          <React.Fragment />
        )}

        {clear && !isEmpty(value) ? (
          <>
            {closeIcon ? (
              <HoveredBox
                display={Display.Flex}
                justifyContent={JustifyContent.center}
                alignItems={AlignItems.center}
                bgcolor={"#EFEFEF"}
                border={"1px solid #EFEFEF"}
                borderRadius={"100px"}
                width={24}
                height={24}
                // style={{
                //   cursor: "pointer",
                // }}
                onClick={() => onChange && onChange("")}
              >
                <img src={closeIcon} alt="Close" width={"8px"} height={"8px"} />
              </HoveredBox>
            ) : (
              <TextButton text="Clear" type="danger" onClick={() => onChange && onChange("")} />
            )}
          </>
        ) : (
          <></>
        )}
      </Box>

      <Box
        bgcolor={Colors.LightPeriwinkle}
        height={borderWidth ? borderWidth : "2px"}
        width="100%"
      />
      {maxLength && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
          <Box fontFamily="Open Sans" fontSize="14px" color={Colors.BlueyGrey} mt={1}>{`${
            value?.length || 0
          } / ${maxLength}`}</Box>
        </Box>
      )}
      {showErrorImage && (
        <img
          style={{
            position: "relative",
            right: "10px",
            float: "right",
            bottom: "40px",
            // position: "absolute",
            // right: "10px",
            // bottom: "40px",
          }}
          src={errorInfo}
          alt="error"
        />
      )}
    </Box>
  );
}
