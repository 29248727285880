import { Box } from "@material-ui/core";
import { Cursor, Position } from "../v2/Styled/enum";
import RemoveIcon from "../../images/Cancel.svg";
import { MouseEvent } from "react";

interface Props {
  fileUrl: string;
  allowRemove: boolean;
  onRemove?: (e: MouseEvent) => unknown;
  clickable?: boolean;
  onClick?: (e: MouseEvent) => unknown;
  height?: string;
  width?: string;
}

const RoundedImage = ({
  fileUrl,
  onRemove,
  allowRemove,
  clickable = false,
  onClick = () => {},
  height = "72px",
  width = "72px",
}: Props) => {

  const openImageURL = (url: string) => window.open(url, "_blank");

  const onImageTapped = (e: MouseEvent) => {
    if (!!onClick) onClick(e);
    else if (clickable) openImageURL(fileUrl);
  };

  const onRemoveTapped = (e: MouseEvent) => {
    e.stopPropagation();
    if (!!onRemove) onRemove(e);
  };

  return (
    <Box
      height={height}
      width={width}
      position={Position.Relative}
      onClick={onImageTapped}
      style={{
        borderRadius: "4px",
        backgroundSize: "cover",
        backgroundImage: `url('${fileUrl}')`,
        cursor: clickable ? Cursor.pointer: Cursor.default,
      }}
    >
      {allowRemove && <img
        src={RemoveIcon}
        alt="Remove file"
        onClick={(e) => onRemoveTapped(e)}
        style={{
          position: Position.Absolute,
          top: 0,
          right: 0,
          transform: "translate(50%, -50%)",
          cursor: "pointer"
        }}
      />}
    </Box>
  );
};

export default RoundedImage;
