import { SHORT_LINK_PATH } from "./config";
import { Redirect } from "react-router-dom";
import { Paths } from "../../constants/paths";
import { getValue } from "../../utils/object";
import { parseQueryString } from "../../helpers/string";
import { objectToQueryString } from "../../utils/string";

const redirectShortLinkUrl = (path = "") => {
  const isProUrl = path.startsWith(SHORT_LINK_PATH.PRO);
  if (isProUrl) {
    // handle pro user urls
    if (path.startsWith(SHORT_LINK_PATH.PRO_NEW_JOB)) {
      const jobId = path.replace(SHORT_LINK_PATH.PRO_NEW_JOB, "");
      return <Redirect to={`${Paths.ProDashboardBookings}/${jobId}`} />;
    }
    return <Redirect to={Paths.ProDashboardProfile} />;
  } else {
    // handle client user urls
    return <Redirect to={Paths.Bookings} />;
  }
};

const redirect = (search = "") => {
  const PATH_NAME = "path";

  const query = parseQueryString(search);
  const path = getValue(query, PATH_NAME, "");

  // build query string
  const queryString = objectToQueryString({ queryObj: query, excludeKeys: [PATH_NAME] });

  const paths = Object.values(Paths);
  if (paths.includes(path)) {
    return <Redirect to={`${path}${queryString}`} />;
  }

  return <Redirect to={Paths.Bookings} />;
};

export { redirectShortLinkUrl, redirect };
