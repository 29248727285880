import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing
} from "../../../../components/v2/Styled/enum";
import Each from "../../../../components/Each";
import { Colors } from "../../../../constants/colors";
import { Box } from "../../../../components/v2/Styled";
import { useAlertStore } from "../../../../stores/alert";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import Button, { ButtonType } from "../../../../components/Button";
import { useBlockClient } from "../../../../hooks/client/blockClient.hooks";
import { useCreateClientReview } from "../../../../hooks/review/review.hooks";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import { CompletedActionsOptions, CompletedModalType } from "../../../../data/treatmentNotesEnum";
import ButtonOptionsForNoteModal from "../../../../components/TreatmentNotes/ButtonOptionsForNoteModal";

interface Props {
  visible: boolean;
  jobId: number | string;
  onModalClose: () => unknown;
  setSelectedOption: (value: string) => unknown;
  selectedOption?: string;
  reviewData: any;
  blockData: any;
  noteData: any;
  removeBlockedData: () => unknown;
  removeNoteData: () => unknown;
  removeReviewData: () => unknown;
}

interface ActionsOptionsInterface {
  title: string;
  description: string;
  value: string;
}

const FeedbackModal = (
  {
    visible,
    jobId,
    onModalClose,
    setSelectedOption,
    noteData,
    blockData,
    reviewData,
    removeBlockedData,
    removeNoteData,
    removeReviewData
  }: Props) => {

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const createNoteCB = {
    onSuccess: (response: any) => {
      const successMsg = getValue(response, "response.data.message") || "Treatment note added.";
      setSuccessMessage(successMsg);
    },
    onError: () => setErrorMessage("Unable to add treatment note. Please try again."),
  };

  const { mutate: blockClient, isLoading: isBlockLoading } = useBlockClient();
  const { mutate: addClientRating, isLoading: isAddingReview } = useCreateClientReview(createNoteCB);


  const handleSubmit = () => {
    if (!checkIfEmpty(blockData)) {
      const validReason = getValue(blockData, "reason", "");
      if (!validReason) return;
      blockClient({
        jobId,
        blockReason: validReason.trim()
      });
    }

    if (!checkIfEmpty(reviewData)) {
      const { rating, review, isUploadToPortfolio, reviewFiles, imagesToRemove } = reviewData;

      if (!rating) {
        setErrorMessage("Rating cannot be empty.");
        return;
      }

      const data: any = new FormData();
      data.append("rating", rating);
      if (review) data.append("review", review);
      if (isUploadToPortfolio) data.append("isUploadToPortfolio", isUploadToPortfolio);

      if (reviewFiles && reviewFiles.length) {
        reviewFiles.forEach((image: any) => {
          if (typeof image !== "string") data.append("images", image.file);
        });
      }

      if (!checkIfEmpty(imagesToRemove)) {
        data.append("imagesToRemove", JSON.stringify(imagesToRemove));
      }

      if (!checkIfEmpty(noteData)) {
        if (checkIfEmpty(noteData.noteText) && checkIfEmpty(noteData.noteFiles)) {
          setErrorMessage("Note cannot be empty.");
          return;
        }

        const treatmentNote = {
          files: noteData.noteFiles,
          text: noteData.noteText,
        };
        data.append("treatmentNote", JSON.stringify(treatmentNote));
      };
      addClientRating({ jobId, data });
    }
    onModalClose();
  };

  const actions = [
    <Button
      onClick={onModalClose}
      type={ButtonType.outlined}
      title="Skip"
    />,
    <Button
      disabled={checkIfEmpty(blockData) && checkIfEmpty(noteData) && checkIfEmpty(reviewData)}
      loading={isBlockLoading || isAddingReview}
      onClick={handleSubmit}
      type={ButtonType.secondary}
      title="Submit"
    />,
  ];

  const getData = (value: string) => {
    switch (value) {
    case CompletedModalType.ADD_TREATMENT_NOTE:
      return noteData;
    case CompletedModalType.BLOCK_CLIENT:
      return blockData;
    case CompletedModalType.RATE_AND_REVIEW:
      return reviewData;
    }
  };

  const onDeleteClicked = (value: string) => {
    switch (value) {
    case CompletedModalType.ADD_TREATMENT_NOTE:
      return removeNoteData;
    case CompletedModalType.BLOCK_CLIENT:
      return removeBlockedData;
    case CompletedModalType.RATE_AND_REVIEW:
      return removeReviewData;
    }
  };

  const buildActionButtons = (item: ActionsOptionsInterface) => {
    const data = getData(item.value);
    const handleRemove = onDeleteClicked(item.value);

    return (
      <ButtonOptionsForNoteModal
        option={item}
        handleOptionsClicked={(value) => {
          setSelectedOption(value);
          if (value === CompletedModalType.REPORT_AN_INCIDENT) {
            window.open("mailto:hello@getblys.com");
          }
        }}
        data={data}
        onDeleteClicked={handleRemove}
        onEditClicked={setSelectedOption}
      />);
  };

  return (
    <ContentModal
      fullWidth
      maxWidth={"sm"}
      divider={false}
      visible={visible}
      onClose={onModalClose}
      actionStyle={{ padding: "24px 40px 48px 40px" }}
      contentStyle={{ padding: "48px 40px 0px 40px" }}
      childrenStyle={{ marginTop: 0 }}
      actions={actions}
    >
      <Box
        display={Display.Flex}
        direction={FlexDirection.Column}
        justifyContent={JustifyContent.spaceBetween}
        gap={Spacing.S6}
      >
        <Box
          fontSize={FontSize.F24}
          color={Colors.NightBlue}
          marginBottom={Spacing.S3}
          fontWeight={FontWeight.Bold}
          fontFamily={FontFamily.Museo}
        >
          How was your session?
        </Box>
        <Box
          gap={Spacing.S4}
          display={Display.Flex}
          direction={FlexDirection.Column}
        >
          {!checkIfEmpty(CompletedActionsOptions) && (
            <Each of={CompletedActionsOptions} render={buildActionButtons} />
          )}
        </Box>
      </Box>

    </ContentModal >
  );
};

export default FeedbackModal;
