import React from "react";
import { Box, Checkbox, styled } from "@material-ui/core";
import { Colors } from "../constants/colors";
import { MassageFor } from "../constants/booking";
import * as Styled from "./v2/Styled/enum";

export enum MessageType {
  pro,
  client,
}

interface CovidVaccinationInfoProps {
  isCovidVaccinated: boolean | null;
  onCheckBoxChange: (status: boolean) => unknown;
  type: MessageType;
  massageFor?: MassageFor;
}

const CORONAVIRUS_POLICY_FOR_CLIENT =
  "https://help.getblys.com.au/support/solutions/articles/33000251040";
const CORONAVIRUS_POLICY_FOR_PRACTITIONER =
  "https://help.getblys.com.au/support/solutions/articles/33000251041";

const COVID_MESSAGE_FOR_PRO =
  "Vaccination isn't required to use the Blys platform; however, we give you the option to share your vaccination status should it be important to you or your client.";
const COVID_MESSAGE_FOR_CLIENT_MYSELF =
  "Vaccination isn't required to use the Blys platform; however, we give you the option to share your vaccination status should it be important to you or your provider.";
const COVID_MESSAGE_FOR_CLIENT_SOMEONE =
  "Vaccination isn't required to use the Blys platform; however, we give you the option to share recipient vaccination status should it be important to recipient or your provider.";

const COVID_CHECKBOX_MESSAGE_FOR_MYSELF =
  "I confirm I have received two doses of a COVID-19 vaccine";
const COVID_CHECKBOX_MESSAFE_FOR_CLIENT_SOMEONE =
  "I confirm the recipient has received two doses of a COVID-19 vaccine";

const CovidVaccinationInfo = ({
  isCovidVaccinated = false,
  onCheckBoxChange,
  type = MessageType.client,
  massageFor = MassageFor.myself,
}: CovidVaccinationInfoProps) => {
  const getCovidMessage = () => {
    if (type === MessageType.pro) {
      return COVID_MESSAGE_FOR_PRO;
    }
    if (type === MessageType.client) {
      return massageFor === MassageFor.someoneElse
        ? COVID_MESSAGE_FOR_CLIENT_SOMEONE
        : COVID_MESSAGE_FOR_CLIENT_MYSELF;
    }
  };

  const getCovidCheckBoxMessage = () => {
    if (type === MessageType.pro) {
      return COVID_CHECKBOX_MESSAGE_FOR_MYSELF;
    }
    if (type === MessageType.client) {
      if (type === MessageType.client) {
        return massageFor === MassageFor.someoneElse
          ? COVID_CHECKBOX_MESSAFE_FOR_CLIENT_SOMEONE
          : COVID_CHECKBOX_MESSAGE_FOR_MYSELF;
      }
    }
  };

  return (
    <Box display={Styled.Display.Flex} flexDirection={Styled.FlexDirection.Column} >
      <Box fontFamily={Styled.FontFamily.Museo} fontSize={Styled.FontSize.F14} color={Colors.Dusk} fontWeight={Styled.FontWeight.Medium} mb={Styled.Spacing.S4} >
        COVID-19 vaccination status (optional)
      </Box>
      <Box fontFamily={Styled.FontFamily.OpenSans} fontSize={Styled.FontSize.F14} color={Colors.Dusk} fontWeight={Styled.FontWeight.Regular} mb={Styled.Spacing.S4}>
        {getCovidMessage()}
      </Box>
      <Box
        fontFamily="Museo"
        fontSize="16px"
        color={Colors.Dusk}
        fontWeight={400}
        lineHeight="24px"
        display={"flex"}
        alignItems={"center"}
        gridGap={"8px"}
      >
        <Checkbox
          style={{
            color: Colors.TurquoiseBlue,
            margin: 0,
            padding: 0,
          }}
          checked={isCovidVaccinated || false}
          onChange={(event: any) => {
            onCheckBoxChange(event.target.checked);
          }}
        />{" "}
        {getCovidCheckBoxMessage()}
      </Box>
      <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" my={"16px"} />
    </Box>
  );
};
export default CovidVaccinationInfo;
