import { Box } from "@material-ui/core";
import axios from "axios";
import { get, isNil } from "lodash";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import Button, { ButtonType } from "../../components/Button";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import PaginationControl from "../../components/PaginationControl";
import RecipientItem from "../../components/Recipients/RecipientItem";
import RecipientModal from "../../components/Recipients/RecipientModal";
import TextLink from "../../components/TextLink";
import { Paths } from "../../constants/paths";
import { parseApiError } from "../../helpers/error";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { Recipient } from "../../models";
import { trackEvent } from "../../services/segment/track.service";
import { MassageFor, STEP_PROGRESS } from "../../constants/booking";
import { fetchAddresses, useAddressStore } from "../../stores/address";
import { useAlertStore } from "../../stores/alert";
import { updateBooking, useBookingStore, useRecipientDetailsStore } from "../../stores/booking";
import { fetchLocationTypes } from "../../stores/types";
import { useUserStore } from "../../stores/user";
import queryClient from "../../config/queryClient";
import { QUERY_KEYS } from "../../constants/queryKey";
import pixelsService from "../../services/pixels/pixels.service";

export default function SavedRecipients(): JSX.Element {
  const accessToken = useAccessToken();
  const history = useHistory();
  const { state } = useLocation();
  const isMobile = useMobile();

  const next = get(state, "next");

  const { recipient, setRecipient, updatingForBooking } = useBookingStore();

  const { massageFor, setMassageFor } = useRecipientDetailsStore();
  const [page, setPage] = useState(1);

  const { data: recipientsData, mutate: recipientsMutate } = useSWR(
    `/users/me/recipients?accessToken=${accessToken}&page=${page}&paginate=true`,
    {
      onSuccess: (response) => {
        if (response?.data?.length === 0) {
          setMassageFor(MassageFor.myself);
        }
      },
    }
  );
  const recipients = recipientsData?.data ? (recipientsData?.data as [Recipient]) : [];

  const [selectedRecipientForEdit, setSelectedRecipientForEdit] = React.useState<Recipient | null>(
    null
  );

  const [showRecipientModal, setShowRecipientModal] = React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { user, fetchMe } = useUserStore();

  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    fetchLocationTypes();
    fetchMe();
    fetchAddresses();
    if (!massageFor && !recipient) {
      setMassageFor(MassageFor.myself);
    }
    trackEvent("Recipient Details Viewed", {
      step: 3,
      version: 2,
    });
    pixelsService.trackPageView();
  }, []);
  return (
    <>
      <NewBookingWrapper
        overline="Step 3 of 7"
        title="Recipient details"
        // subtitle={"Select from your list of saved recipients or add a new one"}
        footerLeftButton={
          <Button
            type={ButtonType.outlined}
            title={updatingForBooking ? "Back" : "Previous"}
            onClick={() => history.push(Paths.ServiceDetails)}
            style={{ padding: "12px 24px" }}
          />
        }
        footerRightButton={
          <Button
            loading={saving}
            type={ButtonType.indigo}
            style={{ padding: "12px 24px" }}
            title={"Continue"}
            onClick={() => {
              if (massageFor !== MassageFor.myself && isNil(recipient)) {
                setErrorMessage("Please select a recipient");
                return;
              }

              if (massageFor === MassageFor.someoneElse) {
                // Add recipient
                trackEvent("Recipient Details Completed", {
                  step: 3,
                  recipient_role: recipient?.relationship,
                  recipient_gender: recipient?.gender,
                  version: 2,
                });
              } else {
                // Myself
                trackEvent("Recipient Details Completed", {
                  step: 3,
                  recipient_role: "myself",
                  recipient_gender: user?.gender,
                  version: 2,
                });
              }

              if (updatingForBooking) {
                setSaving(true);

                updateBooking()
                  .then(() => {
                    setSaving(false);
                    history.goBack();
                    //invalidate the booking in recipient list and upcoming
                    queryClient.invalidateQueries([QUERY_KEYS.UPCOMING_BOOKINGS]);
                    queryClient.invalidateQueries([{ recipientId: recipient?.id }]);
                    // history.push(`/bookings/${updatingForBooking.id}`);
                  })
                  .catch((error) => {
                    setSaving(false);
                    setErrorMessage(parseApiError(error));
                  });
              } else {
                const nextPath = next || Paths.DateAndTime;
                const urlSearch = new URLSearchParams("");
                if (recipient) {
                  urlSearch.set("recipientId", recipient.id.toString());
                }

                const isEditing = get(state, "editing");

                history.push({
                  pathname: nextPath,
                  state: { preFetchPaymentMethod: false, isEditing },
                  search: urlSearch.toString(),
                });
              }
            }}
          />
        }
        progress={STEP_PROGRESS[3]}
      >
        <Box mt={4}>
          <RecipientItem
            selected={massageFor === MassageFor.myself}
            onSelected={() => {
              setRecipient(null);
              setMassageFor(MassageFor.myself);
            }}
            myself
            onEditClicked={() => {
              history.push(Paths.Profile);
            }}
          />

          {recipients.map((thisRecipient: Recipient) => (
            <RecipientItem
              recipient={thisRecipient}
              selected={massageFor === MassageFor.someoneElse && thisRecipient.id === recipient?.id}
              onSelected={() => {
                setRecipient(thisRecipient);
                setMassageFor(MassageFor.someoneElse);
              }}
              onEditClicked={() => {
                setSelectedRecipientForEdit(thisRecipient);
                setShowRecipientModal(true);
              }}
              onDeleteClicked={() => {
                axios
                  .delete(`/users/me/recipients/${thisRecipient.id}?accessToken=${accessToken}`)
                  .then((response) => {
                    recipientsMutate();
                    setSuccessMessage("Recipient deleted");
                  })
                  .catch((error) => {
                    setErrorMessage(parseApiError(error));
                  });
              }}
            />
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection={`${isMobile ? "column-reverse" : "row"}`}
          mt="15px"
          justifyContent={`${isMobile ? "center" : "space-between"}`}
          alignItems="center"
          marginBottom="25px"
          maxWidth={"100%"}
        >
          <Box mt={`${isMobile ? "20px" : "0px"}`}>
            <TextLink
              title="Add new recipient"
              onClick={() => {
                setSelectedRecipientForEdit(null);
                setShowRecipientModal(true);
              }}
            />
          </Box>
          <PaginationControl
            currentPage={recipientsData?.page}
            pages={recipientsData?.totalPage || 1}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            startFromOne={true}
          />
        </Box>
      </NewBookingWrapper>
      <RecipientModal
        existingRecipient={selectedRecipientForEdit}
        open={showRecipientModal}
        onClose={() => setShowRecipientModal(false)}
        onSaved={(recipient) => {
          setMassageFor(MassageFor.someoneElse);
          setRecipient(recipient);
          setShowRecipientModal(false);
          recipientsMutate();
        }}
      />
    </>
  );
}
