import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BOOKING_STATUS } from "../../../constants/booking";
import { Colors } from "../../../constants/colors";
import { BOOKING_TIP_TYPE } from "../../../constants/tip";
import { useMobile } from "../../../hooks/mobile";
import CloseIcon from "../../../images/modal-close.svg";
import ProfilePicturePlaceholder from "../../../images/profile-pic-placeholder.png";
import { PartialJobForTip, TipPerJob, TipSplitType } from "../../../types/tip.types";
import Button, { ButtonType } from "../../Button";
import Dialog from "../../Dialog";
import RadioButton from "../../RadioButton";
import TextButton from "../../TextButton/TextButton";
import { FontFamily, Spacing } from "../../v2/Styled/enum";
import { SingleProTippingModal } from "./SingleProTippingModal";
import { TippingAmounts } from "./TippingAmounts";
import { Booking } from "../../../models";
import { COUNTRY } from "../../../constants/countries";

type Props = {
  visible: boolean;
  onClose: () => void;
  onAdd: (tipByJob: TipPerJob[], splitType: TipSplitType) => void;
  baseRate: number;
  currency: string;
  bookingType?: BOOKING_STATUS;
  jobs: PartialJobForTip[];
  bookingTips: Booking["bookingTips"];
  countryCode: string;
};

export const MultipleProTippingModal = ({
  bookingType = BOOKING_STATUS.NEW,
  bookingTips,
  ...props
}: Props) => {
  const [tipAmount, setTipAmount] = useState(0);
  const [tipCardId, setTipCardId] = useState<string | undefined>(undefined);

  const [selectedSplitType, setSelectedSplitType] = useState<TipSplitType>("even");
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [tipPerJobs, setTipsPerJob] = useState<TipPerJob[]>([]);

  const selectedJob = props.jobs.find((job) => job.id === selectedJobId);
  const selectedTipPerJob = tipPerJobs.find((tip) => tip.jobId === selectedJobId);

  const isMobile = useMobile();

  useEffect(() => {
    // if booking tips is evenly splited then set tip amount and tip card id
    if (bookingTips.length > 0 && bookingTips[0].equallySplit) {
      setTipCardId(bookingTips[0].type);
      setTipAmount(bookingTips.reduce((acc, tip) => acc + tip.amount, 0));

      const tips = bookingTips.map((tip) => ({
        jobId: tip.jobId,
        tipAmount: tip.amount,
        tipId: BOOKING_TIP_TYPE.CUSTOM,
      }));
      setTipsPerJob(tips);

      setSelectedSplitType("even");
    }

    // if booking tips is not evenly splited then set tip per job
    if (bookingTips.length > 0 && !bookingTips[0].equallySplit) {
      const tips = bookingTips.map((tip) => ({
        jobId: tip.jobId,
        tipAmount: tip.amount,
        tipId: tip.type,
      }));
      setTipsPerJob(tips);
      setSelectedSplitType("individual");
    }
  }, []);

  const onSplitTypeChanged = (type: TipSplitType) => {
    setSelectedSplitType(type);
  };

  const onTipAddedToJob = (tipAmount: number, cardId: string) => {
    // find the job in the list and update the tip amount
    const tip = tipPerJobs.find((tip) => tip.jobId === selectedJobId);
    if (tip) {
      tip.tipAmount = tipAmount;
      tip.tipId = cardId;
    } else {
      tipPerJobs.push({ jobId: selectedJobId!, tipAmount, tipId: cardId });
    }

    setTipsPerJob([...tipPerJobs]);
    setSelectedJobId(null);
  };

  const onAddTipClicked = () => {
    let tips: TipPerJob[] = tipPerJobs;

    if (selectedSplitType === "even") {
      const splitAmount = Number((tipAmount / props.jobs.length || 1).toFixed(2));
      tips = props.jobs.map(
        (job): TipPerJob => ({
          jobId: job.id,
          tipAmount: splitAmount,
          tipId: tipCardId,
        })
      );
    }

    props.onAdd(tips, selectedSplitType);
    props.onClose();
  };

  return (
    <>
      <Dialog
        open={props.visible}
        fullWidth={isMobile}
        maxWidth={"md"}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: !isMobile ? "544px" : undefined,
            paddingTop: !isMobile ? "30px" : "20px",
            paddingBottom: !isMobile ? "30px" : "20px",
            gap: "24px",
            boxSizing: "border-box",
          },
        }}
      >
        {/* Dialog Title */}
        <Box paddingX={!isMobile ? "30px" : "20px"}>
          <Box display={"flex"} gridGap={"8px"} flexDirection={"row"}>
            <Typography>
              <Box sx={Styles.title}>Add tip</Box>
            </Typography>
            <Box position={"absolute"} top={"8px"} right={"8px"}>
              <IconButton
                onClick={() => {
                  props.onClose();
                }}
              >
                <img src={CloseIcon} alt="closeIcon" />
              </IconButton>
            </Box>
          </Box>
        </Box>

        {/* Dialog content */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          gridGap={"24px"}
          paddingX={!isMobile ? "30px" : "20px"}
        >
          {/* Split evenly section */}
          <Box display={"flex"} flexDirection={"column"} gridGap={"16px"}>
            <Box display={"flex"} alignItems={"start"}>
              <RadioButton
                selected={selectedSplitType === "even"}
                onSelected={() => onSplitTypeChanged("even")}
                style={{ marginTop: "-4px" }}
              />
              <Typography>
                <Box sx={Styles.secondaryTitle}>Split evenly between providers</Box>
                <Box sx={Styles.text}>
                  Providers receive your tip in full, with a{" "}
                  {props.countryCode === COUNTRY.US ? "5" : "3"}% processing fee
                </Box>
              </Typography>
            </Box>
            {selectedSplitType === "even" && (
              <Box paddingLeft={"35px"}>
                <Typography>
                  <Box fontWeight={700} fontSize={"14px"} color={Colors.Dusk}>
                    Add tip
                  </Box>
                  <Box paddingY={"4px"}>
                    <TippingAmounts
                      baseRate={props.baseRate}
                      bookingType={bookingType}
                      currency={props.currency}
                      therapistCount={props.jobs.length}
                      onTipUpdate={(tipAmount, cardId) => {
                        setTipAmount(tipAmount);
                        setTipCardId(cardId);
                      }}
                      cardSize="sm"
                      cardSpacing="compact"
                      selectedCardId={tipCardId}
                      customTipAmount={
                        tipCardId === BOOKING_TIP_TYPE.CUSTOM ? tipAmount : undefined
                      }
                      countryCode={props.countryCode}
                    />
                  </Box>
                </Typography>
              </Box>
            )}
          </Box>
          <Divider />
          {/* Tip individually */}
          <Box display={"flex"} flexDirection={"column"} gridGap={"16px"}>
            <Box display={"flex"} alignItems={"start"}>
              <RadioButton
                selected={selectedSplitType === "individual"}
                onSelected={() => onSplitTypeChanged("individual")}
                style={{ marginTop: "-4px" }}
              />
              <Typography>
                <Box sx={Styles.secondaryTitle}>Tip each provider separately</Box>
                <Box sx={Styles.text}>Each provider will receive the amount you sent for them.</Box>
              </Typography>
            </Box>
            {selectedSplitType === "individual" && (
              <Box display={"flex"} flexDirection={"column"} paddingX={"35px"} gridGap={"16px"}>
                {/* Pro Detail */}
                {props.jobs.map((job) => (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={"16px"}
                    border={"1px solid"}
                    borderColor={Colors.LightPeriwinkle}
                    borderRadius={"4px"}
                    key={job.id}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"center"}
                      gridGap={"16px"}
                    >
                      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <img
                          src={job.user.profileImage ?? ProfilePicturePlaceholder}
                          alt={job.user.firstName}
                          style={Styles.profileImageStyle}
                        />
                      </Box>
                      <Typography>
                        <Box
                          fontWeight={700}
                          fontSize={"16px"}
                          lineHeight={"24px"}
                          color={Colors.Dusk}
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {job.user.firstName}
                        </Box>
                      </Typography>
                    </Box>
                    <Box>
                      <TextButton
                        textStyle={{ color: Colors.TurquoiseBlue, fontSize: "14px" }}
                        onClick={() => {
                          setSelectedJobId(job.id);
                        }}
                        text={tipPerJobs.find((tips) => tips.jobId === job.id) ? "Change" : "Add"}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>

        <Divider />

        {/*  Dialog Actions */}
        <Box
          display={"flex"}
          justifyContent={"end"}
          gridGap={Spacing.S4}
          width={"100%"}
          paddingX={!isMobile ? "30px" : "20px"}
          boxSizing={"border-box"}
        >
          <Button
            type={ButtonType.outlined}
            width={"103px"}
            onClick={props.onClose}
            title="Cancel"
          />
          <Button type={ButtonType.primary} width={"103px"} onClick={onAddTipClicked} title="Add" />
        </Box>
      </Dialog>

      {selectedJobId && (
        <SingleProTippingModal
          baseRate={props.baseRate}
          currency={props.currency}
          visible={selectedJobId !== null}
          onClose={() => setSelectedJobId(null)}
          onAdd={onTipAddedToJob}
          proImage={selectedJob?.user.profileImage}
          proName={selectedJob?.user.firstName}
          bookingType={bookingType}
          therapistCount={1}
          selectedCardId={selectedTipPerJob?.tipId}
          tipAmount={selectedTipPerJob?.tipAmount}
          countryCode={props.countryCode}
        />
      )}
    </>
  );
};

const Styles = {
  title: {
    fontWeight: 700,
    fontSize: "28px",
    color: Colors.NightBlue,
    textAlign: "center",
  },
  secondaryTitle: {
    fontSize: "18px",
    color: Colors.NightBlue,
    fontWeight: 700,
    lineHeight: "27px",
  },
  text: {
    fontFamily: FontFamily.OpenSans,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
  },
  profileImageStyle: {
    width: "40px",
    height: "40px",
    borderRadius: "48px",
    "object-fit": "cover",
  },
};
