import { Box } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useMobile } from "../../../hooks/mobile";
import Dialog from "../../Dialog";
import { CustomLottieAnimation } from "../../LottieAnimation/CustomLottieAnimation";
import * as Styled from "../../v2/Styled/enum";
import "./FadeInAnimation.css";

type Props = {
  visible: boolean;
  onClose: () => void;
  animationData: any;
  children: React.ReactNode;
  speed?: number;
};

/**
 * This animation modal slide up the animation icon after completing it and description and action component are displayed below the completed animation icon.
 */
export const FadeInAnimationModal = ({ speed = 1, ...props }: Props): JSX.Element => {
  const actionComponentRef = useRef(null);
  const [isAnimationComplete, setIsAnimationComplete] = useState<boolean>(false);

  const isMobile = useMobile();

  useEffect(() => {
    // clean up function
    return () => {
      setTimeout(() => {
        setIsAnimationComplete(false);
      }, 500);
    };
  }, []);

  const onAnimationComplete = () => setIsAnimationComplete(true);

  return (
    <Dialog open={props.visible} fullWidth={isMobile} maxWidth={"xs"} onClose={props.onClose}>
      <div className={"wrapper"}>
        <div className={"check-wrapper"}></div>
        <Box
          width={isMobile ? "100%" : "444px"}
          display={Styled.Display.Flex}
          alignItems={Styled.AlignItems.center}
          justifyContent={Styled.JustifyContent.center}
          className={`animation-wrapper ${isAnimationComplete ? "collapsed" : "expanded"}`}
        >
          <CustomLottieAnimation
            animationData={props.animationData}
            autoPlay
            loop={false}
            onAnimationComplete={onAnimationComplete}
            styles={{ width: "300px", height: "300px" }}
            speed={speed}
          />
        </Box>
        <div
          id={"action-wrapper-id"}
          ref={actionComponentRef}
          className={`action-wrapper ${isAnimationComplete ? "desc-collapsed" : "desc-expanded"}`}
          style={{
            gap: Styled.Spacing.S6,
            display: Styled.Display.Flex,
            paddingLeft: Styled.Spacing.S6,
            paddingRight: Styled.Spacing.S6,
            marginBottom: Styled.Spacing.S6,
            flexDirection: Styled.FlexDirection.Column,
          }}
        >
          {props.children}
        </div>
      </div>
    </Dialog>
  );
};
