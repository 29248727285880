import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../../../stores/V2";
import { ClientReview } from "../../../../models";
import { checkIfEmpty } from "../../../../utils/object";
import BlockModal from "../../Modals/UserRatingModal/BlockModal";
import TextButton from "../../../../components/TextButton/TextButton";
import FeedbackModal from "../../Modals/UserRatingModal/FeedbackModal";
import { CompletedModalType } from "../../../../data/treatmentNotesEnum";
import UserRatingModal from "../../Modals/UserRatingModal/UserRatingModal";
import TreatmentNoteModal from "../../Modals/UserRatingModal/TreatmentNoteModal";
import { actions as bookingActions } from "../../../../stores/V2/booking/booking";
import SectionHeader from "../../../../components/Headers/SectionHeader/SectionHeader";
import ReviewRatingCard from "../../../../components/Cards/ReviewRatingCard/ReviewRatingCard";
import { Display, FlexDirection, JustifyContent, Spacing } from "../../../../components/v2/Styled/enum";
interface Props {
  timezone: string;
  review?: ClientReview;
  jobId: number | string;
  refreshJob: () => unknown;
}

const ClientRating = ({ review, jobId, timezone, refreshJob }: Props) => {

  const bookingStore = useSelector(({ booking }: RootState) => booking);
  const dispatch = useDispatch();

  const { showUserRatingModal } = bookingStore;

  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);

  const [reviewData, setReviewData] = useState({});
  const [noteData, setNoteData] = useState({});
  const [blockData, setBlockedData] = useState({});
  const [selectedReviewId, setSelectedReviewId] = useState<number | null>(null);

  const closeRatingModal = () => {
    dispatch(bookingActions.setShowUserRatingModal(false));
  };

  useEffect(() => {
    if (showUserRatingModal) setOpenFeedbackModal(showUserRatingModal);
  }, [showUserRatingModal]);

  const onCloseRating = () => {
    setSelectedOption("");
    closeRatingModal();
    setOpenFeedbackModal(false);
    setOpenRatingModal(false);
    setOpenBlockModal(false);
    setOpenNoteModal(false);
  };

  const onReviewAction = () => {
    if (checkIfEmpty(review)) {
      dispatch(bookingActions.setShowUserRatingModal(true));
    } else {
      setOpenRatingModal(true);
      if (review) {
        setReviewData(review);
        if (review.id) setSelectedReviewId(review.id);
      }
    }
  };

  const { review: reviewText, updatedAt: date } = review || {};

  const LoadActionModal = () => {
    switch (selectedOption) {
    case CompletedModalType.RATE_AND_REVIEW:
      setOpenRatingModal(true);
      setOpenFeedbackModal(false);
      break;
    case CompletedModalType.ADD_TREATMENT_NOTE:
      setOpenNoteModal(true);
      setOpenFeedbackModal(false);
      break;
    case CompletedModalType.BLOCK_CLIENT:
      setOpenBlockModal(true);
      setOpenFeedbackModal(false);
      break;
    }
  };

  useEffect(() => {
    LoadActionModal();
  }, [selectedOption]);

  const handleAddReview = (data: any) => {
    if (selectedReviewId) {
      setReviewData(data);
      setSelectedOption("");
      setOpenRatingModal(false);
    } else {
      setReviewData(data);
      setSelectedOption("");
      setOpenRatingModal(false);
      setOpenFeedbackModal(true);
    }
  };

  const handleAddNote = (data: any) => {
    setNoteData(data);
    setSelectedOption("");
    setOpenRatingModal(false);
    setOpenFeedbackModal(true);
  };

  const handleBlockUser = (data: any) => {
    setBlockedData(data);
    setSelectedOption("");
    setOpenRatingModal(false);
    setOpenFeedbackModal(true);
  };

  const handleReviewModalClose = () => {
    if (selectedReviewId) {
      setOpenRatingModal(false);
      setSelectedOption("");
    } else {
      setOpenFeedbackModal(true);
      setOpenRatingModal(false);
      setSelectedOption("");
    }
  };

  return (
    <Box>
      <Box
        gridGap={Spacing.S2}
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
      >
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          justifyContent={JustifyContent.spaceBetween}
        >
          <SectionHeader title="Client rating"/>
          <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3}>
            <TextButton
              type="primary"
              text={checkIfEmpty(review) ? "Leave a review" : "Update review"}
              textStyle={{ padding: 0 }}
              onClick={onReviewAction}
            />
          </Box>
        </Box>

        {review && !checkIfEmpty(review) && (
          <Box>
            <ReviewRatingCard rating={review.rating} text={reviewText} date={date} timezone={timezone} />
          </Box>
        )}
      </Box>

      <FeedbackModal
        jobId={jobId}
        visible={showUserRatingModal && openFeedbackModal}
        onModalClose={onCloseRating}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        reviewData={reviewData}
        removeReviewData={() => setReviewData({})}
        noteData={noteData}
        removeNoteData={() => setNoteData({})}
        blockData={blockData}
        removeBlockedData={() => setBlockedData({})}
      />

      <UserRatingModal
        jobId={jobId}
        review={reviewData}
        visible={openRatingModal}
        onClose={handleReviewModalClose}
        selectedReviewId={selectedReviewId}
        onAddReview={handleAddReview}
      />

      <TreatmentNoteModal
        visible={openNoteModal}
        onClose={() => {
          setOpenFeedbackModal(true);
          setOpenNoteModal(false);
          setSelectedOption("");
        }}
        onAddedNote={handleAddNote}
        noteData={noteData}
      />

      <BlockModal
        open={openBlockModal}
        onClose={() => {
          setOpenFeedbackModal(true);
          setOpenBlockModal(false);
          setSelectedOption("");
        }}
        onBlockClicked={handleBlockUser}
        blockData={blockData}
      />
    </Box>
  );
};

export default ClientRating;
