import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Redirect,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import "./App.css";
import GlobalScrollToTop from "./components/GlobalScrollToTop";
import MobileAppNewPaymentMethod from "./components/MobileAppNewPaymentMethod";
import MobileAppNewPaymentMethodSuccessful from "./components/MobileAppNewPaymentMethodSuccessful";
import WithSuspense from "./components/WithSuspense";
import { Paths } from "./constants/paths";
import Addresses from "./pages/Addresses";
import AfterpayProcessOrder from "./pages/Afterpay/AfterpayProcessOrder";
import AfterpaySuccess from "./pages/Afterpay/AfterpaySuccess";
import ApplePay from "./pages/ApplePay/ApplePay";
import Schedules from "./pages/BlysTeam/Schedules";
import TeamSettings from "./pages/BlysTeam/Settings";
import ListSlots from "./pages/BlysTeam/Slot";
import ConfirmSlot from "./pages/BlysTeam/Slot/ConfirmSlot";
import SlotReserved from "./pages/BlysTeam/Slot/SlotReserved";
import Bookings from "./pages/Bookings";
import AlternativeOffer from "./pages/Bookings/AlternativeOffer/AlternativeOffer";
import BookingDetails from "./pages/Bookings/BookingDetails";
import ConfirmedBookingDetails from "./pages/Bookings/ConfirmedBookingDetails";
import Calendar from "./pages/Calendar";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import InviteFriends from "./pages/InviteFriends";
import JobDetails from "./pages/Job/JobDetails";
import Landing from "./pages/Landing";
import Login from "./pages/Login/Login";
import MfaLogin from "./pages/Login/MfaLogin";
import LoginAs from "./pages/LoginAs";
import Membership from "./pages/Membership";
import NewBooking from "./pages/NewBooking/Booking";
import BookingFrequency from "./pages/NewBooking/BookingFrequency";
import DateAndTimeWrapper from "./pages/NewBooking/DateAndTimeWrapper";
import NewBookingLanding from "./pages/NewBooking/Landing";
import LocationDetails from "./pages/NewBooking/LocationDetails";
import PreferredProviders from "./pages/NewBooking/PreferredProviders";
import RecipientDetails from "./pages/NewBooking/RecipientDetails";
import ReviewAndBook from "./pages/NewBooking/ReviewAndBook";
import ReviewAndBookUser from "./pages/NewBooking/ReviewAndBookUser";
import SavedLocations from "./pages/NewBooking/SavedLocations";
import SavedRecipients from "./pages/NewBooking/SavedRecipients";
import Payment from "./pages/Payment";
import PractitionerSignUp from "./pages/PractitionerSignUp";
import AgreeLinkDocument from "./pages/ProDashboard/Agreement/AgreeLinkDocument";
import ProDashboardAvailability from "./pages/ProDashboard/Availability";
import ProDashboardBookings from "./pages/ProDashboard/Bookings";
import ProDashboardBusiness from "./pages/ProDashboard/Business";
import ProDashboard from "./pages/ProDashboard/Dashboard";
import ProReviewLocation from "./pages/ProDashboard/ProReviewLocation";
import ProDashboardProfile from "./pages/ProDashboard/Profile";
import ProDashboardReferrals from "./pages/ProDashboard/Referrals/Referrals";
import ProDashboardReports from "./pages/ProDashboard/Reports";
import ProDashboardReviews from "./pages/ProDashboard/Reviews";
import ProDashboardServiceArea from "./pages/ProDashboard/ServiceArea/ServiceAreas";
import ProDashboardBusinessPayoutInformation from "./pages/ProDashboard/Business/Payouts/PayoutInformation";
import { ServiceAreaProvider } from "./pages/ProDashboard/ServiceArea/context/ServiceAreaState";
import ProDashboardServices from "./pages/ProDashboard/Services";
import ProDashboardSettings from "./pages/ProDashboard/Settings";
import TreatmentAddOn from "./pages/ProDashboard/TreatmentAddOn";
import Profile from "./pages/Profile";
import PublicTherapists from "./pages/Public/ProDirectory";
import PublicReviews from "./pages/Public/Reviews";
import PublicTherapistProfile from "./pages/PublicTherapistProfile";
import Purchases from "./pages/Purchases";
import Recipients from "./pages/Recipients";
import ResetInstructionsSent from "./pages/ResetInstructionsSent";
import ResetPassword from "./pages/ResetPassword";
import ReviewBookingFrequency from "./pages/ReviewBooking/ReviewBookingFrequency";
import ReviewBookingRecipient from "./pages/ReviewBooking/ReviewBookingRecipient";
import ReviewDateAndTime from "./pages/ReviewBooking/ReviewDateAndTime";
import ReviewLocation from "./pages/ReviewBooking/ReviewLocation";
import ReviewTreatment from "./pages/ReviewBooking/ReviewTreatment";
import SignUp from "./pages/SignUp/SignUp";
import Therapists from "./pages/Therapists";
import Users from "./pages/Users/Users";
import VerifyInvitation from "./pages/VerifyInvitation";
import PurchaseVoucher from "./pages/Voucher/PurchaseVoucher";
import PurchaseVoucherPayment from "./pages/Voucher/PurchaseVoucherPayment";
import PurchaseVoucherSignUp from "./pages/Voucher/PurchaseVoucherSignUp";
import PurchaseVoucherSuccess from "./pages/Voucher/PurchaseVoucherSuccess";
import { redirect, redirectShortLinkUrl } from "./services/applinking/LinkingRedirection";
import { fetchCountryCode } from "./utils/country";
import { checkIsAUdomain, getDomainReplacedCurrentURL } from "./helpers/url";
import DomainRedirect from "./pages/Redirect/DomainRedirect";
import AddRecipientPage from "./pages/Recipient/AddRecipientPage";
import RecipientDetailsPage from "./pages/Recipient/RecipientPage";
import TrackBookingSource from "./pages/NewBooking/TrackBookingSource";
import PublicBookings from "./pages/Public/PublicBookings";
import JobDetailsPublic from "./pages/Public/JobDetailsPublic";

interface AuthRedirectRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const ChatWithProvidersPage = WithSuspense(React.lazy(() => import("./pages/Bookings/Chat")));
const ChatWithClientsPage = WithSuspense(
  React.lazy(() => import("./pages/ProDashboard/Bookings/Chat")),
);

const AuthRedirectRoute: React.FC<AuthRedirectRouteProps> = ({ component: Component, ...rest }) => {
  const hasToken = !!localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={(props) => (hasToken ? <Redirect to={Paths.Bookings} /> : <Component {...props} />)}
    />
  );
};

function App() {
  const redirectAUdomain = () => {
    const isAUDomain = checkIsAUdomain();
    if (isAUDomain) {
      const redirectURL = getDomainReplacedCurrentURL();
      localStorage.removeItem("token");
      window.location.href = redirectURL;
    }
  };

  useEffect(() => {
    fetchCountryCode();
    redirectAUdomain();
  }, []);

  const RedirectShortLinks = () => {
    const { pathname = "" } = useLocation();
    return redirectShortLinkUrl(pathname);
  };

  const InternalRedirection = () => {
    const { search } = useLocation();
    return redirect(search);
  };

  return (
    <Router>
      <GlobalScrollToTop />
      <Route component={TrackBookingSource} />
      <Switch>
        <AuthRedirectRoute exact path={Paths.Login} component={Login} />
        <Route exact path={Paths.MfaLogin} component={MfaLogin} />
        <AuthRedirectRoute exact path={Paths.SignUp} component={SignUp} />
        <Route exact path={Paths.ForgotPassword} component={ForgotPassword} />
        <Route exact path={Paths.ResetPassword} component={ResetPassword} />
        <Route exact path="/reset-instructions-sent" component={ResetInstructionsSent} />
        <Route exact path={Paths.Bookings} component={Bookings} />
        <Route exact path={Paths.Therapists} component={Therapists} />
        <Route exact path={Paths.BlockedTherapists} component={Therapists} />
        <Route exact path={Paths.RecentTherapists} component={Therapists} />
        <Route exact path={Paths.BrowseTherapists} component={Therapists} />
        <Route exact path={Paths.Addresses} component={Addresses} />
        <Route exact path={Paths.Payment} component={Payment} />
        <Route exact path={Paths.Profile} component={Profile} />
        <Route exact path={Paths.Business} component={Profile} />
        <Route exact path={Paths.Preferences} component={Profile} />
        <Route exact path={Paths.TermsAndPolicies} component={Profile} />
        <Route exact path={Paths.Membership} component={Membership} />
        <Route exact path={Paths.Recipients} component={Recipients} />
        <Route exact path={Paths.AddRecipient} component={AddRecipientPage} />
        <Route exact path={Paths.RecipientDetailsPage} component={RecipientDetailsPage} />
        <Route exact path={Paths.Purchases} component={Purchases} />

        {/* New booking */}
        <Route exact path={Paths.NewBookingLanding} component={NewBookingLanding} />
        <Route exact path={Paths.NewBooking} component={LocationDetails} />
        <Route exact path={Paths.ServiceDetails} component={NewBooking} />
        <Route exact path={Paths.RecipientDetails} component={RecipientDetails} />
        <Route exact path={Paths.LocationDetails} component={LocationDetails} />
        <Route exact path={Paths.SavedLocations} component={SavedLocations} />
        <Route exact path={Paths.SavedRecipients} component={SavedRecipients} />
        <Route exact path={Paths.DateAndTime} component={DateAndTimeWrapper} />
        <Route exact path={Paths.BookingFrequency} component={BookingFrequency} />
        <Route exact path={Paths.PreferredProviders} component={PreferredProviders} />
        <Route exact path={Paths.ReviewAndBook} component={ReviewAndBook} />
        <Route exact path={Paths.ReviewAndBookUser} component={ReviewAndBookUser} />

        {/* Bookings */}
        <Route exact path="/bookings/:id" component={BookingDetails} />
        <Route exact path="/bookings/:id/alternative-offer" component={AlternativeOffer} />
        <Route exact path={"/bookings/:id/chat"} component={ChatWithProvidersPage} />

        {/* Job details */}
        <Route exact path="/pro/bookings/:id" component={JobDetails} />
        <Route exact path="/pro/bookings/:id/chat" component={ChatWithClientsPage} />

        {/* review bookings routes */}
        <Route exact path="/bookings/review/:id" component={ConfirmedBookingDetails} />
        <Route exact path="/bookings/datetime/:id" component={ReviewDateAndTime} />
        <Route exact path="/bookings/recipient/:id" component={ReviewBookingRecipient} />
        <Route exact path="/bookings/booking-frequency/:id" component={ReviewBookingFrequency} />
        <Route exact path="/bookings/treatments/:id" component={ReviewTreatment} />
        <Route exact path="/pro/boooking-add-on/:id" component={TreatmentAddOn} />
        <Route
          exact
          path={`${Paths.ReviewConfirmedBookingLocation}/:id`}
          component={ReviewLocation}
        />

        <Route
          exact
          path={`${Paths.ProReviewConfirmedBookingLocation}/:id`}
          component={ProReviewLocation}
        />

        {/* Invite friends */}
        <Route exact path="/invite-friends" component={InviteFriends} />

        {/* Verify Invitation */}
        <Route exact path="/invitation/:code" component={VerifyInvitation} />

        {/* Calendar  */}
        <Route exact path="/calendar/" component={Calendar} />

        <AuthRedirectRoute exact path="/provider-signup" component={PractitionerSignUp} />
        <Route exact path="/practitioner-signup">
          <Redirect to={"/provider-signup"} />
        </Route>

        {/* Voucher */}
        <Route exact path={Paths.PurchaseVoucher} component={PurchaseVoucher} />
        <Route exact path={Paths.PurchaseVoucherSignUp} component={PurchaseVoucherSignUp} />
        <Route exact path={Paths.PurchaseVoucherPayment} component={PurchaseVoucherPayment} />
        <Route path={Paths.AfterpayVoucherSuccess} component={PurchaseVoucherPayment} />
        <Route path={Paths.AfterpayVoucherError} component={PurchaseVoucherPayment} />

        <Route exact path={Paths.PurchaseVoucherSuccess} component={PurchaseVoucherSuccess} />

        {/* Afterpay */}
        <Route path={Paths.AfterpayProcessOrder} component={AfterpayProcessOrder} />
        <Route path={Paths.AfterpaySuccess} component={AfterpaySuccess} />
        <Route path={Paths.AfterpayError} component={AfterpayProcessOrder} />

        {/* Pro Dashboard */}
        <Route exact path={Paths.AgreeLinkDocument} component={AgreeLinkDocument} />
        <Route exact path={Paths.ProDashboard} component={ProDashboard} />
        <Route exact path={Paths.ProDashboardProfile} component={ProDashboardProfile} />
        <Route exact path={Paths.ProDashboardServices} component={ProDashboardServices} />
        <Route exact path={Paths.ProDashboardBusiness} component={ProDashboardBusiness} />
        <Route
          exact
          path={Paths.ProDashboardBusinessPayoutInformation}
          component={ProDashboardBusinessPayoutInformation}
        />

        <Route exact path={Paths.ProDashboardReviews} component={ProDashboardReviews} />

        <Route
          exact
          path={Paths.ProDashboardBookings}
          render={() => (
            <ServiceAreaProvider>
              <ProDashboardBookings />
            </ServiceAreaProvider>
          )}
        />

        /* public bookings routes */
        <Route
          exact
          path={Paths.ProPublicBookings}
          render={() => (
            <ServiceAreaProvider>
              <PublicBookings />
            </ServiceAreaProvider>
          )}
        />
        <Route exact path="/public/bookings/:id" component={JobDetailsPublic} />
        /* public bookings routes */

        <Route exact path={Paths.ProDashboardReports} component={ProDashboardReports} />

        <Route
          exact
          path={Paths.ProDashboardServiceAreas}
          render={() => (
            <ServiceAreaProvider>
              <ProDashboardServiceArea />
            </ServiceAreaProvider>
          )}
        />

        <Route exact path={Paths.ProDashboardAvailability} component={ProDashboardAvailability} />

        <Route exact path={Paths.ProDashboardSettings} component={ProDashboardSettings} />
        <Route exact path={Paths.Referrals} component={ProDashboardReferrals} />

        <Route exact path="/practitioner/:id" component={PublicTherapistProfile} />

        {/* Add the route for user. */}
        <Route exact path={Paths.Users} component={Users} />
        {/* Blys Team Settings */}
        <Route exact path={Paths.Settings} component={TeamSettings} />

        {/* Login As */}
        <Route exact path="/my-account/login-as" component={LoginAs} />

        <Route path="/new-payment-method" component={MobileAppNewPaymentMethod} />
        <Route path="/new-payment-successful" component={MobileAppNewPaymentMethodSuccessful} />
        {/* Public */}
        <Route exact path={Paths.PublicReviews} component={PublicReviews} />
        <Route exact path={Paths.PublicTherapists} component={PublicTherapists} />
        <Route exact path={`${Paths.PublicTherapists}/:location`} component={PublicTherapists} />

        {/* Apple Pay - Special page for Expo mobile app use */}
        <Route exact path={Paths.ApplePay} component={ApplePay} />

        {/* Slots Booking by Team */}
        <Route exact path={`${Paths.CorporateSlots}/:uuid`} component={ListSlots} />
        <Route
          exact
          path={`${Paths.CorporateSlots}/:uuid/:slotId/confirm`}
          component={ConfirmSlot}
        />
        <Route
          exact
          path={`${Paths.CorporateSlots}/:uuid/:slotId/reserved`}
          component={SlotReserved}
        />

        <Route exact path={`${Paths.CorporateSlots}/:uuid/schedules`} component={Schedules} />

        <Route path={Paths.DomainRedirect} component={DomainRedirect} />

        <Route path={Paths.Redirect}>
          <InternalRedirection />
        </Route>

        {/*Short url mapping*/}
        {/* Pro side */}
        <Route path={Paths.ShortUrlPro}>
          <RedirectShortLinks />
        </Route>
        {/* Client side */}
        <Route path={Paths.ShortUrlClient}>
          <RedirectShortLinks />
        </Route>
        {/* Default path */}
        <AuthRedirectRoute component={Landing} />
      </Switch>
    </Router>
  );
}

export default App;
