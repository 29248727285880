import { useMutation, useQuery } from "@tanstack/react-query";
import { get, put } from "../api/client";
import { DefaultTipOption, TipPerJob } from "../types/tip.types";
import { equal } from "assert";
import { useAlertStore } from "../stores/alert";

export const TIP_QUERY_KEYS = {
  tipOptions: "api/v2/tips/options",
  tipInfo: "bookings/price",
};

const TIP_MUTATION_KEY = {
  updateTip: (bookingId: string) => `api/v2/tips/booking/${bookingId}`,
};

export const useDefaultTipOptionsQuery = () => {
  const { data } = useQuery(
    [TIP_QUERY_KEYS.tipOptions],
    () => {
      return get(TIP_QUERY_KEYS.tipOptions);
    },
    {
      staleTime: Infinity,
    }
  );

  return { defaultTipOptions: ((data as any)?.data?.options ?? []) as DefaultTipOption[] };
};

export const useTipInfoQuery = () => {
  const { data } = useQuery(
    [TIP_QUERY_KEYS.tipInfo],
    () => {
      return get(TIP_QUERY_KEYS.tipInfo);
    },
    {
      staleTime: Infinity,
    }
  );

  return { tipInfo: (data as any)?.tipInfo as { title: string; description: string } };
};

export const useUpdateTipMutation = () => {
  const { setErrorMessage } = useAlertStore();
  return useMutation({
    mutationFn: async (variable: {
      bookingId: string;
      tipPerJobs: TipPerJob[];
      isEvenlySplit: boolean;
    }) => {
      let payload = {
        equallySplit: false,
        tips: [] as any[],
      };

      if (variable.isEvenlySplit) {
        payload = {
          equallySplit: true,
          tips: [
            {
              id: variable.tipPerJobs[0].tipId,
              value: variable.tipPerJobs[0].tipAmount,
            },
          ],
        };
      } else {
        payload = {
          equallySplit: false,
          tips: [],
        };

        variable.tipPerJobs.forEach((tip) => {
          payload.tips.push({
            id: tip.tipId,
            value: tip.tipAmount, // used only if tipId is custom
            jobId: tip.jobId,
          });
        });
      }

      return await put(TIP_MUTATION_KEY.updateTip(variable.bookingId), payload);
    },
    onError: (error: any) => {
      console.log(error.message);
      setErrorMessage("Error while updating tip amount");
    },
  });
};
