import React from "react";
import { Box, Link } from "@material-ui/core";
import HeroImage from "../../../images/no-bookings-placeholder.svg";
import { Colors } from "../../../constants/colors";

export enum NoNotesPlaceholderType {
    clientnote,
    providernote,
}

interface Props {
    type: NoNotesPlaceholderType;
    onAddNoteClicked?: () => unknown;
    onViewProviderNotes?: ()=> unknown;
}

export default function NoNotesPlaceholder({
  type,
  onAddNoteClicked,
  onViewProviderNotes,
}: Props): JSX.Element {

  if (type === NoNotesPlaceholderType.clientnote) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" width="300px">
        <img src={HeroImage} />
        <Box
          fontFamily="Museo"
          fontSize="20px"
          textAlign="center"
          fontWeight={700}
          color={Colors.Dusk}
          mt="19px"
        >
                    No notes added
        </Box>

        <Box
          fontFamily="Open Sans"
          fontSize="14px"
          color={Colors.Dusk}
          letterSpacing="0.39px"
          textAlign="center"
          mt="10px"
        >
          <Link style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }} onClick={onAddNoteClicked}>
                        Add a note
          </Link>
                    , or{" "}
          <Link style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }} onClick={onViewProviderNotes}>
                    view notes from providers.
          </Link>
        </Box>
      </Box>
    );
  }

  if (type === NoNotesPlaceholderType.providernote) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" width="300px">
        <img src={HeroImage} />
        <Box
          fontFamily="Museo"
          fontSize="20px"
          textAlign="center"
          fontWeight={700}
          color={Colors.Dusk}
          mt="19px"
        >
                    No notes from other providers
        </Box>

        <Box
          fontFamily="Open Sans"
          fontSize="14px"
          color={Colors.Dusk}
          letterSpacing="0.39px"
          textAlign="center"
          mt="10px"
        >
                    All treatment notes from the providers for this client will appear here.
        </Box>
      </Box>
    );
  }

  return <Box />;
}
