import React from "react";
import { Box } from "@material-ui/core";
import { getValue } from "../../../../utils/object";
import * as Styled from "../../../../components/v2/Styled/enum";
import StripeTos from "../../../../components/ProDashboard/Business/Payouts/CoBranding/StripeTOS";
import { PayoutCoBranding } from "../../../../components/ProDashboard/Business/Payouts/CoBranding";
import { StripeConnectAccountStatus, StripeConnectAccountType } from "../../../../constants/stripe";
import { StripeConnectCustomV2, StripeConnectExpress } from "../../../../components/Stripe/Connect";
import BankingInformation from "../../../../components/ProDashboard/Business/Banking/BankingInformation";
import {
  useAccountStatus,
  useStripeAccountRequirements,
} from "../../../../hooks/stripe/connect/stripeConnect.hooks";
import StripeConnectCustom from "./StripeConnectCustom";

interface StripeConnectProps {
  bankingInfoConfig: Record<string, any>;
}

export default function StripeConnect({ bankingInfoConfig }: StripeConnectProps) {
  const accountStatus = useAccountStatus();
  const { data: accountStatusData, isLoading: isAccountStatusLoading } = accountStatus;
  const accountRequirements = useStripeAccountRequirements();

  const accountType = getValue(accountStatusData, "accountType");
  const isExpressAccount = accountType === StripeConnectAccountType.EXPRESS;

  const StripeComponent = isExpressAccount ? (
    <StripeConnectExpress isLoading={isAccountStatusLoading} />
  ) : (
    <StripeConnectCustom accountStatus={accountStatus} accountRequirements={accountRequirements} />
  );

  return (
    <Box
      gridGap={Styled.Spacing.S6}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      <BankingInformation
        bankingInformation={bankingInfoConfig}
        accountStatus={accountStatusData}
        hideBadge={isAccountStatusLoading}
      />
      <Box
        gridGap={Styled.Spacing.S10}
        display={Styled.Display.Flex}
        flexDirection={Styled.FlexDirection.Column}
      >
        {StripeComponent}
      </Box>
    </Box>
  );
}
