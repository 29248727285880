import { environment } from "../../../constants/common";

const fbqTrack = (eventName: string, data: any, source: any) => {
  const isProduction = environment?.isProduction;
  //@ts-ignore
  if (isProduction && window.fbq && source === "facebook") {
    //@ts-ignore
    window.fbq("track", eventName, data);
    // console.log(`Tracking ${eventName} from ${source}:`, data);
  }
};

const trackPageView = (source: any) => {
  fbqTrack("PageView", null, source);
};

const trackAddToCart = (data: any, source: any) => {
  fbqTrack("AddToCart", data, source);
};

const trackInitiateCheckout = (data: any, source: any) => {
  fbqTrack("InitiateCheckout", data, source);
};

const trackPurchase = (data: any, source: any) => {
  fbqTrack("Purchase", data, source);
};


const facebookPixels = {
  trackPageView,
  trackAddToCart,
  trackInitiateCheckout,
  trackPurchase,
};

export default facebookPixels;
