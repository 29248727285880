import { Box, ButtonBase, CircularProgress, Link } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isNil } from "lodash";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button, { ButtonSize, ButtonType } from "../components/Button";
import { TreatmentFor } from "../constants/booking";
import { Colors } from "../constants/colors";
import { Paths } from "../constants/paths";
import { TrackingEvents } from "../constants/tracking";
import { BOOKING_SESSION_TYPE } from "../helpers/booking";
import { withVersion } from "../helpers/tracking";
import { isLoggedIn } from "../helpers/user";
import { useServiceRates } from "../hooks/services/rates.hooks";
import BookMassageIcon from "../images/new-booking-icon.svg";
import { Booking, TherapistPublicProfile } from "../models";
import { trackEvent } from "../services/segment/track.service";
import { useAddressStore } from "../stores/address";
import { useAlertStore } from "../stores/alert";
import {
  getBookingQuestions,
  getMassageService,
  getTimeRanges,
  setServiceRates,
  useBookingStore,
  useDateTimeStore,
  useLocationDetailsStore,
  useServicesStore,
} from "../stores/booking";
import { DEFAULT_COUNTRY } from "../utils/country";
import { getValue } from "../utils/object";
import pixelsService from "../services/pixels/pixels.service";

export enum BookMassageButtonSource {
  Navigation = "Navigation",
  TherapistProfileSlideIn = "Therapist profile slide-in",
  FavouriteTherapistProfile = "Favourite therapist profile",
  PastBookings = "Past bookings",
}

export enum BookMassageButtonType {
  textButton,
  iconButton,
  iconButtonTitled,
  textLink,
}

interface Props {
  booking?: Booking;
  type?: BookMassageButtonType;
  size?: ButtonSize;
  preferredTherapist?: TherapistPublicProfile | Array<TherapistPublicProfile>;
  preferredTherapistId?: number | Array<number> | null;
  title?: string;
  width?: string;
  source?: BookMassageButtonSource;
  onNavigationToBookingFlow?: () => unknown;
  onBookPress?: () => unknown | null;
  rebookText?: string;
}

export default function BookingMassageButton({
  booking,
  type = BookMassageButtonType.textButton,
  size = ButtonSize.large,
  preferredTherapist,
  preferredTherapistId,
  title = "New booking",
  width = "180px",
  source,
  onBookPress,
  onNavigationToBookingFlow,
  rebookText = "Rebook",
}: Props): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage } = useAlertStore();
  const { addresses } = useAddressStore();
  const { setCountryCode } = useLocationDetailsStore();
  const { timeRange } = useServicesStore();
  const { reset: resetDateTime } = useDateTimeStore();

  const {
    address,
    setAddress,
    setLastBooking,
    setPreferredTherapists,
    setExitBookingFlowPath,
    setTreatmentDetails,
    setNumOfRecipients,
    resetTreatmentLength,
    resetTreatmentDetails,
    setServiceId,
    setTreatmentId,
    setUpdatingForBooking,
  } = useBookingStore();

  const usersCountry = localStorage.getItem("countryCode") || DEFAULT_COUNTRY;
  const countryCode = getValue(address, "countryCode") || usersCountry;
  
  const { services } = useServiceRates(countryCode);

  const { duration: serviceDurations = [] } = getMassageService();
  const DEFAULT_SERVICE_DURATION = serviceDurations[0]?.value || 60;

  useEffect(() => {
    setServiceRates(services);
  }, [services, countryCode]);

  // to make sure preferredTherapist route param is sent on rebook just like from providers subdomain
  const navigateToNewBookings = (path: string, state: any = {}) => {
    let slug;
    if (preferredTherapistId) {
      slug = Array.isArray(preferredTherapistId)
        ? preferredTherapistId.map((therapistId) => `pro-${therapistId}`).join(",")
        : `pro-${preferredTherapistId}`;
    }
    return history.push(slug ? `${path}?preferredTherapist=${slug}` : path, state);
  };

  const onClick = async () => {
    const navigationPath = !isEmpty(addresses) ? Paths.SavedLocations : Paths.LocationDetails;
    if (!isLoggedIn()) {
      return navigateToNewBookings(navigationPath, { firstBooking: true });
    }

    if (!!onBookPress) {
      return onBookPress();
    }

    if (!timeRange || timeRange.length <= 0) {
      await getTimeRanges();
    }

    let lastBooking: Booking | undefined = booking;

    resetDateTime(); // reset the persisted backup time first

    if (!lastBooking) {
      try {
        setLoading(true);
        const response = await axios.get("/bookings/last?channel=web", {
          params: {
            accessToken: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        lastBooking = response.data as Booking;
        if (!lastBooking) {
          setUpdatingForBooking(null);
          setLastBooking(null);
          setExitBookingFlowPath(location.pathname);
          navigateToNewBookings(navigationPath, { firstBooking: true });
        }
      } catch (err) {
        setLoading(false);
        navigateToNewBookings(navigationPath);
        setErrorMessage("Unable to fetch last booking details");
      }
    }
    if (lastBooking) {
      setAddress(lastBooking.address);
      await Promise.all([getBookingQuestions(), setLastBooking(lastBooking)]);
      setCountryCode(lastBooking?.address?.countryCode || "AU");

      //if last booking massage length is not in dropdown
      if (
        lastBooking.bookingdetails &&
        lastBooking.isMassage &&
        lastBooking.bookingdetails.length &&
        lastBooking.bookingdetails[0].treatmentDetails
      ) {
        const serviceDurationFromLastBooking = lastBooking.bookingdetails[0].serviceDuration;
        const equivalentDuration = serviceDurations.find(
          (duration) => duration.value === serviceDurationFromLastBooking
        );
        if (!equivalentDuration) {
          lastBooking.bookingdetails[0].treatmentDetails[0].serviceDuration =
            DEFAULT_SERVICE_DURATION;
          lastBooking.bookingdetails[0].serviceDuration = DEFAULT_SERVICE_DURATION;
        }
        if (lastBooking.bookingdetails[1]?.treatmentDetails) {
          const serviceDurationFromLastBooking2 = lastBooking.bookingdetails[1].serviceDuration;
          const equivalentDuration2 = serviceDurations.find(
            (duration) => duration.value === serviceDurationFromLastBooking2
          );
          if (!equivalentDuration2) {
            lastBooking.bookingdetails[1].treatmentDetails[0].serviceDuration =
              DEFAULT_SERVICE_DURATION;
            lastBooking.bookingdetails[1].serviceDuration = DEFAULT_SERVICE_DURATION;
          }
        }
        setLastBooking(lastBooking);
      }

      // set the treatment details from last booking
      // when new bookings button is pressed
      if (
        lastBooking.bookingdetails &&
        lastBooking.isHairAndMakeup &&
        lastBooking.bookingdetails.length &&
        lastBooking.bookingdetails[0].treatmentDetails
      ) {
        const treatmentDetails = lastBooking.bookingdetails[0].treatmentDetails.map((detail) => ({
          treatmentFor: detail.treatmentFor || TreatmentFor.myself,
          treatmentId: detail.treatmentTypeId,
          noteForProvider: detail.noteForProvider,
        }));
        setTreatmentDetails(treatmentDetails);
        setNumOfRecipients(lastBooking.numberOfClients || 1);
      }

      if (preferredTherapist) {
        if (!Array.isArray(preferredTherapist)) {
          setPreferredTherapists([preferredTherapist]);
        } else {
          setPreferredTherapists(preferredTherapist);
        }
      }

      // reset service and treatment details if last booking is manual corporate or group booking
      const isCorporateOrGroup =
        lastBooking.sessionType === BOOKING_SESSION_TYPE.CORPORATE ||
        lastBooking.sessionType === BOOKING_SESSION_TYPE.GROUP_BOOKING;
      if (isCorporateOrGroup && lastBooking.isManual) {
        lastBooking.bookingdetails = [];
        lastBooking.isActuallyPaid = false;
        lastBooking.isPaid = false;
        setLastBooking(lastBooking);
        if (isEmpty(lastBooking.corporateInfo)) {
          setServiceId(null);
          setTreatmentId(null);
          resetTreatmentLength();
          resetTreatmentDetails();
        }
      }

      // track add to cart event
      const cartData = {
        currency: lastBooking?.currency || "AUD",
        value: lastBooking?.priceWithoutDiscount,
        subtotal: lastBooking?.originalPrice,
        userId: lastBooking?.userId,
      };

      pixelsService.trackAddToCart(cartData);

      setExitBookingFlowPath(location.pathname);
      navigateToNewBookings(Paths.ReviewAndBook);
    }

    const commonTrackingProps = withVersion({
      elementPosition: source,
    });

    if (preferredTherapistId) {
      trackEvent(TrackingEvents.RebookClicked, {
        therapistId: preferredTherapistId,
        ...commonTrackingProps,
      });
    } else {
      trackEvent(TrackingEvents.BookNowClicked, commonTrackingProps);
    }
  };

  if (type === BookMassageButtonType.textButton) {
    return (
      <Button
        type={ButtonType.secondary}
        size={size}
        title={
          !isNil(preferredTherapist) && !Array.isArray(preferredTherapist)
            ? `${rebookText} ${preferredTherapist.therapistData.firstName}`
            : title
        }
        onClick={onClick}
        width={width}
        loading={loading}
      />
    );
  }

  if (
    type === BookMassageButtonType.iconButton ||
    type === BookMassageButtonType.iconButtonTitled
  ) {
    return (
      <ButtonBase onClick={onClick}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {loading ? (
            <Box
              bgcolor="#0DAEC8"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="60px"
              height="60px"
              borderRadius="30px"
            >
              <CircularProgress
                variant="indeterminate"
                style={{ color: "white" }}
                size="25px"
                thickness={4}
              />
            </Box>
          ) : (
            <img src={BookMassageIcon} width="60px" height="60px" alt="Icon" />
          )}
          {type === BookMassageButtonType.iconButtonTitled && (
            <Box
              fontFamily="SF UI Text"
              fontWeight={500}
              fontSize="12px"
              mt={0.5}
              color={Colors.Dusk}
            >
              New booking
            </Box>
          )}
        </Box>
      </ButtonBase>
    );
  }

  if (type === BookMassageButtonType.textLink) {
    return (
      <Link style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }} onClick={onClick}>
        new booking
      </Link>
    );
  }

  return <></>;
}
