import { useState } from "react";
import { Colors } from "../../../../constants/colors";
import { Box } from "../../../../components/v2/Styled";
import Button, { ButtonType } from "../../../../components/Button";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import TreatmentNoteForm from "../../../../components/UserTreatmentNote/TreatmentNoteForm/TreatmentNoteForm";
import { AlignItems, Display, FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../../../../components/v2/Styled/enum";
import { checkIfEmpty } from "../../../../utils/object";

interface Props {
  visible: boolean;
  onClose: () => unknown;
  onAddedNote: (data: any) => unknown;
  noteData: object;
}

const TreatmentNoteModal = ({ visible, onClose, onAddedNote, noteData }: Props) => {

  const [noteText, setNoteText] = useState("");
  const [noteFiles, setNoteFiles] = useState<any>([]);

  const onNoteFileUploaded = (uploadedFiles = []) => {
    const files = [...noteFiles, ...uploadedFiles];
    setNoteFiles(files);
  };

  const onRemoveNote = (index: number) => {
    const uploadedFiles = [...noteFiles];
    uploadedFiles.splice(index, 1);
    setNoteFiles(uploadedFiles);
  };

  const handleAddNote = () => {
    const data = {
      noteText: noteText,
      noteFiles: noteFiles,
    };

    onAddedNote(data);
  };

  const actions = [
    <Button
      onClick={onClose}
      type={ButtonType.outlined}
      title={"Cancel"}
    />,
    <Button
      disabled={checkIfEmpty(noteFiles) && checkIfEmpty(noteText)}
      onClick={handleAddNote}
      type={ButtonType.secondary}
      title={"Add"}
    />,
  ];

  return (
    <ContentModal
      fullWidth
      maxWidth={"sm"}
      divider={false}
      visible={visible}
      onClose={onClose}
      actionStyle={{ padding: "0px 48px 48px 48px" }}
      contentStyle={{ padding: "48px 48px 0px 48px" }}
      childrenStyle={{ marginTop: 0 }}
      actions={actions}
    >
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S8}>
        <TreatmentNoteForm
          textLabel={"Note"}
          note={noteText}
          files={noteFiles}
          title="Add client treatment notes"
          onRemoveNote={onRemoveNote}
          onfileUploaded={onNoteFileUploaded}
          onNoteChange={(text) => setNoteText(text)}
          wrapperStyle={{ gridGap: Spacing.S3 }}
          subTitleStyle={{
            fontSize: FontSize.F14,
            FontFamily: FontFamily.OpenSans,
            FontWeight: FontWeight.Regular,
          }}
          titleStyle={{
            fontSize: FontSize.F22,
            color: Colors.NightBlue,
            fontWeight: FontWeight.Bold,
            fontFamily: FontFamily.Museo,
          }}
        />
      </Box>
    </ContentModal>
  );
};

export default TreatmentNoteModal;