import { Box } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import RecipientItem from "../components/Recipients/RecipientItem";
import RecipientModal from "../components/Recipients/RecipientModal";
import SectionPageHeader from "../components/SectionPageHeader";
import TextLink from "../components/TextLink";
import Wrapper from "../components/Wrapper";
import { Colors } from "../constants/colors";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import { Recipient } from "../models";
import { useAlertStore } from "../stores/alert";
import { AllSortBy } from "../constants/sortOptions";
import PaginationControl from "../components/PaginationControl";
import { FilterButton } from "../components/FilterButton/FilterButton";
import { RecipientFilterAndSortModal } from "../components/Modals/FilterModal/RecipientFilterAndSortModal";
import { useBookingFilterCount, useCachedRecipientSort } from "../hooks/utility/filter.hooks";
import useRecipientFilterQuery from "../hooks/recipient/recipientFilter.hook";
import RemoveRecipient from "./Recipient/components/RemoveRecipient";
import ActionModal from "../components/ActionModal";
import { useDeleteRecipient } from "../hooks/recipient.hook";
import pixelsService from "../services/pixels/pixels.service";

export const RecipientPage = () => {
  const isMobile = useMobile();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const perPage = 20;
  const { cachedSortBy } = useCachedRecipientSort();
  const { getFilterCount } = useBookingFilterCount();
  const [openModal, setOpenModal] = useState(false);
  const [recipientId, setRecipientId] = useState(parseInt(""));
  const { deleteRecipient } = useDeleteRecipient();

  const recipientSortBy = cachedSortBy || AllSortBy[0];

  const [accessToken, setAccessToken] = React.useState(localStorage.getItem("token"));
  // const [searchParams, setSearchParams] = React.useState("");
  const { recipientQueries } = useRecipientFilterQuery({ sortBy: recipientSortBy });

  const {
    data: recipientsData,
    mutate: recipientsMutate,
    revalidate,
  } = useSWR(
    `/users/me/recipients?accessToken=${accessToken}&page=${page}&paginate=true&limit=${perPage}&${new URLSearchParams(
      { search: JSON.stringify(recipientQueries) }
    ).toString()}`
  );

  const recipients = recipientsData?.data ? (recipientsData?.data as [Recipient]) : [];

  // const [selectedRecipient, setSelectedRecipient] = React.useState<Recipient | null>(null);
  const [showRecipientModal, setShowRecipientModal] = React.useState(false);
  const [showRecipientFilterModal, setShowRecipientFilterModal] = React.useState(false);

  const [filterCount, setFilterCount] = React.useState(cachedSortBy ? 1 : 0);

  const onClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);
  const handleConfirm = async () => {
    try {
      await deleteRecipient(recipientId);
      setSuccessMessage("Recipient deleted");
      onClose();
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };
  useEffect(() => {
    setFilterCount(getFilterCount(recipientQueries, AllSortBy[0]));
  }, [recipientQueries, getFilterCount]);


  useEffect(() => {
    pixelsService.trackPageView();
  }, []);
  

  // const handleSave = () => {
  //   setShowRecipientModal(false);
  //   recipientsMutate();
  // };

  return (
    <>
      <Wrapper
        header={
          <SectionPageHeader title="Saved recipients" subtitle="Manage your recipients here" />
        }
        onLoggedIn={() => {
          setAccessToken(localStorage.getItem("token"));
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          mb={isMobile ? "5px" : "24px"}
          ml={isMobile ? "40px" : "80px"}
          mr={isMobile ? "20px" : "80px"}
          mt="40px"
          maxWidth={isMobile ? undefined : "935px"}
        >
          <FilterButton
            title="Filter and sort"
            onClick={() => {
              setShowRecipientFilterModal(true);
            }}
            filterCount={filterCount}
          />
        </Box>
        <Box
          style={{
            marginLeft: isMobile ? undefined : "80px",
            marginRight: isMobile ? undefined : "80px",
            backgroundColor: Colors.White,
            borderRadius: "6px",
            border: `solid 1px ${Colors.VeryLightPink}`,
          }}
          maxWidth={isMobile ? "100%" : "935px"}
          mt={isMobile ? 2 : undefined}
        >
          <Box ml="24px" mr="24px">
            {page === 1 && (
              <RecipientItem
                onEditClicked={() => {
                  history.push("/profile");
                }}
                myself
                showMyself
              />
            )}

            {recipients.map((recipient: Recipient) => (
              <RecipientItem
                recipient={recipient}
                onEditClicked={() => {
                  history.push(`/recipients/${recipient.id}/details`);
                  // setSelectedRecipient(recipient);
                  // setShowRecipientModal(true);
                }}
                onDeleteClicked={() => {
                  setRecipientId(recipient.id);
                  handleOpen();
                }}
              />
            ))}
          </Box>
          <Box m={3}>
            <TextLink
              title={`Add ${recipients.length > 0 ? "another" : ""} recipient`}
              onClick={() => {
                history.push("/recipients/add");
                // setSelectedRecipient(null);
                // setShowRecipientModal(true);
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          // flexWrap={true}
          mt="15px"
          justifyContent={`${isMobile ? "center" : "flex-end"}`}
          alignItems="center"
          marginBottom="25px"
          marginLeft={`${isMobile ? undefined : "80px"}`}
          marginRight={`${isMobile ? undefined : "80px"}`}
          maxWidth={isMobile ? "100%" : "609px"}
        >
          <PaginationControl
            currentPage={recipientsData?.page}
            pages={recipientsData?.totalPage || 1}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            startFromOne={true}
          />
        </Box>
      </Wrapper>
      <ActionModal
        open={openModal}
        title={"Delete recipient"}
        description={"Please confirm that you want to delete this recipient."}
        onCancel={() => {
          onClose();
        }}
        onConfirm={() => {
          handleConfirm();
        }}
        confirmText="Confirm"
        cancelText="Back"
      />

      {/* <RecipientModal
        existingRecipient={selectedRecipient}
        open={showRecipientModal}
        onClose={() => setShowRecipientModal(false)}
        onSaved={() => handleSave()}
      /> */}
      <RecipientFilterAndSortModal
        open={showRecipientFilterModal}
        onClose={() => {
          setShowRecipientFilterModal(false);
        }}
      />
    </>
  );
};

export default RecipientPage;
