import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { formatToPrice } from "../../../helpers/number";
import Button, { ButtonType } from "../../Button";
import { Text } from "../../v2/Styled";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from "../../v2/Styled/enum";
import { FadeInAnimationModal } from "../AnimationModal/FadeInAnimationModal";

type Props = {
  visible: boolean;
  onClose: () => void;
  price: number;
  therapistCount?: number;
  currency: string;
};
export function BeforeBookingTipSuccessModal({ therapistCount = 1, ...props }: Props) {
  const getDescription = () => {
    const formattedPrice = formatToPrice(props.price, props.currency);

    if (therapistCount > 1) {
      const splittedPrice = formatToPrice(props.price / therapistCount, props.currency);
      return `You have successfully added ${formattedPrice} tip. For this booking with ${therapistCount} providers, the tip will be split evenly by default, with each receiving ${splittedPrice}. After your booking is confirmed, you can easily adjust the tip amount for each provider individually.`;
    }
    return `You have successfully added ${formattedPrice} tip for your provider.`;
  };

  return (
    <FadeInAnimationModal
      visible={props.visible}
      onClose={() => {
        props.onClose();
      }}
      animationData={require("../../LottieAnimation/animationJson/success.json")}
    >
      <Box gridGap={Spacing.S4} display={Display.Flex} flexDirection={FlexDirection.Column}>
        <Box style={{ textAlign: "center" }}>
          <Text
            color={Colors.Indigo}
            size={FontSize.F24}
            font={FontFamily.Museo}
            weight={FontWeight.Bold}
          >
            Tip added!
          </Text>
        </Box>
        <Box style={{ textAlign: "center" }}>
          <Text color={Colors.Grey} lineHeight={LineHeight.L24}>
            {getDescription()}
          </Text>
        </Box>
      </Box>
      <Box>
        <Button
          onClick={() => {
            props.onClose();
          }}
          title={"Sweet, thanks"}
          type={ButtonType.outlined}
        />
      </Box>
    </FadeInAnimationModal>
  );
}
