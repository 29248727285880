import { Box } from "@material-ui/core";

import Divider from "../../Divider";
import { Preferences } from "../../../models";
import { Spacing } from "../../v2/Styled/enum";
import { getValue } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { formatDate } from "../../../utils/date.util";
import NoteEditOrDelete from "../../../pages/Recipient/components/NoteCardActions";
import { generateInstructionFromPreference, generatePreferenceFromUserPreference } from "../../../helpers/specialInstruction";

interface Props {
  preferences: Preferences
  fullwidth?: boolean;
  showDivider?: boolean;
  note?: any;
  onEditClicked: (data: any) => void;
  onDeleteNote: (data: any) => void;
}

export default function ClientTreatmentNote({
  preferences,
  fullwidth = true,
  showDivider = true,
  onEditClicked,
  onDeleteNote,
}: Props): JSX.Element {

  const isMobile = useMobile();

  const parsedPreference = preferences ? JSON.parse(preferences.preference) : {};

  const handleNoteDelete = () => {
    const data = {
      recipientId: getValue(preferences, "recipientId"),
      serviceId: getValue(preferences, "serviceId"),
    };
    onDeleteNote(data);
  };

  const note = generateInstructionFromPreference(parsedPreference, preferences.serviceId);
  const massagePreferences = generatePreferenceFromUserPreference(parsedPreference, preferences.serviceId);

  return (
    <>
      <Box style={{ marginBottom: "14px" }}>
        <Box
          maxWidth={isMobile ? undefined : fullwidth ? undefined : "885px"}
          borderRadius="6px"
          border={`solid 1px ${Colors.VeryLightPink}`}
          bgcolor="#ffffff"
          flexDirection="column"
          display="flex"
          py="24px"
          px="24px"
          style={{ cursor: "pointer" }}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            flex={1}
          >

            <Box display="flex" flexDirection="row" flex={1}>

              <Box
                display="flex"
                flexDirection="column"
                style={{
                  gap: Spacing.S4,
                }}
                justifyContent="start"
                mr={isMobile ? "16px" : undefined}
                width={"209px"}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    gap: Spacing.S2,
                  }}>
                  <Box
                    fontFamily="Open Sans"
                    fontWeight={600}
                    fontSize="14px"
                    color={Colors.BlueyGrey}
                    textAlign="left"
                  >
                    Added
                  </Box>

                  <Box
                    fontFamily="Museo"
                    fontWeight={500}
                    fontSize="16px"
                    color={Colors.DarkSteelGrey}
                    textAlign="left"
                  >
                    {formatDate({ date: preferences.createdAt, format: "DD MMM YYYY" })}
                  </Box>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    gap: Spacing.S2,
                  }} >
                  <Box
                    fontFamily="Open Sans"
                    fontWeight={600}
                    fontSize="14px"
                    color={Colors.BlueyGrey}
                    textAlign="left"
                  >
                    Service type
                  </Box>

                  <Box
                    fontFamily="Museo"
                    fontWeight={500}
                    fontSize="16px"
                    color={Colors.DarkSteelGrey}
                    textAlign="left"
                  >
                    {getValue(preferences.service, "alias")}
                  </Box>
                </Box>

              </Box>

              <Box width="1px" bgcolor={Colors.PaleLilac} />

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                style={{
                  gap: Spacing.S4,
                }}
                ml={isMobile ? "16px" : "24px"}
                mr={isMobile ? "16px" : undefined}
                flex={1}
              >
                {preferences.service.isMassage ? (<Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    gap: "10px",
                  }}>
                  <Box
                    fontFamily="Open Sans"
                    fontWeight={600}
                    fontSize="14px"
                    color={Colors.BlueyGrey}
                    textAlign="left"
                  >
                    Preferences
                  </Box>

                  <Box
                    whiteSpace="pre-wrap"
                    lineHeight={"24px"}
                    fontFamily="Museo"
                    fontWeight={500}
                    fontSize="16px"
                    color={Colors.Dusk}
                    textAlign="left">

                    {massagePreferences}

                  </Box>
                </Box>) : <></>}


                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    gap: "10px",
                  }}>
                  <Box
                    fontFamily="Open Sans"
                    fontWeight={600}
                    fontSize="14px"
                    color={Colors.BlueyGrey}
                    textAlign="left"
                  >
                    Contraindications
                  </Box>

                  <Box
                    fontFamily="Museo"
                    fontWeight={500}
                    fontSize="16px"
                    color={Colors.Dusk}
                    textAlign="left"
                  >
                    {getValue(parsedPreference, "hasContraindications") ? getValue(parsedPreference, "contraindications") : "No contraindications"}
                  </Box>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    gap: "10px",
                  }}>
                  <Box
                    fontFamily="Open Sans"
                    fontWeight={600}
                    fontSize="14px"
                    color={Colors.BlueyGrey}
                    textAlign="left"
                  >
                    Note
                  </Box>

                  <Box
                    fontFamily="Museo"
                    fontWeight={500}
                    fontSize="16px"
                    color={Colors.Dusk}
                    textAlign="left"
                  >
                    {note ? note : "No Notes"}
                  </Box>

                </Box>

              </Box>
            </Box>
          </Box>

          <Divider mt="24px" />
          <Box display="flex" flexDirection="column" flex={1}>
            <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <NoteEditOrDelete handleEditNote={() => onEditClicked(preferences)} handleDeleteNote={handleNoteDelete} />
            </Box>
          </Box>
        </Box >
      </Box >

    </>
  );
}
