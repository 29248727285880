import React from "react";
import { Box, ButtonBase, Typography } from "@material-ui/core";

import { Colors } from "../constants/colors";
import AddressesIcon from "../images/sidemenu-addresses.svg";
import BookingsIcon from "../images/sidemenu-bookings.svg";
import MembershipIcon from "../images/sidemenu-membership.svg";
import PaymentIcon from "../images/sidemenu-payment.svg";
import TherapistsIcon from "../images/sidemenu-providers.svg";
import RecipientsIcon from "../images/sidemenu-therapists.svg";
import ProDashboardIcon from "../images/sidemenu-prodashboard.svg";
import SettingsIcon from "../images/settings-icon.svg";
import InviteIcon from "../images/sidemenu-invite.svg";
import PurchasesIcon from "../images/sidemenu-purchases.svg";
export enum SideMenuButtonType {
  bookings,
  providers,
  membership,
  addresses,
  payment,
  recipients,
  purchases,
  prodashboard,
  users,
  settings,
  inviteFriends
}

interface Props {
  type: SideMenuButtonType;
  selected?: boolean;
  onClick?: () => unknown;
}

export default function SideMenuButton({ type, selected = false, onClick }: Props): JSX.Element {
  const title = () => {
    switch (type) {
    case SideMenuButtonType.bookings:
      return "Bookings";
    case SideMenuButtonType.providers:
      return "Providers";
    case SideMenuButtonType.membership:
      return "Membership";
    case SideMenuButtonType.addresses:
      return "Addresses";
    case SideMenuButtonType.payment:
      return "Payment";
    case SideMenuButtonType.recipients:
      return "Recipients";
    case SideMenuButtonType.purchases:
      return "Purchases";
    case SideMenuButtonType.prodashboard:
      return "Pro dashboard";
    case SideMenuButtonType.users:
      return "Users";
    case SideMenuButtonType.settings:
      return "Settings";
    case SideMenuButtonType.inviteFriends:
      return "Invite Friends";
    }
  };

  const icon = () => {
    switch (type) {
    case SideMenuButtonType.bookings:
      return BookingsIcon;
    case SideMenuButtonType.providers:
      return TherapistsIcon;
    case SideMenuButtonType.membership:
      return MembershipIcon;
    case SideMenuButtonType.addresses:
      return AddressesIcon;
    case SideMenuButtonType.payment:
      return PaymentIcon;
    case SideMenuButtonType.recipients:
      return RecipientsIcon;
    case SideMenuButtonType.purchases:
      return PurchasesIcon;
    case SideMenuButtonType.users:
      return TherapistsIcon;
    case SideMenuButtonType.prodashboard:
      return ProDashboardIcon;
    case SideMenuButtonType.settings:
      return SettingsIcon;
    case SideMenuButtonType.inviteFriends:
      return InviteIcon;
    }
  };

  return (
    <ButtonBase
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: selected ? Colors.CornflowerBlue : undefined,
        width: "240px",
        height: "50px",
        paddingLeft: "20px",
        paddingRight: "20px",
        justifyContent: "left",
        borderRadius: "6px",
      }}
      onClick={onClick}
    >
      <Box width="45px" display="flex" justifyContent="left" mr={1}>
        <img src={icon()} />
      </Box>
      <Box
        fontFamily="Museo"
        fontWeight={selected ? 700 : 500}
        color={selected ? Colors.NightBlue : Colors.Dusk}
        fontSize="15px"
        letterSpacing="0.2px"
      >
        {title()}
      </Box>
    </ButtonBase>
  );
}
