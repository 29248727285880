/* eslint-disable indent */
import { Box, Typography } from "@material-ui/core";
import { isNil, sumBy } from "lodash";
import { useState } from "react";

import { BOOKING_STATUS, PRICE_BREAKDOWN } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { bookingIsCancelled } from "../../helpers/booking";
import { formatToPrice } from "../../helpers/number";
import { usePeakHourFixed } from "../../hooks/booking/peakHour.hooks";
import { Booking } from "../../models";
import { useUserStore } from "../../stores/user";
import { checkIfEmpty, getValue } from "../../utils/object";
import CurrencyConversionInfo from "../CurrencyConversionInfo";
import InfoModal from "../Modals/InfoModal/InfoModal";
import TextButton from "../TextButton/TextButton";
import { SingleProTippingModal } from "../Modals/TippingModal/SingleProTippingModal";
import { MultipleProTippingModal } from "../Modals/TippingModal/MultipleProTippingModal";
import { AfterBookingTipSuccessModal } from "../Modals/TippingModal/AfterBookingTipSuccessModal";
import { getBookingPrice } from "../../stores/booking";
import { useTipInfoQuery, useUpdateTipMutation } from "../../hooks/tip.hook";
import TippingConfirmModal from "../Modals/TippingModal/TippingConfirmModal";
import { PartialJobForTip, TipPerJob } from "../../types/tip.types";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKey";
import { PaymentType } from "../../constants/payment";

export enum BookingDetailsSummaryItemType {
  massage,
  peakHourSurcharge,
  discount,
  coupon,
  credit,
  cancellationFee,
  publicHolidaySurcharge,
  clientPlatformFee,
  totalPaid,
  parkingAndTravel,
  tipping,
}

interface Props {
  type: BookingDetailsSummaryItemType;
  booking: Booking;
}

export default function BookingDetailsSummaryItem({ type, booking }: Props): JSX.Element {
  const { originalPrice, platformFeeData, peakHourInfo } = booking;
  const [infoModal, setInfoModal] = useState({
    visible: false,
    content: {
      title: "",
      description: "",
      data: [],
    },
  });
  const [showTipModal, setShowTipModal] = useState(false);
  const [showTipSuccessModal, setShowTipSuccessModal] = useState(false);
  const [tipPerJob, setTipPerJob] = useState<TipPerJob[]>([]);
  const [confirmModalData, setConfirmModalData] = useState<{
    tipPerJob: TipPerJob[];
    isEvenlySplit: boolean;
    isSingle?: boolean;
  } | null>(null);

  const queryClient = useQueryClient();

  const { currency: userCurrency, currencyCode: userCurrencyCode } = useUserStore();

  const { tipInfo } = useTipInfoQuery();

  const { mutateAsync: updateTip } = useUpdateTipMutation();

  const cancelled = bookingIsCancelled(booking);

  const bookingCurrency = getValue(booking, "service.selectedCountry.currencySymbol", "A$");
  const bookingCurrencyCode = getValue(booking, "service.selectedCountry.currency", "AUD");

  const originalCouponAmount = getValue(booking, "coupon.originalAmount");
  const originalCouponCurrency = getValue(booking, "coupon.originalCurrency");
  const originalCouponSymbol = getValue(booking, "coupon.originalCouponCurrencySymbol");
  const couponType = getValue(booking, "coupon.type");

  const originalAmount = sumBy(booking.credituses, (creditUse) => creditUse.originalAmount);

  const { upToText } = usePeakHourFixed({
    lateNightSurcharge: booking.lateNightSurcharge,
    lateNightSurchargeApplied: booking.lateNightSurchargeApplied,
  });

  const clickableSummaryItems = [
    BookingDetailsSummaryItemType.clientPlatformFee,
    BookingDetailsSummaryItemType.peakHourSurcharge,
    BookingDetailsSummaryItemType.parkingAndTravel,
    BookingDetailsSummaryItemType.tipping,
  ];

  const onAddTipConfirmed = () => {
    // make api calls
    updateTip({
      bookingId: booking.id.toString(),
      tipPerJobs: confirmModalData?.tipPerJob ?? [],
      isEvenlySplit: confirmModalData?.isEvenlySplit ?? false,
    }).then(() => {
      // invalidate booking
      queryClient.invalidateQueries([QUERY_KEYS.BOOKING_DETAIL, booking.id.toString()], {
        refetchType: "all",
      });
    });

    // set tipPerJob state
    setTipPerJob([...(confirmModalData?.tipPerJob ?? [])]);

    // close modal
    setConfirmModalData(null);

    // show success modal
    setShowTipSuccessModal(true);
  };

  const getPartialJob = (): PartialJobForTip[] => {
    return (
      booking.bookingdetails?.map(
        (details): PartialJobForTip => ({
          id: details.job.id,
          user: {
            id: details.job.user.id,
            firstName: details.job.user?.preferredFirstName || details.job.user?.firstName,
            profileImage: details.job.user?.profileImage,
          },
        })
      ) ?? []
    );
  };

  const getBookingBaseRate = () => {
    return cancelled ? 0 : originalPrice - booking.addressSurcharge;
  };

  const getBaseRateWithSurcharges = () => {
    return booking.priceWithoutDiscount - booking.clientPlatformFee - (booking.tipAmount || 0);
  };

  const enabled = () => {
    const parkingAndTravelPrice = (booking?.addressSurcharge ?? 0) + (booking?.hotelSurcharge ?? 0);
    switch (type) {
      case BookingDetailsSummaryItemType.massage:
        return true;
      case BookingDetailsSummaryItemType.parkingAndTravel:
        return parkingAndTravelPrice > 0;
      case BookingDetailsSummaryItemType.peakHourSurcharge:
        return ((booking?.lateNightSurcharge ?? 0) || (booking?.publicHolidaySurcharge ?? 0)) > 0;
      case BookingDetailsSummaryItemType.discount:
        return false;
      case BookingDetailsSummaryItemType.coupon:
        return !isNil(booking.coupon);
      case BookingDetailsSummaryItemType.credit:
        return booking.credituses.length > 0;
      case BookingDetailsSummaryItemType.cancellationFee:
        return cancelled;
      case BookingDetailsSummaryItemType.publicHolidaySurcharge:
        return false;
      case BookingDetailsSummaryItemType.clientPlatformFee:
        return booking.clientPlatformFee ?? 0 > 0;
      case BookingDetailsSummaryItemType.totalPaid:
        return (booking.totalAmountDue || 0) > 0;
      case BookingDetailsSummaryItemType.tipping:
        return (
          booking.paymentType !== PaymentType.payWithInvoice &&
          booking.paymentType !== PaymentType.payWithNDISFunding
        );
    }
  };

  const title = () => {
    switch (type) {
      case BookingDetailsSummaryItemType.massage:
        return PRICE_BREAKDOWN.BASERATE;
      case BookingDetailsSummaryItemType.parkingAndTravel:
        return PRICE_BREAKDOWN.PARKINGTRAVEL;
      case BookingDetailsSummaryItemType.peakHourSurcharge:
        return PRICE_BREAKDOWN.PEAK_PERIOD;
      case BookingDetailsSummaryItemType.discount:
        return "Discount";
      case BookingDetailsSummaryItemType.coupon:
        return `Coupon (${booking.coupon.code})`;
      case BookingDetailsSummaryItemType.credit:
        return PRICE_BREAKDOWN.CREDIT;
      case BookingDetailsSummaryItemType.cancellationFee:
        return "Cancellation fee";
      case BookingDetailsSummaryItemType.publicHolidaySurcharge:
        return PRICE_BREAKDOWN.PUBLIC_HOLIDAY;
      case BookingDetailsSummaryItemType.clientPlatformFee:
        return getValue(booking, "platformFeeData.title", "Platform Fee");
      case BookingDetailsSummaryItemType.totalPaid:
        return "Total paid";
      case BookingDetailsSummaryItemType.tipping:
        return PRICE_BREAKDOWN.TIP_FOR_THE_PROVIDER;
    }
  };

  const value = () => {
    let price = 0;

    switch (type) {
      case BookingDetailsSummaryItemType.massage:
        price = getBookingBaseRate();
        return formatToPrice(price, bookingCurrency);
      case BookingDetailsSummaryItemType.parkingAndTravel:
        const parkingAndTravelPrice =
          (booking?.addressSurcharge ?? 0) + (booking?.hotelSurcharge ?? 0);
        price = cancelled ? price : parkingAndTravelPrice;
        return formatToPrice(price, bookingCurrency);
      case BookingDetailsSummaryItemType.peakHourSurcharge:
        price = cancelled ? price : booking.lateNightSurcharge + booking.publicHolidaySurcharge;
        return price > 0
          ? `${upToText}${formatToPrice(price, bookingCurrency)}`
          : `${formatToPrice(price, bookingCurrency)}`;
      case BookingDetailsSummaryItemType.discount:
        return formatToPrice(price, bookingCurrency);

      case BookingDetailsSummaryItemType.coupon:
        if (booking.coupon.type === "percent") {
          const couponPrice =
            (booking.priceWithoutDiscount - booking.clientPlatformFee) *
            (booking.coupon.value / 100);
          return `-${formatToPrice(couponPrice, bookingCurrency)}`;
        }

        if (booking.coupon.type === "referral" || booking.coupon.type === "value") {
          const couponPrice = booking.coupon.value;
          return `-${formatToPrice(couponPrice, bookingCurrency)}`;
        }

        return null;

      case BookingDetailsSummaryItemType.credit:
        const creditUsesAmount = sumBy(booking.credituses, (creditUse) => creditUse.amount);
        return formatToPrice(creditUsesAmount, bookingCurrency);
      case BookingDetailsSummaryItemType.cancellationFee:
        return formatToPrice(booking.cancellationFee, bookingCurrency);

      case BookingDetailsSummaryItemType.publicHolidaySurcharge:
        return formatToPrice(booking.publicHolidaySurcharge, bookingCurrency);
      case BookingDetailsSummaryItemType.clientPlatformFee:
        return formatToPrice(booking.clientPlatformFee, bookingCurrency);
      case BookingDetailsSummaryItemType.totalPaid:
        return formatToPrice(booking.totalPaid, bookingCurrency);
      case BookingDetailsSummaryItemType.tipping:
        return formatToPrice(booking.tipAmount || 0, bookingCurrency);
    }
  };

  const isClickable = () => clickableSummaryItems.includes(type);

  const getInfoModalContent = (type: BookingDetailsSummaryItemType) => {
    let modalContent = {
      title: "",
      description: "",
      data: [],
    };

    switch (type) {
      case BookingDetailsSummaryItemType.clientPlatformFee:
        modalContent.title = getValue(platformFeeData, "title", "");
        modalContent.description = getValue(platformFeeData, "remarks", "");
        break;
      case BookingDetailsSummaryItemType.peakHourSurcharge:
        modalContent.title = getValue(peakHourInfo, "title", "");
        modalContent.description = getValue(peakHourInfo, "subtitle", "");
        modalContent.data = getValue(peakHourInfo, "data", []);
        break;
      case BookingDetailsSummaryItemType.parkingAndTravel:
        if (!booking?.parkingAndTravelInfo || checkIfEmpty(booking.parkingAndTravelInfo)) break;
        const parkingInfo = booking.parkingAndTravelInfo;
        modalContent = {
          title: parkingInfo.title,
          description: parkingInfo.subtitle,
          data: parkingInfo.data,
        };
        break;
      case BookingDetailsSummaryItemType.tipping:
        modalContent = {
          title: tipInfo.title || "",
          description: tipInfo.description || "",
          data: [],
        };
        break;
    }

    return modalContent;
  };

  const handleInfoIconClicked = (type: BookingDetailsSummaryItemType) => {
    const modalContent = getInfoModalContent(type);
    setInfoModal({
      visible: true,
      content: modalContent,
    });
  };

  const handleInfoDismiss = () => {
    setInfoModal({ ...infoModal, visible: false });
  };

  const actionLink = () => {
    switch (type) {
      case BookingDetailsSummaryItemType.tipping: {
        if (booking.status !== BOOKING_STATUS.COMPLETED || !booking.isTippingAllowed) {
          return <></>;
        }

        return (
          <TextButton
            textStyle={{ color: Colors.TurquoiseBlue, fontSize: "14px" }}
            onClick={() => {
              setShowTipModal(() => true);
            }}
            text={tipPerJob.length > 0 || booking.bookingTips.length > 0 ? "Change" : "Add"}
          />
        );
      }
      default:
        return null;
    }
  };
  return enabled() ? (
    <Box>
      <Box display="flex" flexDirection="row" alignItems={"center"}>
        <Box
          fontFamily="Museo"
          fontSize="14px"
          color={Colors.Dusk}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gridColumnGap={"10px"}
        >
          <Typography
            style={isClickable() ? styles.clickable : {}}
            onClick={() => handleInfoIconClicked(type)}
          >
            {title()}
          </Typography>
          {actionLink()}
        </Box>
        <Box flex={1} fontFamily="Museo" fontSize="14px" color={Colors.Dusk} textAlign="right">
          {value()}
        </Box>
      </Box>

      {type === BookingDetailsSummaryItemType.coupon && couponType === "value" ? (
        <CurrencyConversionInfo
          convertedToCurrencyCode={originalCouponCurrency}
          convertedFromCurrencyCode={bookingCurrencyCode}
          convertedCurrency={formatToPrice(originalCouponAmount, originalCouponSymbol)}
        />
      ) : (
        <></>
      )}
      {type === BookingDetailsSummaryItemType.credit ? (
        <CurrencyConversionInfo
          convertedToCurrencyCode={userCurrencyCode}
          convertedFromCurrencyCode={bookingCurrencyCode}
          convertedCurrency={formatToPrice(originalAmount, userCurrency)}
        />
      ) : (
        <></>
      )}
      {isClickable() && (
        <InfoModal
          visible={infoModal.visible}
          title={infoModal.content.title}
          description={infoModal.content.description}
          data={infoModal.content.data}
          divider={false}
          handleClose={handleInfoDismiss}
        />
      )}
      {booking?.bookingdetails?.length === 1 && showTipModal && (
        <SingleProTippingModal
          baseRate={getBaseRateWithSurcharges()}
          currency={bookingCurrency}
          onAdd={(tipAmount, cardId) => {
            setConfirmModalData({
              isEvenlySplit: true,
              tipPerJob: [
                {
                  jobId: booking.bookingdetails[0].job.id,
                  tipAmount: tipAmount,
                  tipId: cardId,
                },
              ],
              isSingle: true,
            });
            setShowTipModal(false);
          }}
          onClose={() => {
            setShowTipModal(false);
          }}
          visible={showTipModal}
          therapistCount={booking.bookingdetails.length}
          bookingType={booking.status as BOOKING_STATUS}
          proName={
            booking.bookingdetails?.[0]?.job?.user?.preferredFirstName ||
            booking.bookingdetails?.[0]?.job?.user?.firstName
          }
          proImage={booking.bookingdetails?.[0]?.job?.user?.profileImage}
          selectedCardId={booking.bookingTips[0]?.type}
          tipAmount={getValue(booking, "bookingTips[0].amount")}
          countryCode={booking.service.selectedCountry.code ?? booking.service.country}
        />
      )}

      {booking?.bookingdetails.length > 1 && showTipModal && (
        <MultipleProTippingModal
          baseRate={getBaseRateWithSurcharges()}
          currency={bookingCurrency}
          onAdd={(tipPerJob, splitType) => {
            setConfirmModalData({
              isEvenlySplit: splitType === "even",
              tipPerJob: tipPerJob,
              isSingle: false,
            });
            setShowTipModal(false);
          }}
          onClose={() => {
            setShowTipModal(false);
          }}
          visible={showTipModal}
          jobs={getPartialJob()}
          bookingType={booking.status as BOOKING_STATUS}
          bookingTips={booking.bookingTips}
          countryCode={booking.service.selectedCountry.code ?? booking.service.country}
        />
      )}

      {!!confirmModalData && (
        <TippingConfirmModal
          currency={bookingCurrency}
          jobs={getPartialJob()}
          tipPerJobs={confirmModalData.tipPerJob}
          onClose={() => setConfirmModalData(null)}
          onConfirm={onAddTipConfirmed}
        />
      )}

      {showTipSuccessModal && (
        <AfterBookingTipSuccessModal
          currency={bookingCurrency}
          onClose={() => {
            setShowTipSuccessModal(false);
          }}
          jobs={getPartialJob()}
          tipByJob={tipPerJob}
          visible={showTipSuccessModal}
        />
      )}
    </Box>
  ) : (
    <></>
  );
}

const styles = {
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};
