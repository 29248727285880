import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { getValue } from "../../../../../utils/object";
import { useUserStore } from "../../../../../stores/user";
import TextField from "../../../../../components/TextField";
import { getRoutingNumberLabelForCountry } from "../../../../../services/proDashboard/bank.service";

interface Props {
  accountDetails: any;
  onSave: (f: string, v: any) => unknown;
}

const BankAccountForm = ({ accountDetails, onSave }: Props): JSX.Element => {
  const [details, setDetails] = useState(accountDetails);
  const { user } = useUserStore();
  const userCountry = getValue(user, "country") || "";

  const routingNumberLabel = getRoutingNumberLabelForCountry(userCountry);

  const handleChange = (field: string, value: any) => {
    setDetails({
      ...details,
      [field]: value,
    });
  };

  const handleSave = (field: string) => {
    onSave(field, details[field]);
  };

  return (
    <Box>
      <TextField
        title={"Account number"}
        placeholder={"000000000"}
        value={details.accountNumber}
        onChange={(val) => handleChange("accountNumber", val)}
        onBlur={() => handleSave("accountNumber")}
      />
      <TextField
        title={"Confirm account number"}
        placeholder={"000000000"}
        value={details.confirmAccountNumber}
        onChange={(val) => handleChange("confirmAccountNumber", val)}
        onBlur={() => handleSave("confirmAccountNumber")}
      />
      <TextField
        title={routingNumberLabel}
        placeholder={"000000"}
        value={details.routingNumber}
        onChange={(val) => handleChange("routingNumber", val)}
        onBlur={() => handleSave("routingNumber")}
      />
    </Box>
  );
};

export default BankAccountForm;
