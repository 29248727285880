import { Moment } from "moment";
import { post, put } from "../../api/client";
import { BOOKING_STATUS, BOOKING_TIME } from "../../constants/booking";
import { ACC_STATUS_RESTRICT_DATA } from "../../constants/profile";
import { Job } from "../../models";
import {
  addMomentTime,
  checkIfBefore,
  checkIsBetweenMoment,
  convertToMoment,
  formatStringToMoment,
  getCurrentTimeStamp,
  getDayEndMoment,
  getDayStartMoment,
  getTimeRangeForDate,
  isSameDate,
  isSameDay,
} from "../../utils/date.util";
import { checkIfEmpty, getValue } from "../../utils/object";
import {
  getFormattedRangedBookingDate,
  getFormattedRangedBookingTime,
} from "../bookings/bookingTime.service";
import { CLIENT_NO_SHOW_WAIT_TIME } from "../../constants/policy";
import { isEmpty } from "lodash";

interface acceptProps {
  jobId: number;
  status: string;
  timeOfArrival: string;
}

const acceptJob = ({ jobId, status, timeOfArrival }: acceptProps) => {
  return put(`api/v2/therapists/jobs/${jobId}/accept`, { status, timeOfArrival });
};

const declineJob = ({ jobId, reason = "", reasonAlias = "" }: any) => {
  return post(`therapists/bookings/${jobId}/decline`, {
    reasonForDeclining: reason,
    reasonForDecliningAlias: reasonAlias,
  });
};

const removeAlternateOffer = async ({ id }: { id: number }) => {
  return post(`api/v2/bookings/alternative-offer/remove/${id}`, {});
};

const shouldHideSensitiveData = ({ accountStatus }: { accountStatus: string }) => {
  return ACC_STATUS_RESTRICT_DATA.includes(accountStatus);
};

const getInvalidDateMessage = ({ earliestTime, latestTime, backup, timezone }: any) => {
  let message = "Please select a valid date (";
  message += `${getFormattedRangedBookingDate({
    earliestTime,
    latestTime,
    timezone,
    prefix: false,
  })}`;

  if (!checkIfEmpty(backup)) {
    const { earliestTime: earliestBackup, latestTime: latestBackup } = backup;
    const isSameRange =
      isSameDay(earliestBackup, earliestTime, timezone) &&
      isSameDay(latestBackup, latestTime, timezone);
    if (!isSameRange) {
      message += ` or ${getFormattedRangedBookingDate({
        earliestTime: earliestBackup,
        latestTime: latestBackup,
        timezone,
        prefix: false,
      })}`;
    }
  }
  message += ")";

  return message;
};

const validateDateOfArrival = ({ booking, date, showToast }: any) => {
  const { timezone } = booking;
  let isValid = true;

  const [earliestTime, latestTime] = [booking.earliestTime, booking.latestTime].map((dateObj) =>
    convertToMoment(dateObj, timezone)
  );

  const isValidPreferred = checkIsBetweenMoment(
    earliestTime.startOf("day"),
    latestTime.endOf("day"),
    date
  );
  if (isValidPreferred) return isValid;

  let backupData = {};

  const hasBookingBackup = !checkIfEmpty(booking.backup);
  if (hasBookingBackup) {
    const { earliestTime: earliestBackup, latestTime: latestbackup } = booking.backup;
    const [backupEarliestMoment, backupLatestMoment] = [earliestBackup, latestbackup].map(
      (dateObj) => convertToMoment(dateObj, timezone)
    );

    const isValidBackupDate = checkIsBetweenMoment(
      backupEarliestMoment.startOf("day"),
      backupLatestMoment.endOf("day"),
      date
    );
    if (isValidBackupDate) return isValid;
    backupData = {
      earliestTime: backupEarliestMoment,
      latestTime: backupLatestMoment,
    };
  }

  isValid = false;

  const message = getInvalidDateMessage({ earliestTime, latestTime, backup: backupData, timezone });
  showToast(message);

  return isValid;
};

const getValidEarliestMoment = (earliestMoment: Moment, timezone: string) => {
  let validEarliestMoment = earliestMoment;
  const currentMoment = getCurrentTimeStamp(timezone);
  if (earliestMoment.isBefore(currentMoment)) {
    validEarliestMoment = getCurrentTimeStamp().add({ minutes: 5 });
  }
  return validEarliestMoment;
};

const checkInDateRange = ({ earliestTime, latestTime, selected, timezone }: any) => {
  const [earliestMoment, latestMoment] = [earliestTime, latestTime].map((date) =>
    convertToMoment(date, timezone)
  );

  // check for preferred date time
  const inDateRange = checkIsBetweenMoment(
    getDayStartMoment(earliestMoment),
    getDayEndMoment(latestMoment),
    selected
  );

  // eslint-disable-next-line prefer-const
  let { start: startDateTime, end: endDateTime } = getTimeRangeForDate({
    date: selected,
    startTime: earliestMoment,
    endTime: latestMoment,
    timezone,
  });

  startDateTime = getValidEarliestMoment(startDateTime, timezone);

  const currentTimeMoment = getCurrentTimeStamp(timezone);
  const inTimeRange =
    latestMoment.isAfter(currentTimeMoment) &&
    checkIsBetweenMoment(startDateTime, endDateTime, selected);

  return {
    inDateRange,
    inTimeRange,
  };
};

const checkTimeValidity = ({ booking, date, showToast }: any) => {
  let isValid = true;
  let arrivalType = BOOKING_TIME.PREFERRED;

  const { earliestTime, latestTime, timezone } = booking;
  const selectedTimeOfArrival = formatStringToMoment({
    date,
    format: "YYYY-MM-DD HH:mm:ss",
    timezone,
  });

  const { inDateRange: inPreferredDateRange, inTimeRange: inPreferredTimeRange } = checkInDateRange(
    {
      earliestTime,
      latestTime,
      selected: selectedTimeOfArrival,
      timezone,
    }
  );

  let inBackupDateRange = false;
  let inBackupTimeRange = false;
  // check for backup date time
  if (!checkIfEmpty(booking.backup)) {
    const { earliestTime: backupEarliest, latestTime: backupLatest } = booking.backup;
    const [backupEarliestMoment, backupLatestMoment] = [backupEarliest, backupLatest].map((date) =>
      convertToMoment(date, timezone)
    );
    const backupRangeValid = checkInDateRange({
      earliestTime: backupEarliestMoment,
      latestTime: backupLatestMoment,
      selected: selectedTimeOfArrival,
      timezone,
    });
    inBackupDateRange = backupRangeValid.inDateRange;
    inBackupTimeRange = backupRangeValid.inTimeRange;
  }

  const backupValid = inBackupDateRange && inBackupTimeRange;
  const preferredValid = inPreferredDateRange && inPreferredTimeRange;

  // compile invalid time error message
  if (!preferredValid && !backupValid) {
    isValid = false;

    // eslint-disable-next-line prefer-const
    let [earliestMoment, latestMoment] = [earliestTime, latestTime].map((date) =>
      convertToMoment(date, timezone)
    );

    let errorMessage = "Please select a valid time (";
    if (inPreferredDateRange) {
      earliestMoment = getValidEarliestMoment(earliestMoment, timezone);
      errorMessage += getFormattedRangedBookingTime({
        earliestTime: earliestMoment.toString(),
        latestTime: latestMoment.toString(),
        timezone,
        prefix: false,
      });
    }
    if (inBackupDateRange) {
      if (inPreferredDateRange) errorMessage += " or ";
      const { earliestTime: backupEarliest, latestTime: backupLatest } = booking.backup;
      // eslint-disable-next-line prefer-const
      let [backupEarliestMoment, backupLatestMoment] = [backupEarliest, backupLatest].map((date) =>
        convertToMoment(date, timezone)
      );
      backupEarliestMoment = getValidEarliestMoment(backupEarliestMoment, timezone);
      errorMessage += getFormattedRangedBookingTime({
        earliestTime: backupEarliestMoment.toString(),
        latestTime: backupLatestMoment.toString(),
        timezone,
        prefix: false,
      });
    }
    errorMessage += ")";
    showToast(errorMessage);
  }

  if (!preferredValid && backupValid) {
    arrivalType = BOOKING_TIME.BACKUP;
  }

  return { isValid, arrivalType };
};

const validateTimeOfArrival = (date: any, timezone: string, booking: any, showToast: any) => {
  let selectedArrivalType = BOOKING_TIME.PREFERRED;
  if (checkIfEmpty(booking)) return { isValid: false, arrivalType: selectedArrivalType };

  // validate Date
  const isDateValid = validateDateOfArrival({ booking, date, showToast });
  if (!isDateValid) return { isValid: false, arrivalType: selectedArrivalType };

  // validate Time
  const { isValid: isTimeValid, arrivalType } = checkTimeValidity({ booking, date, showToast });
  if (!isTimeValid) return { isValid: false, arrivalType: selectedArrivalType };

  return { isValid: true, arrivalType };
};

const getFormattedJob = (job: Job) => {
  if (!job || checkIfEmpty(job)) return {};

  const bookingdetail = getValue(job, "bookingdetail", {});
  const booking = getValue(job, "bookingdetail.booking", {});

  const { earliestTime, latestTime, timezone, address, sessionType } = booking;

  const { deliveryMethod } = bookingdetail;

  const dateTime = {
    earliestTime,
    latestTime,
    timezone,
  };

  const treatmentDetails = getValue(bookingdetail, "treatmentDetails", [])?.map((detail: any) => {
    const {
      treatment,
      treatmentDuration,
      treatmentId,
      treatmentTypeId,
      questions,
      serviceDuration,
      addons,
    } = detail;
    const formattedQuestions = (questions || []).map((question: any) => {
      const { answer, question: questionText, id, type } = question;
      return { answer, question: questionText, id, type };
    });

    return {
      treatmentId: treatmentTypeId || treatmentId,
      treatmentDuration,
      name: treatment?.label || "",
      answers: formattedQuestions,
      serviceDuration,
      addons: addons || [],
    };
  });

  const numberOfTherapists = getValue(booking, "bookingdetails", []).length;
  const formattedData = {
    dateTime,
    address,
    sessionType,
    deliveryMethod,
    treatmentDetails,
    numberOfTherapists,
    booking: {
      status: booking.status,
    },
  };

  return formattedData;
};

const onMyWay = ({ jobId }: { jobId: string }) => {
  return put(`therapists/bookings/${jobId}/onway`, {});
};

const completeBooking = ({ jobId }: { jobId: string }) => {
  return put(`therapists/bookings/${jobId}/finish`, {});
};

const acceptUpdateRequest = ({ bookingId }: any) => {
  return put(`api/v2/bookings/update-request/${bookingId}/accept`, {});
};

const declineUpdateRequest = ({ bookingId }: any) => {
  return put(`api/v2/bookings/update-request/${bookingId}/decline`, {});
};

const calculateEndTime = (booking: any) => {
  const bookingDetails = booking.bookingdetails;

  let totalServiceDuration = 0;

  bookingDetails.forEach((detail: any) => {
    totalServiceDuration += detail.serviceDuration || 0;
  });

  return totalServiceDuration;
};

const canCompleteJob = (timeOfArrival: string | Moment, booking: any) => {
  const startTime =
    typeof timeOfArrival === "string" ? convertToMoment(timeOfArrival, "UTC") : timeOfArrival;
  const thresholdTime = addMomentTime(startTime.clone(), CLIENT_NO_SHOW_WAIT_TIME, "minutes");

  const currentTime = getCurrentTimeStamp("UTC");

  const isBetween = checkIsBetweenMoment(startTime, thresholdTime, currentTime);
  const isBefore = checkIfBefore(currentTime, thresholdTime);

  return {
    canComplete: !isBefore,
    waitTime: thresholdTime,
    hasSessionStarted: isBetween,
  };
};

const showCompleteButton = (timeOfArrival: string | Moment, booking: any) => {
  if (!timeOfArrival || isEmpty(booking) || booking.status !== BOOKING_STATUS.ARRANGED)
    return false;

  const startTime =
    typeof timeOfArrival === "string" ? convertToMoment(timeOfArrival, "UTC") : timeOfArrival;

  const endTime = calculateEndTime(booking);
  const thresholdTime = addMomentTime(startTime.clone(), endTime, "minutes");

  const currentTime = getCurrentTimeStamp("UTC");

  const isBefore = checkIfBefore(currentTime, thresholdTime);

  return !isBefore;
};

const acceptOrDeclineBackToBackConversionUpdateRequest = ({ jobId, body }: any) => {
  return put(`api/v2/bookings/fallback/${jobId}/pro-status`, body);
};

export {
  acceptJob,
  declineJob,
  removeAlternateOffer,
  getFormattedJob,
  validateTimeOfArrival,
  shouldHideSensitiveData,
  onMyWay,
  completeBooking,
  acceptUpdateRequest,
  declineUpdateRequest,
  canCompleteJob,
  showCompleteButton,
  acceptOrDeclineBackToBackConversionUpdateRequest,
};
