import { Box } from "@material-ui/core";

import { Colors } from "../../constants/colors";
import { FontFamily, FontSize, FontWeight } from "../v2/Styled/enum";

const VerifiedBadge = () => {
  return (
    <Box display={"flex"} color={Colors.VerifiedGreen} height={22} alignItems="center" gridGap={4} fontSize={12}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <circle cx="8" cy="8.5" r="7" fill={Colors.VerifiedGreen} />
        <path
          d="M4.5 9L7 11.5L11.5 6.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span style={{
        color: Colors.VerifiedGreen,
        fontWeight: FontWeight.SemiBold,
        fontSize: FontSize.F14,
        fontFamily: FontFamily.OpenSans
      }}>Verified</span>
    </Box>
  );
};

export default VerifiedBadge;
