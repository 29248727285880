import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isNil } from "lodash";
import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import Button, { ButtonType } from "../components/Button";
import CheckboxGroup from "../components/CheckboxGroup";
import Dropdown, { DropdownOption } from "../components/Dropdown";
import GoogleReCaptcha from "../components/GoogleReCaptcha";
import LandingWrapper from "../components/LandingWrapper";
import LogoLink from "../components/LogoLink";
import MobileInput from "../components/MobileModalOtp/MobileInput";
import TextField from "../components/TextField";
import TextLink from "../components/TextLink";
import { Colors } from "../constants/colors";
import { Paths } from "../constants/paths";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import { trackUsersSignedUp } from "../services/segment/track.service";
import { useAlertStore } from "../stores/alert";

import EmailVerificationModal from "../components/Account/EmailVerificationModal";
import SelectCountry from "../components/SelectCountry";
import { useCountryOptions } from "../hooks/utility/utilty.hooks";
import { getServicesRatesForSignup, resetPaymentStore, useServicesStore } from "../stores/booking";
import ServiceAreaInnerComponent from "../components/ServiceAreaInnerComponent";
import {
  DEFAULT_RADIUS,
  ServiceAreaProvider,
} from "./ProDashboard/ServiceArea/context/ServiceAreaState";
import { getValue } from "../utils/object";
import DateField from "../components/DateField";
import { getCurrentDate } from "../utils/date.util";
import config from "../config/proDashboard/dashboardConfig.json";
import { ABN_REQUIRED_COUNTRIES, ABN_VALIDATION_MESSAGE } from "../constants/countries";

export enum Country {
  AU = "AU",
  GB = "GB",
  US = "US",
  NZ = "NZ",
}

const genderOptions = [
  { value: "male", title: "Male" },
  { value: "female", title: "Female" },
  { value: "non binary / non confirming", title: "Non binary / non confirming" },
] as DropdownOption[];

const cityOptions = [
  { value: "Auckland NZ", title: "Auckland, NZ" },
  { value: "Adelaide", title: "Adelaide" },
  { value: "Brisbane", title: "Brisbane" },
  { value: "Byron Bay", title: "Byron Bay" },
  { value: "Canberra", title: "Canberra" },
  { value: "Central Coast", title: "Central Coast" },
  { value: "Christchurch, NZ", title: "Christchurch, NZ" },
  { value: "Coffs Harbour", title: "Coffs Harbour" },
  { value: "Geelong", title: "Geelong" },
  { value: "Gold Coast", title: "Gold Coast" },
  { value: "Hobart", title: "Hobart" },
  { value: "Melbourne", title: "Melbourne" },
  { value: "Newcastle", title: "Newcastle" },
  { value: "Perth", title: "Perth" },
  { value: "Sunshine Coast", title: "Sunshine Coast" },
  { value: "Sydney", title: "Sydney" },
  { value: "Wollongong", title: "Wollongong" },
  { value: "Wellington, NZ", title: "Wellington, NZ" },
  { value: "other", title: "Other" },
];

const experienceOptions = [
  { value: "less-1", title: "Less than 1 year" },
  { value: "1-3", title: "1-3 years" },
  { value: "3-5", title: "3-5 years" },
  { value: "5+", title: "5+ years" },
  { value: "none", title: "None" },
];

const QUALIFICATION_OPTIONS = [
  {
    title: "Diploma of Remedial Massage",
    value: "Diploma of Remedial Massage",
  },
  { title: "Cert IV in Massage Therapy", value: "Cert IV in Massage Therapy" },
  { title: "Other", value: "other" },
];

const heardFromOptions = [
  {
    value: "referredByAnother",
    title: "Referred by another provider",
  },
  { value: "Indeed", title: "Indeed" },
  { value: "Gumtree", title: "Gumtree" },
  {
    value: "Searched Google for massage jobs",
    title: "Searched Google for massage jobs",
  },
  {
    value: "Social media (Instagram, Facebook...)",
    title: "Social media (Instagram, Facebook...)",
  },
  { value: "From a friend", title: "From a friend" },
  {
    value: "throughMySchoolCollege",
    title: "Through my school/college",
  },
  {
    value: "Heard it from my association",
    title: "Heard it from my association",
  },
  {
    value: "I was contacted directly by Blys",
    title: "I was contacted directly by Blys",
  },
  {
    value: "I came to one of your events",
    title: "I came to one of your events",
  },
  { value: "other", title: "Other" },
] as DropdownOption[];

export default function SignUp(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();

  const [firstName, setFirstName] = React.useState("");
  const [preferredFirstName, setPreferredFirstName] = React.useState<string | undefined>();
  const [middleName, setMiddleName] = React.useState<string | undefined>();
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [radius, setRadius] = React.useState(DEFAULT_RADIUS);
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [mobile, setMobile] = React.useState("");
  const [referralCode, setReferralCode] = React.useState("");
  const { countries: countryOption } = useCountryOptions();
  const [cityOptions, setCityOption] = React.useState<DropdownOption[]>([]);
  const [abn, setAbn] = React.useState<string>("");

  const [addressCords, setAddressCords] = useState({
    latitude: null,
    longitude: null,
  });
  const [fullAddress, setFullAddress] = useState("");

  const [selectedGenderOption, setSelectedGenderOption] = React.useState<DropdownOption | null>(
    null
  );

  const [selectedExperienceOption, setSelectedExperienceOption] =
    React.useState<DropdownOption | null>(null);

  const [selectedQualificationOption, setSelectedQualificationOption] = React.useState<Array<any>>(
    []
  );

  const [serviceOfferedOption, setServiceOfferedOption] = React.useState<Array<any>>([]);

  const [selectedAdditionalQualification, setSelectedAdditionalQualification] = React.useState("");

  const [selectedHeardFromOption, setSelectedHeardFromOption] =
    React.useState<DropdownOption | null>(null);

  const [suburb, setSuburb] = React.useState("");
  const [otherHeardFromWhere, setOtherHeardFromWhere] = React.useState("");
  const [referredByAnother, setReferredByAnother] = React.useState("");
  const [throughMySchoolCollege, setThroughMySchoolCollege] = React.useState("");
  const [birthday, setBirthday] = useState<Date | null>(null);
  const [recaptchaSuccess, setRecaptchaSuccess] = React.useState(false);

  const { setErrorMessage } = useAlertStore();
  const { rates } = useServicesStore();

  const ratesForCheckbox = rates ? rates.map((r) => ({ value: r.id, title: r.alias })) : [];

  React.useEffect(() => {
    getServicesRatesForSignup();
  }, []);

  React.useEffect(() => {
    const selectedCountry = countryOption.find(({ value }: any) => value === country);
    if (selectedCountry) {
      setCityOption(selectedCountry.city);
    }
  }, [country, countryOption]);

  const onSignUp = () => {
    if (isEmpty(firstName)) {
      setErrorMessage("Please enter your first name");
      return;
    }

    if (isEmpty(lastName)) {
      setErrorMessage("Please enter your last name");
      return;
    }

    if (isEmpty(email)) {
      setErrorMessage("Please enter your email");
      return;
    }

    if (isEmpty(mobile)) {
      setErrorMessage("Please enter your mobile");
      return;
    }

    if (isNil(birthday)) {
      setErrorMessage("Please choose your date of birth");
      return;
    }

    if (isEmpty(country)) {
      setErrorMessage("Please specify your country");
      return;
    }

    if (isEmpty(city) && cityOptions?.length > 0) {
      setErrorMessage("Please specify your city");
      return;
    }

    if (isNil(selectedGenderOption)) {
      setErrorMessage("Please specify your gender");
      return;
    }

    if (isNil(selectedExperienceOption)) {
      setErrorMessage("Please specify your professional experience");
      return;
    }

    if (ABN_REQUIRED_COUNTRIES.includes(country) && isEmpty(abn)) {
      setErrorMessage(`Please add ${ABN_VALIDATION_MESSAGE[country]}`);
      return;
    }

    if (isNil(selectedHeardFromOption)) {
      setErrorMessage("Please specify how did you hear about us");
      return;
    }

    if (selectedHeardFromOption.value === "other" && isEmpty(otherHeardFromWhere)) {
      setErrorMessage("Please specify where did you hear us from?");
      return;
    }

    if (selectedHeardFromOption.value === "referredByAnother" && isEmpty(referredByAnother)) {
      setErrorMessage("Please specify the name of the provider who referred you.");
      return;
    }

    if (
      selectedHeardFromOption.value === "throughMySchoolCollege" &&
      isEmpty(throughMySchoolCollege)
    ) {
      setErrorMessage("Please specify the name of the school/college.");
      return;
    }

    if (!recaptchaSuccess) {
      setErrorMessage("Please complete reCAPTCHA");
      return;
    }

    setLoading(true);

    let qualifications = selectedQualificationOption
      .map((item) => {
        if (item === "other") return selectedAdditionalQualification;
        return item;
      })
      .join(",");

    const getWhereOtherValue = (selectedHereFromValue: string) => {
      switch (selectedHereFromValue) {
      case "other":
        return otherHeardFromWhere;
      case "referredByAnother":
        return referredByAnother;
      case "throughMySchoolCollege":
        return throughMySchoolCollege;
      default:
        return "";
      }
    };

    const data = {
      firstName,
      lastName,
      preferredFirstName,
      middleName,
      email,
      mobile,
      suburb,
      gender: selectedGenderOption?.value,
      birthday: getCurrentDate(birthday),
      experience: selectedExperienceOption?.value,
      whereHear: selectedHeardFromOption?.value,
      otherWhere: getWhereOtherValue(selectedHeardFromOption?.value),

      qualifications,
      // NOTE: There are not used but needed by API to work
      currentInsurance: "",
      massageTable: "",
      otherQual: "",
      massageType: {
        swedish: false,
        pregnancy: false,
        sports: false,
        deeptissue: false,
        corporate: false,
        reflexology: false,
        aromatherapy: false,
        lymphaticDrainage: false,
        hotStone: false,
        scalpHeadMassage: false,
      },
      serviceOffered: serviceOfferedOption,
      country,
      city,
      referralCode,
      notificationRadius: radius,
      latitude: getValue(addressCords, "latitude"),
      longitude: getValue(addressCords, "longitude"),
      fullAddress,
      abn,
    };

    axios
      .post("/therapists/signup", data)
      .then((response) => {
        trackUsersSignedUp(response.data.id, "therapist");

        setLoading(false);

        const { accessToken } = response.data;

        if (accessToken) {
          resetPaymentStore(); // hotfix
          localStorage.setItem("token", accessToken);
          history.push(Paths.ProDashboardProfile);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.debug("error: ", error);

        setErrorMessage(parseApiError(error));
      });
  };

  const handleServiceAreaSelected = (data: any) => {
    const { countryCode, city, suburb, latitude, longitude, fullAddress } = data;
    setCountry(countryCode);
    setCity(city || suburb);
    setSuburb(suburb);
    setFullAddress(fullAddress);
    setAddressCords({ latitude, longitude });
  };

  const handleRadiusSelected = (value: number) => {
    setRadius(value);
  };

  const getProConfig = (config: any, country: string) => {
    // defaults to AU if country code is not found
    const countryConfig = config[country] || config[Country.AU];
    return countryConfig.proDashboard;
  };

  const businessInformation = useMemo(() => {
    return getProConfig(config, country);
  }, [country]);
  const { business } = businessInformation;
  const { businessNumber } = business?.information;

  return (
    <LandingWrapper showPromoImages={false}>
      <Helmet>
        <title>Join Blys - Be Your Own Boss, Earn More & Safely | Blys</title>
        <meta
          name="description"
          content="Join Blys Today, Australia's Premium Mobile Wellness Company. Enjoy Flexible Hours, Amazing Rates, High Quality Clients. Recruiting Now. Apply Online!"
        />
      </Helmet>
      <Box position="relative">
        <Box width={isMobile ? undefined : "608px"}>
          <LogoLink />
          <Typography variant="h1" style={{ color: Colors.NightBlue, marginTop: "48px" }}>
            Sign up as a provider
          </Typography>

          <Box mt={4}>
            <TextField
              title="First name"
              subtitle={
                "The legal name on your government document, such as a license or a passport."
              }
              placeholder="First name"
              value={firstName}
              onChange={(text) => setFirstName(text)}
            />
          </Box>

          <Box display="flex" flexDirection={isMobile ? "column" : "row"} position="relative">
            <TextField
              title="Middle name"
              placeholder="Middle name"
              value={middleName}
              onChange={(text) => setMiddleName(text)}
            />
            <Box width="20px" />
            <TextField
              title="Last name"
              placeholder="last name"
              value={lastName}
              onChange={(text) => setLastName(text)}
            />
          </Box>

          <Box>
            <TextField
              title="Preferred first name"
              subtitle={
                "The name that you prefer to be called or to have on your public Blys profile."
              }
              placeholder="Preferred first name"
              value={preferredFirstName}
              onChange={(text) => setPreferredFirstName(text)}
            />
          </Box>

          <EmailVerificationModal
            onVerified={(email) => setEmail(email)}
            isVerified={!!email}
            forProSignUp
          />

          <MobileInput onVerified={(formattedMobileNumber) => setMobile(formattedMobileNumber)} />

          <Dropdown
            title="Gender"
            options={genderOptions}
            selectedOption={selectedGenderOption}
            onSelectedOption={(option) => setSelectedGenderOption(option)}
          />
          <DateField
            title="Date of birth"
            selectedDate={birthday}
            onSelectedDate={(date) => setBirthday(date)}
            width="100%"
            disableFuture
          />

          <ServiceAreaProvider>
            <ServiceAreaInnerComponent
              onRadiusChange={handleRadiusSelected}
              handleServiceAreaSelected={(data: any) => handleServiceAreaSelected(data)}
            />
          </ServiceAreaProvider>

          <CheckboxGroup
            title="Services offered"
            options={ratesForCheckbox}
            selectedOption={serviceOfferedOption}
            onSelectedOption={(option) => setServiceOfferedOption(option)}
          />

          <Dropdown
            title="Professional experience"
            options={experienceOptions}
            selectedOption={selectedExperienceOption}
            onSelectedOption={(option) => setSelectedExperienceOption(option)}
          />

          {businessNumber && (
            <TextField
              title={businessNumber.title}
              subtitle={businessNumber.description}
              placeholder="123456789"
              value={abn}
              onChange={(text) => setAbn(text)}
            />
          )}

          {selectedQualificationOption.includes("other") && (
            <TextField
              title="Additional Qualifications"
              value={selectedAdditionalQualification}
              onChange={(text) => setSelectedAdditionalQualification(text)}
            />
          )}

          <Dropdown
            title="How did you hear about us?"
            options={heardFromOptions}
            selectedOption={selectedHeardFromOption}
            onSelectedOption={(option) => setSelectedHeardFromOption(option)}
          />

          {selectedHeardFromOption?.value === "referredByAnother" && (
            <TextField
              title="What is the name of the provider who referred you?"
              value={referredByAnother}
              onChange={(text) => setReferredByAnother(text)}
            />
          )}

          {selectedHeardFromOption?.value === "throughMySchoolCollege" && (
            <TextField
              title="What's the name of the school/college?"
              value={throughMySchoolCollege}
              onChange={(text) => setThroughMySchoolCollege(text)}
            />
          )}

          {selectedHeardFromOption?.value === "other" && (
            <TextField
              title="Other source"
              value={otherHeardFromWhere}
              onChange={(text) => setOtherHeardFromWhere(text)}
            />
          )}

          <TextField
            title="Referral code"
            value={referralCode}
            onChange={(text) => setReferralCode(text)}
            placeholder={"Referral code"}
          />

          <Box mt={4}>
            <GoogleReCaptcha
              useCustomProvider={true}
              elementID={"therapist-signup"}
              verifyCallback={() => setRecaptchaSuccess(true)}
            />
          </Box>

          <Box
            fontFamily="Open Sans"
            fontWeight={400}
            fontSize="14px"
            color={Colors.Dusk}
            mt="32px"
            textAlign="center"
            lineHeight="21px"
          >
            By creating an account, you agree to our{" "}
            <a
              href="https://getblys.com.au/terms/software-licence-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              Terms
            </a>{" "}
            and{" "}
            <a href="https://getblys.com.au/privacy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </Box>
          <Box mt="32px">
            <Button
              loading={loading}
              title="Create account"
              type={ButtonType.secondary}
              onClick={() => onSignUp()}
            />
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                display="flex"
                flexDirection="row"
                fontFamily="Museo"
                fontWeight={500}
                fontSize="16px"
                color={Colors.BlueyGrey}
                mt="29px"
                mb="100px"
              >
                Already have an account?&nbsp;
                <TextLink title="Log in" onClick={() => history.push("/login")} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </LandingWrapper>
  );
}
