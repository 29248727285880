import React from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { PayoutInfo } from "./PayoutInfo";
import { Paths } from "../../../../constants/paths";
import { getValue } from "../../../../utils/object";
import Button, { ButtonType } from "../../../Button";
import { Colors } from "../../../../constants/colors";
import { Display, FlexDirection, Spacing } from "../../../v2/Styled/enum";

const BuildInfoRow = ({ infoData }: { infoData: [] }) => {
  return (
    <>
      {(infoData || []).map((item, index) => {
        const title = getValue(item, "title", "");
        const amount = getValue(item, "amount", 0).toFixed(2);
        const currencySymbol = getValue(item, "currencySymbol", "");
        const info = getValue(item, "info", "");
        const amountText = `${currencySymbol}${amount}`;
        const details = getValue(item, "breakDown");
        return (
          <PayoutInfo
            key={index}
            title={title}
            description={amount > 0 ? info : ""}
            value={amountText}
            details={details}
          />
        );
      })}
    </>
  );
};

interface Props {
  infoData: [];
}

export const PayoutInfoCard = ({ infoData }: Props) => {
  const history = useHistory();
  return (
    <Box
      bgcolor={Colors.White}
      style={{ border: `1px solid ${Colors.LightPeriwinkle}`, overflow: "hidden" }}
      borderRadius={"8px"}
      paddingBottom={Spacing.S6}
    >
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} style={{ gap: Spacing.S6 }}>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          style={{ gap: Spacing.S2 }}
        >
          <BuildInfoRow infoData={infoData} />
        </Box>
        <Box paddingRight={Spacing.S6} paddingLeft={Spacing.S6} mt={3.5}>
          <Button
            title={"Manage banking information"}
            type={ButtonType.secondary}
            onClick={() => {
              history.push(Paths.ProDashboardBusinessPayouts);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
