import { Box, Typography } from "@material-ui/core";
import Dialog from "./Dialog";
import Button, { ButtonType } from "./Button";
import ProfilePicturePlaceholder from "../images/profile-pic-placeholder.png";
import { Colors } from "../constants/colors";
import { FontFamily } from "./v2/Styled/enum";
import { formatToPrice } from "../helpers/number";
import { CustomLottieAnimation } from "./LottieAnimation/CustomLottieAnimation";

type Props = {
  visible: boolean;
  onClose: () => void;
  proName: string;
  tipAmount: number;
  currency: string;
};
export function RateTherapistSuccessModal(props: Props) {
  return (
    <Dialog
      open={props.visible}
      fullWidth={true}
      maxWidth={"md"}
      onClose={props.onClose}
      PaperProps={{
        style: {
          width: "445px",
          padding: "40px 32px",
          boxSizing: "border-box",
        },
      }}
    >
      <Box display={"flex"} flexDirection={"column"} position={"relative"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gridGap={"16px"}
          alignItems={"center"}
          zIndex={10}
        >
          <img
            src={ProfilePicturePlaceholder}
            alt={"Profile"}
            style={{ borderRadius: "50%", width: "64px", height: "64px" }}
          />
          <Typography>
            <Box
              sx={{
                fontSize: "22px",
                fontWeight: 700,
                lineHeight: "24px",
                color: Colors.Indigo,
                textAlign: "center",
              }}
            >
              Thanks for the review & tip!
            </Box>
          </Typography>
          <Box
            gridGap={"16px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  color: Colors.Grey,
                  textAlign: "center",
                  fontFamily: FontFamily.OpenSans,
                }}
              >
                You have successfully added a {formatToPrice(props.tipAmount, props.currency)} tip
                and review for ${props.proName}.
              </Box>
            </Typography>
            <Button title="Sweet, thanks" type={ButtonType.outlined} onClick={props.onClose} />
          </Box>
        </Box>
        <Box position={"absolute"} top={"-45px"} zIndex={1}>
          <CustomLottieAnimation
            animationData={require("./LottieAnimation/animationJson/confetti.json")}
            autoPlay={true}
            loop={false}
            speed={1}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
