/* eslint-disable import/no-anonymous-default-export */
import { Box, InputBase, Typography, withStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import * as httpClient from "../../api/client";
import { Colors } from "../../constants/colors";
import { DEBOUNCE_WAIT_TIMEOUT, VERIFICATION_CODE_RESEND_TIMEOUT } from "../../constants/time";
import { parseApiError } from "../../helpers/error";
import {
  CountryCode,
  getCountryCallingCode,
  getCountryName,
  getCountryPhonePlaceholder,
  getDialCodeFromCountryCode,
} from "../../helpers/mobile";
import { useMobile } from "../../hooks/mobile";
import firebaseApp from "../../services/firebaseApp";
import { useAlertStore } from "../../stores/alert";
import { getCountryCode } from "../../utils/country";
import { getCountryCode as getMobileCountryCode } from "../../helpers/mobile";
import { getFutureTimeStamp } from "../../utils/date.util";
import VerifiedBadge from "../Account/VerifiedBadge";
import Button, { ButtonSize, ButtonType } from "../Button";
import Dialog from "../Dialog";
import CircleExclamation from "../Icons/CircleExclamation";
import CountdownTimer from "./CountdownTimer";
import "./MobileInput.css";
import MobileInputAction from "./MobileInputAction";
import PhoneInput from "./PhoneInput";
import { useUserStore } from "../../stores/user";
import { Cursor, FontFamily, FontSize, FontWeight } from "../v2/Styled/enum";

interface Props {
  phoneNumber?: string;
  onVerified: (
    mobileNumber: string,
    numberOnly?: string,
    countryCode?: string,
    country?: string
  ) => void;
  isVerified?: boolean;
}

const StyledInputBase = withStyles({
  input: {
    cursor: "pointer",
    "&::placeholder": {
      color: Colors.LightBlueGrey,
      opacity: 1.0,
    },
  },
})(InputBase);

const INITIAL_PHONE_STATE = {
  mobileNumber: "",
  numberOnly: "",
  phoneNumberState: "",
};

export default function (props: Props): JSX.Element {
  const isMobile = useMobile();
  const { user } = useUserStore();
  const { setSuccessMessage } = useAlertStore();

  const [validatedPhoneNumber, setValidatedPhoneNumber] = React.useState("");
  const [otpModal, setOtpModal] = React.useState(false);

  // MODAL STATE
  const [isVerified, setIsVerified] = React.useState(false);

  const [phoneState, setPhoneState] = React.useState(INITIAL_PHONE_STATE);
  const [existingPhone, setExistingPhone] = React.useState("");
  const [phonePlaceholder, setPhonePlaceholder] = React.useState("400 000 000");
  const [phoneCountryCode, setPhoneCountryCode] = React.useState<CountryCode>("AU");
  const [phoneCountryCallingCode, setPhoneCountryCallingCode] = React.useState("+61");

  const [serviceId, setServiceId] = React.useState(null);
  const [hasSendOtp, setHasSendOtp] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [otpError, setOtpError] = React.useState("");
  const [shouldShowTimer, setShouldShowTimer] = React.useState(false);
  const [isDebouncedError, setIsDebouncedError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [waitTime, setWaitTime] = React.useState("");
  const [isActionDisabled, setIsActionDisabled] = React.useState(true);
  // ================================ LIFECYCLE =================================

  useEffect(() => {
    if (validatedPhoneNumber !== props.phoneNumber) {
      setValidatedPhoneNumber(props.phoneNumber || "");
    }
  }, [props.phoneNumber]);

  useEffect(() => {
    if (otpError == "Please try again later") {
      setIsDebouncedError(true);
      setShouldShowTimer(true);
    } else {
      setShouldShowTimer(false);
      setIsDebouncedError(false);
    }
  }, [otpError]);

  useEffect(() => {
    if (!otpModal) {
      setServiceId(null);
      setPhoneState(INITIAL_PHONE_STATE);
      setOtpCode("");
      setHasSendOtp(false);
      setOtpError("");
      setExistingPhone("");
    }
    if (otpModal && user && !user.mobileVerifiedAt && user.mobile) {
      setExistingPhone(user.mobile);
    }
  }, [otpModal, user]);

  useEffect(() => {
    setCountryCode();
  }, []);

  useEffect(() => {
    //updating the phone sate if exisiting phone number.
    const dial_code = getDialCodeFromCountryCode(phoneCountryCode);
    const hasCountryCode = existingPhone.startsWith(dial_code);
    const countryCodeLength = dial_code.length;
    setPhoneState({
      mobileNumber: hasCountryCode ? existingPhone : `${getDialCodeFromCountryCode(phoneCountryCode)}${existingPhone}`,
      numberOnly: hasCountryCode
        ? existingPhone.substring(countryCodeLength, existingPhone?.length)
        : existingPhone,
      phoneNumberState: existingPhone,
    });
  }, [existingPhone]);

  useEffect(() => {
    setIsVerified(!!props.isVerified);
  }, [props.isVerified]);

  useEffect(() => {
    const phonePlaceholder = getCountryPhonePlaceholder(phoneCountryCode);
    const phoneCallingCode = getCountryCallingCode(phoneCountryCode);
    setPhonePlaceholder(phonePlaceholder);
    setPhoneCountryCallingCode(`+${phoneCallingCode}`);
  }, [phoneCountryCode]);
  // ============================= FUNCTION ===================================

  const setCountryCode = async () => {

    let countryCode = await getCountryCode();

    if (user && !user.mobileVerifiedAt && user.mobile) {
      const phoneDetail = getMobileCountryCode(user.mobile);
      countryCode = getCountryName(phoneDetail?.countryCode || "");
    }

    if (countryCode) {
      const countryCallingCode = `+${getCountryCallingCode(countryCode)}`;
      const phonePlaceholder = getCountryPhonePlaceholder(countryCode);
      setPhoneCountryCode(countryCode);
      setPhonePlaceholder(phonePlaceholder);
      setPhoneCountryCallingCode(countryCallingCode);
    }
  };

  const getVerificationCode = async () => {
    try {
      setOtpError("");

      if (!phoneState.mobileNumber || !phoneState.numberOnly) {
        setOtpError("Phone number cannot be empty");
        return;
      }

      setIsLoading(true);

      const fbToken = await firebaseApp.getToken();

      const path = "api/v2/users/mobile-number/verification-code-v2";
      const response = await httpClient.post(
        path,
        { mobileNumber: phoneState.mobileNumber },
        false,
        {
          "X-SESSION": fbToken,
        }
      );

      if (response) {
        setHasSendOtp(true);
        setServiceId(response.serviceId);
        setWaitTime(getFutureTimeStamp({ seconds: VERIFICATION_CODE_RESEND_TIMEOUT }).format());
        setIsActionDisabled(true);
      }
    } catch (error: any) {
      setOtpError(parseApiError(error));
      console.log(`========== ${error.message} ==========`);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    try {
      setIsLoading(true);

      if (!otpCode) {
        setOtpError("Verification code cannot be empty");
        return;
      }

      setOtpError("");

      const fbToken = await firebaseApp.getToken();
      const path = "api/v2/users/mobile-number/verify-v2";
      const payload = {
        mobileNumber: phoneState.mobileNumber,
        code: otpCode,
        serviceId: serviceId,
      };

      const data = await httpClient.post(path, payload, true, {
        "X-SESSION": fbToken,
      });

      if (data.success) {
        const { message, isValid, formattedMobileNumber } = data.data;
        if (isValid) {
          setValidatedPhoneNumber(formattedMobileNumber);
          setOtpModal(false);
          props.onVerified(
            formattedMobileNumber,
            phoneState.numberOnly,
            phoneCountryCallingCode,
            phoneCountryCode
          );
          setIsVerified(true);
          setSuccessMessage(message);
        } else {
          setOtpError(message);
        }
      } else {
        setOtpError(data.message);
      }
    } catch (error: any) {
      setOtpError("Invalid verification code");
      console.log(`========== ${error.message} ==========`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    // if(validatedPhoneNumber !== "") return
    setOtpModal(true);
  };

  const handleChangeNumberClick = () => {
    setOtpCode("");
    setHasSendOtp(false);
    setOtpError("");
  };

  return (
    <>
      <Box width={"100%"} paddingTop="21px" paddingBottom="21px">
        <Box fontFamily="Museo" fontSize="14px" color={Colors.Dusk} fontWeight={700}>
          Mobile number
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" mt={1} mb={1}>
          <StyledInputBase
            disabled={false}
            value={validatedPhoneNumber}
            style={{
              color: Colors.TurquoiseBlue,
            }}
            fullWidth
            onFocus={(e) => {
              handleOpenModal();
              e.target.blur();
            }}
            placeholder={"Mobile number"}
          />
          {isVerified && <VerifiedBadge />}
          {!isVerified && props.phoneNumber && (
            <Box
              color={Colors.Danger}
              fontWeight={FontWeight.Bold}
              fontSize={FontSize.F14}
              fontFamily={FontFamily.OpenSans}
              style={{ cursor: Cursor.pointer }}
              onClick={() => handleOpenModal()}
            >
              Verify
            </Box>
          )}
        </Box>
        <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />
        {!isVerified && props.phoneNumber && (
          <Box
            display="flex"
            gridGap={8}
            alignItems={"center"}
            fontFamily={"Open sans"}
            mt={1}
            fontSize={14}
          >
            <CircleExclamation />
            <Box color={Colors.Danger}>Please verify your mobile number</Box>
          </Box>
        )}
      </Box>

      <Dialog
        open={otpModal}
        onClose={() => setOtpModal(false)}
        maxWidth={"xl"}
        fullScreen={isMobile}
        style={{ height: "450px" }}
      >
        <Box maxHeight={"365px"} style={{ padding: "48px 48px 24px 48px" }}>
          {!hasSendOtp ? (
            <>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "24px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#301E79",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Enter a phone number
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#41506F",
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  You will receive a text message with verification code
                </Typography>
              </Box>

              <Box paddingX={isMobile ? "22px" : "88px"} marginTop={"32px"}>
                {/* <Box
              display="flex"
              flexDirection="column"
              style={{width:"80%"}}
            >
              <input
                style={{width:"100%", height:"35px"}}
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              { phoneError && <span style={{color: "#f24a58", fontSize: "12px", textAlign: "left", marginTop: "5px"}} >{phoneError}</span> }
            </Box> */}

                <Typography
                  style={{
                    height: "32px",
                    lineHeight: "110%",
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#41506F",
                    fontWeight: 600,
                  }}
                >
                  Phone Number
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "100%" }}
                  className="verifyPhonePicker"
                >
                  <PhoneInput
                    value={phoneState.phoneNumberState}
                    masks={{ au: "... ... ..." }}
                    placeholder={phonePlaceholder}
                    onChange={(phone: any, countryData: any) => {
                      const hasCountryCode = phone.startsWith(countryData.dialCode);
                      const countryCodeLength = countryData.dialCode.length;
                      setPhoneState({
                        mobileNumber: hasCountryCode ? phone : `${countryData.dialCode}${phone}`,
                        numberOnly: hasCountryCode
                          ? phone.substring(countryCodeLength, phone?.length)
                          : phone,
                        phoneNumberState: phone,
                      });
                      setPhoneCountryCode(countryData.countryCode.toUpperCase());
                    }}
                    inputStyle={{ width: "100%", height: "48px", paddingLeft: "90px" }}
                    prefix={"+"}
                    inputProps={
                      //This is for showing default value if the phone number exists and the number is not verified.
                      //Value of existing email could have also been used in the value props directly but the country flag changes based on the initial value of the number.
                      existingPhone ? {
                        value: getMobileCountryCode(existingPhone)?.withoutCountryCode,
                        onChange: (e: any) => {
                          const { value } = e.target;
                          setExistingPhone(value);
                        }
                      } : {}
                    }
                    country={phoneCountryCode.toLowerCase()}
                    countryCode={phoneCountryCallingCode}
                  />
                  {otpError && !isDebouncedError && (
                    <span
                      style={{
                        color: "#f24a58",
                        fontSize: "12px",
                        textAlign: "left",
                        marginTop: "5px",
                      }}
                    >
                      {otpError}
                    </span>
                  )}
                  {otpError && isDebouncedError && shouldShowTimer && (
                    <span
                      style={{
                        color: "#f24a58",
                        fontSize: "12px",
                        textAlign: "left",
                        marginTop: "5px",
                      }}
                    >
                      {otpError}
                      <CountdownTimer
                        time={getFutureTimeStamp({ seconds: DEBOUNCE_WAIT_TIMEOUT }).format()}
                        setIsActionDisabled={setShouldShowTimer}
                        style={{ color: "#f24a58" }}
                      />
                    </span>
                  )}
                </Box>
              </Box>
              <Box style={{ marginTop: "60px" }}>
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    style={{ marginTop: "20px" }}
                    type={ButtonType.outlined}
                    size={ButtonSize.large}
                    title="Cancel"
                    width="244px"
                    onClick={() => setOtpModal(false)}
                    disabled={isLoading}
                  />
                  <Box width="14.5px" />
                  <Button
                    style={{ marginTop: "20px" }}
                    size={ButtonSize.large}
                    title="Next"
                    width="244px"
                    onClick={getVerificationCode}
                    loading={isLoading}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "24px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#301E79",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Verify your number
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#41506F",
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  Enter the 6 digit code we sent to {phoneState.mobileNumber}
                </Typography>
              </Box>
              <Box paddingX={isMobile ? "22px" : "88px"} marginTop={"32px"}>
                <Box display="flex" flexDirection="column">
                  <input
                    placeholder="123456"
                    maxLength={6}
                    style={{ width: "100%", height: "35px" }}
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                  />
                  {otpError && (
                    <span
                      style={{
                        color: "#f24a58",
                        fontSize: "12px",
                        textAlign: "left",
                        marginTop: "5px",
                      }}
                    >
                      {otpError}
                    </span>
                  )}
                </Box>
              </Box>
              <Box marginTop={"58px"}>
                <MobileInputAction
                  onResend={getVerificationCode}
                  onChange={handleChangeNumberClick}
                  retryIn={waitTime}
                  isActionDisabled={isActionDisabled}
                  setIsActionDisabled={setIsActionDisabled}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  marginTop={"12px"}
                >
                  <Button
                    style={{ marginTop: "20px" }}
                    type={ButtonType.outlined}
                    size={ButtonSize.large}
                    title="Cancel"
                    width="244px"
                    onClick={() => setOtpModal(false)}
                    disabled={isLoading}
                  />
                  <Box width="14.5px" />
                  <Button
                    style={{ marginTop: "20px" }}
                    size={ButtonSize.large}
                    title="Verify"
                    width="244px"
                    onClick={verifyOtp}
                    loading={isLoading}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
}
