import { Box } from "@material-ui/core";
import { Display, FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { useVoucherCards } from "../../../hooks/voucher/voucher.hooks";
import { getFullUploadsUrl } from "../../../utils/url";

interface CardProps {
  cardType: number;
  toName: string;
  fromName: string;
  note: string;
  contentWrapperStyle?: any;
  wrapperStyle?: any;
};

interface RowProps {
  label: string;
  value: string;
}

const CardRow = ({ label, value }: RowProps) => {
  if (checkIfEmpty(value)) return <></>;

  return (
    <Box>
      <Box
        fontSize={FontSize.F16}
        fontWeight={FontWeight.Bold}
        fontFamily={FontFamily.OpenSans}
        display={Display.Inline}
        color={Colors.Dusk}
        lineHeight={Spacing.S6}
      >
        {label}:
      </Box>
      <Box
        fontSize={FontSize.F16}
        fontWeight={FontWeight.Medium}
        fontFamily={FontFamily.OpenSans}
        display={Display.Inline}
        color={Colors.Dusk}
        lineHeight={Spacing.S5}
      >
        &nbsp;{value}
      </Box>
    </Box>
  );
};

const VoucherCard = ({
  cardType,
  toName,
  fromName,
  note, contentWrapperStyle = {},
  wrapperStyle = {}
}: CardProps) => {

  const { data: response, isLoading: cardsLoading } = useVoucherCards(); 

  const getVoucherImages = () => {
    const cards: any = getValue(response, "voucherCards") || [];
    const parsedCards = cards.map((card: string) => getFullUploadsUrl(card));
    return parsedCards;
  };

  const voucherCards = getVoucherImages();

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      maxWidth={"400px"}
      maxHeight={"500px"}
      style={{ ...wrapperStyle }}
    >
      {!cardsLoading && voucherCards[cardType] && <Box maxHeight={"245px"} maxWidth={"400px"}>
        <img src={voucherCards[cardType]} alt="Voucher card" height="100%" width="100%" style={{ borderRadius: "8px 8px 0px 0px" }} />
      </Box>}
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        gridGap={Spacing.S2}
        padding={Spacing.S6}
        style={{
          border: 0,
          borderLeft: "1px solid #DCDFE6",
          borderRight: "1px solid #DCDFE6",
          borderBottom: "1px solid #DCDFE6",
          borderRadius: "0px 0px 8px 8px",
          ...contentWrapperStyle
        }}>
        <CardRow label="To" value={toName} />
        <CardRow label="From" value={fromName} />
        <CardRow label="Note" value={note} />
      </Box>
    </Box>
  );
};

export default VoucherCard;