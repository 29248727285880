import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";

import { useMobile } from "../../../../hooks/mobile";
import { Colors } from "../../../../constants/colors";
import { Box } from "../../../../components/v2/Styled";
import { useUserStore } from "../../../../stores/user";
import { useAlertStore } from "../../../../stores/alert";
import { COUNTRY } from "../../../../constants/countries";
import RadioButton from "../../../../components/RadioButton";
import recipientIcon from "../../../../images/user-icon.png";
import Button, { ButtonType } from "../../../../components/Button";
import { noteSortOptions } from "../../../../constants/sortOptions";
import TextButton from "../../../../components/TextButton/TextButton";
import Dropdown, { DropdownOption } from "../../../../components/Dropdown";
import { AutoCompleteField } from "../../../../components/AutoCompleteField";
import { useUserServices } from "../../../../hooks/services/userServices.hooks";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import { capitalizeFirstLetter, stringifyParams } from "../../../../helpers/string";
import BookingDateFilter from "../../../../components/BookingFilter/BookingDateFilter";
import { FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../../../../components/v2/Styled/enum";
import { useSearchProviderByName } from "../../../../hooks/search.hook";
import { useRecentProviders } from "../../../../hooks/booking/preferredProviders.hooks";
import { checkIfEmpty, getValue } from "../../../../utils/object";

interface Props {
  open: boolean;
  onClose: () => unknown;
  userCountry?: string;
  forClientNotes?: boolean;
  handleFilterApplied: (data: any) => unknown
}
export interface FilterNoteValue {
  recipientId?: number | undefined | null;
  paymentStatus?: Array<string>;
  providerId?: number | undefined | null;
  serviceId?: number | undefined | null;
  sortBy?: string;
  status?: Array<string>;
  filterDate?: object;
}

interface NoteFilterState {
  providerNameToSearch: string;
  providerName: string;
  isProviderSelected: boolean;
  providerId: number | undefined | null;
  serviceId: number | undefined | null;
  sortBy: string | undefined | null;
  startDate: Date | string | null;
  endDate: Date | string | null;
  bookingId?: string;
}

export const NoteFilterModal = ({
  open,
  onClose,
  forClientNotes = false,
  userCountry,
  handleFilterApplied,
}: Props) => {
  const isMobile = useMobile();

  const defaultFilter = {
    providerNameToSearch: "",
    providerName: "",
    providerId: null,
    serviceId: null,
    isProviderSelected: false,
    sortBy: "desc",
    startDate: "",
    endDate: "",
    bookingId: "",
  } as const;

  const [filter, setFilter] = useState<NoteFilterState>(defaultFilter);

  const [providerName, setProviderName] = useState("");
  const [listOfProvider, setListOfProvider] = useState([]);
  const [bookingId, setBookingId] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState<number | null>(null);

  const { setErrorMessage } = useAlertStore();
  const { data: providerOptions, isLoading } = useSearchProviderByName({
    name: filter.providerNameToSearch, myProviders: true
  });

  const { data: recentPros } = useRecentProviders({
    currentPage: 1,
    perPage: 20,
    options: { allProviders: true, includeUpcoming: true },
  });

  const { services: rates } = useUserServices(userCountry || COUNTRY.AU);

  const _serviceTypeOptions = () => {
    const services = rates
      .filter(({ country }) => country === (userCountry || COUNTRY.AU))
      .map((rate) => {
        return {
          value: rate.id,
          title: capitalizeFirstLetter(rate.alias || rate.name),
          profession: rate.profession || "therapist",
          isMassage: rate.isMassage,
        };
      });
    return [...services];
  };

  const serviceTypeOptions = _serviceTypeOptions();

  useEffect(() => {
    if (!checkIfEmpty(recentPros) && !filter.providerNameToSearch) {
      const therapistList = getValue(recentPros, "therapistList", []);
      const transformRecentPros = therapistList.map((data: any) => ({
        value: data.id,
        title: `${data.firstName} ${data.lastName}`,
      }));
      setListOfProvider(transformRecentPros);
    }
  }, [recentPros, filter.providerNameToSearch]);

  useEffect(() => {
    if (filter.providerNameToSearch) {
      setListOfProvider(providerOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerOptions, isLoading]);

  useEffect(() => {
    const debounceSearchProvider = debounce(
      () => setFilter({ ...filter, providerNameToSearch: providerName }),
      1000
    );

    // Call the debounced function
    debounceSearchProvider();

    // Clean up the debounced function on component unmount
    return () => {
      debounceSearchProvider.cancel();
    };
  }, [providerName]);

  const handleProviderChange = (value: any) => {
    if (value.length >= 2) {
      setFilter({ ...filter, providerNameToSearch: value });
    }
  };

  const debounceProviderChangeHandler = debounce(handleProviderChange, 500);

  const onProviderSelect = (option: DropdownOption) => {
    setProviderName(option.title);
    setFilter({
      ...filter,
      providerId: option.value,
      providerName: option.title,
      isProviderSelected: true,
    });
  };

  const handleFilterApply = () => {
    if (providerName && !filter.isProviderSelected) {
      return setErrorMessage("The provider name you have entered could not be found.");
    }

    if (filter.startDate && !filter.endDate) {
      return setErrorMessage("Please select 'To' date first.");
    }
    if (filter.endDate && !filter.startDate) {
      return setErrorMessage("Please select 'From' date first.");
    }

    if (filter.endDate && !filter.startDate) {
      return setErrorMessage("Please select 'From' date first.");
    }


    const searchParams: any = {
      sortBy: filter.sortBy ? filter.sortBy : null,
    };

    if (providerName && filter.providerId) {
      searchParams.providerId = filter.providerId;
      searchParams.providerName = filter.providerName;
    }

    if (filter.startDate && filter.endDate) {
      searchParams.filterDate = JSON.stringify({
        startDate: filter.startDate,
        endDate: filter.endDate,
      });
    }
    if (filter.serviceId) {
      searchParams.serviceId = selectedServiceType;
    }

    if (bookingId) {
      searchParams.bookingId = bookingId;
    }

    handleFilterApplied(searchParams);
    onClose();
  };

  const handleClearAll = () => {
    setFilter(defaultFilter);
    setProviderName("");
    setBookingId("");
    setSelectedServiceType(null);
    debounceProviderChangeHandler("");
  };

  const shouldShowClearAll = () => providerName || filter.sortBy;

  const handleDateRangeFilterApplied = (field: string, value: Date | string | null) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const handleBookingIdChange = (value: string) => {
    // discarding non number chars
    let id = "";
    for (let ch of value) {
      if (!isNaN(+ch)) {
        id += ch;
      }
    }

    setBookingId(id);
  };

  return (
    <ContentModal
      visible={open}
      onClose={onClose}
      actions={[
        <TextButton
          text="Clear all"
          type={shouldShowClearAll() ? "danger" : "disabled"}
          onClick={handleClearAll}
        />,
        <Button
          title="Apply"
          type={ButtonType.secondary}
          onClick={handleFilterApply}
          width={"161px"}
        />,
      ]}
      title="Filter and sort"
      divider={false}
      maxWidth="md"
      PaperProps={{
        style: {
          minWidth: !isMobile ? "438px" : "100%",
          minHeight: !isMobile ? "default" : "100%",
        },
      }}
      actionStyle={{
        paddingLeft: Spacing.S6,
        paddingRight: Spacing.S6,
        justifyContent: "space-between",
      }}
      titleStyle={{ fontSize: "16px" }}
      fullWidth={isMobile}
      childrenStyle={{ marginTop: 0 }}
      fixButtonsWithDivider={true}
      showCrossIcon={true}
    >
      <Box direction={FlexDirection.Column} gap={Spacing.S3} marginY={Spacing.S6}>
        <Box fontWeight={700} fontSize={"22px"} color={Colors.NightBlue} fontFamily="Museo">
          Sort by
        </Box>
        {noteSortOptions.map((sortOpt) => (
          <Box alignItems="center" gap={Spacing.S4}>
            <RadioButton
              style={{ height: 16, width: 16 }}
              selected={sortOpt.value === filter.sortBy}
              onSelected={() => setFilter({ ...filter, sortBy: sortOpt.value })}
            />
            <Box
              color={Colors.Dusk}
              fontSize="16px"
              fontFamily="Museo"
              className="cursor-pointer"
              onClick={() => setFilter({ ...filter, sortBy: sortOpt.value })}
            >
              {sortOpt.title}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        fontWeight={700}
        fontSize={"22px"}
        color={Colors.NightBlue}
        fontFamily="Museo"
        marginY={Spacing.S3}
      >
        Filter by
      </Box>
      <Box direction={FlexDirection.Column} gap={Spacing.S4} marginBottom={Spacing.S4}>
        <Box direction={FlexDirection.Column} gap={Spacing.S4}>
          <Box
            fontWeight={FontWeight.Bold}
            fontSize={FontSize.F18}
            lineHeight={"27px"}
            fontFamily={FontFamily.Museo}
            color={Colors.Dusk}
          >
            Date
          </Box>
          <BookingDateFilter
            startingDate={filter.startDate}
            endingDate={filter.endDate}
            handleDateSelected={handleDateRangeFilterApplied}
          />
        </Box>
        {
          forClientNotes ? (

            <Dropdown
              title="Service type"
              options={serviceTypeOptions}
              selectedOption={serviceTypeOptions.find((option) => option.value === selectedServiceType)}
              onSelectedOption={(option) => {
                setSelectedServiceType(option.value);
                setFilter({ ...filter, serviceId: option.value });
              }}
              paddingTop={Spacing.S4}
              paddingBottom={Spacing.S3}
              titleStyles={{
                fontWeight: FontWeight.Bold,
                fontSize: FontSize.F18,
                lineHeight: "27px",
                fontFamily: FontFamily.Museo,
                color: Colors.Dusk,
              }}
            />
          ) : (
            <>
              <AutoCompleteField
                title="Provider name"
                options={listOfProvider as Array<DropdownOption>}
                placeholder="Enter provider name"
                onOptionClick={(option) => onProviderSelect(option)}
                onValueChange={(value) => {
                  setProviderName(value);
                  setFilter({
                    ...filter,
                    providerName: value,
                    isProviderSelected: false,
                  });
                  debounceProviderChangeHandler(value);
                }}
                value={providerName}
                dropDownOptionIcon={recipientIcon}
              />

              <AutoCompleteField
                title="Booking no."
                options={[]}
                placeholder="Enter booking no."
                onOptionClick={(option) => { }}
                onValueChange={handleBookingIdChange}
                value={bookingId}
              />
            </>
          )
        }
        <Box marginY={Spacing.S3} />
      </Box>
    </ContentModal>
  );
};
