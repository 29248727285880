import { Box, GridProps } from "@material-ui/core";

import Each from "../../../components/Each";
import { isAUDomain } from "../../../utils/url";
import { useMobile } from "../../../hooks/mobile";
import { getAccessToken } from "../../../helpers/accessToken";
import { checkIfEmpty, getValue } from "../../../utils/object";
import PaginationControl from "../../../components/PaginationControl";
import RecentTherapist from "../../../components/Therapists/RecentTherapist";
import { getProfileLink } from "../../../services/therapist/therapist.service";
import RecentTherapistsItem from "../../../components/Therapists/RecentTherapistItem";
import { BlockedTherapist, FavoriteTherapist, RecentlyBookedPro, Recipient } from "../../../models";
import { useGetFavouritePros, useGetRecipientRecentPros } from "../../../hooks/recipient/recipient.hooks";
import TherapistsPlaceholder, { TherapistsPlaceholderType } from "../../../components/TherapistsPlaceholder";
import { Spacing } from "../../../components/v2/Styled/enum";

interface RecipientFavProsProps {
  recipient: Recipient;
}

const RecipientRecentPros = ({ recipient }: RecipientFavProsProps) => {
  const isMobile = useMobile();

  const { isLoading, data } = useGetRecipientRecentPros(recipient.id, {});


  const { data: favTherapistsData } = useGetFavouritePros(recipient.id, {});

  const favTherapists = favTherapistsData
    ? (favTherapistsData.data as [FavoriteTherapist])
    : [];
  const providers = getValue(data, "data", []);

  const gridProps = {
    container: true,
    spacing: 3,
    style: {
      marginTop: isMobile ? "16px" : "40px",
      marginBottom: "16px",
      width: "auto",
      marginRight: "0px",
    },
    direction: isMobile ? "column" : undefined,
    alignItems: isMobile ? "center" : undefined,
  } as GridProps;

  const onProfileClicked = (therapist: FavoriteTherapist | BlockedTherapist) => {
    let publicProfileURL = getProfileLink({
      id: therapist.therapistId,
      firstName: therapist.therapist?.firstName || "",
    });
    const userToken = getAccessToken();
    publicProfileURL += `?token=${userToken}`;

    if (isAUDomain()) publicProfileURL += "&country=AU";

    window.open(publicProfileURL, "_blank");
  };

  if (checkIfEmpty(providers)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={isMobile ? "400px" : "75%"}
        marginTop={"80px"}
      >
        <TherapistsPlaceholder type={TherapistsPlaceholderType.recent} />
      </Box>
    );
  }

  return (
    <Box
      marginTop="43px"
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
    >
      <Box
        style={gridProps.style}
        gridGap={"24px"}
        display={"flex"}
        flexDirection={"column"}
        maxWidth={"1500px"}
        justifyContent={"center"}
      >
        {!isLoading && (
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            style={{ justifyContent: isMobile ? "center" : undefined }}
          >
            <Each
              of={providers}
              render={(pro) => (
                <Box p={0}>
                  <RecentTherapistsItem
                    therapist={pro}
                    onClick={() => onProfileClicked(pro)}
                    isFavourite={favTherapists.some(
                      (data: FavoriteTherapist) => data.therapistId === pro.id
                    )}
                  />
                </Box>
              )}
            />
          </Box>)}
      </Box>
    </Box>
  );
};

export default RecipientRecentPros;