import { Box } from "@material-ui/core";
import { useState } from "react";
import { Colors } from "../../../constants/colors";
import { useMobile } from "../../../hooks/mobile";
import Dialog from "../../Dialog";
import { CustomLottieAnimation } from "../../LottieAnimation/CustomLottieAnimation";
import * as Styled from "../../v2/Styled/enum";
import "./SlideUpOverrideAnimation.css";

type Props = {
  visible: boolean;
  onClose: () => void;
  animationData: any;
  children: React.ReactNode;
  speed?: number;
};

const modalHeight = "348px";

export function SlideUpOverrideAnimationModal({ speed = 1, ...props }: Props) {
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);

  const isMobile = useMobile();

  const onAnimationComplete = () => {
    setIsAnimationCompleted(true);
  };

  return (
    <Dialog
      open={props.visible}
      fullWidth={isMobile}
      maxWidth={"xs"}
      onClose={props.onClose}
      PaperProps={{
        style: {
          boxSizing: "border-box",
          overflow: "hidden",
          height: modalHeight,
          width: isMobile ? undefined : "444px",
        },
      }}
    >
      <Box
        width={"100%"}
        min-height={modalHeight}
        paddingY={"24px"}
        display={Styled.Display.Flex}
        alignItems={Styled.AlignItems.center}
        justifyContent={Styled.JustifyContent.center}
      >
        <CustomLottieAnimation
          animationData={props.animationData}
          autoPlay
          loop={false}
          onAnimationComplete={onAnimationComplete}
          styles={{ width: "300px", height: "300px" }}
          speed={speed}
        />
      </Box>
      <Box
        position={"absolute"}
        width={"100%"}
        min-height={modalHeight}
        bgcolor={Colors.White}
        top={"0"}
        left={"0"}
        bottom={"0"}
        right={"0"}
        style={{ transform: "translateY(100%)" }}
        className={isAnimationCompleted ? "visible" : ""}
      >
        {props.children}
      </Box>
    </Dialog>
  );
}
