import { Box, Typography } from "@material-ui/core";
import axios, { AxiosError } from "axios";
import { get, isEmpty, isError, isNil } from "lodash";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import EmailVerificationModal from "../../components/Account/EmailVerificationModal";
import Button, { ButtonType } from "../../components/Button";
import Contraindications from "../../components/Contraindications";
import CovidVaccinationInfo, { MessageType } from "../../components/CovidVaccinationInfo";
import DisclaimerModal from "../../components/DisclaimerModal/DisclaimerModal";
import Dropdown from "../../components/Dropdown";
import Gender from "../../components/Gender";
import MobileInput from "../../components/MobileModalOtp/MobileInput";
import MobileTextField from "../../components/MobileTextField";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import TextField from "../../components/TextField";
import TextLink from "../../components/TextLink";
import {
  AllMassageFors,
  AllRelationships,
  MassageFor,
  Relationship,
  STEP_PROGRESS,
} from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST } from "../../constants/common";
import { Paths } from "../../constants/paths";
import { NOTE_FOR_THERAPIST_PLACHOLDER } from "../../constants/placeholder";
import { REGEX } from "../../data/enums";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { Address, ErrorResponse, Recipient, User } from "../../models";
import ForgotPasswordModal from "../../pages/ForgotPassword/ForgotPasswordModal";
import LoginModal from "../../pages/Login/LoginModal";
import SignUpModal from "../../pages/SignUp/SignUpModal";
import { trackEvent } from "../../services/segment/track.service";
import {
  checkEmailExists,
  createUserAddress,
  updateUserPreferences,
} from "../../services/users/users.service";
import { fetchAddresses, useAddressStore } from "../../stores/address";
import { useAlertStore } from "../../stores/alert";
import {
  generateHandleNext,
  isMassageType,
  resetPaymentStore,
  updateBooking,
  useBookingStore,
  useLocationDetailsStore,
  useRecipientDetailsStore,
} from "../../stores/booking";
import { useUserStore } from "../../stores/user";
import { getCountryCode } from "../../utils/country";
import { checkIfEmpty } from "../../utils/object";
import { parseApiErrorFromResponse } from "../../helpers/error";
import pixelsService from "../../services/pixels/pixels.service";

export default function RecipientDetails(): JSX.Element {
  const history = useHistory();
  const { state } = useLocation();
  const isMobile = useMobile();

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [accessToken, setAccessToken] = React.useState<string | null>(useAccessToken());

  const [shouldSubmit, setShouldSubmit] = React.useState(false);

  const next = get(state, "next");

  const { user, fetchMe } = useUserStore();

  const {
    gender,
    setGender,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    countryCode,
    setCountryCode,
    mobile,
    setMobile,
    email,
    setEmail,
    specialInstructions,
    setSpecialInstructions,
    hasContraindications,
    setHasContraindications,
    contraindications,
    setContraindications,
    massageFor,
    setMassageFor,
    theirFirstName,
    setTheirFirstName,
    theirLastName,
    setTheirLastName,
    theirCountryCode,
    setTheirCountryCode,
    theirMobile,
    setTheirMobile,
    theirEmail,
    setTheirEmail,
    relationship,
    setRelationship,
    theirGender,
    setTheirGender,
    theirSpecialInstructions,
    setTheirSpecialInstructions,
    isCovidVaccinated,
    setIsCovidVaccinated,
    hasTheirContraindications,
    setHasTheirContraindications,
    theirContraindications,
    setTheirContraindications,
  } = useRecipientDetailsStore();

  const {
    serviceId,
    recipient,
    setRecipient,
    updatingForBooking,
    hasFluLikeSymptoms,
    setAddress: setSelectedAddress,
    setHasFluLikeSymptoms,
  } = useBookingStore();

  const { newAddress } = useLocationDetailsStore();

  const isMassage = isMassageType();

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const [saving, setSaving] = React.useState(false);

  const massageForOptions = AllMassageFors.map((massageFor) => {
    return {
      value: massageFor,
      title: MassageFor.toString(massageFor),
    };
  });

  const relationshipOptions = AllRelationships.map((relationship) => {
    return {
      value: relationship,
      title: Relationship.toString(relationship),
    };
  });

  const verifyFields = () => {
    if (isNil(accessToken) || isEmpty(accessToken)) {
      if (isNil(firstName) || isEmpty(firstName)) {
        setErrorMessage("Please enter your first name");
        return false;
      }

      if (isNil(lastName) || isEmpty(lastName)) {
        setErrorMessage("Please enter your last name");
        return false;
      }

      if (isNil(mobile) || isEmpty(mobile)) {
        setErrorMessage("Please enter your mobile");
        return false;
      }

      if (isNil(email) || isEmpty(email)) {
        setErrorMessage("Please enter your email");
        return false;
      }

      if (!REGEX.EMAIL.test(email.toLowerCase())) {
        setErrorMessage("Please enter valid email address");
        return false;
      }

      if (isNil(gender)) {
        setErrorMessage("Please select your gender");
        return false;
      }

      if (isNil(massageFor)) {
        setErrorMessage("Please select who this massage is for");
        return false;
      }
    }

    if (massageFor === MassageFor.someoneElse) {
      if (isNil(theirFirstName) || isEmpty(theirFirstName)) {
        setErrorMessage("Please enter their first name");
        return false;
      }

      if (isNil(theirLastName) || isEmpty(theirLastName)) {
        setErrorMessage("Please enter their last name");
        return false;
      }

      if (isNil(theirCountryCode) || isEmpty(theirCountryCode)) {
        setErrorMessage("Please select their country code");
        return false;
      }

      if (isNil(theirMobile) || isEmpty(theirMobile)) {
        setErrorMessage("Please enter their mobile");
        return false;
      }

      if (isNil(theirEmail) || isEmpty(theirEmail)) {
        setErrorMessage("Please enter their email");
        return false;
      }

      if (isNil(relationship)) {
        setErrorMessage("Please select your relationship with them");
        return false;
      }

      if (isNil(theirGender)) {
        setErrorMessage("Please provide their gender");
        return false;
      }
    }

    return true;
  };

  React.useEffect(() => {
    if (accessToken) {
      fetchMe();
    }
  }, [accessToken]);

  React.useEffect(() => {
    if (shouldSubmit) {
      setShouldSubmit(false);

      onSubmit();
    }
  }, [shouldSubmit]);

  const signUp = async () => {
    return axios
      .post("/users/signup", {
        firstName,
        lastName,
        mobile,
        email,
        gender,
        isCovidVaccinated,
        country: countryCode || (await getCountryCode()),
      })
      .then((response) => {
        const user = response.data as User;

        const { accessToken } = user;
        console.debug("accessToken: ", accessToken);

        resetPaymentStore(); //hotfix
        localStorage.setItem("token", accessToken);

        return accessToken;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 490) {
          // Email already exists
          // Show modal for logging in
          setSaving(false);
          setLoginModalOpen(true);
        } else {
          setSaving(false);
          const errorMessage = parseApiErrorFromResponse({ error });
          setErrorMessage(errorMessage);
        }

        return error;
      });
  };

  const addRecipient = () => {
    console.debug("add recipient");
    return axios
      .post("/users/me/recipients", {
        accessToken: localStorage.getItem("token"),
        firstName: theirFirstName,
        lastName: theirLastName,
        mobile: `${theirCountryCode}${theirMobile}`,
        email: theirEmail,
        gender: theirGender,
        relationship,
        specialInstructions: theirSpecialInstructions,
        isCovidVaccinated,
        hasContraindications: hasTheirContraindications,
        contraindications: theirContraindications,
      })
      .then((response) => {
        const recipient = response.data as Recipient;

        console.debug("recipient: ", recipient);
        setRecipient(recipient);

        return recipient;
      })
      .catch((error: AxiosError) => {
        setErrorMessage((error.response?.data as ErrorResponse).errorMessage);

        return error;
      });
  };

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = React.useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);

  React.useEffect(() => {
    fetchAddresses();
    pixelsService.trackPageView();
  }, []);

  const { addresses } = useAddressStore();

  const toggleDisclaimerModal = (show: boolean) => setShowDisclaimer(show);

  const handleDisclaimerNext = () => {
    toggleDisclaimerModal(false);
    onSubmit(false);
  };

  const closeDisclaimerModal = () => {
    setSaving(false);
    toggleDisclaimerModal(false);
  };

  // checks if user entered email is already in use or not
  const checkEmailUse = async (email: string | null) => {
    try {
      if (!email) throw Error("Email required");

      const checkResponse = await checkEmailExists(email);

      const { exists } = checkResponse.data;
      return exists;
    } catch (err) {
      throw err;
    }
  };

  // on continue click
  const checkSubmit = async () => {
    const isFormValid = verifyFields();
    if (!isFormValid) return;

    setSaving(true);
    try {
      // if email does not exists and serviceType is massage show disclaimer modal
      const mailExists = await checkEmailUse(email);
      if (!mailExists && isMassage) {
        toggleDisclaimerModal(true);
        return;
      }
    } catch (err) {
      setSaving(false);
      setErrorMessage("Could not validate your email. Please try again");
      return;
    }

    onSubmit(false);
  };

  const createNewAddress = async () => {
    if (checkIfEmpty(newAddress)) return;
    return createUserAddress({ data: newAddress }).then((response) => {
      const address = response as Address;
      setSelectedAddress(address);
    });
  };

  // continue with booking process
  const onSubmit = async (validateForm = true) => {
    if (validateForm) {
      const isValidForm = verifyFields();
      if (!isValidForm) return;
    }

    setSaving(true);

    // Sign up and create recipient if needed
    // Then forward
    let signUpResult;
    let addRecipientResult;

    if (isNil(accessToken)) {
      signUpResult = await signUp();
    }

    if (!isError(signUpResult)) {
      await createNewAddress();
      if (massageFor === MassageFor.someoneElse) {
        // Add recipient
        addRecipientResult = await addRecipient();

        const recipient = addRecipientResult as Recipient;

        trackEvent("Recipient Details Completed", {
          step: 3,
          recipient_role: recipient.relationship,
          recipient_gender: recipient.gender,
          version: 2,
        });
      } else {
        // Myself
        const preferencePayload = {
          preferences: { specialInstructions, contraindications, hasContraindications },
          serviceId,
        };
        const token = signUpResult || accessToken;
        await updateUserPreferences(preferencePayload, token);
        trackEvent("Recipient Details Completed", {
          step: 3,
          recipient_role: "myself",
          recipient_gender: gender,
          flu_like_symptom: hasFluLikeSymptoms,
          version: 2,
        });
      }

      if (updatingForBooking) {
        updateBooking()
          .then(() => {
            setSaving(false);

            history.push(`/bookings/${updatingForBooking.id}`);
          })
          .catch((error) => {
            setSaving(false);
            setErrorMessage("Unable to update booking");
          });
      } else {
        const nextPath = next || Paths.DateAndTime;

        // Go to next step
        if (!isError(signUpResult) && !isError(addRecipientResult)) {
          history.push(nextPath, { preFetchPaymentMethod: false });
        }
      }
    }
  };

  React.useEffect(() => {
    trackEvent("Recipient Details Viewed", {
      step: 3,
      version: 2,
    });
  }, []);

  const handleNextObject = generateHandleNext(checkSubmit);

  return (
    <NewBookingWrapper
      overline="Step 3 of 7"
      title="Recipient details"
      // subtitle={isNil(accessToken) ? "Details will be used to create account" : undefined}
      footerLeftButton={
        <Button
          type={ButtonType.outlined}
          title={updatingForBooking ? "Back" : "Previous"}
          onClick={() => history.push(Paths.ServiceDetails)}
          style={{ padding: "12px 24px" }}
        />
      }
      nextGeneratorObject={handleNextObject}
      footerRightButton={
        <Button
          loading={saving}
          type={updatingForBooking ? ButtonType.secondary : ButtonType.indigo}
          title={updatingForBooking ? "Save & Apply" : "Continue"}
          style={{ padding: "12px 24px" }}
          onClick={() => handleNextObject.next()}
        />
      }
      progress={STEP_PROGRESS[3]}
    >
      {!isNil(accessToken) && (
        <Box mt={5} mb={5}>
          <Typography>
            You are logged in as
            <b> {user?.email}</b>
          </Typography>
          <TextLink
            title="Logout"
            onClick={() => {
              localStorage.removeItem("token");
              setAccessToken(null);
            }}
          />
        </Box>
      )}
      {isNil(accessToken) && (
        <Box mt={4}>
          {/* <ToggleSwitch
            leftTitle="Female"
            rightTitle="Male"
            selectedValue={
              gender === "male"
                ? ToggleSwitchValue.right
                : ToggleSwitchValue.left
            }
            onSelectedValue={(value) =>
              setGender(value === ToggleSwitchValue.left ? "female" : "male")
            }
          /> */}
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <TextField
              title="Your first name"
              value={firstName}
              onChange={(text) => setFirstName(text)}
            />
            <Box width="60px" />
            <TextField
              title="Your last name"
              value={lastName}
              onChange={(text) => setLastName(text)}
            />
          </Box>
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            {/* <MobileTextField
              countryCode={countryCode}
              mobile={mobile}
              onCountryCodeChange={(code) => setCountryCode(code)}
              onMobileChange={(mobile) => setMobile(mobile)}
              mobileFieldTitle="Your mobile"
            /> */}

            <MobileInput
              onVerified={(formattedMobileNumber, numberOnly, countryCode, country) => {
                setMobile(formattedMobileNumber);
                setCountryCode((country || "").toUpperCase());
              }}
            />
            <Box width="60px" />
            <EmailVerificationModal onVerified={setEmail} isVerified={!!email} />
          </Box>
          <Gender onChange={(text: any) => setGender(text)} value={gender!} />
        </Box>
      )}

      <Dropdown
        title="This booking is for"
        options={massageForOptions}
        selectedOption={massageForOptions.find((option) => option.value === massageFor)}
        onSelectedOption={(option) => setMassageFor(option.value)}
      />

      {massageFor === MassageFor.myself && (
        <>
          <Contraindications
            hasContraindications={hasContraindications}
            setHasContraindications={setHasContraindications}
            contraindications={contraindications}
            setContraindications={setContraindications}
            gender={gender}
          />
          <TextField
            title="Note for your therapist"
            subtitle="Your note is visible to providers."
            placeholder={NOTE_FOR_THERAPIST_PLACHOLDER}
            value={specialInstructions}
            onChange={(text) => setSpecialInstructions(text)}
            multiline
            maxLength={SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST}
          />
        </>
      )}
      {massageFor === MassageFor.someoneElse && (
        <Box>
          <Box
            fontFamily="SF UI Text"
            fontWeight={500}
            fontSize="14px"
            marginTop="12px"
            marginBottom="32px"
            color={Colors.Dusk}
          >
            If this is a surprise, please enter your own mobile and email so we don't ruin the
            surprise by sending the booking confirmation message to the recipient!
          </Box>

          <Box fontFamily="Museo" fontWeight={700} fontSize="24px" color={Colors.NightBlue}>
            Their details
          </Box>
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <TextField
              title="Their first name"
              value={theirFirstName}
              onChange={(text) => setTheirFirstName(text)}
            />
            <Box width="60px" />
            <TextField
              title="Their last name"
              value={theirLastName}
              onChange={(text) => setTheirLastName(text)}
            />
          </Box>
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            {/* <TextField
              title="Their mobile"
              value={theirMobile}
              onChange={(text) => setTheirMobile(text)}
            /> */}
            <MobileTextField
              countryCode={theirCountryCode}
              mobile={theirMobile}
              mobileFieldTitle="Their mobile"
              onCountryCodeChange={(code) => setTheirCountryCode(code)}
              onMobileChange={(text) => setTheirMobile(text)}
            />
            <Box width="60px" />
            <TextField
              title="Their email"
              placeholder="your@email.com"
              value={theirEmail}
              onChange={(text) => setTheirEmail(text)}
            />
          </Box>
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Dropdown
              title="Relationship to you"
              options={relationshipOptions}
              selectedOption={relationshipOptions.find((option) => option.value === relationship)}
              onSelectedOption={(option) => setRelationship(option.value)}
            />
          </Box>
          <Gender
            onChange={(text: any) => setTheirGender(text)}
            value={theirGender!}
            genderFor="someone_else"
          />
          <Contraindications
            hasContraindications={hasTheirContraindications}
            setHasContraindications={setHasTheirContraindications}
            contraindications={theirContraindications}
            setContraindications={setTheirContraindications}
            gender={theirGender}
          />
          <TextField
            title="Note for their therapist"
            value={theirSpecialInstructions}
            onChange={(text) => setTheirSpecialInstructions(text)}
            multiline
            maxLength={SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST}
            placeholder={NOTE_FOR_THERAPIST_PLACHOLDER}
          />
        </Box>
      )}
      <Box height="20px" />

      <CovidVaccinationInfo
        massageFor={massageFor as MassageFor}
        isCovidVaccinated={isCovidVaccinated}
        type={MessageType.client}
        onCheckBoxChange={(status) => setIsCovidVaccinated(status)}
      />
      {isNil(accessToken) && (
        <Box
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="14px"
          marginTop="11px"
          color={Colors.Dusk}
          textAlign="center"
        >
          By clicking ‘continue’ and creating an account, you agree to our{" "}
          <a
            href="https://getblys.com.au/terms/"
            target="_blank"
            rel="noreferrer"
            style={{ color: Colors.Dusk }}
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://getblys.com.au/privacy/"
            target="_blank"
            rel="noreferrer"
            style={{ color: Colors.Dusk }}
          >
            Privacy Policy
          </a>
        </Box>
      )}
      {isNil(user) && (
        <LoginModal
          open={loginModalOpen}
          onClose={() => setLoginModalOpen(false)}
          onLoggedIn={async () => {
            setAccessToken(localStorage.getItem("token"));

            // NOTE: Fetch addresses to help with deciding next path
            await fetchAddresses();

            fetchMe();
            setLoginModalOpen(false);
            setShouldSubmit(true);
          }}
          onForgotPassword={() => {
            setLoginModalOpen(false);
            setForgotPasswordModalOpen(true);
          }}
          onCreateAccount={() => {
            setLoginModalOpen(false);
            setSignUpModalOpen(true);
          }}
          email={email}
        />
      )}

      {isNil(user) && (
        <ForgotPasswordModal
          open={forgotPasswordModalOpen}
          onClose={() => setForgotPasswordModalOpen(false)}
          onBackToLogin={() => {
            setForgotPasswordModalOpen(false);
            setLoginModalOpen(true);
          }}
          onSentInstructions={(email) => {
            setForgotPasswordModalOpen(false);
            setSuccessMessage(`Password reset instructions have been sent to ${email}`);
          }}
        />
      )}

      {isNil(user) && (
        <SignUpModal
          open={signUpModalOpen}
          onLogin={() => {
            setSignUpModalOpen(false);
            setLoginModalOpen(true);
          }}
          onCreatedAccount={() => {
            fetchMe();
            setSignUpModalOpen(false);

            setShouldSubmit(true);
          }}
          onClose={() => setSignUpModalOpen(false)}
        />
      )}

      <DisclaimerModal
        isOpen={showDisclaimer}
        onNext={() => handleDisclaimerNext()}
        onClose={() => closeDisclaimerModal()}
      />

      {/* <LoginModal
        open={showLoginModal}
        email={email}
        onClose={() => setShowLoginModal(false)}
        onSuccess={(accessToken) => {
          setShowLoginModal(false);
          setAccessToken(accessToken);
          setShouldSubmit(true);
        }}
      /> */}
    </NewBookingWrapper>
  );
}
