import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";

import { useMobile } from "../../hooks/mobile";
import Wrapper from "../../components/Wrapper";
import { useUserStore } from "../../stores/user";
import { useAlertStore } from "../../stores/alert";
import { useAccessToken } from "../../hooks/common";
import { parseApiV2Error } from "../../helpers/error";
import { useTherapistStore } from "../../stores/therapist";
import { useProfileLock } from "../../hooks/userProfileLock.hooks";
import { TherapistPublicProfile, UserTreatment } from "../../models";
import ContentSectionControl from "../../components/ContentSectionControl";
import { getPublicProfile } from "../../services/therapist/therapist.service";
import { groupedTreatments } from "../../services/therapist/treatments.service";
import SystemServices from "../../components/ProDashboard/Service/SystemServices";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import CustomServices from "../../components/ProDashboard/Service/Custom/CustomServices";
import { getProTreatments, toggleTreatment } from "../../services/therapist/proTreatment.service";

interface Modality {
  value: string;
  text: string;
  id: number;
}

const CUSTOM_SERVICE = "other";

export default function Services() {
  const accessToken = useAccessToken();
  const { user } = useUserStore();
  const isMobile = useMobile();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { setCustomServices } = useTherapistStore();

  const [loading, setLoading] = React.useState(true);
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);
  const [sections, setSections] = useState<Array<string>>([]);
  const [sectionTreatments, setSectionTreatments] = useState<Modality[]>([]);
  const [userTreatments, setUserTreatments] = useState<Array<UserTreatment>>([]);
  const [categorizedTreatments, setCategorizedTreatments] = useState<any>({});
  const [publicProfileData, setPublicProfileData] = useState<TherapistPublicProfile>();

  const { isLoading: isLockLoading, toggleLock } = useProfileLock();

  useEffect(() => {
    if (accessToken) {
      getProTreatments(accessToken || "").then((response) => {
        setUserTreatments(response.data);
      });

      groupedTreatments(accessToken || "", { country: user?.country })
        .then((response) => {
          setLoading(false);
          setCategorizedTreatments(response);
        })
        .catch((error) => {
          setLoading(false);
          console.debug("Error while fetching grouped treatments", error);
        });
    }
  }, [accessToken, isLockLoading]);

  useEffect(() => {
    if (user) {
      getPublicProfile(user.id, accessToken).then((response) =>
        setPublicProfileData(response.data)
      );
    }
  }, [user, accessToken]);

  useEffect(() => {
    setCustomServices(
      publicProfileData?.therapistData?.userservices?.filter(
        (service: any) => service?.service?.custom === true
      ) || []
    );
  }, [publicProfileData]);

  useEffect(() => {
    if (categorizedTreatments) {
      setSections([...Object.keys(categorizedTreatments)]);
    }
  }, [categorizedTreatments]);

  useEffect(() => {
    if (categorizedTreatments && sections.length > 1) {
      setSectionTreatments(categorizedTreatments[sections[selectedSectionIndex]]);
    }
  }, [selectedSectionIndex, sections, categorizedTreatments]);

  const updateUserTreatments = (items: UserTreatment[], added: boolean) => {
    if (added) {
      setUserTreatments([...userTreatments, ...items]);
    } else {
      const updatedTreatments = userTreatments.filter(
        (treatment) => !items.some(({ treatmentId }) => treatmentId === treatment.treatmentId)
      );
      setUserTreatments([...updatedTreatments]);
    }
  };

  const handleTreatmentChange = (treatmentId: number, value: boolean) => {
    toggleTreatment(accessToken || "", treatmentId, value)
      .then((response) => {
        setSuccessMessage(`Treatment successfully ${value ? "added" : "removed"}`);
        updateUserTreatments([response.data], value);
      })
      .catch((error) => setErrorMessage(parseApiV2Error(error)));
  };

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Services" />

        {loading && (
          <Box mt={"12%"} ml={"auto"} mr={"auto"}>
            <CircularProgress variant="indeterminate" size={30} thickness={5} />
          </Box>
        )}

        {!loading && (
          <Box mt="40px" ml={isMobile ? "16px" : "80px"} mr={isMobile ? "16px" : "80px"}>
            <ContentSectionControl
              sections={sections}
              selectedIndex={selectedSectionIndex}
              onSelectedIndex={(index) => setSelectedSectionIndex(index)}
            />

            <SystemServices
              services={sectionTreatments}
              userTreatments={userTreatments}
              onChange={handleTreatmentChange}
              updateUserTreatments={updateUserTreatments}
              handleToggleLock={toggleLock}
              loading={isLockLoading}
            />

            {(sections[selectedSectionIndex] || "").toLocaleLowerCase() === CUSTOM_SERVICE && (
              <CustomServices />
            )}
          </Box>
        )}
      </Wrapper>
    </>
  );
}
