import React from "react";
import { Box } from "@material-ui/core";
import AvatarImageWithCovidStatus, { ImageSize } from "../AvatarImageWithCovidStatus";
import { BASE_UPLOADS_URL } from "../../constants/common";

interface Props {
  image: string;
  style?: object;
  isCovidVaccinated: boolean;
}

const UserAvatar = ({ image, style = {}, isCovidVaccinated }: Props) => {
  const profileUrl = `${BASE_UPLOADS_URL}/${image}`;
  return (
    <Box style={{ ...{ height: "56px", width: "56px" }, ...style }}>
      <AvatarImageWithCovidStatus
        imageSrc={profileUrl}
        size={ImageSize.small}
        isCovidVaccinated={isCovidVaccinated}
      />
    </Box>
  );
};

export default UserAvatar;
