import React from "react";
import Link from "@mui/material/Link";

import Dialog from "../../../components/Dialog";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { Box, ButtonBase } from "@material-ui/core";
import { checkIfEmpty } from "../../../utils/object";
import ModalFooter from "../../../components/ModalFooter";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import RoundedImage from "../../../components/Image/RoundedImage";
import ProviderInfoModalHeader from "./ProviderNoteInfoModalHeader";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import { Display, FlexDirection, Spacing } from "../../../components/v2/Styled/enum";
import ProfilePicturePlaceholder from "../../../images/profile-pic-placeholder.png";

export interface ProviderNoteInfoModalItem {
  icon: string;
  title: string;
  description: string;
  selected: boolean;
  value: any;
  backgroundColor: string;
}

interface Props {
  open: boolean;
  title: string;
  profileImage: string;
  firstName: string;
  date: string;
  note: string;
  bookingId: number;
  images: Array<any>;
  onClose: () => unknown;
  onOpenProProfile: () => unknown;
  handleViewBooking: () => unknown;
}

const ProviderNoteInfoModal = ({
  open,
  title,
  profileImage,
  firstName,
  date,
  bookingId,
  note,
  images,
  onClose,
  onOpenProProfile,
  handleViewBooking,
}: Props) => {
  const isMobile = useMobile();

  const openImageURL = (url: string) => window.open(url, "_blank");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullScreen={isMobile}
    >
      <Box
        width={isMobile ? "100%" : "640px"}
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <ProviderInfoModalHeader title={title} onClose={onClose} />
        <Box
          display="flex"
          flexDirection="column"
          bgcolor={Colors.PaleGrey}
          mx="48px"
          my="48px"
          borderRadius="8px"
          padding="16px"
          paddingLeft={isMobile ? "12px" : "35px"}
          paddingRight={isMobile ? "12px" : "35px"}
          alignItems="left"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {profileImage && (<img
              src={`${BASE_UPLOADS_URL}/${profileImage}`}
              style={{
                width: "64px",
                height: "64px",
                borderRadius: "32px",
                objectFit: "cover",
              }}
            />)}
            {!profileImage && (
              <img src={ProfilePicturePlaceholder} style={{
                width: "64px", height: "64px", borderRadius: "32px",
                objectFit: "cover"
              }} />
            )}

            <Box display="flex" flexDirection="column" gridGap="4px" justifyContent="center" ml="16px">
              <Link style={{ fontFamily: "Museo", fontWeight: 700, color: Colors.Dusk, fontSize: "20px", textDecoration: "underline", lineHeight: "30px", cursor: "pointer" }} onClick={onOpenProProfile}>{firstName}</Link>
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                <Box
                  fontFamily="Open Sans"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="21px"
                  color={Colors.BlueyGrey}>{`Added on ${date} for booking`}&nbsp;</Box>
                <Link style={{ fontFamily: "Open Sans", fontWeight: 500, color: Colors.Dusk, fontSize: "14px", lineHeight:"21px", textDecoration: "underline", cursor: "pointer" }} onClick={handleViewBooking}>
                  {`#${bookingId}`}
                </Link>

              </Box>
            </Box>


          </Box>

          <Box
            fontFamily="Museo"
            fontWeight={500}
            fontSize="16px"
            lineHeight="24px"
            color={Colors.Dusk}
            mt="16px"
          >{note}</Box>

          {!checkIfEmpty(images) && (<Box display={Display.Flex} flexDirection={FlexDirection.Row} flexWrap="wrap" gridGap={Spacing.S3} my="16px">
            {images.map((file: string) => <RoundedImage
              fileUrl={file}
              allowRemove={false}
              clickable
              onClick={() => openImageURL(file)}
            />)}
          </Box>)}
        </Box>

        <ModalFooter>
          <Button
            title="Ok"
            type={ButtonType.outlined}
            size={ButtonSize.small}
            onClick={onClose}
            width="130px"
          />
        </ModalFooter>
      </Box>
    </Dialog>
  );
};

export default ProviderNoteInfoModal;
