import { Box, ButtonBase, Checkbox, Popover } from "@material-ui/core";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../../../components/v2/Styled/enum";
import { Colors } from "../../../../constants/colors";
import Ratings from "../../../../components/Ratings";
import TextField from "../../../../components/TextField";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Button, { ButtonType } from "../../../../components/Button";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import RoundedImage from "../../../../components/Image/RoundedImage";
import { getFullUploadsUrl } from "../../../../utils/url";
import { useCreateClientReview } from "../../../../hooks/review/review.hooks";
import { useAlertStore } from "../../../../stores/alert";

interface Props {
  jobId: number | string;
  review?: any;
  visible: boolean;
  onClose: () => unknown;
  onAddReview: (data: any) => unknown;
  selectedReviewId?: number | null;
}

const UserRatingModal = ({
  jobId,
  review,
  visible,
  onClose,
  onAddReview,
  selectedReviewId,
}: Props) => {

  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [reviewFiles, setReviewFiles] = useState<any>([]);
  const [imagesToRemove, setImagesToRemove] = useState<number[]>([]);
  const [uploadToPortfolio, setUploadToPortfolio] = useState(false);

  const reviewImageRef = useRef<HTMLInputElement>(null);

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const createNoteCB = {
    onSuccess: (response: any) => {
      const successMsg = "Your review has been updated successfully.";
      setSuccessMessage(successMsg);
    },
    onError: () => setErrorMessage("Unable to update review. Please try again."),
  };
  const { mutate: addClientRating } = useCreateClientReview(createNoteCB);


  useEffect(() => {
    if (checkIfEmpty(review) && visible) {
      resetData();
    } else {
      setReviewText(review.review);
      setReviewFiles(reviewFiles);
      setRating(review.rating);
      setUploadToPortfolio(review.isUploadToPortfolio || false);
    }
  }, [review, visible]);

  const onRatingUser = (index: number) => {
    setRating(index);
  };

  const resetData = () => {
    setRating(0);
    setReviewText("");
    setReviewFiles([]);
    setUploadToPortfolio(false);
  };

  const onModalClose = () => {
    if (!selectedReviewId) {      
      resetData();
    }
    onClose();
  };

  const addClientReview = () => {
    const data: any = {};
    const formData: any = new FormData();

    if (!rating) {
      setErrorMessage("Rating cannot be empty.");
      return;
    }

    data.rating = rating.toString();
    formData.append("rating", rating);

    data.review = reviewText;
    formData.append("review", review);

    data.isUploadToPortfolio = JSON.stringify(uploadToPortfolio);
    formData.append("isUploadToPortfolio", uploadToPortfolio);

    if (reviewFiles && reviewFiles.length) {
      data.reviewFiles = reviewFiles;
      reviewFiles.forEach((image: any) => {
        if (typeof image !== "string") formData.append("images", image.file);
      });
    };

    if (!checkIfEmpty(imagesToRemove)) {
      data.imagesToRemove = JSON.stringify(imagesToRemove);
      formData.append("imagesToRemove", JSON.stringify(imagesToRemove));
    }

    if (selectedReviewId) {
      addClientRating({ jobId, data });
    }

    onAddReview(data);
  };

  const onUploadFileClick = () => {
    if (reviewImageRef.current) {
      reviewImageRef.current.click();
    }
  };

  const onReviewImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    const uploadedFile = {
      file,
      tempURL: URL.createObjectURL(file),
    };

    setReviewFiles([...reviewFiles, uploadedFile]);
  };

  const onRemoveReviewFile = (index: number) => {
    if (reviewFiles[index]) {
      const fileId = reviewFiles[index].id;
      if (fileId) setImagesToRemove([...imagesToRemove, fileId]);
    }
    const uploadedFiles = [...reviewFiles];
    uploadedFiles.splice(index, 1);
    setReviewFiles(uploadedFiles);
  };

  const hasReview = !checkIfEmpty(review);

  const actions = [
    <Button
      onClick={onModalClose}
      type={ButtonType.outlined}
      title={"Cancel"}
    />,
    <Button
      disabled={!rating}
      onClick={addClientReview}
      type={ButtonType.secondary}
      title={hasReview ? "Save changes" : "Add"}
    />,
  ];

  return (
    <ContentModal
      fullWidth
      maxWidth={"sm"}
      divider={false}
      visible={visible}
      onClose={onModalClose}
      actionStyle={{ padding: "0px 48px 48px 48px" }}
      contentStyle={{ padding: "48px 48px 40px 48px" }}
      childrenStyle={{ marginTop: 0 }}
      actions={actions}
    >
      <input
        type="file"
        id="note-file-upload"
        ref={reviewImageRef}
        style={{ display: "none" }}
        onChange={onReviewImageChange}
      />
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S8}>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          gridGap={Spacing.S8}
        >
          <Box>
            <Box
              display={Display.Flex}
              flexDirection={FlexDirection.Row}
              justifyContent={JustifyContent.spaceBetween}
            >
              <Box
                fontSize={FontSize.F22}
                color={Colors.NightBlue}
                marginBottom={Spacing.S3}
                fontWeight={FontWeight.Bold}
                fontFamily={FontFamily.Museo}
              >
                {hasReview ? "Update review" : "Rate & review the client"}
              </Box>
            </Box>
            <Box
              color={Colors.Grey}
              fontSize={FontSize.F14}
              marginBottom={Spacing.S4}
              fontWeight={FontWeight.Medium}
              fontFamily={FontFamily.OpenSans}
            >
              Your review will be visible to the client and other providers they book with.
              Please keep your feedback relevant and avoid sensitive details.
              You can also upload images to provide additional context with your review.
            </Box>
            <Ratings rating={rating} width="19.8px" height="18.9px" onClickRating={onRatingUser} />
          </Box>

          <TextField
            multiline
            paddingTop="0px"
            paddingBottom="0px"
            value={reviewText}
            maxLength={2000}
            placeholder="Leave a review (optional)"
            onChange={(text: string) => setReviewText(text)}
          />

          {(reviewFiles || []).length ? (
            <Box>
              <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3}>
                {reviewFiles.map((file: any, index: number) => {
                  const { filePath } = file;
                  const fileURL =
                    filePath && typeof filePath === "string"
                      ? getFullUploadsUrl(filePath)
                      : file.tempURL;
                  return (
                    <RoundedImage
                      fileUrl={fileURL}
                      allowRemove
                      onRemove={() => onRemoveReviewFile(index)}
                    />
                  );
                })}
              </Box>
              <Box
                fontFamily="Museo"
                fontSize="16px"
                color={Colors.Dusk}
                fontWeight={400}
                lineHeight="24px"
                display={"flex"}
                alignItems={"center"}
                gridGap={"8px"}
                marginTop={"16px"}
              >
                <Checkbox
                  style={{
                    color: Colors.TurquoiseBlue,
                    margin: 0,
                    padding: 0,
                  }}
                  checked={uploadToPortfolio}
                  onChange={(event: any) => {
                    setUploadToPortfolio(event.target.checked);
                  }}
                />{" "}
                Upload images to my portfolio
              </Box>
            </Box>
          ) : (
            <></>
          )}

          <Button
            width="132px"
            loading={false}
            title="Add image"
            type={ButtonType.outlined}
            onClick={onUploadFileClick}
          />
        </Box>
      </Box>
    </ContentModal>
  );
};

export default UserRatingModal;
