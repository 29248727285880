export enum Spacing {
  "S0" = "0px",
  "S1" = "4px",
  "S2" = "8px",
  "S3" = "12px",
  "S4" = "16px",
  "S5" = "20px",
  "S6" = "24px",
  "S7" = "28px",
  "S8" = "32px",
  "S9" = "36px",
  "S10" = "40px",
  "S11" = "44px",
  "S12" = "48px",
  "S13" = "52px",
  "S14" = "56px",
  "S15" = "60px",
  "S16" = "64px",
  "S17" = "68px",
  "S18" = "72px",
  "S19" = "76px",
  "S20" = "80px",
}

export enum Display {
  Flex = "flex",
  Block = "block",
  None = "none",
  InlineBlock = "inline-block",
  Inline = "inline",
  InlineFlex = "inline-flex",
}

export enum FlexDirection {
  Row = "row",
  Column = "column",
  RowReverse = "row-reverse",
  ColumnReverse = "column-reverse",
}

export enum JustifyContent {
  center = "center",
  start = "flex-start",
  end = "flex-end",
  spaceBetween = "space-between",
  spaceAround = "space-around",
  spaceEvenly = "space-evenly",
}

export enum AlignItems {
  center = "center",
  start = "flex-start",
  end = "flex-end",
  stretch = "stretch",
  baseline = "baseline",
}

export enum TextAlign {
  center = "center",
  left = "left",
}

export enum Position {
  Fixed = "fixed",
  Relative = "relative",
  Absolute = "absolute",
  Static = "static",
}

export enum FontFamily {
  Museo = "Museo",
  OpenSans = "Open Sans",
  SFPro = "SF UI Text",
  WorkSans = "WorkSans",
}

export enum FontWeight {
  Bolder = "900",
  Bold = "700",
  SemiBold = "600",
  Medium = "500",
  Regular = "400",
  Light = "300",
}

export enum FontSize {
  "F10" = "10px",
  "F11" = "11px",
  "F12" = "12px",
  "F14" = "14px",
  "F16" = "16px",
  "F18" = "18px",
  "F20" = "20px",
  "F22" = "22px",
  "F24" = "24px",
  "F28" = "28px",
  "F30" = "30px",
  "F32" = "32px",
  "F36" = "36px",
}

export enum LineHeight {
  "L16" = "16px",
  "L18" = "18px",
  "L21" = "21px",
  "L22" = "22px",
  "L24" = "24px",
  "L27" = "27px",
  "L30" = "30px",
  "L33" = "33px",
  "L35" = "35px",
}

export enum LetterSpacing {
  S0 = "0px",
  S05 = "0.5px",
}

export enum Variant {
  Text = "text",
  Outlined = "outlined",
  Contained = "contained",
}

export enum Orientation {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum Size {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum Cursor {
  pointer = "pointer",
  default = "default",
}

export enum TextDecoration {
  underline = "underline",
}

export enum FlexWrap {
  Wrap = "wrap",
  WrapReverse = "wrap-reverse",
  NoWrap = "nowrap",
}
