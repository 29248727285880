import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import enabledCalenderIcon from "./../../images/filterSelectedCalender.svg";
import disabledCalenderIcon from "./../../images/disabled-calendar.svg";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Position,
  Spacing,
} from "../v2/Styled/enum";

import { Colors } from "../../constants/colors";
import { Calendar } from "./Calendar";
import { getDateObject } from "../../utils/date.util";

interface Props {
  title: string;
  forceSelect?: boolean;
  onDateSelected?: (selectedDate: Date) => unknown;
  wrapperStyle?: object;
  placeHolder?: string;
  value: string;
  minValidDate?: Date | null;
  fromDate?: Date | null | string | undefined;
  validateOnDateSelect?: () => boolean;
  titleStyle?: object;
}

export const DateSelectorFieldV2 = ({
  title,
  forceSelect = false,
  wrapperStyle,
  placeHolder,
  onDateSelected,
  value,
  minValidDate,
  fromDate = null,
  validateOnDateSelect = () => true,
  titleStyle = {},
}: Props) => {
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    if (forceSelect) {
      setIsSelected(forceSelect);
    }
  }, [forceSelect]);

  const handleDaySelected = (selectedDate: Date) => {
    setIsSelected(false);
    onDateSelected && onDateSelected(selectedDate);
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsSelected(false);
      }}
    >
      <Box style={{ flex: 1, position: Position.Relative, ...wrapperStyle }}>
        <Typography
          style={{
            fontSize: FontSize.F14,
            lineHeight: LineHeight.L21,
            fontWeight: FontWeight.Bold,
            color: Colors.Dusk,
            marginBottom: Spacing.S1,
            ...titleStyle,
          }}
        >
          {title}
        </Typography>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          alignItems={AlignItems.center}
          bgcolor={isSelected ? Colors.PaleGrey : Colors.White}
          style={{
            "border-bottom": `2px solid ${Colors.LightPeriwinkle}`,
            padding: "8px 12px 8px 12px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (!validateOnDateSelect()) {
              return;
            }
            setIsSelected(!isSelected);
          }}
        >
          <Box
            fontFamily={FontFamily.Museo}
            fontWeight={FontWeight.Regular}
            fontSize={FontSize.F16}
            lineHeight={LineHeight.L24}
            flex={1}
            style={{
              color: value ? Colors.TurquoiseBlue : Colors.LightPeriwinkle,
            }}
          >
            {value || placeHolder}
          </Box>
          <Box
            style={{
              height: "100%",
            }}
          >
            <img
              src={isSelected ? enabledCalenderIcon : disabledCalenderIcon}
              alt="enabled-calendar"
            />
          </Box>

          <Calendar
            open={isSelected}
            onDaySelected={(selectedDate: Date) => {
              handleDaySelected(selectedDate);
            }}
            minValidDate={minValidDate}
            selectedDate={value ? getDateObject(value) : undefined}
            hasDateRange={!!fromDate}
            selectedFromDate={fromDate}
          />
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
