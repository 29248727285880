import { Box, Checkbox } from "@material-ui/core";
import { get, isEmpty, isNil } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ConfirmFutureBookingCancellation from "../../components/Bookings/Action/ConfirmFutureBookingCancellation";
import FutureBookingOptions from "../../components/Bookings/Frequency/FutureBookingOptions";
import RecurringEndsOn from "../../components/Bookings/RecurringEndsOn";
import { CustomRecurrence } from "../../components/Bookings/V2/CustomRecurrence/CustomRecurrence";
import Button, { ButtonType } from "../../components/Button";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import { Display, Spacing } from "../../components/v2/Styled/enum";
import { BOOKING_STATUS, STEP_PROGRESS } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { MIN_HOUR_ALLOWED_TODAY, RECURRING_ENDS } from "../../constants/time";
import { parseApiError } from "../../helpers/error";
import { getCurrentMoment, getDate } from "../../helpers/time";
import { useUpcomingBookings } from "../../hooks/booking/booking.hooks";
import { useCountryCode } from "../../hooks/countryCode.hooks";
import { useMobile } from "../../hooks/mobile";
import { sendUpdateRequest } from "../../services/bookings/bookings.service";
import { useAlertStore } from "../../stores/alert";
import { updateBooking, useBookingStore, useDateTimeStore, usePaymentStore } from "../../stores/booking";
import {
  convertDayMomentToDate,
  getCurrentDate,
  getCurrentDayInTimezone,
  getNextBookingDateForRecurrence,
} from "../../utils/date.util";
import { checkIfEmpty, getValue } from "../../utils/object";
import { requestRecurringDataUpdate } from "../../services/bookings/recurring/recurringBooking.service";
import ConfirmRecurringCancellationModal from "../Bookings/Components/ConfirmRecurringCancellationModal";
import pixelsService from "../../services/pixels/pixels.service";
import { useUserStore } from "../../stores/user";

export default function BookingFrequency(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const { state } = useLocation();
  const next = get(state, "next");
  const isEditing = get(state, "editing");

  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { selectedDay } = useDateTimeStore();

  const {
    updatingForBooking,
    frequency,
    setFrequency,
    preferredTherapists,
    isSameProvider,
    recurring,
    setRecurring,
    setIsSameProvider,
    setRecurringEndOn,
    setRecurringType,
    setMonthData,
    setRecurringMonthDataForDayOfMonth,
    setRecurringMonthDataForWeekOfMonth,
    recuringEndOn,
    address,
    currency,
    resetRecurring,
  } = useBookingStore();

  const {
    bookingPrice,
  } = usePaymentStore();

  const { user } = useUserStore();

  const [initialState] = useState({
    frequency,
    isSameProvider,
    recurring,
    recuringEndOn,
  });

  const { invalidateUpcomingBookings } = useUpcomingBookings({});

  const addressTimezone = address?.timezone;

  const countryCode = useCountryCode();

  const [saving, setSaving] = useState(false);
  const [endType, setEndType] = useState(RECURRING_ENDS.NEVER);
  const [shouldConfirmFutureBookingCancellation, setShouldConfirmFutureBookingCancellation] =
    useState(false);

  const [showCancellationFeeWarningModal, setShowCancellationFeeWarningModal] = useState(false);
  const [cancellationFeeWarningMsg, setCancellationFeeWarningMsg] = useState("");
  const [hasAddToCartStarted, setHasAddToCartStarted] = useState(false);



  const defaultRecurringEndDate = getNextBookingDateForRecurrence({
    date: selectedDay,
    monthData: getValue(recurring, "monthly", null),
    frequency,
    type: recurring?.type,
  });

  const [recurringEndData, setRecurringEndData] = useState({
    occurrence: 1,
    endDate: recuringEndOn?.endsOn
      ? recuringEndOn?.endsOn
      : defaultRecurringEndDate?.format("YYYY-MM-DD") || getCurrentDate(defaultRecurringEndDate),
  });

  const [localRecurringEndsOn, setLocalRecurringEndsOn] = useState(recuringEndOn?.endsOn || null);
  useEffect(() => {
    const endDate = localRecurringEndsOn
      ? localRecurringEndsOn
      : defaultRecurringEndDate?.format("YYYY-MM-DD");
    setRecurringEndData({
      occurrence: recuringEndOn?.occurrence || 1,
      endDate: getCurrentDate(endDate),
    });
    setEndType(recuringEndOn?.type);
    setLocalRecurringEndsOn(null);
  }, [
    // recuringEndOn?.endsOn,
    recuringEndOn?.occurrence,
    recuringEndOn?.type,
    recurring?.monthly,
    frequency,
  ]);

  useEffect(() => {
    setLocalRecurringEndsOn(recuringEndOn?.endsOn);
    pixelsService.trackPageView();
  }, []);

  useEffect(() => {
    // trigger add to cart event
    // Track meta pixels

    const cartData = {
      currency: currency || "AUD",
      value: bookingPrice?.priceWithoutDiscount,
      subtotal: bookingPrice?.subtotal,
      userId: user?.id,
    };

    if(!isEditing && !isEmpty(bookingPrice) && !hasAddToCartStarted) {
      pixelsService.trackAddToCart(cartData);
      setHasAddToCartStarted(true);
    }
  }, [bookingPrice, hasAddToCartStarted]);


  const getCurrentHours = (timezone?: string) => getCurrentMoment(timezone).hours();

  const getMinValidDate = () => {
    const currentMoment = getCurrentMoment(addressTimezone);
    return getCurrentHours(addressTimezone) >= MIN_HOUR_ALLOWED_TODAY
      ? getDate(convertDayMomentToDate(currentMoment), 1)
      : convertDayMomentToDate(currentMoment);
  };

  const oneYearFromNow = getCurrentDayInTimezone(addressTimezone);
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const verifyFields = () => {
    if (isNil(frequency)) {
      setErrorMessage("Please select a booking frequency.");
      return false;
    }

    return true;
  };

  const handleBack = () => {
    const accessToken = localStorage.getItem("token");
    if (!isEmpty(accessToken)) {
      history.push(Paths.DateAndTime);
    } else {
      history.push(Paths.DateAndTime);
    }
  };

  const handleRecurringUpdate = async () => {
    if (!updatingForBooking || !updatingForBooking.id) return;
  
    const recurringData = { ...recurring, frequency };
    const updateRequestResponse = await requestRecurringDataUpdate({ bookingId: updatingForBooking?.id, data: { recurring: recurringData } });

    const totalCancellationFee = getValue(updateRequestResponse, "cancellationFee") || 0;
    if (totalCancellationFee > 0) {
      setShowCancellationFeeWarningModal(true);
      setCancellationFeeWarningMsg(updateRequestResponse.warningMessage);
      return;
    }

    updatePendingBooking();
  };

  const handleNext = () => {
    if (!verifyFields()) {
      return;
    }

    if (updatingForBooking && !checkIfEmpty(updatingForBooking)) {
      const { status: bookingStatus } = updatingForBooking;
      const inactiveBookingStatus = [BOOKING_STATUS.COMPLETED, BOOKING_STATUS.CANCELLED];
      const isCancellingFutureBookings =
        updatingForBooking.recurring?.shouldCreateFutureBookings === true &&
        recurring?.shouldCreateFutureBookings === false;

      if (inactiveBookingStatus.includes(bookingStatus as BOOKING_STATUS)) {
        updateInactiveBooking();
      } else if (isCancellingFutureBookings) {
        setShouldConfirmFutureBookingCancellation(true);
      } else if (!checkIfEmpty(recurring) ) {
        handleRecurringUpdate();
      } else {
        updatePendingBooking();
      }
    } else {
      history.push(next || Paths.PreferredProviders, { preFetchPaymentMethod: false, isEditing });
    }
  };

  const updatePendingBooking = () => {
    setSaving(true);
    updateBooking()
      .then(() => {
        invalidateUpcomingBookings();
        history.goBack();
        // history.push(`/bookings/${updatingForBooking?.id}`);
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      })
      .finally(() => setSaving(false));
  };

  // incase for completed / cancelled bookings
  const updateInactiveBooking = async () => {
    setSaving(true);
    try {
      if (!updatingForBooking || checkIfEmpty(updatingForBooking)) return;

      const recurringData = { frequency, recuringEndOn, recurring, isSameProvider };
      const bookingId = updatingForBooking.id.toString();

      const { status } = await sendUpdateRequest(bookingId, recurringData);
      if (status !== "OK") throw Error("Unable to update booking.");

      setSuccessMessage("Your booking has been updated successfully.");
      // history.push(`/bookings/${updatingForBooking.id}`);
      history.goBack();
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    } finally {
      setSaving(false);
    }
  };

  const onCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSameProvider(event.target.checked);
    if(event.target.checked && recurring.shouldCreateFutureBookings){
      setRecurring({
        ...recurring,
        shouldCreateFutureBookings:false,
      });
    }
    
  };

  const saveRecurringEndTypeData = (data: any) => {
    setRecurringEndOn(data);
  };

  const setIsRepeating = (isRepeating: boolean) => {
    if (isRepeating) {
      !noPreferredTherapists && setIsSameProvider(true);
      setRecurring({
        ...recurring,
        isRepeating,
      });
    } else {
      resetRecurring();
    }
  };

  const updateShouldCreateFutureBookings = (shouldCreateFutureBookings: boolean) => {
    setRecurring({
      ...recurring,
      shouldCreateFutureBookings,
    });
  };

  const confirmFutureBookingCancellation = () => {
    setShouldConfirmFutureBookingCancellation(false);
    updatePendingBooking();
  };

  const resetToInitialState = () => {
    setFrequency(initialState.frequency);
    setRecurring(initialState.recurring);
    setIsSameProvider(initialState.isSameProvider);
    setRecurringEndOn(initialState.recuringEndOn);
  };

  const confirmRecurringFrequencyCancellation = () => {
    setShowCancellationFeeWarningModal(false);
    updatePendingBooking();
  };

  const  noPreferredTherapists=  (checkIfEmpty(preferredTherapists) &&
        checkIfEmpty(updatingForBooking?.preferredTherapists));

  const futureRadioDisabled = isSameProvider && noPreferredTherapists;

  return (
    <NewBookingWrapper
      overline="Step 5 of 7"
      title={"Booking frequency"}
      subtitle={
        "Select how often you would like to have this service. Recurring bookings will be created automatically until/unless you cancel."
      }
      footerLeftButton={
        <Button
          type={ButtonType.outlined}
          title={updatingForBooking ? "Back" : "Previous"}
          onClick={() => handleBack()}
          style={{ padding: "12px 24px" }}
        />
      }
      footerRightButton={
        <Button
          loading={saving}
          type={updatingForBooking ? ButtonType.secondary : ButtonType.indigo}
          title={updatingForBooking ? "Save & apply" : "Continue"}
          onClick={() => handleNext()}
          style={{ padding: "12px 24px" }}
        />
      }
      progress={STEP_PROGRESS[5]}
      onClosed={resetToInitialState}
    >
      <Box mt={Spacing.S8}>
        <CustomRecurrence
          selectedDate={selectedDay}
          onFrequencyChange={setFrequency}
          frequency={frequency}
          recurring={recurring}
          onRecurringTypeChange={setRecurringType}
          onRecurringMonthDataChange={setMonthData}
          onDayOfMonthChange={setRecurringMonthDataForDayOfMonth}
          onWeekOfMonthChange={setRecurringMonthDataForWeekOfMonth}
          timezone={addressTimezone}
          updateRepeating={setIsRepeating}
        />
      </Box>

      { 
        recurring.isRepeating && (
          <Box
            fontFamily="Museo"
            fontSize="16px"
            color={Colors.Dusk}
            fontWeight={400}
            lineHeight="24px"
            mt={Spacing.S8}
            display={Display.Flex}
            gridGap={Spacing.S4}
          >
            <Checkbox
              style={{
                color: Colors.TurquoiseBlue,
                margin: 0,
                padding: 0,
                borderRadius: 30,
              }}
              className="booking-frequency-checkbox"
              checked={isSameProvider}
              onChange={(event: any) => {
                onCheckBoxChange(event);
              }}
            />{" "}
            <Box className="cursor-pointer" onClick={() => setIsSameProvider(!isSameProvider)}>
              Book the same provider for my future repeat bookings.
            </Box>
          </Box>
        )}

      {/* ends on option */}
      {recurring.isRepeating && (
        <Box mt={Spacing.S8}>
          <RecurringEndsOn
            endType={endType}
            setEndType={setEndType}
            recurringEndData={recurringEndData}
            setRecurringEndData={setRecurringEndData}
            saveRecurringEndTypeData={saveRecurringEndTypeData}
            isMobile={isMobile}
            getMinValidDate={getMinValidDate}
            oneYearFromNow={oneYearFromNow}
            countryCode={countryCode}
          />
        </Box>
      )}

      {recurring.isRepeating ? (
        <Box mt={Spacing.S8}>
          <FutureBookingOptions
            disabled={futureRadioDisabled}
            shouldCreateFutureBookings={recurring.shouldCreateFutureBookings}
            onChange={updateShouldCreateFutureBookings}
          />
        </Box>
      ) : null}
      
      {updatingForBooking ? (
        <>
          <ConfirmFutureBookingCancellation
            open={shouldConfirmFutureBookingCancellation}
            onClose={() => setShouldConfirmFutureBookingCancellation(false)}
            onConfirm={confirmFutureBookingCancellation}
            bookingId={updatingForBooking.id}
          />
          <ConfirmRecurringCancellationModal
            onClose={() => setShowCancellationFeeWarningModal(false)}
            open={showCancellationFeeWarningModal}
            handleUpdate={confirmRecurringFrequencyCancellation}
            description={cancellationFeeWarningMsg}
          />
        </>
      ) : null}
    </NewBookingWrapper>
  );
}
