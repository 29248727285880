import React, { useEffect, useRef, useState } from "react";
import TextField from "./TextField";
import { Box } from "@material-ui/core";
import { DropdownOption, StyledButtonBase, dropdownStylesCombined } from "./Dropdown";
import { FontFamily, FontSize, Spacing } from "./v2/Styled/enum";
import { Colors } from "../constants/colors";
import CloseIcon from "../images/cross-small.svg";

interface Props {
  title: string;
  placeholder: string;
  onOptionClick: (selectedOption: DropdownOption) => unknown;
  options: Array<DropdownOption>;
  value: any;
  onValueChange: (value: any) => unknown;
  dropDownOptionIcon?: any;
  errorMessage?: string;
  showCloseIcon?: boolean;
  borderWidth?: string;
  titleFontSize?: string;
}
export const AutoCompleteField = ({
  title,
  placeholder,
  onValueChange,
  value,
  options,
  onOptionClick,
  borderWidth,
  dropDownOptionIcon = null,
  errorMessage,
  showCloseIcon = false,
  titleFontSize = "14px",
}: Props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <TextField
        style={{ padding: 0 }}
        clear
        title={title}
        placeholder={placeholder}
        onChange={onValueChange}
        onFocus={() => {
          setShowDropdown(true);
        }}
        paddingBottom="0px"
        paddingTop="0px"
        value={value}
        titleStyle={{ fontSize: "18px" }}
        closeIcon={showCloseIcon ? CloseIcon : null}
        borderWidth={borderWidth}
        titleFontSize={titleFontSize}
      />

      {showDropdown ? (
        <Box
          position="absolute"
          style={{ ...dropdownStylesCombined, maxHeight: "268px" }}
          className="dropdown"
          mt={Spacing.S4}
        >
          {(options || []).map((option, index) => {
            return (
              <Box key={`option-${index}`}>
                <Box display="flex" flexDirection="row">
                  <StyledButtonBase
                    onClick={() => {
                      onOptionClick(option);
                      setShowDropdown(false);
                    }}
                  >
                    <Box
                      style={{ gap: Spacing.S3 }}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"row"}
                      padding={"0px 16px"}
                    >
                      {dropDownOptionIcon ? (
                        <img
                          src={dropDownOptionIcon}
                          alt="recipient-icon"
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      <Box
                        fontSize={FontSize.F16}
                        fontFamily={FontFamily.Museo}
                        color={Colors.Dusk}
                      >
                        {option.title}
                      </Box>
                    </Box>
                  </StyledButtonBase>
                </Box>
                {index !== options.length && (
                  <Box width="100%" height="1px" bgcolor={Colors.LightPeriwinkle} />
                )}
              </Box>
            );
          })}
        </Box>
      ) : (
        <></>
      )}
    </div>
  );
};
