import React, { useState } from "react";

import Each from "../../../components/Each";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { ProviderNote, Recipient } from "../../../models";
import { Box, Text } from "../../../components/v2/Styled";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { FilterButton } from "../../../components/FilterButton/FilterButton";
import NoNotesPlaceholder, { NoNotesPlaceholderType } from "./NoNotesPlaceholder";
import { useGetRecipientProNotes } from "../../../hooks/recipient/recipient.hooks";
import ProviderTreatmentNote from "../../../components/Cards/TreatmentNoteCard/ProviderTreatmentNoteCard";
import { FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../../../components/v2/Styled/enum";
import { FilterNoteValue, NoteFilterModal } from "./modals/SortAndFilterNoteModal";

interface Props {
  recipient: Recipient;
}

const RecipientProNotes = ({ recipient }: Props) => {
  const isMobile = useMobile();

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<FilterNoteValue>({});

  const { data, isLoading } = useGetRecipientProNotes(recipient.id, { search: searchQuery });
  const providerNotes = getValue(data, "data.notes", []);

  const buildProviderNotes = (providerNote: ProviderNote) => {
    return (
      <ProviderTreatmentNote
        providerNote={providerNote}
        fullwidth
        showDivider
      />
    );
  };

  const getFilterCount = () => {
    let keys = Object.keys(searchQuery);
    if (searchQuery.sortBy === "asc") {
      return keys.length;
    } else {
      return keys.filter((val) => val !== "sortBy").length;
    }
  };

  return (
    <Box gap={Spacing.S6} marginY={Spacing.S11} direction={FlexDirection.Column}>
      <Text
        font={FontFamily.Museo}
        weight={FontWeight.Bold}
        size={FontSize.F24}
        color={Colors.NightBlue}
      >
        Provider notes
      </Text>
      <Text
        font={FontFamily.Museo}
        weight={FontWeight.Medium}
        size={FontSize.F16}
        color={Colors.Dusk}
      >
        Notes added by the providers on the recipient’s bookings. These notes will be shared with the other providers for potential future bookings.
      </Text>

      <Box gap={isMobile ? Spacing.S2 : Spacing.S4} justifyContent={"flex-end"}>
        <div style={{ gap: 0, width: "160px" }}>
          <FilterButton
            title={isMobile ? "" : "Filter and sort"}
            onClick={() => setShowFilterModal(true)}
            filterCount={getFilterCount()}
          />
        </div>
      </Box>

      {!checkIfEmpty(providerNotes) && !isLoading ? (<Box direction={FlexDirection.Column}>
        <Each of={providerNotes} render={buildProviderNotes} />
      </Box>) : (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          height={isMobile ? "400px" : "75%"}
          marginY={isMobile ? Spacing.S0 : Spacing.S20}
        >
          <div
            style={{
              marginTop: isMobile ? 0 : "8vh",
            }}
          >
            <NoNotesPlaceholder type={NoNotesPlaceholderType.providernote}>

            </NoNotesPlaceholder>
          </div></Box>
      )}

      <NoteFilterModal
        open={showFilterModal}
        handleFilterApplied={setSearchQuery}
        onClose={() => setShowFilterModal(false)}
      />
    </Box >
  );
};

export default RecipientProNotes;
