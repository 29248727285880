import { Box } from "../v2/Styled";
import { Colors } from "../../constants/colors";
import HeroImage from "../../images/no-bookings-placeholder.svg";
import { AlignItems, Display, FlexDirection, FontFamily, FontSize, FontWeight, JustifyContent, Spacing, TextAlign } from "../v2/Styled/enum";

interface Props {
  title: string;
  subtitle: any;
  imageSource?: string;
}

export const EmptyDataPlaceHolder = ({ imageSource = HeroImage, title, subtitle }: Props) => {
  return (
    <Box width="100%" height="410px" justifyContent={JustifyContent.center} alignItems={AlignItems.center}>
      <Box display={Display.Flex} direction={FlexDirection.Column} alignItems={AlignItems.center} width="100%" >
        <img src={imageSource} alt="" height={"56px"} width={"78px"} />
        <Box
          fontFamily={FontFamily.Museo}
          fontSize={FontSize.F16}
          textAlign={TextAlign.center}
          fontWeight={FontWeight.Bold}
          color={Colors.Dusk}
          marginY={Spacing.S2}
        >
          {title}
        </Box>

        <Box >
          {subtitle}
        </Box>
      </Box>
    </Box>
  );
};