import React from "react";
import { Box } from "../v2/Styled";
import Button, { ButtonType } from "../Button";
import { Colors } from "../../constants/colors";
import { AlignItems, Display, FlexDirection, JustifyContent, Spacing } from "../v2/Styled/enum";
import ContentModal from "../Modals/ContentModal/contentModal";
import SuccessIcon from "../../images/success-icon.png";

interface Props {
  isMobile: boolean;
  visible: boolean;
  onClose: () => void;
}

const PayWithInvoiceSuccessModal: React.FC<Props> = ({ isMobile, visible, onClose }) => {
  return (
    <div>
      <ContentModal
        visible={visible}
        onClose={onClose}
        actions={[
          <Button
            style={{ marginBottom: 15 }}
            type={ButtonType.outlined}
            title="Sweet, thanks"
            onClick={onClose}
          />,
        ]}
        divider={false}
        maxWidth="xs"
        PaperProps={{
          style: {
            overflowY: "scroll",
            minWidth: !isMobile ? "438px" : undefined,
          },
        }}
        titleStyle={{ height: "10px" }}
        fullWidth={isMobile}
        childrenStyle={{ marginTop: 0 }}
      >
        <Box marginY={Spacing.S1} paddingY={Spacing.S6} bgcolor="#ffffff" borderRadius="11px">
          <Box
            width={"100%"}
            display={Display.Flex}
            direction={FlexDirection.Column}
            alignItems={AlignItems.center}
            gap={Spacing.S1}
            justifyContent={JustifyContent.center}
          >
            <Box marginY={Spacing.S3}>
              <img src={SuccessIcon} alt="Success" width={"64px"} height={"64px"} />
            </Box>

            <Box color={Colors.Indigo} fontFamily={"Museo"} fontWeight={600} fontSize={"22px"}>
              Invoice sent
            </Box>
            <br style={{ height: "10px" }} />
            <Box
              display={Display.Block}
              fontFamily="Open Sans"
              fontWeight={400}
              fontSize="16px"
              color={Colors.Grey}
              textAlign="center"
              width="100%"
              // marginY={Spacing.S5}
            >
              Please check the invoice in your email and
            </Box>
            <Box
              display={Display.Block}
              fontFamily="Open Sans"
              fontWeight={400}
              fontSize="16px"
              color={Colors.Grey}
              textAlign="center"
              width="100%"
              // marginY={Spacing.S5}
            >
              settle the pending payment.
            </Box>
          </Box>
        </Box>
      </ContentModal>
    </div>
  );
};

export default PayWithInvoiceSuccessModal;
