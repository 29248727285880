import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

import {
  Spacing,
  Display,
  FlexDirection,
  JustifyContent
} from "../../../../components/v2/Styled/enum";
import Each from "../../../../components/Each";
import TreatmentNoteCard from "./TreatmentNoteCard";
import { useAlertStore } from "../../../../stores/alert";
import { QUERY_KEYS } from "../../../../constants/queryKey";
import { invalidateQueries } from "../../../../services/query";
import TextButton from "../../../../components/TextButton/TextButton";
import { checkIfEmpty, checkIfEqual, getValue } from "../../../../utils/object";
import SectionHeader from "../../../../components/Headers/SectionHeader/SectionHeader";
import DeleteConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import { removeTreatmentNote } from "../../../../services/users/treatmentNote.service";
import AddTreatmentNoteModal from "../../Modals/TreatmentNoteModal/AddTreatmentNoteModal";
import TreatmentNoteDetailModal from "../../Modals/TreatmentNoteModal/TreatmentNoteDetailModal";
import TreatmentNoteListModal from "../../Modals/TreatmentNoteModal/TreatmentNoteListModal/TreatmentNoteListModal";

interface Props {
  job: any,
  booking: any,
  refreshJob: () => unknown;
}

const TreatmentNotes = ({ job, booking, refreshJob }: Props) => {
  const { userId } = booking || {};

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const [redirectToDetail, setRedirectToDetail] = useState(false);
  const [redirectToList, setRedirectToList] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showNoteDetail, setShowNoteDetail] = useState(false);
  const [showAllNotesModal, setShowAllNotesModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // update selected note for detail modal, if note has been
  useEffect(() => {
    if (!selectedNote) return;

    const userNotes = getLatestTreatmentNotes();

    //check if the selectedNote is in the freshly updated set of notes

    for (const userNote of userNotes) {
      if (checkIfEqual(userNote, selectedNote)) return;
      setSelectedNote(userNote);
    }

  }, [job]);

  const toggleNoteModal = (show: boolean) => {
    setShowNoteModal(show);

    if (redirectToDetail) {
      setShowNoteDetail(true);
      return;
    }

    if (redirectToList) {
      setTimeout(() => setShowAllNotesModal(true), 150);
      setRedirectToList(false);
    }

    if (!show) setSelectedNote(null);
  };

  const toggleAllNotesModal = (show: boolean) => setShowAllNotesModal(show);

  const toggleDeleteConfirmation = (show: boolean) => {
    setShowDeleteConfirmation(show);
    if (!show) {
      if (redirectToDetail) {
        setShowNoteDetail(true);
        setRedirectToDetail(false);
        return;
      }
      if (redirectToList) setShowAllNotesModal(true);
      setSelectedNote(null);
      setRedirectToList(false);
    }
  };

  const getLatestTreatmentNotes = () => {
    const treatmentNotes = getValue(job, "treatmentNotes", []);
    return treatmentNotes;
  };

  const refreshNotes = () => {
    refreshJob();
    invalidateQueries([QUERY_KEYS.USER_TREATMENT_NOTES]);
  };

  const onAddNote = () => {
    setRedirectToList(true);
    setShowAllNotesModal(false);
    setShowNoteModal(true);
  };

  const onEditNote = (note: any) => {
    if (showNoteDetail) setShowNoteDetail(false);
    setSelectedNote(note);
    setShowNoteModal(true);
  };

  const onEditNoteFromList = (note: any) => {
    setRedirectToList(true);
    setShowAllNotesModal(false);
    setSelectedNote(note);
    setShowNoteModal(true);
  };

  const onDeleteNoteFromList = (note: any) => {
    setRedirectToList(true);
    setShowAllNotesModal(false);
    setSelectedNote(note);
    setShowDeleteConfirmation(true);
  };

  const onDeleteNote = (note: any) => {
    setSelectedNote(note);

    if (showNoteDetail) setShowNoteDetail(false);
    setShowDeleteConfirmation(true);
  };

  const onDeleteConfirmed = async () => {
    try {
      if (!selectedNote) {
        setErrorMessage("Unable to delete note. Please try again.");
        return;
      }

      const noteId = getValue(selectedNote, "id");
      const response = await removeTreatmentNote(noteId);
      const message = getValue(response, "data.message");
      setSuccessMessage(message);

      refreshNotes();
      setSelectedNote(null);
      setShowDeleteConfirmation(false);

      if (redirectToDetail) setRedirectToDetail(false);

      if (redirectToList) {
        setRedirectToList(false);
        setShowAllNotesModal(true);
      }
    } catch (err) {
      setErrorMessage("Unable to remove note. Please try again.");
    }
  };

  const onShowFullNote = (note: any) => {
    setSelectedNote(note);
    setShowNoteDetail(true);
    setRedirectToDetail(true);
  };
  
  const closeNoteDetail = () => {
    setSelectedNote(null);
    setShowNoteDetail(false);
    setRedirectToDetail(false);
  };

  const userNotes = getLatestTreatmentNotes();

  const buildTreatmentNotes =(userNote: object) =>{ 
    return(<TreatmentNoteCard
      pro={getValue(userNote,"pro")}
      note={userNote}
      timezone={booking.timezone}
      onClick={() => onShowFullNote(userNote)}
      onDelete={() => onDeleteNote(userNote)}
      onEdit={() => onEditNote(userNote)}
      showEditDeleteButtons={checkIfEqual(job.user.id,getValue(userNote,"pro.id"))}
    />);
  };

  return <Box>
    <Box>
      <Box
        gridGap={Spacing.S2}
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
      >
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          justifyContent={JustifyContent.spaceBetween}
        >
          <SectionHeader title="Client treatment notes"/>
          <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3}>
            <TextButton
              type="primary"
              text="Add note"
              textStyle={{ padding: 0 }}
              onClick={() => toggleNoteModal(true)}
            />
            <TextButton
              type="primary"
              text="View all notes"
              textStyle={{ padding: 0 }}
              onClick={() => toggleAllNotesModal(true)}
            />
          </Box>
        </Box>
        
        {!checkIfEmpty(userNotes) && (
          <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S3}>
            <Each of={userNotes} render={buildTreatmentNotes}/>
          </Box>
        )}
      </Box>
    </Box>
    <TreatmentNoteDetailModal
      visible={showNoteDetail}
      note={selectedNote}
      onClose={closeNoteDetail}
      timezone={booking.timezone}
      onDelete={onDeleteNote}
      onEdit={onEditNote}
      showEditDeleteButtons={checkIfEqual(job.user.id,getValue(selectedNote,"pro.id"))}
    />
    <AddTreatmentNoteModal
      jobId={job.id}
      title={selectedNote ? "Edit client treatment notes" : "Add client treatment notes"}
      note={selectedNote}
      visible={showNoteModal}
      refreshNotes={refreshNotes}
      onClose={() => toggleNoteModal(false)}
    />
    <TreatmentNoteListModal
      clientId={userId}
      jobId={job.id}
      timezone={booking.timezone}
      visible={showAllNotesModal}
      refreshJob={refreshJob}
      onAddNote={onAddNote}
      onEditNote={onEditNoteFromList}
      onDeleteNote={onDeleteNoteFromList}
      onClose={() => toggleAllNotesModal(false)}
    />
    <DeleteConfirmationModal
      title="Delete note"
      description="Please confirm that you want to delete this note."
      visible={showDeleteConfirmation}
      onConfirm={onDeleteConfirmed}
      onClose={() => toggleDeleteConfirmation(false)}
    />
  </Box>;
};

export default TreatmentNotes;
