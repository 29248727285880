import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";

import { Box } from "../../components/v2/Styled";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../components/v2/Styled/enum";
import { Colors } from "../../constants/colors";
import { FilterButton } from "../../components/FilterButton/FilterButton";
import { useMobile } from "../../hooks/mobile";
import BillingInvoiceFilterModal from "../../components/Modals/FilterModal/BillingInvoiceFilterModal";
import BillingInvoiceTable from "../../components/Payment/BillingInvoiceTable";
import TotalDueInvoice from "../../components/Payment/TotalDueInvoice";
import CustomBillingCheckBox from "../../components/Payment/CustomBillingCheckBox";
import PaginationControl from "../../components/PaginationControl";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import { useHistory } from "react-router-dom";
import {
  BILLINGS_INVOICE_KEYS,
  useGetAllBillingInvoices,
  useGetAllDueBillingInvoiceSummary,
} from "../../hooks/billing.hooks";
import { useUserStore } from "../../stores/user";
import { PaymentType } from "../../constants/payment";
import { scrollToTop } from "../../helpers/scrollToTop";
import { checkIfEmpty, getValue } from "../../utils/object";
import { BillingInvoicePaymentStatus } from "../../constants/booking";
import PayWithInvoiceConfirmationModal from "../../components/Payment/PayWithInvoiceConfirmationModal";
import PaymentModal from "../../components/PaymentFrame/PaymentModal";
import PayWithInvoiceSuccessModal from "../../components/Payment/PayWithInvoiceSuccessModal";
import { useBulkPaymentWithCard, useBulkPaymentWithInvoice } from "../../hooks/payment.hooks";
import { useAlertStore } from "../../stores/alert";
import { parseApiError } from "../../helpers/error";
import { usePaymentStore } from "../../stores/booking";
import queryClient from "../../config/queryClient";
import BillingInvoiceEmptyPlaceholder from "../../components/Payment/BillingInvoiceEmptyPlaceholder";
export interface BillingInvoiceCheckBoxChange {
  checked: boolean;
  bookingId: number;
}
interface SearchQuery {
  paymentStatus: string[];
  providerId: string | number | undefined | null;
  dueDate: string;
  amountToPay: string;
  recipientId?: number;
}

const Billing = ({ recipientId, isRecipient }: { recipientId?: number; isRecipient?: boolean }) => {
  const isMobile = useMobile();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<SearchQuery>({
    paymentStatus: [],
    providerId: "",
    dueDate: "",
    amountToPay: "",
    recipientId,
  });
  const [showBillingInvoiceFilterModal, setShowBillingInvoiceFilterModal] = useState(false);
  const [isAllDueInvoiceSelected, setIsAllDueInvoiceSelected] = useState(false);
  const [isCurrentPageAllDueInvoiceSelected, setIsCurrentPageAllDueInvoiceSelected] =
    useState(false);
  const [selectedBillingInvoices, setSelectedBillingInvoices] = useState<number[]>([]);
  const [payWithInvoice, setPayWithInvoice] = useState(false);
  const [payWithCard, setPayWithCard] = useState(false);
  const [showPayWithInvoiceSuccessModal, setShowPayWithInvoiceSuccessModal] = useState(false);
  const [shouldClearFilter, setShouldClearFilter] = useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { paymentMethod } = usePaymentStore();
  const { user } = useUserStore();

  const { data: billingInvoiceSummary, isLoading: isBillingSummaryFetching } =
    useGetAllDueBillingInvoiceSummary(recipientId);
  const { mutateAsync: bulkPayWithInvoice, isLoading: payWithInvoiceLoading } =
    useBulkPaymentWithInvoice();

  const { mutateAsync: bulkPayWithCard } = useBulkPaymentWithCard();

  const { data, isLoading: isBillingInvoiceFetching } = useGetAllBillingInvoices({
    currentPage,
    perPage: 10,
    search,
  });

  const onBillingInvoiceCheckBoxChange = ({ checked, bookingId }: BillingInvoiceCheckBoxChange) => {
    if (checked) {
      setSelectedBillingInvoices((prev) => [...prev, bookingId]);
    } else {
      const billingInvoices = [...selectedBillingInvoices].filter((data) => data !== bookingId);
      if (isAllDueInvoiceSelected) {
        setSelectedBillingInvoices([]);
      } else {
        setSelectedBillingInvoices(billingInvoices);
      }
      setIsCurrentPageAllDueInvoiceSelected(false);
    }
  };

  const onViewBookingClick = (id: number) => {
    history.push(`/bookings/${id}`);
  };

  const onPageChange = (inc = 1) => {
    setCurrentPage((prev: number) => prev + inc);
    if (!isAllDueInvoiceSelected) {
      setIsCurrentPageAllDueInvoiceSelected(false);
      setSelectedBillingInvoices([]);
    }
    scrollToTop();
  };

  const onCurrentPageAllDueInvoiceSelected = (checked: boolean) => {
    const billingInvoices = getValue(data, "billingInvoices", []);
    if (checked && !checkIfEmpty(billingInvoices)) {
      const allDueInvoices = billingInvoices
        .filter((data: any) => data.paymentStatus !== BillingInvoicePaymentStatus.full)
        .map((data: any) => data.id);
      setSelectedBillingInvoices(allDueInvoices);
      setIsCurrentPageAllDueInvoiceSelected(true);
    } else {
      setSelectedBillingInvoices([]);
      setIsCurrentPageAllDueInvoiceSelected(false);
      setIsAllDueInvoiceSelected(false);
    }
  };

  const onFilterApply = ({ providerId, paymentStatus, dueDate, amountToPay }: SearchQuery) => {
    setCurrentPage(1);
    setSelectedBillingInvoices([]);
    setIsCurrentPageAllDueInvoiceSelected(false);
    setIsAllDueInvoiceSelected(false);

    setSearch({
      providerId,
      paymentStatus,
      dueDate,
      amountToPay,
      recipientId,
    });
  };

  const onAllDueInvoiceSelected = () => {
    const allDueInvoices = getValue(billingInvoiceSummary, "invoices", []);
    setSelectedBillingInvoices(allDueInvoices);
    setIsAllDueInvoiceSelected(true);
  };

  const onAllDueInvoiceRemoved = () => {
    setSelectedBillingInvoices([]);
    setIsAllDueInvoiceSelected(false);
    setIsCurrentPageAllDueInvoiceSelected(false);
  };

  const handleBulkPaymentWithInvoice = async () => {
    try {
      await bulkPayWithInvoice({
        invoices: selectedBillingInvoices,
      });
      setPayWithInvoice(false);
      setShowPayWithInvoiceSuccessModal(true);
      onAllDueInvoiceRemoved();
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  const validateCardPayment = () => {
    const userIdInPaymentMethod = getValue(paymentMethod, "userId");
    const loggedInUserId = getValue(user, "id");
    const paymentMethodId = getValue(paymentMethod, "id");
    const paymentMethodType = getValue(paymentMethod, "type");

    if (userIdInPaymentMethod !== loggedInUserId || !paymentMethodId) {
      setErrorMessage("Unable to process payment for bookings using the selected payment method.");
      return false;
    }

    if (paymentMethodType !== PaymentType.card) {
      setErrorMessage("Please select a card.");
      return false;
    }

    return true;
  };

  const handleBulkPaymentWithCard = async () => {
    try {
      const isValid = validateCardPayment();
      if (!isValid) {
        return;
      }

      const paymentMethodId = getValue(paymentMethod, "id");
      await bulkPayWithCard({
        paymentMethodId,
        invoices: selectedBillingInvoices,
      });
      queryClient.invalidateQueries({
        queryKey: [BILLINGS_INVOICE_KEYS.billingSummary],
      });
      queryClient.invalidateQueries({
        queryKey: [BILLINGS_INVOICE_KEYS.dueBillingSummary],
      });
      setPayWithCard(false);
      onAllDueInvoiceRemoved();
      setSuccessMessage("Payment settled for bookings.");
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  const shouldDisableAllSelect = getValue(data, "billingInvoices", []).every(
    (invoice: any) => invoice.paymentStatus === BillingInvoicePaymentStatus.full
  );

  const getFilterCount = () => {
    let count = 0;
    if (search.paymentStatus) {
      count += search.paymentStatus.length;
    }
    if (search.providerId) {
      count += 1;
    }
    if (search.amountToPay) {
      count += 1;
    }
    if (search.dueDate && search.dueDate !== "desc") {
      count += 1;
    }
    return count;
  };

  const clearAllFilter = () => {
    setShouldClearFilter(true);
    setSearch({
      paymentStatus: [],
      providerId: "",
      amountToPay: "",
      dueDate: "",
      recipientId,
    });
  };

  return (
    <Box
      // maxWidth={isMobile ? undefined : "936px"}
      display={Display.Flex}
      direction={FlexDirection.Column}
    >
      <Box display={Display.Flex} direction={FlexDirection.Column} width={"100%"}>
        <Box
          display={Display.Flex}
          alignItems={AlignItems.center}
          justifyContent={JustifyContent.spaceBetween}
          width={"100%"}
        >
          <Box
            fontWeight={FontWeight.Bold}
            fontFamily={FontFamily.Museo}
            fontSize={FontSize.F24}
            color={Colors.NightBlue}
          >
            Billing
          </Box>

          <FilterButton
            onClick={() => {
              setShouldClearFilter(false);
              setShowBillingInvoiceFilterModal(true);
            }}
            filterCount={getFilterCount()}
            title="Filter and sort"
          />
        </Box>
      </Box>

      {/* Total invoice due section */}
      {isBillingSummaryFetching ? (
        <Box marginY={Spacing.S10}>
          <CircularProgress />
        </Box>
      ) : (
        <TotalDueInvoice
          totalPendingCount={getValue(billingInvoiceSummary, "pending.count")}
          totalPendingAmount={getValue(billingInvoiceSummary, "pending.amount")}
          totalOverdueCount={getValue(billingInvoiceSummary, "due.count")}
          totalOverdueAmount={getValue(billingInvoiceSummary, "due.amount")}
          totalPartiallyPaidCount={getValue(billingInvoiceSummary, "partial.count")}
          totalPartiallyPaidAmount={getValue(billingInvoiceSummary, "partial.amount")}
          currency={getValue(billingInvoiceSummary, "currency", "AUD")}
          totalDueAmount={getValue(billingInvoiceSummary, "totalDueAmount")}
        />
      )}

      <Box display={Display.Flex} alignItems={AlignItems.center} gap={Spacing.S2}>
        <CustomBillingCheckBox
          onCheckBoxChange={(checked) => {
            onCurrentPageAllDueInvoiceSelected(checked);
          }}
          isChecked={isCurrentPageAllDueInvoiceSelected}
          isDisabled={shouldDisableAllSelect}
        />
        <Box
          fontSize={FontSize.F16}
          fontFamily={FontFamily.Museo}
          fontWeight={FontWeight.Medium}
          color={Colors.Dusk}
        >
          Select all due invoices
        </Box>
      </Box>

      <>
        {isBillingInvoiceFetching ? (
          <Box marginY={Spacing.S10}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!checkIfEmpty(getValue(data, "billingInvoices", [])) ? (
              <>
                <Box marginY={Spacing.S10} direction={FlexDirection.Column}>
                  <BillingInvoiceTable
                    onCheckBoxChange={onBillingInvoiceCheckBoxChange}
                    onViewBookingClick={onViewBookingClick}
                    billingInvoices={getValue(data, "billingInvoices", [])}
                    selectedBillingInvoices={selectedBillingInvoices}
                    totalDueCount={getValue(billingInvoiceSummary, "totalDueCount", 0)}
                    isCurrentPageAllDueInvoiceSelected={isCurrentPageAllDueInvoiceSelected}
                    onAllDueInvoiceSelected={onAllDueInvoiceSelected}
                    onAllDueInvoiceRemoved={onAllDueInvoiceRemoved}
                    isAllDueInvoiceSelected={isAllDueInvoiceSelected}
                  />
                </Box>

                <Box justifyContent={JustifyContent.end}>
                  <PaginationControl
                    pages={getValue(data, "totalPages", 1)}
                    currentPage={getValue(data, "currentPage", 1)}
                    onPrevious={() => onPageChange(-1)}
                    onNext={onPageChange}
                    startFromOne
                  />
                </Box>
              </>
            ) : (
              <BillingInvoiceEmptyPlaceholder
                clearAllFilter={clearAllFilter}
                isFilterApplied={getFilterCount() > 0}
                isRecipient={isRecipient}
              />
            )}

            <Box gap={Spacing.S5} marginY={Spacing.S10}>
              <Button
                title="Pay with invoice"
                type={ButtonType.outlined}
                size={ButtonSize.large}
                onClick={() => {
                  setPayWithInvoice(true);
                }}
                disabled={selectedBillingInvoices.length < 1}
              />
              <Button
                title="Pay online with card"
                type={ButtonType.secondary}
                size={ButtonSize.large}
                onClick={() => {
                  setPayWithCard(true);
                }}
                disabled={selectedBillingInvoices.length < 1}
              />
            </Box>
          </>
        )}
      </>

      <BillingInvoiceFilterModal
        open={showBillingInvoiceFilterModal}
        onClose={() => {
          setShowBillingInvoiceFilterModal(false);
        }}
        onFilterBooking={onFilterApply}
        shouldClearFilter={shouldClearFilter}
      />

      <PayWithInvoiceConfirmationModal
        visible={payWithInvoice}
        isPaying={payWithInvoiceLoading}
        onClose={() => {
          setPayWithInvoice(false);
        }}
        onConfirm={handleBulkPaymentWithInvoice}
        isMobile={isMobile}
      />

      <PaymentModal
        open={payWithCard}
        onClose={() => {
          setPayWithCard(false);
        }}
        title="Pay online with card"
        booking={{}}
        showInvoiceLink={false}
        handleBulkPayment={handleBulkPaymentWithCard}
        isBulkPayment={true}
        onlyCard
      />

      <PayWithInvoiceSuccessModal
        visible={showPayWithInvoiceSuccessModal}
        onClose={() => {
          setShowPayWithInvoiceSuccessModal(false);
        }}
        isMobile={isMobile}
      />
    </Box>
  );
};

export default Billing;
