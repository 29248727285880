import React from "react";
import { Box } from "../v2/Styled";
import { FlexDirection, FontFamily, Spacing } from "../v2/Styled/enum";
import { Colors } from "../../constants/colors";
import RadioButton from "../RadioButton";
import { AllRelationships, Relationship } from "../../constants/booking";

interface RelationshipRadioInterface {
  relationship: string;
  setRelationship: (value: string) => void;
}

const relationshipOptions = AllRelationships.map((relationship) => {
  return {
    value: relationship,
    title: Relationship.toString(relationship),
  };
});

const RelationshipRadio = ({ relationship, setRelationship }: RelationshipRadioInterface) => {
  return (
    <Box direction={FlexDirection.Column} gap={Spacing.S2} marginY={Spacing.S4}>
      <Box fontFamily={FontFamily.Museo} fontSize="14px" color={Colors.Dusk} fontWeight={700}>
        Relationship
      </Box>
      <Box alignItems="center" gap={Spacing.S1}>
        <RadioButton
          style={{ height: 20 }}
          selected={relationship === "any"}
          onSelected={() => setRelationship("any")}
        />
        <Box
          color={Colors.Dusk}
          fontFamily="Museo"
          fontSize="16px"
          className="cursor-pointer"
          onClick={() => setRelationship("any")}
        >
          Any
        </Box>
      </Box>
      {relationshipOptions.map((option) => {
        return (
          <Box alignItems="center" gap={Spacing.S1}>
            <RadioButton
              style={{ height: 20 }}
              selected={relationship === option.value}
              onSelected={() => setRelationship(option.value)}
            />
            <Box
              color={Colors.Dusk}
              fontFamily="Museo"
              fontSize="16px"
              className="cursor-pointer"
              onClick={() => {}}
            >
              {option.title}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default RelationshipRadio;
