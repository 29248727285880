import { NONAME } from "dns";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";
import { ProviderNote } from "../../../models";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import RoundedImage from "../../Image/RoundedImage";
import { useEffect, useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { checkIfEmpty, getValue } from "../../../utils/object";
import TherapistProfileDrawer from "../../TherapistProfileDrawer";
import { formatDate, getDayOfMonth } from "../../../utils/date.util";
import ProviderNoteInfoModal from "../../../pages/Recipient/components/ProviderNoteInfoModal";
import { Display, FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../../v2/Styled/enum";

interface Props {
  providerNote: ProviderNote;
  fullwidth?: boolean;
  showDivider?: boolean;
}

export default function ProviderTreatmentNote({
  providerNote,
  fullwidth = true,
}: Props): JSX.Element {

  const files = getValue(providerNote, "data.files", []);
  const note = getValue(providerNote, "data.note", "");
  const formattedDate = formatDate({ date: providerNote.createdAt, format: "DD MMM YYYY" });
  const [showReasonNotValidModal, setShowReasonNotValidModal] = useState(false);
  const [openProProfileDialog, setOpenProProfileDialog] = useState<boolean>(false);

  const styles = makeStyles({
    lineClamp2: {
      "textOverflow": "ellipsis",
      "display": "-webkit-box !important",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      "overflow": "hidden",
    },
  });

  const classes = styles();

  const [truncateLongText, settruncateLongText] = useState(false);

  const noteRef = useRef<HTMLDivElement>(null);

  const checkIsClamped = () => {
    if (!noteRef.current) return false;
    return noteRef.current.scrollHeight > noteRef.current.clientHeight;
  };

  useEffect(() => {
    const isLongText = checkIsClamped();
    settruncateLongText(isLongText);
  }, [noteRef]);


  const handleInfoModalClose = () => {
    setShowReasonNotValidModal(false);
  };

  const handleReadMore = () => {
    setShowReasonNotValidModal(true);
  };

  const isMobile = useMobile();

  const openImageURL = (url: string) => window.open(url, "_blank");

  const history = useHistory();

  const handleViewBooking = () => {
    history.push(`/bookings/${providerNote.bookingId}`);
  };

  const handleOpenProProfile = () => {
    setOpenProProfileDialog(true);
  };

  return (
    <>
      <Box style={{ marginBottom: "14px" }}>
        <Box
          maxWidth={isMobile ? undefined : fullwidth ? undefined : "885px"}
          borderRadius="6px"
          border={`solid 1px ${Colors.VeryLightPink}`}
          bgcolor="#ffffff"
          flexDirection="column"
          display="flex"
          py="24px"
          px="24px"
          style={{ cursor: "pointer" }}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            flex={1}
          >

            <Box display="flex" flexDirection="row" flex={1}>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="start"
                mr={isMobile ? "16px" : undefined}
                width={"209px"}
              >
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize="14px"
                  color={Colors.BlueyGrey}
                  textAlign="left"
                  mb={"8px"}
                >
                  Added
                </Box>

                <Box
                  fontFamily="Museo"
                  fontWeight={500}
                  fontSize="16px"
                  color={Colors.DarkSteelGrey}
                  textAlign="left"
                  mb={"16px"}
                >
                  {formattedDate}
                </Box>

                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize="14px"
                  color={Colors.BlueyGrey}
                  textAlign="left"
                  mb={"8px"}
                >
                  Provider
                </Box>

                <Link style={{ fontFamily: "Museo", fontWeight: 500, color: Colors.Dusk, fontSize: "16px", textDecoration: "underline" }} onClick={handleOpenProProfile}>
                  {providerNote.pro.firstName}
                </Link>

              </Box>

              <Box width="1px" bgcolor={Colors.PaleLilac} />

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                style={{
                  gap: Spacing.S3,
                }}
                ml={isMobile ? "16px" : "24px"}
                mr={isMobile ? "16px" : undefined}
                flex={1}
              >

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  style={{
                    gap: Spacing.S2,
                  }}
                >
                  <Box
                    fontFamily="Open Sans"
                    fontWeight={600}
                    fontSize="14px"
                    color={Colors.BlueyGrey}
                    textAlign="left"
                  >
                    Booking
                  </Box>

                  <Link style={{ fontFamily: "Museo", fontWeight: 500, fontSize: "16px", color: Colors.Dusk, textDecoration: "underline" }} onClick={handleViewBooking}>
                    {`#${providerNote.bookingId}`}
                  </Link>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  style={{
                    gap: Spacing.S2,
                  }}
                >

                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    style={{
                      gap: Spacing.S2,
                    }}>
                    {note || (!checkIfEmpty(files)) ? (<Box
                      fontFamily="Open Sans"
                      fontWeight={600}
                      fontSize="14px"
                      color={Colors.BlueyGrey}
                      textAlign="left"
                    >
                      Note
                    </Box>) : <></>}

                    <div
                      ref={noteRef}
                      style={{
                        fontSize: FontSize.F16,
                        color: Colors.Dusk,
                        fontWeight: FontWeight.Regular,
                        fontFamily: FontFamily.Museo,
                        lineHeight: "24px",
                        maxHeight:"48px"
                      }}
                      className={truncateLongText ? classes.lineClamp2 : ""}
                    >
                      {note}
                    </div>
                  </Box>

                  {truncateLongText ?
                    (<Link style={{ color: Colors.TurquoiseBlue, fontFamily: "Museo", fontWeight: 500, fontSize: "16px", cursor: "pointer" }} underline={"none"} onClick={handleReadMore}>
                      Read more
                    </Link>) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        style={{
                          gap: Spacing.S3
                        }}>

                        {!checkIfEmpty(files) && (<Box display={Display.Flex} flexDirection={FlexDirection.Row} flexWrap={"wrap"}  gridGap={Spacing.S3}>
                          {files.map((file: string) => <RoundedImage
                            fileUrl={file}
                            allowRemove={false}
                            clickable
                            onClick={() => openImageURL(file)}
                          />)}
                        </Box>)}
                      </Box>
                    )
                  }
                </Box>



                <ProviderNoteInfoModal
                  open={showReasonNotValidModal}
                  title={"Note"}
                  profileImage={providerNote.pro.profileImage}
                  firstName={providerNote.pro.firstName}
                  date={formattedDate}
                  note={note}
                  bookingId={providerNote.bookingId}
                  images={files}
                  onClose={handleInfoModalClose}
                  onOpenProProfile={handleOpenProProfile}
                  handleViewBooking={handleViewBooking}
                />
                <TherapistProfileDrawer
                  type={"popup"}
                  open={openProProfileDialog}
                  therapistId={providerNote.proId}
                  onClose={() => setOpenProProfileDialog(false)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  );
}
