import { Box } from "@material-ui/core";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing
} from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import TextField from "../../TextField";
import RoundedImage from "../../Image/RoundedImage";
import Button, { ButtonType } from "../../Button";
import { ChangeEvent, useRef } from "react";
import { getValue } from "../../../utils/object";
import { useAlertStore } from "../../../stores/alert";
import { useUploadNoteFile } from "../../../hooks/user/userTreatmentNote.hooks";

interface Props {
  title: string;
  note: string;
  files: string[];
  textLabel?: string;
  titleStyle?: any;
  subTitleStyle?: any;
  wrapperStyle?: any;
  contentWrapper?: any;
  onNoteChange: (note: string) => unknown;
  onfileUploaded: (file: any) => unknown;
  onRemoveNote: (index: number) => unknown;
}

const TreatmentNoteForm = ({
  title = "",
  note = "",
  files = [],
  textLabel = "",
  titleStyle = {},
  subTitleStyle = {},
  wrapperStyle = {},
  contentWrapper = {},
  onfileUploaded = () => {},
  onRemoveNote = () => {},
  onNoteChange = () => {},
}: Props) => {
  const noteFileUploadRef = useRef<HTMLInputElement>(null);

  const { setErrorMessage } = useAlertStore();

  const uploadNoteFileCB = {
    onSuccess: (response: any) => {
      const uploadedFiles = getValue(response, "data.files") || [];
      onfileUploaded(uploadedFiles);
    },
    onError: () => setErrorMessage("Unable to upload file. Please try again."),
  };
  const { mutate: uploadNoteFile, isLoading: isUploading } = useUploadNoteFile(uploadNoteFileCB);

  const onUploadFileClick = () => {
    if (noteFileUploadRef.current){ 
      noteFileUploadRef.current.click();
    }
  };

  const onNoteUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = getValue(event, "target.files[0]");
    if (!file) return;

    const form = new FormData();
    form.append("files", file);
    
    uploadNoteFile(form);
  };

  return (
    <>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
        <Box display={Display.Flex} flexDirection={FlexDirection.Column} alignItems={AlignItems.start} gridGap={Spacing.S4} style={{ ...wrapperStyle }}>
          <Box
            display={Display.Flex}
            fontSize={FontSize.F30}
            color={Colors.NightBlue}
            fontWeight={FontWeight.Bold}
            fontFamily={FontFamily.Museo}
            justifyContent={JustifyContent.center}
            style={{ ...titleStyle }}
          >
            {title}
          </Box>
          <Box
            fontSize={FontSize.F18}
            fontFamily={FontFamily.OpenSans}
            fontWeight={FontWeight.Medium}
            color={Colors.Grey}
            style={{ ...subTitleStyle }}
          >
            These notes are visible to the client and any future providers they book.
          </Box>
        </Box>

        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          gridGap={"24px"}
          marginTop={Spacing.S8}
          marginBottom={Spacing.S6}
          style={{ ...contentWrapper }}
        >
          <TextField
            multiline
            title={textLabel}
            value={note}
            paddingTop="0px"
            paddingBottom="0px"
            onChange={onNoteChange}
            placeholder="Add your note (optional)"
            maxLength={2000}
            // minInputHeight="76px"
            // textWrapperStyle={{ alignItems: "flex-start" }}
            inputStyle={{ alignItems: "flex-start", minHeight: "76px" }}
          />
          {(files || []).length ? (
            <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3} >
              {files.map((file: string, index: number) => {
                return <RoundedImage fileUrl={file} allowRemove onRemove={() => onRemoveNote(index)} />;
              })}
            </Box>
          ) : <></>}

          <input
            type="file"
            id="note-file-upload"
            ref={noteFileUploadRef}
            style={{ display: "none" }}
            onChange={onNoteUploadChange}
          />
          <Button
            width="132px"
            loading={isUploading}
            title="Upload file"
            type={ButtonType.outlined}
            onClick={onUploadFileClick}
          />
        </Box>
      </Box>
    </>
  );
};

export default TreatmentNoteForm;
