import { useEffect, useState } from "react";
import { GENDER_DESCRIPTION } from "../constants/message";
import { useGenderOptions } from "../hooks/utility/utilty.hooks";
import Dropdown, { DropdownOption } from "./Dropdown";
import { Spacing } from "./v2/Styled/enum";

interface Prop {
  onChange: (value: string | number) => unknown;
  value?: string | number;
  genderFor?: string;
}

export default function Gender({
  onChange,
  value,
  genderFor = "myself",
}: Prop) {
  const { genders } = useGenderOptions();
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );

  useEffect(() => {
    const gender = genders.find((gender) => gender.value === value);
    setSelectedOption(gender || null);
  }, [genders, value]);

  const handleSelected = (option: DropdownOption) => {
    onChange(option.value);
  };

  return (
    <>
      <Dropdown
        title={genderFor === "myself" ? "Gender" : "Their gender"}
        description={GENDER_DESCRIPTION}
        options={genders}
        selectedOption={selectedOption}
        onSelectedOption={handleSelected}
        paddingBottom={Spacing.S4}
        paddingTop={Spacing.S4}
      />
    </>
  );
}
