import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import React from "react";

type Props = {
  id: string;
  isSelected: boolean;
  title: string;
  secondaryTitle: string;
  isSecondaryTitle: boolean;
  onSelect: (key: string) => void;
  size: CardSize;
};

export type CardSize = "md" | "sm";

const cardSize: Record<CardSize, Record<any, any>> = {
  md: {
    width: "65px",
    height: "66px",
    fontSize: "16px",
    lineHeight: "24px",
  },
  sm: {
    width: "53px",
    height: "54px",
    fontSize: "14px",
    lineHeight: "18px",
  },
};

export function TippingCard(props: Props) {
  return (
    <Box
      minWidth={cardSize[props.size].width}
      minHeight={cardSize[props.size].height}
      width={cardSize[props.size].width}
      height={cardSize[props.size].height}
      padding="8px"
      borderRadius="8px"
      border="1px solid #C5CBD6"
      bgcolor={props.isSelected ? Colors.TurquoiseBlue : ""}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        cursor: "pointer",
      }}
      onClick={() => props.onSelect(props.id)}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        fontFamily={"Museo"}
        fontWeight={700}
        fontSize={cardSize[props.size].fontSize}
        lineHeight={cardSize[props.size].lineHeight}
        color={props.isSelected ? Colors.White : Colors.DarkSteelGrey}
      >
        <Box>{props.title}</Box>
        <Box
          style={{
            ...(props.isSecondaryTitle
              ? {
                fontSize: "12px",
                fontFamily: "SF UI Text",
                fontWeight: 500,
              }
              : {}),
            color: props.isSelected
              ? Colors.White
              : props.isSecondaryTitle
                ? Colors.Grey
                : Colors.DarkSteelGrey,
          }}
        >
          {props.secondaryTitle}
        </Box>
      </Box>
    </Box>
  );
}
