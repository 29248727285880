import React from "react";
import { TableCell, TableRow, makeStyles } from "@material-ui/core";
import { CommunicationMediumType } from "./PreferencesTable";

import { Colors } from "../../constants/colors";

import PreferenceDropdown from "./preferenceDropdown";
import PreferencesTitle from "./title";
import { getValue } from "../../utils/object";

interface TableRowMobileProps {
  data: {
    title: string;
    value: string;
    subtitle?: string;
    medium: CommunicationMediumType;
  };
  isMarketing?: boolean;
  metaData?: object;
}

const TableRowMobile: React.FC<TableRowMobileProps> = ({ data: item, isMarketing = false, metaData = {} }) => {
  const classes = useStyles();
  const mediumMap = {
    email: "Email",
    push: "App push",
    sms: "SMS",
  };
  let mediumNames = ["email", "push", "sms"];
  //@ts-ignore
  let mediums = mediumNames.map((med) => ({ name: mediumMap[med], ...item.medium[med] }));

  const  smsChargeText = getValue(metaData, "smsChargeText", "");
  
  return (
    <TableRow className={classes.tableRow} key={`user-lg-${item.title}`}>
      <br />
      <TableCell className={classes.cell} component="td" scope="row">
        <PreferencesTitle subtitle={item.subtitle} title={item.title} />
      </TableCell>
      {mediums.map((medium, index) => {
        const isPaid = !isMarketing && medium.name === mediumMap.sms;
        return (
          <div
            key={`com-medium-${index}`}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* @ts-ignore */}
            <p className={classes.header}>
              <span>{medium.name}</span>
              {isPaid && <span className={classes.headerInfoText}>{smsChargeText}</span>}
            </p>
            <TableCell
              // style={{ marginLeft: "100px" }}
              className={classes.cell}
              component="td"
              scope="row"
            >
              {/* <div style={{ marginLeft: "100px" }}> */}
              <PreferenceDropdown
                type={item.value}
                medium={mediumNames[index]}
                value={medium.value}
                options={medium.options}
                disabled={medium.isDisabled}
              />
            </TableCell>
          </div>
        );
      })}
      <br />
    </TableRow>
  );
};
const useStyles = makeStyles({
  cell: {
    fontFamily: "Museo",
    fontSize: "16px",
    color: Colors.Dusk,
    borderBottom: "0px",
    padding: "0px",
  },
  tableRow: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  header: {
    fontFamily: "Museo",
    fontSize: "16px",
    font: "Museo",
    fontWeight: 700,
    color: "#41506F",
    // padding: "0px 10px",
    // paddingLeft: "20px",
  },
  headerInfoText: {
    fontWeight: 500,
  }
});

export default TableRowMobile;
