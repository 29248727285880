import React, { MouseEventHandler } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { Cursor, FontFamily, FontSize, FontWeight, Display, AlignItems, JustifyContent, TextDecoration } from "../../components/v2/Styled/enum";

interface SummaryRowProps {
  title: string;
  value: string;
  handleOnClick?: MouseEventHandler<HTMLImageElement> | undefined;
}
const SummaryRow = ({ title, value, handleOnClick }: SummaryRowProps) => {
  const shouldHandleClick = !!handleOnClick;

  return (
    <Box display="flex" flexDirection="row">
      <Box
        style={{ ...styles.title, ...(shouldHandleClick ? styles.clickable : {}) }}
        onClick={handleOnClick}
      >
        {title}
      </Box>
      <Box style={styles.price}>{value}</Box>
    </Box>
  );
};

const styles: Record<string, React.CSSProperties> = {
  title: {
    fontFamily: FontFamily.Museo,
    fontSize: FontSize.F16,
    fontWeight: FontWeight.Medium,
    color: Colors.Dusk,
  },
  price: {
    fontWeight: FontWeight.Medium,
    flex: 1,
    display: Display.Flex,
    fontFamily: FontFamily.Museo,
    fontSize: FontSize.F14,
    color: Colors.Dusk,
    alignItems: AlignItems.center,
    justifyContent: JustifyContent.end,
  },
  clickable: {
    textDecoration: TextDecoration.underline,
    cursor: Cursor.pointer,
  },
};

export default SummaryRow;
