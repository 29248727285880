import Lottie, { LottieRef } from "lottie-react";
import { useRef } from "react";

type Props = {
  styles?: React.CSSProperties;
  speed?: number;
  onAnimationComplete?: () => void;
  autoPlay: boolean;
  loop: boolean;
  animationData: any;
};

export function CustomLottieAnimation(props: Props) {
  const lottieRef: LottieRef = useRef(null);

  return (
    <Lottie
      animationData={props.animationData}
      autoPlay={props.autoPlay}
      loop={props.loop}
      style={props.styles ?? {}}
      lottieRef={lottieRef}
      onEnterFrame={() => lottieRef.current?.setSpeed(props.speed ?? 1)}
      onComplete={props.onAnimationComplete}
    />
  );
}
