import copy from "copy-to-clipboard";
import { camelCase, isString } from "lodash";

const checkIsString = (data: any) => isString(data);

const copyStringToClipboard = (value: string) => copy(value);

const toJSON = (string: any, defaultValue = null) => {
  if (!string) return defaultValue;
  try {
    return isString(string) ? JSON.parse(string) : string;
  } catch (e) {
    return defaultValue;
  }
};

const toCamelCase = camelCase;

const checkStringEndsWith = (str: string, check: string) => str.endsWith(check);

const toBool = (val: string) => {
  if (isString(val)) {
    if (val === "true") return true;
    return false;
  }
  return val;
};

const objectToQueryString = ({
  queryObj = {},
  excludeKeys = [],
}: {
  queryObj: Record<string, any>;
  excludeKeys: string[];
}) =>
  Object.keys(queryObj)
    .filter((p) => !excludeKeys.includes(p))
    .reduce((str, params) => {
      str += `${str ? "&" : "?"}${params}=${queryObj[params]}`;
      return str;
    }, "");

export {
  checkIsString,
  copyStringToClipboard,
  toJSON,
  checkStringEndsWith,
  toCamelCase,
  toBool,
  objectToQueryString,
};
