import axios, { AxiosResponse } from "axios";

export const groupedTreatments = (accessToken: string, params = {}) => {
  return axios
    .get("/api/v2/service/category", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    })
    .then((res: AxiosResponse) => res.data);
};
