import React, { useState } from "react";
import { formatDate, getDateObject } from "../../../utils/date.util";
import { Box } from "@mui/material";
import { Display, FlexDirection, Position, Spacing } from "../../../components/v2/Styled/enum";
import { DateSelectorFieldV2 } from "../../../components/DateSelectorField/DateSelectorFieldV2";

interface BookingDateFilterInterface {
  startingDate: Date | string | null;
  endingDate: Date | string | null;
  handleDateSelected: (field: string, value: Date | string | null) => unknown;
}

const BookingDateFilterPublic = ({
  startingDate,
  endingDate,
  handleDateSelected,
}: BookingDateFilterInterface) => {

  const [forceSelectToCalendar, setForceSelectToCalendar] = useState(false);
  const [actualFromDate, setActualFromDate] = useState<Date | null>(null);

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Row}
      gap={Spacing.S4}
      style={{
        width: "100%",
        paddingTop: Spacing.S4,
        paddingBottom: Spacing.S4,
      }}
      position={Position.Relative}
    >
      <DateSelectorFieldV2
        title="From"
        value={
          startingDate
            ? formatDate({ date: startingDate, format: "D MMM YYYY" })
            : ""
        }
        placeHolder="Select date"
        onDateSelected={(selectedDate: Date) => {
          setForceSelectToCalendar(true);
          handleDateSelected("startDate", selectedDate);
          setActualFromDate(selectedDate);
        }}
        wrapperStyle={{
          position: Position.Static,
        }}
      />
      <DateSelectorFieldV2
        title="To"
        fromDate={actualFromDate}
        forceSelect={forceSelectToCalendar}
        value={
          endingDate
            ? formatDate({ date: endingDate, format: "D MMM YYYY" })
            : ""
        }
        placeHolder="Select date"
        onDateSelected={(selectedDate: Date) => {
          setForceSelectToCalendar(false);
          handleDateSelected("endDate", selectedDate);
        }}
        wrapperStyle={{
          position: Position.Static,
        }}
        minValidDate={
          startingDate ? getDateObject(startingDate) : null
        }
      />
    </Box>
  );

};

export default BookingDateFilterPublic;
