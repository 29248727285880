import { useEffect, useRef, useState } from "react";
import { Box as MUIBox,Checkbox, makeStyles } from "@material-ui/core";

import {
  AlignItems,
  Cursor,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing
} from "../v2/Styled/enum";
import Ratings from "../Ratings";
import { Box, Text } from "../v2/Styled";
import { toBool } from "../../utils/string";
import EditIcon from "../../images/pen.svg";
import { Colors } from "../../constants/colors";
import RoundedImage from "../Image/RoundedImage";
import DeleteIcon from "../../images/trashbin.svg";
import { getFullUploadsUrl } from "../../utils/url";
import CaretRight from "../../images/carat-right-grey.svg";
import { checkIfEmpty, getValue } from "../../utils/object";
import { CompletedModalType } from "../../data/treatmentNotesEnum";

interface ActionsOptionsInterface {
  title: string;
  description: string;
  value: string;
}

interface Props {
  option: ActionsOptionsInterface;
  handleOptionsClicked: (value: string) => unknown;
  data: any | null;
  onEditClicked: (value: string) => unknown;
  onDeleteClicked?: () => unknown;
}

interface selectedData {
  title?: any;
  desc?: any;
  files?: any;
  uploadToProfile?: boolean;
  rating?: number;
}

const ButtonOptionsForNoteModal = ({ option, handleOptionsClicked, data, onEditClicked, onDeleteClicked }: Props) => {

  const [selectedData, setSelectedData] = useState<selectedData | null>(null);
  const [truncateLongText, setTruncateLongText] = useState(false);
  const noteRef = useRef<HTMLDivElement>(null);

  const styles = makeStyles({
    lineClamp2: {
      "textOverflow": "ellipsis",
      "display": "-webkit-box !important",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      "overflow": "hidden",
    },
  });

  const classes = styles();

  const checkIsClamped = () => {
    if (!noteRef.current) return false;
    return noteRef.current.scrollHeight > noteRef.current.clientHeight;
  };

  useEffect(() => {
    const isLongText = checkIsClamped();
    setTruncateLongText(isLongText);
  }, [noteRef, data, selectedData]);

  const openImageURL = (url: string) => window.open(url, "_blank");

  const formatData = () => {
    switch (option.value) {
    case CompletedModalType.ADD_TREATMENT_NOTE:
      return setSelectedData({
        title: "Note",
        desc: getValue(data, "noteText"),
        files: getValue(data, "noteFiles"),
      });
    case CompletedModalType.BLOCK_CLIENT:
      return setSelectedData({
        title: "Reason",
        desc: getValue(data, "reason"),
      });
    case CompletedModalType.RATE_AND_REVIEW:
      return setSelectedData({
        title: "Review",
        rating: getValue(data, "rating"),
        desc: getValue(data, "review"),
        files: getValue(data, "reviewFiles"),
        uploadToProfile: toBool(getValue(data, "isUploadToPortfolio")),
      });
    default:
      return setSelectedData(null);
    }
  };

  useEffect(() => {
    formatData();
  }, [data]);

  const getFileURL = (file: any, selectedData: any) => {
    let fileURL = file;

    if (selectedData.title === "Review") {
      const { filePath } = file;
      fileURL = filePath && typeof filePath === "string"
        ? getFullUploadsUrl(filePath)
        : file.tempURL;
    }
    return fileURL;
  };

  const getFilePreview = (files: [], selectedData: any) => files.map((file: any) => {
    const fileURL = getFileURL(file, selectedData);

    return (
      <RoundedImage
        fileUrl={fileURL}
        allowRemove={false}
        clickable={false}
        onClick={() => openImageURL(file)}
        height="50px"
        width="50px"
      />
    );
  });


  const createDescriptionSection = (selectedData: any) => {
    const { desc, files, uploadToProfile } = selectedData;

    return (
      <Box display={Display.Flex} direction={FlexDirection.Column} gap={Spacing.S2}>

        <div
          ref={noteRef}
          style={{
            fontSize: FontSize.F16,
            color: Colors.Dusk,
            fontWeight: FontWeight.Regular,
            fontFamily: FontFamily.Museo,
            lineHeight: "24px",
            maxHeight: "42px"
          }}
          className={truncateLongText ? classes.lineClamp2 : ""}
        >
          {desc}
        </div>
        {truncateLongText && (
          <Text
            font={FontFamily.Museo}
            weight={FontWeight.Bold}
            color={Colors.TurquoiseBlue}
            size={FontSize.F14}
          >

            Read more
          </Text>
        )}
        {files && (
          <Box display={Display.Flex} direction={FlexDirection.Row} gap={Spacing.S3}>
            {getFilePreview(files, selectedData)}
          </Box>
        )}
        {toBool(uploadToProfile) && (
          <Box
            fontSize={FontSize.F14}
            color={Colors.DarkSteelGrey}
            fontWeight={FontWeight.Regular}
            fontFamily={FontFamily.OpenSans}
            gap={Spacing.S2}
            alignItems={AlignItems.center}
          >
            <Checkbox
              style={{
                margin: 0,
                padding: 0,
              }}
              checked={toBool(uploadToProfile)}
              disabled={true}
            />
            Upload images to "My Portfolio"
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      direction={FlexDirection.Column}
      border={`1px solid ${Colors.LightPeriwinkle}`}
      borderRadius={"8px"}
    >
      <MUIBox
        borderRadius={"8px"}
        style={{
          cursor: checkIfEmpty(data) ? Cursor.pointer : Cursor.default,
          gap: Spacing.S4,
          padding: Spacing.S4,
          margin: Spacing.S0,
          display: Display.Flex,
          direction: FlexDirection.Row,
          backgroundColor: Colors.White,
        }}
      >
        <Box
          direction={FlexDirection.Row}
          justifyContent={JustifyContent.spaceBetween}
          width={"100%"}
          alignItems={AlignItems.center}
          onClick={() => {
            handleOptionsClicked(option.value);
          }}
        >
          <Box direction={FlexDirection.Column}>
            <Text
              font={FontFamily.Museo}
              weight={FontWeight.Medium}
              lineHeight={LineHeight.L24}
              color={Colors.Dusk}
            >
              {option.title}
            </Text>
            <Text lineHeight={LineHeight.L21} color={Colors.Grey} size={FontSize.F14}>
              {option.description}
            </Text>
          </Box>
          {
            checkIfEmpty(data) && (
              <img
                src={CaretRight}
                height={14}
                alt="Select"
                style={{ cursor: Cursor.pointer }}
              />
            )
          }
        </Box>
      </MUIBox>

      {
        (!checkIfEmpty(selectedData) && (selectedData?.title && (selectedData.desc || selectedData.files || selectedData.rating))) && (
          <Box
            direction={FlexDirection.Column}
            gap={Spacing.S4}
            paddingX={Spacing.S4}
            borderRadius={"8px"}
            paddingY={Spacing.S4}
            backgroundColor={Colors.PaleGrey}
          >
            <Box
              direction={FlexDirection.Row}
              justifyContent={JustifyContent.spaceBetween}
              alignItems={AlignItems.center}
            >
              {(selectedData.title === "Review" && selectedData.rating) ? (
                <Ratings rating={selectedData.rating} />
              ) : (
                <Box
                  fontFamily={FontFamily.SFPro}
                  fontWeight={FontWeight.Medium}
                  fontSize={FontSize.F12}
                  color={Colors.Grey}
                >
                  {selectedData.title}
                </Box>
              )}
              <Box direction={FlexDirection.Row} gap={Spacing.S2}>
                <img src={EditIcon} onClick={() => {
                  onEditClicked(option.value);
                }} alt="edit" style={{ cursor: Cursor.pointer }} />
                <img src={DeleteIcon} onClick={onDeleteClicked} alt="delete" style={{ cursor: Cursor.pointer }} />
              </Box>
            </Box>
            {createDescriptionSection(selectedData)}
          </Box>
        )
      }
    </Box>

  );
};

export default ButtonOptionsForNoteModal;