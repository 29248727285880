import TextField from "../TextField";
import { AdditionalBillingIdentifierInterface } from "./interface";
import { Spacing } from "../v2/Styled/enum";
interface Props {
  customerId?: string,
  orderNumber?: string,
  lineItemCode?: string,
  setCustomerId?: (value: string) => void,
  setOrderNo?: (value: string) => void,
  setItemCode?: (value: string) => void,
  additionalBillConfig?: AdditionalBillingIdentifierInterface
}
export default function BillingIdentiferItem({
  customerId,
  orderNumber,
  lineItemCode,
  setCustomerId,
  setOrderNo,
  setItemCode,
  additionalBillConfig = {
    isNDISCustomerIdReq: true,
    isOrderCodeReq: true,
    isItemCodeReq: true,
  },
}: Props): JSX.Element {
  return (
    <>
      {
        additionalBillConfig.isNDISCustomerIdReq ? <TextField
          title="NDIS customer ID"
          value={customerId}
          onChange={(value) => !!setCustomerId && setCustomerId(value)}
          paddingBottom={Spacing.S4}
          paddingTop={Spacing.S4}
        /> : <></>
      }

      {
        additionalBillConfig.isOrderCodeReq ? <TextField
          title="Purchase order number"
          onChange={(value) => !!setOrderNo && setOrderNo(value)}
          value={orderNumber}
          paddingBottom={Spacing.S4}
          paddingTop={Spacing.S4}

        /> : <></>
      }

      {
        additionalBillConfig.isItemCodeReq ? <TextField
          title="Line item code"
          onChange={(value) => !!setItemCode && setItemCode(value)}
          value={lineItemCode}
          paddingBottom={Spacing.S4}
          paddingTop={Spacing.S4}
        /> : <></>
      }
    </>

  );

}