import { Box } from "@material-ui/core";
import TextLink from "../../../components/TextLink";
import { CORPORATE_BOOKING_KEYS } from "../../../data/corporate.options";
import styles from "./styles";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { QUESTION_TYPE } from "../../../constants/questionType";
import { DEFAULT_CURRENCY } from "../../../constants/booking";
import { getTreatmentDetailByTreatmentId, useServicesStore } from "../../../stores/booking";
import TreatmentIcon from "../../../images/review-item-massage.svg";

interface TreatmentDetailProps {
  booking: any;
  treatmentDetails: any;
}

const TreatmentDetails = ({ treatmentDetails, booking }: TreatmentDetailProps) => {
  const { rates } = useServicesStore();

  const getGroupedQuestions = ({ questions }: any) => {
    const imageQuestions: any = [];
    const otherQuestions: any = [];
    if (!questions || checkIfEmpty(questions)) {
      return { image: imageQuestions, other: otherQuestions };
    }

    questions.forEach((question: any) => {
      if (question.type === QUESTION_TYPE.IMAGE) imageQuestions.push(question);
      else otherQuestions.push(question);
    });
    return { image: imageQuestions, other: otherQuestions };
  };

  const getCurrencyForBooking = () => {
    if (checkIfEmpty(booking)) return;
    const selectedService = rates.find(({ id }) => id === booking.serviceId);

    const currency =
      getValue(selectedService, "selectedCountry.currencySymbol") ||
      DEFAULT_CURRENCY.currencySymbol;

    return currency;
  };

  const getTreatmentLabel = ({
    treatmentLabel,
    serviceFee = 0,
  }: {
    treatmentLabel: any;
    serviceFee: number;
  }) => {
    if (!treatmentLabel) return "";

    const currency = getCurrencyForBooking();

    return `${treatmentLabel}  (${currency}${serviceFee.toFixed(2)})`;
  };

  const getAddOnLabel = (addOn: any) => {
    const { name, label, serviceFee, price } = addOn;
    const addOnPrice = serviceFee || price || 0;
    const addOnName = label || name || "";

    const currency = getCurrencyForBooking();

    return `${addOnName} (${currency}${parseInt(addOnPrice).toFixed(2)})`;
  };

  const getFormattedAnswers = (questions: any) => {
    const formattedData = (questions || []).map((question: any) => {
      const answerValue = getValue(question, "answer.answer") || null;
      const answerOptions = question?.answerDetails;

      const selectedAns = answerOptions?.find(
        ({ value }: { value: string }) => value === answerValue
      );
      const payload: any = {
        question: question?.question,
        answer: selectedAns?.title,
        addedPrice: selectedAns?.addedPrice,
        addedDuration: selectedAns?.addedDuration,
        treatmentId: question?.treatmentId,
        serviceId: question?.serviceId,
        type: question?.type,
        addOn: question?.isAddOns,
        attrs: question?.attrs,
      };
      return payload;
    });
    return formattedData;
  };

  const openImage = (imageUrl: string) => window.open(imageUrl);

  return (
    <>
      <Box style={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}>
        {treatmentDetails.map((detail: any, index: number) => {
          const {
            treatmentDuration,
            bookingAnswers: answers,
            questions,
            serviceDuration,
            serviceFee,
            title,
          } = detail;

          const { image: imageQuestions, other: otherQuestions } = getGroupedQuestions({
            questions,
          });

          const addOnForThisTreatment = detail?.addons || [];

          const bookingAnswers = getFormattedAnswers(questions);
          const hasAnswers = bookingAnswers?.length > 0;

          return (
            <Box>
              <Box display="flex" alignItems="flex-start" gridGap={14}>
                <img src={TreatmentIcon} alt="Treatment icon" />
                <Box flex={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Box style={styles.person}>Person {index + 1}</Box>
                  </Box>
                  <Box
                    style={{
                      ...styles.treatmentLabel,
                    }}
                  >
                    {getTreatmentLabel({ treatmentLabel: title, serviceFee })}
                  </Box>
                  {treatmentDuration || serviceDuration ? (
                    <Box style={styles.detail}>{treatmentDuration || serviceDuration} mins</Box>
                  ) : (
                    <></>
                  )}

                  {addOnForThisTreatment?.map((data: any) => (
                    <Box key={data?.id} mt={1} mb={1}>
                      <Box>
                        <Box
                          style={{
                            ...styles.treatmentLabel,
                            // textDecoration: "underline",
                          }}
                        >
                          Add-on: {getAddOnLabel(data)}
                        </Box>
                        <Box style={styles.detail}>{data?.duration} min</Box>
                      </Box>
                    </Box>
                  ))}

                  {hasAnswers ? (
                    <Box mt={1} mb={1}>
                      {otherQuestions.map(({ answer, question }: any) => (
                        <Box style={styles.detail}>
                          {question}: {answer}
                        </Box>
                      ))}

                      {imageQuestions.map(({ answer, question }: any) => (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                          <Box style={styles.detail}>{question}</Box>
                          <Box style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
                            {(answer || []).map(({ filePath, id }: any) => (
                              <Box
                                style={{
                                  ...styles.imageWrapper,
                                  ...styles.clickable,
                                  backgroundImage: `url("${BASE_UPLOADS_URL}/${filePath}")`,
                                }}
                                onClick={() => openImage(`${BASE_UPLOADS_URL}/${filePath}`)}
                              ></Box>
                            ))}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default TreatmentDetails;
