import React from "react";
import MapApiService from "../services/map/MapApiService";
import { MAX_ZOOM_LEVEL, drawMarker } from "../services/map/map.service";
import CIRCLE_MARKER_SELECTED from "../images/booking-ellipse-selected.svg";

interface Props {
  id: string;
  latitude: number;
  longitude: number;
  markerIcon?: any;
  onMapClicked?: () => void;
  isMarkerAccurate?: boolean;
  interractable?: boolean;
}
export default function Map({
  id,
  latitude,
  longitude,
  isMarkerAccurate = true,
  markerIcon,
  onMapClicked,
  interractable = true,
}: Props): JSX.Element {
  let map: google.maps.Map;

  const position = {
    lat: latitude,
    lng: longitude,
  };

  async function initMap(): Promise<void> {
    map = await MapApiService.getMap(document.getElementById(id) as HTMLElement, position);
    map.setZoom(MAX_ZOOM_LEVEL);
    map.setOptions({
      disableDefaultUI: true,
      draggable: false,
      zoomControl: false,
      cameraControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      fullscreenControl: false,
      maxZoom: MAX_ZOOM_LEVEL,
      clickableIcons: interractable ? true : false,
    });

    const icon = isMarkerAccurate
      ? markerIcon
      : {
        url: CIRCLE_MARKER_SELECTED,
        scaledSize: new google.maps.Size(60, 60),
        anchor: new google.maps.Point(30, 30),
      };

    drawMarker({ google, map, coords: position, icon });
  }

  React.useEffect(() => {
    initMap();
  }, []);

  return <div id={id} style={{ width: "100%", height: "100%" }} onClick={onMapClicked}></div>;
}
