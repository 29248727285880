import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import BookingDetailsSummaryItem, {
  BookingDetailsSummaryItemType,
} from "../../../../components/BookingDetails/BookingDetailsSummaryItem";
import TotalDueAccordion from "../../../../components/Bookings/V2/TotalDueAccordion/TotalDueAccordion";
import { Colors } from "../../../../constants/colors";
import { RootState } from "../../../../stores/V2";
import { getValue } from "../../../../utils/object";
import BookingSummaryItem, { BookingSummaryItemType } from "./BookingSummaryItem";

interface Props {
  booking?: any;
  forDialog?: boolean;
}

const PriceBreakdown = ({ booking: bookingData, forDialog = false }: Props): JSX.Element => {
  const bookingState = useSelector(({ booking }: RootState) => booking);

  const booking = bookingData ? bookingData : bookingState;

  const { bookingPrice } = booking;
  const bookingCurrency = getValue(booking, "selectedService.selectedCountry.currencySymbol", "$");
  const isActuallyPaid = bookingData?.isActuallyPaid;

  return (
    <Box width={"100%"}>
      <Box style={{ padding: forDialog ? "0px 16px" : "0px 32px" }}>
        <Box
          fontFamily="Museo"
          fontSize="20px"
          fontWeight={700}
          color={Colors.NightBlue}
          letterSpacing="0.25px"
          mt={3}
          textAlign={forDialog ? "center" : "left"}
        >
          Summary
        </Box>
        <BookingSummaryItem type={BookingSummaryItemType.massage} booking={booking} />
        <BookingSummaryItem type={BookingSummaryItemType.parkingAndTravel} booking={booking} />
        <BookingSummaryItem type={BookingSummaryItemType.peakHourSurcharge} booking={booking} />
        <BookingSummaryItem
          type={BookingSummaryItemType.publicHolidaySurcharge}
          booking={booking}
        />
        <BookingSummaryItem type={BookingSummaryItemType.tipping} booking={booking} />
        <BookingSummaryItem type={BookingSummaryItemType.discount} booking={booking} />
        <BookingSummaryItem type={BookingSummaryItemType.coupon} booking={booking} />
        <BookingSummaryItem type={BookingSummaryItemType.credit} booking={booking} />
        <BookingSummaryItem type={BookingSummaryItemType.clientPlatformFee} booking={booking} />
        <BookingDetailsSummaryItem
          type={BookingDetailsSummaryItemType.totalPaid}
          booking={booking}
        />
      </Box>
      <Box style={{ padding: forDialog ? "0px" : "0px 16px" }}>
        <TotalDueAccordion
          totalPrice={booking.totalAmountDue || bookingPrice.price}
          currency={bookingCurrency}
          bookingStatus={booking?.status}
          hasBackup={booking?.backup}
          isActuallyPaid={isActuallyPaid}
          bookingPrice={bookingPrice}
        />
      </Box>
    </Box>
  );
};

export default PriceBreakdown;
