import { Box, CircularProgress } from "@material-ui/core";
import braintree from "braintree-web";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import React from "react";
import useSWR from "swr";
import { getValue } from "../../utils/object";
import { Colors } from "../../constants/colors";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import PaymentCards from "../../images/payment-cards.png";
import { PaymentMethod } from "../../models";
import { useAlertStore } from "../../stores/alert";
import { usePaymentStore } from "../../stores/booking";
import { useBraintreeStore } from "../../stores/braintree";
import Button, { ButtonType } from "../Button";
import GoogleReCaptcha from "../GoogleReCaptcha";
import AddNewCardModal from "../Payment/AddNewCardModal";
import AddNewCardModelStripe from "../Payment/AddNewCardModelStripe";
import RadioButton from "../RadioButton";
import { PaymentFrameType } from "./PaymentFrame";
import PaymentFrameItem from "./PaymentFrameItem";
import { useUserStore } from "../../stores/user";
import { PaymentType } from "../../constants/payment";
import { useAllowedPayments } from "../../hooks/payment.hooks";

interface Props {
  type?: PaymentFrameType;
  selected: boolean;
  onSelected: () => unknown;
}

const inputLabelStyle = {
  fontFamily: "Museo",
  fontSize: "14px",
  fontWeight: 700,
  color: Colors.Dusk,
};

const inputWrapperStyle = {
  border: "none",
  borderBottom: "1px solid rgba(0, 0, 0, .26)",
  height: "40px",
};

interface CardInputProps {
  onAddedNewCard: (method: PaymentMethod) => unknown;
  loading: boolean;
  onLoading: (loading: boolean) => unknown;
}

function CardInput({ onAddedNewCard, loading, onLoading }: CardInputProps) {
  const [adding, setAdding] = React.useState(false);
  const accessToken = useAccessToken();

  const { setErrorMessage } = useAlertStore();
  const isMobile = useMobile();

  const {
    firstTimeUserCardRecaptchaSuccess,
    setFirstTimeUserCardRecaptchaSuccess,
  } = usePaymentStore();

  const {
    clientToken,
    fetchClientToken,
    hostedFieldsInstance,
    setHostedFieldsInstance,
  } = useBraintreeStore();

  React.useEffect(() => {
    if (!clientToken) {
      fetchClientToken();
    }
  }, []);

  React.useEffect(() => {
    if (clientToken) {
      // console.debug("clientToken: ", clientToken);
      braintree.client.create(
        {
          authorization: clientToken,
        },
        (error, clientInstance) => {
          // Create card instance
          braintree.hostedFields
            .create({
              client: clientInstance,
              styles: {
                input: {
                  "font-size": "18px",
                  "font-family": "monospace", // REMINDER: Custom fonts not allowed
                },
                ":focus": {
                  color: "black",
                },
                "::placeholder": {
                  color: "#BBBBBB",
                  "font-weight": "300",
                },
              },
              fields: {
                number: {
                  selector: "#card-number",
                  placeholder: "Enter card number",
                },
                cvv: {
                  selector: "#cvv",
                  placeholder: "234",
                },
                expirationDate: {
                  selector: "#expiration-date",
                  placeholder: "12/24",
                },
              },
            })
            .then((hostedFieldsInstance) => {
              setHostedFieldsInstance(hostedFieldsInstance);

              onLoading(false);
            })
            .catch((error) => {
              console.debug("hosted fields error: ", error);
            });
        }
      );
    }
  }, [clientToken]);

  return (
    <form
      style={loading ? { display: "none" } : {}}
      id="my-sample-form"
      className="scale-down"
    >
      <Box padding={isMobile ? "40px 11px" : "40px 40px 20px 40px"} style={{ background: Colors.White }}>
        <div
          className="cardinfo-card-number"
          style={{ marginTop: "0px", marginBottom: "21px" }}
        >
          <Box style={inputLabelStyle}>Card number</Box>
          <div
            className="input-wrapper"
            id="card-number"
            style={inputWrapperStyle}
          ></div>
        </div>

        <div
          className="cardinfo-wrapper"
          style={{
            marginTop: "42px",
            marginBottom: "21px",
            flexDirection: isMobile ? "column" : "row",
            display: "flex",
          }}
        >
          <div className="cardinfo-exp-date" style={{ marginRight: "12px" }}>
            <Box style={inputLabelStyle}>Expiry</Box>
            <div
              className="input-wrapper"
              id="expiration-date"
              style={inputWrapperStyle}
            ></div>
          </div>

          <div
            className="cardinfo-cvv"
            style={isMobile ? { marginTop: "42px" } : { marginLeft: "12px" }}
          >
            <Box style={inputLabelStyle}>CVC</Box>
            <div
              className="input-wrapper"
              id="cvv"
              style={inputWrapperStyle}
            ></div>
          </div>
        </div>

        <Box mt={4}>
          <GoogleReCaptcha
            elementID="connect-a-card"
            verifyCallback={() => setFirstTimeUserCardRecaptchaSuccess(true)}
          />
        </Box>
      </Box>
    </form>
  );
}

export default function PaymentFrameCards({ selected, onSelected }: Props) {
  const accessToken = useAccessToken();
  const { user } = useUserStore();
  const { isStripeAllowed } = useAllowedPayments(user);

  const { data: paymentMethodsData, mutate: paymentMethodsMutate } = useSWR(
    `/paymentmethods?accessToken=${accessToken}`
  );
  const _paymentMethods = paymentMethodsData
    ? (paymentMethodsData as [PaymentMethod])
    : [];

  const paymentMethods = _paymentMethods
    .filter((method) => method.type === "card" || "google-pay")
    .sort((a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix());

  const { paymentType, paymentMethod, setPaymentMethod, paymentMethodByType } = usePaymentStore();

  const [addNewCardOpen, setAddNewCardOpen] = React.useState(false);

  const noCards = !isNil(paymentMethodsData) && isEmpty(paymentMethods);

  // const noCards = true; // TESTING

  const [loadingCardInput, setLoadingCardInput] = React.useState(true);

  const handleSelected = () => {
    onSelected();

    const selectPayment = (payment: PaymentMethod | null) => {
      if (!payment || getValue(payment, "isExpired", false)) return;
      setPaymentMethod(payment);
    };

    if (paymentMethods && paymentMethods.length === 1) {
      selectPayment(paymentMethods[0]);
    } else if (paymentType !== PaymentType.card) {
      selectPayment(paymentMethodByType[PaymentType.card] || null);
    }
  };

  return (
    <>
      <Box border="1px solid #C5CBD6" borderRadius="8px" overflow="hidden">
        <Box
          height="56px"
          alignItems="center"
          bgcolor={Colors.PaleGrey}
          display="flex"
          flexDirection="row"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flex={1}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box width="8px" />
              <RadioButton selected={selected} onSelected={handleSelected} />
              <Box
                fontFamily="Museo"
                fontWeight={600}
                fontSize="16px"
                lineHeight="24px"
                color={Colors.Dusk}
              >
                Credit/debit card
              </Box>
            </Box>
            <Box mr={2}>
              <img src={PaymentCards} height="24px" />
            </Box>
          </Box>
        </Box>

        {selected ? (
          <Box display="flex" flexDirection="column">
            {paymentMethods.map((method: PaymentMethod) => (
              <PaymentFrameItem
                paymentMethod={method}
                onSelected={() => {
                  onSelected();
                  setPaymentMethod(method);
                }}
                selected={paymentMethod?.id === method.id}
              />
            ))}

            <Box m={2} mt={1}>
              <Button
                title="Add credit or debit card"
                type={ButtonType.outlined}
                onClick={() => setAddNewCardOpen(true)}
              />
            </Box>
          </Box>
        ) : null}

        {/* {selected && noCards && (
          <Box>
            {loadingCardInput && (
              <CircularProgress
                style={{ color: Colors.NightBlue, margin: "42px" }}
              />
            )}
            <CardInput
              onAddedNewCard={(method: PaymentMethod) => { }}
              loading={loadingCardInput}
              onLoading={(loading) => setLoadingCardInput(loading)}
            />
          </Box>
        )} */}
      </Box>
      { user && isStripeAllowed ? (
        <>
          <AddNewCardModelStripe
            open={addNewCardOpen}
            onClose={() => setAddNewCardOpen(false)}
            onAddedNewCard={(method) => {
              setAddNewCardOpen(false);
              setPaymentMethod(method);
              paymentMethodsMutate();
            }}
            country={user.country}
            cardOnly
          />
        </>
      ) : (
        <>
          <AddNewCardModal
            open={addNewCardOpen}
            onClose={() => setAddNewCardOpen(false)}
            onAddedNewCard={(method) => {
              setAddNewCardOpen(false);
              setPaymentMethod(method);
              paymentMethodsMutate();
            }}
            cardOnly
          />
        </>
      )}
    </>
  );
}
