import { Box } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import PaginationControl from "../../components/PaginationControl";
import NoItemsPlaceholder from "../../components/ProDashboard/NoItemsPlaceholder";
import { BOOKINGS_SECTION_VALUES } from "../../constants/prodashboard";
import { useMobile } from "../../hooks/mobile";
import { Job, PaginatedResponse2 } from "../../models";
import { checkIfEmpty, getValue } from "../../utils/object";
import NewBookingsView from "./Bookings/NewBookingsView";
import { parseApiError } from "../../helpers/error";
import ErrorMessageView from "../../components/Error/ErrorMessageView";
import SectionPageHeaderV2 from "../../components/SectionPageHeaderV2";
import AddressAutocomplete from "../../components/AddressAutocomplete";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import Dropdown from "../../components/Dropdown";
import { useAccessToken } from "../../hooks/common";
import { Paths } from "../../constants/paths";
import BookingDateFilterPublic from "./Bookings/BookingDateFilter";
import { Spacing } from "../../components/v2/Styled/enum";
import Button from "../../components/Button";
import { Helmet } from "react-helmet-async";
import { SetLocationModal } from "../../components/Modals/MapModal/SetLocationModal";
import { stringifyParams } from "../../helpers/string";

interface BookingFilterState {
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  location?: any | null;
  serviceType?: number | null;
}

export default function PublicBookings() {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();
  const accessToken = useAccessToken();
  const query = new URLSearchParams(location.search);
  console.log("query>>>>", query.get("location"));
  useEffect(() => {
    // Find the existing robots meta tag or create a new one
    let metaTag = document.querySelector("meta[name=\"robots\"]");
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "robots");
      document.head.appendChild(metaTag);
    }

    // Set the content to index, follow
    metaTag.setAttribute("content", "index, follow");

    // Clean up if necessary (optional)
    return () => {
      if (metaTag) {
        metaTag.setAttribute("content", "noindex, nofollow");
      }
    };
  }, []);

  useEffect(() => {
    if (accessToken) {
      history.push(Paths.ProDashboardBookings);
    }
  }, [accessToken]);

  const BOOKING_SECTIONS = [
    {
      value: BOOKINGS_SECTION_VALUES.NEW,
      label: "New",
    },
    {
      value: BOOKINGS_SECTION_VALUES.UPCOMING,
      label: "Upcoming",
    },
    {
      value: BOOKINGS_SECTION_VALUES.PAST,
      label: "Past",
    },

    // Past and abandoned bookings are merged
    // {
    //   value: BOOKINGS_SECTION_VALUES.ABANDONED,
    //   label: "Abandoned",
    // },
  ];

  const perPage = 10;

  const [currentPage, setCurrentPage] = React.useState(0);

  const [showPagination, setShowPagination] = useState(true);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  let [filter, setFilter] = useState<BookingFilterState>({
    startDate: query ? query.get("startDate") : null,
    endDate: query ? query.get("endDate") : null,
    location: query ? query.get("location") && JSON.parse(query.get("location") as string) : null,
    // @ts-ignore
    serviceType: query ? typeof query.get("serviceType") === "string" && parseInt(query.get("serviceType") as string) : null,
  });
  console.log("filter>>", isEmpty(filter));
  const { services } = useServiceRates();
  const getServiceOptions = () => {
    if (checkIfEmpty(services)) return [];

    return services.map(({ alias, id }) => ({ value: id, title: alias }));
  };

  const serviceOptions = getServiceOptions();
  const _apiUrl = () => {
    const selectedSection = BOOKING_SECTIONS[0];

    if (selectedSection.value === BOOKINGS_SECTION_VALUES.NEW && filter.location) {
      return `/api/v2/therapists/bookings/public/new?filters=${JSON.stringify(filter)}&currentPage=${
        currentPage + 1
      }&perPage=${perPage}`;
    }

    return null;
  };

  const apiUrl = _apiUrl();
  console.log(apiUrl);
  const { data, mutate, revalidate, error: apiError } = useSWR(apiUrl);
  // fetch direct bookings

  const parsedApiError = apiError && parseApiError(apiError);

  useEffect(() => {
    setApiErrorMessage(parsedApiError);
  }, [parsedApiError]);

  const _items = () => {
    const selectedSection = BOOKING_SECTIONS[0];

    let sectionItems: any = [];
    sectionItems = (getValue(data, "jobs") || []) as Job[];
    sectionItems = sectionItems.filter((item: any) => !!item);
    return sectionItems;
  };

  const items = _items();

  const _pages = () => {
    const paginatedResponse = data && (data as PaginatedResponse2);

    if (paginatedResponse) {
      const { pageCount } = paginatedResponse;
      return pageCount;
    }

    return 0;
  };

  const pages = _pages();

  const noItems = !isNil(data) && isEmpty(items);

  const _placeholder = () => {
    const selectedSection = BOOKING_SECTIONS[0];
    switch (selectedSection.value) {
    case BOOKINGS_SECTION_VALUES.UPCOMING:
      return "No upcoming bookings";
    case BOOKINGS_SECTION_VALUES.NEW:
      return "No new bookings";
    }
    return null;
  };

  const placeholder = _placeholder();

  const handleJobOpen = ({ jobId }: { jobId: number }) => {
    history.push(`/public/bookings/${jobId}`);
  };
  const [fullAddress, setFullAddress] = useState<string | null | undefined>("");
  const [isMapSelected, setIsMapSelected] = useState(false);

  const selectedSection = BOOKING_SECTIONS[0];
  const handleFilterApplied = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };
  const onMapSelected = () => {
    setIsMapSelected(true);
  };

  const getSelectedServiceOption = () =>
    // @ts-ignore
    serviceOptions.find(({ value }) => value === filter.serviceType);

  const clearFilters = () => {
    setFullAddress("");
    setFilter({});
  };

  useEffect(() => {
    console.log("filter.location>>>>>", typeof filter.location);
    if (filter.location && typeof filter.location !== "string") {
      setFullAddress(filter?.location?.fullAddress);
      filter.location = JSON.stringify(filter.location);
    }
    history.push(`?${stringifyParams(filter)}`);
  }, [filter]);

  return (

    <Box>
      <Helmet>
        <title>{"New Bookings | Blys"}</title>
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta
          name="description"
          content="Easily view and manage your new bookings on Blys. Stay updated on upcoming appointments and streamline your schedule, all in one place."></meta>
      </Helmet>
      <SectionPageHeaderV2
        title="Bookings"></SectionPageHeaderV2>
      <Box ml={isMobile ? "16px" : "80px"} mr={isMobile ? "16px" : "80px"}>
        <Box>
          <Box display={"flex"} alignItems={!isMobile && "center"} flexDirection={isMobile ? "column" : "row"}
            gridGap={Spacing.S4}>
            <Box flexGrow={1}>
              <AddressAutocomplete
                locationType={null}
                value={fullAddress || ""}
                onChange={(text) => setFullAddress(text)}
                onSelectedAddress={(address) => {
                  setFullAddress(address.fullAddress);
                  handleFilterApplied("location", address);
                }}
                onMapSelected={() => {
                  setIsMapSelected(true);
                }}
              />
            </Box>
            <Box
              flex={1}
              mt={Spacing.S2}
            >
              <BookingDateFilterPublic
                startingDate={filter.startDate}
                endingDate={filter.endDate}
                handleDateSelected={handleFilterApplied}
              />
            </Box>

            <Box width={isMobile ? "100%" : "50%"} flex={1} mt={Spacing.S2}>
              <Dropdown
                title="Service type"
                options={serviceOptions}
                onSelectedOption={(event) => handleFilterApplied("serviceType", event.value)}
                selectedOption={getSelectedServiceOption()}
              />
            </Box>
            <Box flex={.5} mt={Spacing.S4}>
              <Button size={1} width={!isMobile ? "50%" : "100%"} title={"Clear"} onClick={clearFilters} />
            </Box>
          </Box>
        </Box>
        {apiErrorMessage && (
          <Box
            width="100%"
            height="70vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ErrorMessageView message={apiErrorMessage}></ErrorMessageView>
          </Box>
        )}

        {!apiErrorMessage && noItems && (
          <Box
            width="100%"
            height="70vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <NoItemsPlaceholder>{placeholder}</NoItemsPlaceholder>
          </Box>
        )}

        {!apiErrorMessage && !noItems && [BOOKINGS_SECTION_VALUES.NEW].includes(selectedSection.value) && (
          <Box>
            {items ? (
              <NewBookingsView
                jobs={items}
                directBookingJobs={[]}
                handleJobOpen={handleJobOpen}
                tab={BOOKINGS_SECTION_VALUES.NEW}
                showPagination={setShowPagination}
              />
            ) : (
              <React.Fragment />
            )}
          </Box>
        )}

        {pages > 0 && showPagination && (
          <Box display="flex" justifyContent="flex-start" mb="55px" width="100%">
            <PaginationControl
              currentPage={currentPage}
              pages={pages}
              onPrevious={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1);
                }
              }}
              onNext={() => {
                if (currentPage < pages - 1) {
                  setCurrentPage(currentPage + 1);
                }
              }}
            />
          </Box>
        )}
        <SetLocationModal
          open={isMapSelected}
          onClose={() => {
            setIsMapSelected(false);
          }}
          setIsMapSelected={setIsMapSelected}
          handleAddressSelected={({ address }) => {
            setFullAddress(address.fullAddress || "");
            handleFilterApplied("location", address);
          }}
        />
      </Box>
    </Box>
  );
}
