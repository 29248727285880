import React, { useEffect, useRef, useState } from "react";

import { Display, FontFamily, FontSize, FontWeight, Spacing } from "../v2/Styled/enum";
import { Box, Text } from "../v2/Styled";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";

import PreferencesTable from "./PreferencesTable";
import {
  useGetUserCommunicationPreferences,
  useIsCommunicationPreferenceUnsubbed,
} from "../../hooks/communicationPreferences/communication-preference.hook";
import { LoadingSpinner } from "../loadingSpinner";
import { useAlertStore } from "../../stores/alert";
import UnsubscribeModal from "./UnsubscribeModal";
import { getValue } from "../../utils/object";

const CommunicationPreferences = ({
  proDash,
  fromJourney,
}: {
  proDash?: boolean;
  fromJourney?: boolean;
}) => {
  let { data, isError, isLoading } = useGetUserCommunicationPreferences(proDash);

  let { data: unSubbedResponse } = useIsCommunicationPreferenceUnsubbed();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const isMobile = useMobile();
  const message = "Failed to load communication preferences";
  const { setErrorMessage } = useAlertStore();

  useEffect(() => {
    if (isError) {
      setErrorMessage(message);
    }
  }, [isError, setErrorMessage]);

  useEffect(() => {
    if (fromJourney && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [fromJourney]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || !data) {
    return null;
  }

  const handleModal = () => {
    if (isUnSubbed) return;
    setOpen(!open);
  };

  const checkIsUnsubbed = () => {
    const unSubscribed = getValue(unSubbedResponse, "unSubscribed") ?? false;
    return unSubscribed;
  };

  const isUnSubbed = checkIsUnsubbed();

  const getWrapperMarginX = ()=>{
    return proDash ? undefined:isMobile ? Spacing.S18 : Spacing.S20;
  };

  const metaData = getValue(data, "data.metaData", {});
  
  return (
    <Box display={Display.Block}>
      <Box
        marginY={Spacing.S10}
        display={Display.Block}
        marginX={getWrapperMarginX()}
      >
        <Text
          size={FontSize.F24}
          font={FontFamily.Museo}
          weight={FontWeight.Bold}
          color={Colors.NightBlue}
        >
          Communication preferences
        </Text>

        <Box paddingY={Spacing.S6} display={Display.Block}>
          <Text
            color={Colors.Dusk}
            size={FontSize.F16}
            font={FontFamily.Museo}
            weight={FontWeight.Medium}
          >
            Manage how you'd like to be notified about new bookings, reminders and more.{" "}
          </Text>
        </Box>
        <div style={{ display: "flex", flexDirection: "column", rowGap: "32px" }}>
          <PreferencesTable title="Activity" data={data.data.bookingActivity} metaData = {metaData}/>
          <PreferencesTable title="Marketing" data={data.data.marketing} isMarketing={true} metaData ={metaData}/>
          <div ref={messagesEndRef} />
        </div>
        <p
          style={{
            fontFamily: "Museo",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "21px",
            textAlign: "left",
            marginTop: "20px",
            color: isUnSubbed ? Colors.LightBlueGrey : Colors.Danger,
            cursor: "pointer",
          }}
          onClick={handleModal}
        >
          Unsubscribe from all
        </p>
      </Box>
      <UnsubscribeModal open={open} onClose={handleModal} proDash={!!proDash} />
    </Box>
  );
};

export default CommunicationPreferences;
