import React, { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Paths } from "../../constants/paths";
import { Booking } from "../../models";
import { Colors } from "../../constants/colors";
import { useBookingStore } from "../../stores/booking";
import { fetchAddresses } from "../../stores/address";
import { useAccessToken } from "../../hooks/common";
import { getLastBookings } from "../../services/bookings/bookings.service";
import { checkIsAUdomain } from "../../helpers/url";

export default function Landing() {
  const history = useHistory();
  const { setLastBooking } = useBookingStore();
  const accessToken = useAccessToken();

  useEffect(() => {
    fetchLastBookingAndRedirect();
  }, []);

  const fetchLastBookingAndRedirect = async () => {
    // redirect to other domain without the location url
    const auDomain = checkIsAUdomain();
    if (auDomain) return;

    if (accessToken) {
      const addresses = await fetchAddresses();
      getLastBookings({ accessToken })
        .then((response) => {
          const lastBooking = response.data as Booking;
          if (lastBooking) {
            setLastBooking(lastBooking);
            history.push(Paths.ReviewAndBook);
          } else {
            const newPath = addresses.length > 0 ? Paths.SavedLocations : Paths.LocationDetails;
            history.push(newPath);
          }
        })
        .catch((error) => {
          // Cannot fetch last booking, just go to new booking flow
          history.push(Paths.LocationDetails);
        });
    } else {
      history.push(Paths.LocationDetails);
    }
  };

  return (
    <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress style={{ color: Colors.NightBlue }} />
    </Box>
  );
}
