import React from "react";
import { Box } from "@material-ui/core";
import { FormStepsName } from "./Forms/model";
import Each from "../../../../components/Each";
import { useMobile } from "../../../../hooks/mobile";
import { Colors } from "../../../../constants/colors";
import * as Styled from "../../../../components/v2/Styled/enum";
import TextButton from "../../../../components/TextButton/TextButton";
import CircleExclamation from "../../../../components/Icons/CircleExclamation";

export type PayoutOnboardingSteps = {
  step: number;
  name: string;
  title: string;
  label?: string;
};

interface Props {
  steps: PayoutOnboardingSteps[];
  selectedStep: number;
  onSelect?: (n: number) => unknown;
  highlights?: FormStepsName[];
}

const SetupPayoutTimeline = ({ steps, selectedStep, onSelect, highlights }: Props): JSX.Element => {
  const isMobile = useMobile();

  const totalSteps = steps.length;
  const fraction = 1 / totalSteps;

  const getTitleColor = (step: number) => {
    const isCurrentStep = step === selectedStep;
    const isNextStep = step > selectedStep;

    if (isCurrentStep) return Colors.TurquoiseBlue;
    if (isNextStep) return Colors.Grey;

    return Colors.NightBlue;
  };

  const getTimelineColors = (step: number) => {
    const defaultColors = {
      left: Colors.TurquoiseBlue,
      right: Colors.LightPeriwinkle,
    };

    const isPreviousStep = step < selectedStep;
    const isNextStep = step > selectedStep;
    const isCurrentStep = step === selectedStep;

    if (isPreviousStep) {
      defaultColors.right = Colors.TurquoiseBlue;
    } else if (isNextStep) {
      defaultColors.left = Colors.LightPeriwinkle;
    } else if (isCurrentStep && isMobile) {
      defaultColors.right = Colors.TurquoiseBlue;
    }

    return defaultColors;
  };

  const buildRow = (step: PayoutOnboardingSteps) => {
    const { left, right } = getTimelineColors(step.step);
    const isSelected = step.step === selectedStep;
    const isLastStep = totalSteps === step.step;

    const showHighlight = (highlights || []).includes(step.name as FormStepsName);

    const timelineGradient = `linear-gradient(to right, ${left} 50%, ${
      isLastStep && !isMobile ? "transparent" : right
    } 50%) 1`;

    const progressBarStyle: React.CSSProperties = {
      borderBottom: "2px solid",
      borderImage: timelineGradient,
    };

    const activeIndicatorStyle: React.CSSProperties = {
      ...(isMobile
        ? { display: "none" }
        : { background: `radial-gradient(circle, ${Colors.Blue2} 40%, ${Colors.Athens} 40.1%)` }),
    };

    const handleStepSelect = (step: number) => {
      if (onSelect) {
        onSelect(step);
      }
    };

    return (
      <Box
        flex={fraction}
        position={"relative"}
        style={progressBarStyle}
        display={Styled.Display.Flex}
        paddingBottom={Styled.Spacing.S6}
        justifyContent={Styled.JustifyContent.center}
      >
        {isMobile ? (
          <></>
        ) : (
          <Box
            gridGap={Styled.Spacing.S2}
            display={Styled.Display.Flex}
            alignItems={Styled.AlignItems.center}
          >
            <Box
              width={Styled.Spacing.S5}
              height={Styled.Spacing.S5}
              alignItems={Styled.AlignItems.center}
              justifyContent={Styled.JustifyContent.center}
              display={showHighlight ? Styled.Display.Flex : Styled.Display.None}
            >
              <CircleExclamation />
            </Box>
            <TextButton
              text={step.title}
              onClick={() => handleStepSelect(step.step)}
              textStyle={{
                fontSize: Styled.FontSize.F14,
                color: getTitleColor(step.step),
                fontWeight: Styled.FontWeight.Bold,
                fontFamily: Styled.FontFamily.Museo,
              }}
            />
          </Box>
        )}
        {isSelected ? (
          <Box
            width={"24px"}
            height={"24px"}
            bottom={"-12px"}
            borderRadius={"50%"}
            position={"absolute"}
            style={activeIndicatorStyle}
          />
        ) : (
          <></>
        )}
      </Box>
    );
  };

  return (
    <Box display="flex" justifyContent={"space-between"} flex={1}>
      <Each of={steps} render={buildRow} />
    </Box>
  );
};

export default SetupPayoutTimeline;
