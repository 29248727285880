import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import "../../fonts/Museo.css";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { PaymentMethod } from "../../models";
import { useAlertStore } from "../../stores/alert";
import "../../styles/ApplePay.css";
import Dialog from "../Dialog";
import ModalHeader from "../ModalHeader";
import ModalHeaderApp from "../ModalHeaderApp";
import { useUserStore } from "../../stores/user";
import Snackbar, { SnackbarType } from "../Snackbar";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../../constants/common";
import PaymentFormStripe from "./PaymentFormStripe";
import { useStripeStore } from "../../stores/stripe";
import { checkAndGetUsersStripeCountry } from "../../services/countries/countries.services";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onAddedNewCard: (method: PaymentMethod) => unknown;
  cardOnly?: boolean;
  country: string;
}

export type StripeTypes = {
  clientSecret: string;
  appearance: {
    theme: "stripe";
    variables: {
      [key: string]: string;
    };
  };
};

export default function AddNewCardModelStripe({
  open,
  onClose,
  onAddedNewCard,
  country: userCountry,
}: Props): JSX.Element {
  const { clientSecret, createSetupIntent } = useStripeStore();
  const { setErrorMessage, errorMessage } = useAlertStore();
  const { user } = useUserStore();
  const [stripePromise, setStripePromise] = React.useState<Stripe | null>(null);

  const isMobile = useMobile();
  const accessToken = useAccessToken();

  const setStripeLoadPromise = () => {
    const stripeCountry = checkAndGetUsersStripeCountry(user, userCountry);
    const stripeKey = STRIPE_PUBLISHABLE_KEY(stripeCountry);

    loadStripe(stripeKey)
      .then((stripe) => {
        setStripePromise(stripe);
      })
      .catch(() => null);
  };

  useEffect(() => {
    setStripeLoadPromise();
  }, []);

  useEffect(() => {
    if (!clientSecret) {
      createSetupIntent();
    }
  }, [clientSecret]);

  const options: StripeTypes = {
    clientSecret: clientSecret as string,
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#0570de",
      },
    },
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullScreen={isMobile}
      onClose={onClose}
      disableEnforceFocus
    >
      <Box
        bgcolor="white"
        width={isMobile ? "100%" : "640px"}
        borderRadius="11px"
      >
        {isMobile ? (
          <ModalHeaderApp
            title="Connect a card"
            subtitle="Payment methods will be stored to streamline future bookings"
          />
        ) : (
          <ModalHeader
            title="Connect a card"
            subtitle="Payment methods will be stored to streamline future bookings"
            onClose={onClose}
          />
        )}

        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <PaymentFormStripe
              onAddedNewCard={onAddedNewCard}
              isMobile={isMobile}
              onClose={onClose}
              accessToken={accessToken}
            />
          </Elements>
        )}
      </Box>
      <Snackbar
        type={SnackbarType.error}
        message={errorMessage}
        open={errorMessage !== null}
        onClose={() => setErrorMessage(null)}
      />
    </Dialog>
  );
}
