/* eslint-disable indent */
import { Box } from "@material-ui/core";
import PaymentMethodIcon from "../../images/payement-method.svg";
import TextLink from "../TextLink";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../v2/Styled/enum";
import { Colors } from "../../constants/colors";
import React from "react";
import { usePaymentStore } from "../../stores/booking";
import { checkIfEmpty } from "../../utils/object";
import { PaymentMethod } from "../../models";
import ExpiryInfo from "./ExpiryInfo";
import { capitalizeFirstLetter } from "../../helpers/string";
import { getPaymentMethodDetails } from "../../helpers/payment";
import { PAYMENT_TYPE_LIST, PaymentType } from "../../constants/payment";
import Divider from "../../components/Divider";

interface Props {
  handleAddCard: () => void;
  showPaymentMethodChangeBtn: boolean | string;
  height?: number | "auto";
}

const SelectedPaymentMethodDetails = ({
  paymentType,
  paymentMethod,
}: {
  paymentType: string | undefined;
  paymentMethod: PaymentMethod | null;
}) => {
  if ((paymentType === PaymentType.card || paymentType === PaymentType.paypal) && !paymentMethod)
    return null;

  const paymentMethodDetails = getPaymentMethodDetails({
    paymentMethod: paymentMethod!,
    paymentType,
  });

  return (
    <Box>
      <Box display={Display.Flex} alignItems={AlignItems.center} gridGap={Spacing.S4}>
        <img
          src={paymentMethodDetails.icon}
          width={paymentMethodDetails.width}
          height={paymentMethodDetails.height}
          alt={paymentType}
        />

        {(paymentType === PaymentType.card || paymentType === PaymentType.paypal || paymentType === PaymentType.googlepay) &&
          paymentMethod && (
            <Box
              ml={1}
              display={Display.Flex}
              flex={1}
              flexDirection={FlexDirection.Column}
              alignItems={AlignItems.start}
            >
              <Box
                fontFamily={FontFamily.Museo}
                fontSize={FontSize.F16}
                fontWeight={FontWeight.Medium}
                color={Colors.Dusk}
              >
                {PaymentMethod.toString({
                  ...paymentMethod,
                  cardType: capitalizeFirstLetter(paymentMethod.cardType),
                })}
              </Box>
              {paymentMethod && (
                <ExpiryInfo
                  isExpired={paymentMethod.isExpired}
                  expiryYear={paymentMethod.expiryYear}
                  expiryMonth={paymentMethod.expiryMonth}
                  style={{
                    fontStyle: "italic",
                    fontWeight: FontWeight.SemiBold,
                  }}
                />
              )}
            </Box>
          )}

        {paymentType === PaymentType.payWithInvoice && (
          <Box
            fontFamily={FontFamily.Museo}
            fontSize={FontSize.F16}
            fontWeight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            Pay with invoice
          </Box>
        )}

        {paymentType === PaymentType.payWithNDISFunding && (
          <Box
            fontFamily={FontFamily.Museo}
            fontSize={FontSize.F16}
            fontWeight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            Pay with NDIS funding
          </Box>
        )}

        {paymentType === PaymentType.applepay && (
          <Box
            fontFamily={FontFamily.Museo}
            fontSize={FontSize.F16}
            fontWeight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            Pay with Apple Pay
          </Box>
        )}
      </Box>
    </Box>
  );
};

const BookingPaymentMethod: React.FC<Props> = ({
  handleAddCard,
  showPaymentMethodChangeBtn,
  height,
}) => {
  const { paymentType, paymentMethod } = usePaymentStore();
  const getDefaultText = () => {
    if (!showPaymentMethodChangeBtn && paymentType === PaymentType.default) {
      return "";
    }
    return "Add a payment method";
  };

  return (
    <Box
      display={Display.Flex}
      justifyContent={JustifyContent.spaceBetween}
      alignItems={AlignItems.center}
      height={height || 74}
    >
      {!PAYMENT_TYPE_LIST.includes(paymentType) ? (
        <Box>
          <Box mt={Spacing.S6}>
            <Divider />
          </Box>
          <Box display={Display.Flex} alignItems={AlignItems.center} gridGap={Spacing.S4}>
            <img src={PaymentMethodIcon} alt="Payment method" />
            <Box
              fontFamily={FontFamily.Museo}
              fontSize={FontSize.F16}
              fontWeight={FontWeight.Medium}
              color={Colors.Grey}
            >
              {getDefaultText()}
            </Box>
          </Box>
        </Box>
      ) : (
        <SelectedPaymentMethodDetails paymentMethod={paymentMethod} paymentType={paymentType} />
      )}

      {showPaymentMethodChangeBtn ? (
        <TextLink
          title={checkIfEmpty(paymentType) ? "Add" : "Change"}
          fontWeight={700}
          fontSize={"16"}
          onClick={handleAddCard}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default BookingPaymentMethod;
