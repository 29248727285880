import { Box } from "@material-ui/core";
import copy from "copy-to-clipboard";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import useSWR from "swr";
import Button from "../components/Button";
import TextLink from "../components/TextLink";
import Wrapper from "../components/Wrapper";
import { Colors } from "../constants/colors";
import { useAccessToken } from "../hooks/common";
import { useMobile } from "../hooks/mobile";
import useShareSheet from "../hooks/shareSheet";
import { InvitedFriend } from "../models";
import { useAlertStore } from "../stores/alert";
import { useUserStore } from "../stores/user";
import { Link, Text } from "../components/v2/Styled";
import { withVersion } from "../helpers/tracking";
import { formatToPrice } from "../helpers/number";
import { TrackingEvents } from "../constants/tracking";
import pixelsService from "../services/pixels/pixels.service";
import { trackEvent } from "../services/segment/track.service";
import SectionPageHeader from "../components/SectionPageHeader";
import { EmptyDataPlaceHolder } from "../components/EmptyDataPlaceholder/EmptyDataPlaceHolder";
import { AlignItems, Display, FlexDirection, FontFamily, FontSize, FontWeight, JustifyContent, LineHeight, Spacing, TextAlign } from "../components/v2/Styled/enum";



interface InvitedFriendDataRowProps {
  title: string;
  value: string;
}

function InvitedFriendDataRow({
  title,
  value,
}: InvitedFriendDataRowProps): JSX.Element {
  return (
    <Box
      mt="15px"
      mb="15px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box
        fontFamily={FontFamily.OpenSans}
        fontWeight={500}
        fontSize="15px"
        letterSpacing={0}
        color={Colors.Dusk}
      >
        {title}
      </Box>
      <Box
        fontFamily="Museo"
        fontWeight={500}
        fontSize="15px"
        letterSpacing={0}
        color={Colors.TurquoiseBlue}
        textAlign="right"
      >
        {value}
      </Box>
    </Box>
  );
}

interface InvitedFriendRowProps {
  invited: InvitedFriend;
  currency: string;
}

function InvitedFriendRow({ invited, currency }: InvitedFriendRowProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      fontFamily="Museo"
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={Colors.Dusk}
      height="55px"
      pl="24px"
      pr="24px"
    >
      <Box width="33%">{invited.fullName}</Box>
      <Box width="33%">{moment(invited.bookingDate).format("MMMM D, YYYY")}</Box>

      <Box width="33%">{formatToPrice(invited.amount, currency)}</Box>
    </Box>
  );
}

export default function InviteFriends(): JSX.Element {
  const isMobile = useMobile();
  const accessToken = useAccessToken();

  const { setSuccessMessage } = useAlertStore();

  const { user, fetchMe, credit, currency, fetchCreditBalance, fetchReferralDiscount, referralDiscount } = useUserStore();

  // console.debug("location: ", location);
  const { data: invitedFriendsData } = useSWR(
    `/users/me/referrals?accessToken=${accessToken}`
  );
  const invitedFriends = invitedFriendsData
    ? (invitedFriendsData.referrals as InvitedFriend[])
    : [];

  console.debug("invitedFriendsData: ", invitedFriendsData);

  React.useEffect(() => {
    fetchMe();
    fetchCreditBalance();
    pixelsService.trackPageView();
  }, []);

  React.useEffect(() => {
    fetchReferralDiscount();
  }, [user]);

  const shareSheet = useShareSheet();

  const handleRefferalCodeCopy = async () => {
    try {
      copy(user?.referralCode);
      setSuccessMessage(
        "Your code has been copied to your clipboard!"
      );
      trackEvent(
        TrackingEvents.ReferralCodeCopied,
        withVersion()
      );
    } catch (error) {
      setSuccessMessage("Failed to copy the refferal code. Please try again.");
    }
  };

  const emptyDataSubTitle = () => {
    return (
      <Box>
        {user?.referralCode ? (
          <Box
            width="360px"
            textAlign={TextAlign.center}
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.center}
          >
            <Link onClick={handleRefferalCodeCopy} font={FontFamily.OpenSans} fontColor={Colors.TurquoiseBlue} size={FontSize.F14}>Copy code {user?.referralCode} </Link>
            <Text font={FontFamily.OpenSans} color={Colors.Dusk} size={FontSize.F14}>  and share it with a friend. Once they use it, the credit earned will appear here.</Text>
          </Box>
        ) : <></>}
      </Box >



    );
  };

  return (
    <>
      <Wrapper
        header={
          <SectionPageHeader
            title="Invite friends"
            subtitle={`Give ${currency || "$"}${referralDiscount} to a friend and receive ${currency || "$"
            }${referralDiscount} yourself`}
          />
        }
      >
        <Box
          mt="43px"
          ml={isMobile ? "24px" : "80px"}
          mr={isMobile ? "24px" : "80px"}
        >
          {/* <Box
            fontFamily="Museo"
            fontSize="17px"
            color={Colors.NightBlue}
            flexDirection="row"
            display="flex"
            mt={5}
            mb={2}
          >
            Total Credits:{" "}
            <Box fontWeight={700} ml={0.5}>
              ${credit}
            </Box>
          </Box> */}
          {user?.referralCode && (
            <Box
              width={isMobile ? "100%" : "500px"}
              height="90px"
              bgcolor="#ffffff"
              borderRadius="6px"
              border={`solid 1px ${Colors.VeryLightPink}`}
            >
              <Box paddingLeft={"24px"} paddingTop={"16px"} paddingRight={"24px"}>
                <Box
                  fontFamily="Museo"
                  fontWeight={700}
                  fontSize="14px"
                  color={Colors.Dusk}
                >
                  Code
                </Box>
                <Box height="8px" />
                <Box display="flex" flexDirection="row">
                  <Box
                    fontFamily="Museo"
                    fontWeight={500}
                    fontSize="24px"
                    color={Colors.NightBlue}
                    flex={1}
                  >
                    {user?.referralCode}
                  </Box>
                  <Box marginTop="6px">
                    <TextLink
                      title="Copy code"
                      onClick={handleRefferalCodeCopy}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {shareSheet.isSupported && user?.referralCode && (
            <Box mt={3} maxWidth="134px" >
              <Button
                title="Tell friends"
                onClick={() => {
                  shareSheet.share({
                    title: `Here's ${currency || "$"}${referralDiscount} off your first Blys booking!`,
                    text: `Use my code ${user?.referralCode} to get FREE ${currency || "$"
                    }${referralDiscount} credit for Blys, the #1 app for mobile massage, beauty and more! Sign up or book with my code at https://getblys.com.au`,
                    url: "https://getblys.com.au",
                  });
                }}
              />
            </Box>
          )}

          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="24px"
            color={Colors.NightBlue}
            mt={5}
          >
            Share the code
          </Box>

          <Box
            fontFamily={FontFamily.Museo}
            fontSize="16px"
            color={Colors.Dusk}
            mt={2}
          >
            It’s simple. Share the code above with a friend and when they make their first booking,
            they get {currency || "$"}{referralDiscount} off and you receive {currency || "$"}{referralDiscount} Blys credit.
          </Box>

          {!isMobile ? (
            <>
              <Box
                borderRadius="8px"
                border="1px solid #dcdfe6"
                bgcolor="white"
                maxWidth="100%"
                marginTop={Spacing.S6}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  fontFamily={FontFamily.OpenSans}
                  fontSize={FontSize.F14}
                  lineHeight={LineHeight.L18}
                  fontWeight={FontWeight.Medium}
                  color={Colors.BlueyGrey}
                  height="55px"
                  pl="24px"
                  pr="24px"
                >
                  <Box width="33%">Friend referred</Box>
                  <Box width="33%">Booking date</Box>
                  <Box width="33%">Credit you earned</Box>
                </Box>
                {
                  !isEmpty(invitedFriends) ? (
                    invitedFriends.map((invited) => (
                      <>
                        <Box width="100%" bgcolor={Colors.LightPeriwinkle} height="1px" />
                        <InvitedFriendRow invited={invited} currency={currency} />
                      </>
                    ))
                  ) : (
                    <>
                      <Box borderTop="1px solid #dcdfe6">
                        <EmptyDataPlaceHolder title={"No referral yet"} subtitle={emptyDataSubTitle} />
                      </Box>
                    </>
                  )
                }
              </Box>
            </>
          ) : <></>}
          {isMobile && (
            <Box mt={4} display="flex" justifyContent={JustifyContent.center}>
              {!isEmpty(invitedFriends) ? <> {invitedFriends.map((invited) => (
                <Box
                  pl="10px"
                  pr="10px"
                  borderRadius="6px"
                  border={`solid 1px ${Colors.LightPeriwinkle}`}
                  maxWidth="450px"
                  mt="12px"
                  mb="12px"
                >
                  <InvitedFriendDataRow
                    title="Friend Referred"
                    value={invited.fullName}
                  />
                  <Box
                    width="100%"
                    bgcolor={Colors.LightPeriwinkle}
                    height="1px"
                  />
                  <InvitedFriendDataRow
                    title="Booking Date"
                    value={moment(invited.bookingDate).format("MMMM D, YYYY")}
                  />
                  <Box
                    width="100%"
                    bgcolor={Colors.LightPeriwinkle}
                    height="1px"
                  />
                  <InvitedFriendDataRow
                    title="Credits You Earned"
                    value={`$${formatToPrice(invited.amount)}`}
                  />
                </Box>
              ))}</> :

                <Box width="300px">
                  <EmptyDataPlaceHolder title={"No referral yet"} subtitle={emptyDataSubTitle} />
                </Box>
              }
            </Box>
          )}
        </Box>
      </Wrapper >
    </>
  );
}
