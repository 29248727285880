import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LandingWrapper from "../../components/LandingWrapper";
import LogoLink from "../../components/LogoLink";
import { Paths } from "../../constants/paths";
import { useMobile } from "../../hooks/mobile";
import LoginComponent from "./LoginComponent";
import pixelsService from "../../services/pixels/pixels.service";

interface EmailInterface {
  email: string | null;
}

export default function Login(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();
  const emailFromState = location.state as EmailInterface;

  useEffect(() => {
    pixelsService.trackPageView();
  }, []);
  

  return (
    <LandingWrapper>
      <Box width={isMobile ? undefined : "400px"}>
        <LogoLink />

        <LoginComponent
          onCreateAccount={() => history.push("/signup")}
          onForgotPassword={(email) =>
            history.push("/forgot-password", {
              email,
            })
          }
          onLoggedIn={(user) => {
            if (user.therapistprofile) {
              history.push(Paths.ProDashboardProfile);
            } else {
              history.push(Paths.Bookings);
            }
          }}
          email={emailFromState?.email || null}
        />
      </Box>
    </LandingWrapper>
  );
}
