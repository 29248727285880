import React from "react";
import { Box } from "@material-ui/core";
import { IconSwitch } from "@get-blys/ui.icon-switch";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../v2/Styled/enum";
import { Colors } from "../../constants/colors";
import "./../switch.css";
import TickIcon from "./../../images/toggle-tick-icon.png";
interface Props {
  title: string;
  subTitle: string;
  maxWidth?: string | undefined;
  onToggle: (isToggled: boolean) => unknown;
  isToggled: boolean;
  ml?: number;
}
export const ToggleSection = ({
  title,
  subTitle,
  onToggle,
  isToggled,
  maxWidth,
  ml,
}: Props) => {
  return (
    <Box>
      <Box display={Display.Flex} flexDirection={FlexDirection.Row}>
        <Box flex={1} paddingRight={Spacing.S5}>
          <Box
            fontWeight={FontWeight.Medium}
            fontFamily={FontFamily.Museo}
            fontSize={FontSize.F16}
            lineHeight={"21px"}
            color={Colors.Dusk}
          >
            {title}
          </Box>
          <Box
            fontFamily={FontFamily.SFPro}
            fontSize={FontSize.F12}
            fontWeight={FontWeight.Regular}
            color={Colors.Grey}
            lineHeight="15.6px"
            marginTop={Spacing.S1}
            maxWidth={maxWidth ? maxWidth : "undefined"}
          >
            {subTitle}
          </Box>
        </Box>
        <Box ml={ml ? ml : 0} display={Display.Flex} alignItems={AlignItems.center}>
          <IconSwitch
            onChange={() => onToggle(!isToggled)}
            value={isToggled}
            activeStateImage={TickIcon}
          />
        </Box>
      </Box>
    </Box>
  );
};
