import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import logo from "../images/blys-logo.svg";
import { Colors } from "../constants/colors";
import Button, { ButtonType } from "../components/Button";
import { Paths } from "../constants/paths";
import LandingWrapper from "../components/LandingWrapper";
import TextLink from "../components/TextLink";
import LogoLink from "../components/LogoLink";
import { fetchCountryCode } from "../utils/country";
import pixelsService from "../services/pixels/pixels.service";

export default function Landing(): JSX.Element {
  const history = useHistory();
  
  useEffect(() => {
    fetchCountryCode();
    pixelsService.trackPageView();
  }, []);
  

  return (
    <LandingWrapper>
      <LogoLink />
      <Typography
        variant="h1"
        style={{ color: Colors.NightBlue, marginTop: "68px" }}
      >
        Wellness,
        <br /> delivered.
      </Typography>
      <Typography
        variant="h3"
        style={{ color: Colors.Dusk, marginTop: "30px" }}
      >
        Professional. Friendly. On Time.
        <br />
        Book massage, beauty, fitness.
      </Typography>
      <Box mt={6}>
        {/* <Button title="Login" onClick={() => history.push("/login")} />
        <Box height="9px" /> */}
        <Button
          title="Book your session"
          type={ButtonType.secondary}
          onClick={() => history.push(Paths.LocationDetails)}
        />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          flexDirection="row"
          fontFamily="Museo"
          fontWeight={500}
          fontSize="16px"
          color={Colors.BlueyGrey}
          mt="29px"
          mb="100px"
        >
          Already have an account?&nbsp;
          <TextLink
            title="Log in"
            onClick={() => history.push("/login")}
            color={Colors.Dusk}
          />
        </Box>
      </Box>

      {/* <Box
        fontFamily="Museo"
        fontWeight={700}
        color={Colors.Dusk}
        fontSize="16px"
        mt="23px"
      >
        Account creation
      </Box>
      <Box
        fontFamily="Open Sans"
        fontSize="14px"
        color={Colors.BlueyGrey}
        mt="12px"
      >
        The booking process is also our ‘create account’ process so once your
        booking is complete you can manage your booking with ease using your
        newly created account
      </Box> */}
    </LandingWrapper>
  );
}
