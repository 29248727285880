import {
  Box,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog as MUIDialog,
  PaperProps,
  IconButton,
} from "@material-ui/core";
import styles from "./styles";
import Divider from "../../Divider";
import CloseIcon from "../../../images/modal-close.svg";

interface ContentModalInterface {
  children?: any;
  title?: string;
  actions: any[];
  visible: boolean;
  titleDivider?: boolean;
  divider?: boolean;
  description?: string;
  titleStyle?: object;
  textStyle?: object;
  actionStyle?: object;
  childrenStyle?: object;
  descriptionStyle?: object;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
  fullWidth?: boolean;
  fullScreen?: boolean;
  onClose: () => unknown;
  PaperProps?: Partial<PaperProps>;
  fixButtonsWithDivider?: boolean;
  showCrossIcon?: boolean;
  crossIcon?: any;
  contentStyle?: object;
  closeIconStyle?: object;
  closeWrapperStyle?: object;
}

const ContentModal = ({
  title,
  description,
  actions,
  visible,
  onClose,
  maxWidth = "xs",
  fullWidth = false,
  fullScreen = false,
  divider = true,
  titleStyle = {},
  textStyle = {},
  descriptionStyle = {},
  actionStyle = {},
  children,
  childrenStyle = {},
  PaperProps = {},
  fixButtonsWithDivider = false,
  showCrossIcon = false,
  crossIcon,
  contentStyle = {},
  closeIconStyle = {},
  closeWrapperStyle = {},
  titleDivider = true,
}: ContentModalInterface) => {
  const getCloseIcon = () => crossIcon || CloseIcon;
  return (
    <MUIDialog
      open={visible}
      maxWidth={maxWidth}
      onClose={onClose}
      PaperProps={PaperProps}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
    >
      {title ? (
        <Box display="flex" flexDirection="column" position="relative">
          {showCrossIcon && (
            <Box position="absolute" top="10px" left="10px">
              <IconButton onClick={onClose} style={{ ...closeWrapperStyle }}>
                <img src={getCloseIcon()} alt="closeIcon" style={{ ...closeIconStyle }} />
              </IconButton>
            </Box>
          )}

          <DialogTitle style={{ flex: 1 }}>
            <Typography>
              <Box sx={{ ...styles.modalTitle, ...titleStyle }}>{title}</Box>
            </Typography>
          </DialogTitle>
          {divider && <Divider />}

        </Box>
      ) : (
        <></>
      )}
      <DialogContent
        dividers={divider}
        style={{
          maxHeight: "calc(100vh - 200px)",
          overflowY: "auto",
          ...contentStyle,
        }}
      >
        <Box sx={{ ...styles.infoDescriptionWrapper, ...descriptionStyle }}>
          {description ? (
            <Typography style={{ ...styles.infoModalDescription, ...textStyle }}>
              {" "}
              {description}{" "}
            </Typography>
          ) : (
            <Box />
          )}
          {children ? <Box style={{ marginTop: 12, ...childrenStyle }}>{children}</Box> : <Box />}
        </Box>
      </DialogContent>
      <Box flexDirection={"column"}>
        {fixButtonsWithDivider && <Divider width="100%" height="2px" />}
        <DialogActions
          className="infoModalFooter"
          style={{ padding: "16px 24px 16px 24px", ...actionStyle }}
        >
          {actions.map((action) => action)}
        </DialogActions>
      </Box>
    </MUIDialog>
  );
};

export default ContentModal;
