export const CompletedModalType = {
  RATE_AND_REVIEW: "rate-review-client",
  ADD_TREATMENT_NOTE: "add-treatment-notes",
  BLOCK_CLIENT: "block-client",
  REPORT_AN_INCIDENT: "report-incident",
};

export const CompletedActionsOptions = [
  { title: "Rate & review the client", value: CompletedModalType.RATE_AND_REVIEW, description: "Your review will be visible to the client and other providers they book with." },
  { title: "Add client treatment notes", value: CompletedModalType.ADD_TREATMENT_NOTE, description: "These notes will be visible to the client and any future providers they book." },
  { title: "Block client", value: CompletedModalType.BLOCK_CLIENT, description: "Your note for blocking will remain confidential within Blys." },
  { title: "Report an incident", value: CompletedModalType.REPORT_AN_INCIDENT, description: "This will compose an email to hello@getblys.com to report the incident." },
];