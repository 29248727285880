import { Box } from "@material-ui/core";
import { get, isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AlertModal from "../../components/AlertModal";
import GeneralFlow from "../../components/Bookings/General";
import HairAndMakeupFlow from "../../components/Bookings/HairAndMakeup";
import { CorporateFlow } from "../../components/Bookings/V2/Corporate";
import Button, { ButtonType } from "../../components/Button";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import GenderFallbackInfoModal from "../../components/GenderFallbackInfoModal/GenderFallbackInfoModal";
import MassageTypeInfoModal, {
  MassageTypeInfoModalType,
} from "../../components/MassageTypeInfoModal";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import SessionTypeInfoModal from "../../components/SessionTypeInfoModal";
import {
  CORPORATE_CLIENTS_NUM,
  GenderPreference,
  STEP_PROGRESS,
  TreatmentFor,
} from "../../constants/booking";
import { Paths } from "../../constants/paths";
import { QUESTION_TYPE } from "../../constants/questionType";
import { BOOKING_SESSION_TYPE } from "../../helpers/booking";
import { parseApiError } from "../../helpers/error";
import { capitalizeFirstLetter } from "../../helpers/string";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { useQuestions } from "../../hooks/question.hooks";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import * as segmentTrackService from "../../services/segment/track.service";
import { useAddressStore } from "../../stores/address";
import { useAlertStore } from "../../stores/alert";
import {
  generateHandleNext,
  getBookingQuestions,
  getMassageService,
  getMassageTypes,
  getProductsForSegmentTracking,
  getSubtotal,
  getTotal,
  getTreatmentDataForService,
  getTreatmentFor,
  getTreatmentId,
  isBackToBackMassageType,
  isCorporateTreatmentType,
  isCoupleMassageType,
  isHairAndMakeupBooking,
  isMassageType,
  removeBackupDateAndTime,
  resetAnswers,
  resetAnswersNotService,
  resetTreatmentAddOn,
  setMassageTreatments,
  setServiceRates,
  updateBooking,
  useBookingStore,
  useDateTimeStore,
  useLocationDetailsStore,
  usePaymentStore,
  useServicesStore,
} from "../../stores/booking";
import { getValue } from "../../utils/object";
import MassageDetail from "./MassageDetail";
import pixelsService from "../../services/pixels/pixels.service";

export default function Booking(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const { state } = useLocation();
  const accessToken = useAccessToken();

  const {
    serviceId,
    setServiceId,
    treatmentId,
    setTreatmentId,
    numOfRecipients,
    treatmentDetails,
    setTreatmentDetails,
    resetNumOfRecipients,
    resetTreatmentLength,
    resetTreatmentDetails,
    sessionType,
    setSessionType,
    massageLength,
    massageLength2,
    massageType1,
    genderPreference1,
    massageType2,
    genderPreference2,
    updatingForBooking,
    recipient,
    address,
    setMassageType1,
    setMassageType2,
    resetMassageTreatmentDetails,
    couplesFallback,
    setCurrency,
    couplesFallbackGenderPreference,
    setMassageLength,

    //for corporate booking
    numberOfPerson,
    durationPerPerson,
    numberOfPros,
    eventType,
    massageTreatmentDetails,
  } = useBookingStore();

  const { latestStartInMinutes, setEarliestStartInMinutes } = useDateTimeStore();

  const { countryCode } = useLocationDetailsStore();
  const { isLoading: isServicesLoading, services: rates } = useServiceRates(countryCode || "AU");

  const { answers } = useServicesStore();
  const { addresses } = useAddressStore();

  useEffect(() => {
    if (!updatingForBooking && !serviceId && rates && rates[0]) {
      setServiceId(rates[0].id);
    }
    if (rates && rates[0]) {
      const { selectedCountry } = rates[0];
      const currency = getValue(selectedCountry, "currency", "AUD");
      const currencySymbol = getValue(selectedCountry, "currencySymbol", "A$");
      setCurrency(currency, currencySymbol);
    }
  }, [rates, serviceId, setServiceId, updatingForBooking]);

  const isCorporateType = isCorporateTreatmentType();
  const isMassage = isMassageType();
  const isBackToBackMassage = isBackToBackMassageType(sessionType);
  const isCouplesMassage = isCoupleMassageType(sessionType);
  const allMassageTypes = Object.keys(getMassageTypes());

  const { bookingPrice } = usePaymentStore();

  const { setErrorMessage } = useAlertStore();

  const [saving, setSaving] = React.useState(false);

  const [showGenderFallback, setShowGenderFallback] = React.useState(false);
  const [genderFallbackMessage, setGenderFallbackMessage] = React.useState("");

  const [infoModal, setInfoModal] = React.useState<any>({
    [MassageTypeInfoModalType.massageType1]: false,
    [MassageTypeInfoModalType.massageType2]: false,
    sessionType: false,
    genderFallback: false,
  });

  let questTreatmentDetails = treatmentDetails;
  if (isMassage) {
    questTreatmentDetails = massageTreatmentDetails;
  }
  const questions = useQuestions(serviceId as number, questTreatmentDetails);

  const onInfoModalChange = (type: any, value: boolean) => {
    setInfoModal({ [type]: value });
  };

  const serviceTypeOptions = React.useMemo(() => {
    return rates.map((rate) => {
      return {
        value: rate.id,
        title: capitalizeFirstLetter(rate.alias || rate.name),
      };
    });
  }, [rates]);

  const treatmentData = getTreatmentDataForService(serviceId);

  const verifyFields = () => {
    if (isMassage && !isCorporateType) {
      if (isNil(sessionType)) {
        setErrorMessage("Please select session type");
        return false;
      }

      if (isNil(massageLength)) {
        setErrorMessage("Please select massage length");
        return false;
      }

      if (isNil(massageType1) || !allMassageTypes.includes(massageType1)) {
        setErrorMessage("Please select massage type");
        return false;
      }

      if (isNil(genderPreference1)) {
        setErrorMessage("Please select your gender preference");
        return false;
      }

      if (isCouplesMassage || isBackToBackMassage) {
        if (isNil(massageType2) || !allMassageTypes.includes(massageType2)) {
          setErrorMessage("Please select massage type");
          return false;
        }

        if (isNil(massageLength2)) {
          setErrorMessage("Please select massage duration");
          return false;
        }

        if (isCouplesMassage) {
          if (isNil(genderPreference2)) {
            setErrorMessage("Please select your gender preference");
            return false;
          }
          if (couplesFallback && !couplesFallbackGenderPreference) {
            setErrorMessage("Please select your preferred therapist gender");
            return false;
          }
        }
      }
    } else if (isCorporateType) {
      if (isNil(numberOfPerson)) {
        setErrorMessage("Please enter number of people");
        return false;
      }
      if (isNil(genderPreference1)) {
        setErrorMessage("Please select your gender preference");
        return false;
      }
      if (
        numberOfPerson < CORPORATE_CLIENTS_NUM.min ||
        numberOfPerson > CORPORATE_CLIENTS_NUM.max
      ) {
        setErrorMessage(
          `Number of clients must be in range ${CORPORATE_CLIENTS_NUM.min} to ${CORPORATE_CLIENTS_NUM.max}`
        );
        return false;
      }
      if (isNil(durationPerPerson)) {
        setErrorMessage("Please select duration per person");
        return false;
      }
      if (isNil(numberOfPros)) {
        setErrorMessage("Please select number of providers");
        return false;
      }
      if (isNil(eventType)) {
        setErrorMessage("Please select event type");
        return false;
      }
    } else {
      if (isHairAndMakeupBooking()) {
        let valid = true;
        for (let i = 0; i < treatmentDetails.length; i++) {
          const td = treatmentDetails[i];
          if (!td.treatmentId || !td.treatmentFor) {
            setErrorMessage("Please complete the form before continue");
            valid = false;
            break;
          }
          const bookingQuestionVerified = verifyBookingAnswers(i);
          if (!bookingQuestionVerified) return false;
        }
        return valid;
      } else {
        // Other services
        if (isNil(treatmentId)) {
          setErrorMessage("Please select your treatment type");
          return false;
        }

        const bookingQuestionVerified = verifyBookingAnswers(0);
        if (!bookingQuestionVerified) return false;
      }
    }

    return true;
  };

  const verifyBookingAnswers = (index: number) => {
    try {
      let verification = true;
      answers[index] &&
        answers[index].forEach((bookingAnswer: any) => {
          const question = questions.find((question) => question.id === bookingAnswer.questionId);
          if (
            (bookingAnswer.type === QUESTION_TYPE.SELECT ||
              bookingAnswer.type === QUESTION_TYPE.DURATION) &&
            question?.required &&
            !bookingAnswer.answer
          ) {
            setErrorMessage(`Please select answer for ${question?.question}`);
            verification = false;
          }
          if (
            bookingAnswer.type === QUESTION_TYPE.IMAGE &&
            question?.required &&
            !bookingAnswer.answers?.length
          ) {
            setErrorMessage(`Please add image for ${question?.question}`);
            verification = false;
          }
        });
      return verification;
    } catch (e) {}
  };

  // get rates, treatment & questions for new bookings
  useEffect(() => {
    // getServicesRates();
    getTreatmentFor();
    getBookingQuestions();
    pixelsService.trackPageView();
  }, []);

  useEffect(() => {
    if (!isServicesLoading) {
      setServiceRates(rates);
    }
  }, [rates]);

  const checkIfGenderFallbackOptionsRequired = () => {
    const validSessionAndService = !isCouplesMassage && isMassage;
    const validGenderSelection =
      genderPreference1 === GenderPreference.femaleOnly ||
      genderPreference1 === GenderPreference.maleOnly;

    if (validGenderSelection && validSessionAndService) {
      setShowGenderFallback(true);
      let bookGender = "";
      let unavailableGender = "";
      if (genderPreference1 === GenderPreference.femaleOnly) {
        bookGender = "male";
        unavailableGender = "female";
      } else if (genderPreference1 === GenderPreference.maleOnly) {
        bookGender = "female";
        unavailableGender = "male";
      }

      const genderFallbackMessage = `Book ${bookGender} if ${unavailableGender} is unavailable`;
      setGenderFallbackMessage(genderFallbackMessage);
    } else {
      setShowGenderFallback(false);
    }
  };

  useEffect(() => {
    checkIfGenderFallbackOptionsRequired();
  }, [genderPreference1, sessionType, serviceId]);

  const ERROR_MESSAGES = {
    corporate:
      "To change the service type to corporate, please cancel this booking and create a new one with your preferred service type.",
    fromCorporate:
      "To change the service type of an corporate booking, please cancel this booking and create a new one with your preferred service type.",
    couples:
      "To change the service type for a couples booking, please cancel this booking and create a new one with your preferred service type.",
  };

  const [serviceTypeErrorMessage, setServiceTypeErrorMessage] = useState("");
  const [serviceTypeErrorModalOpen, setServiceTypeErrorModalOpen] = React.useState(false);

  const analyticsValues = () => {
    return {
      subtotal: getSubtotal(),
      total: getTotal(),
      currency: "AUD",
      affiliation: "web",
      product_id: getTreatmentId(),
      session_type: isMassage ? sessionType : undefined,
      recipient_role: recipient?.relationship,
      recipient_gender: recipient?.gender,
      peak_hour: bookingPrice?.lateNightSurcharge,
      location_type: address?.type,
      location_parking: address?.parking,
      location_stairs: address?.stairs,
      version: 2,
      products: getProductsForSegmentTracking(),
    };
  };

  // analytics
  useEffect(() => {
    setTimeout(() => {
      // const values = analyticsValues();
      // segmentTrackService.trackEvent("Checkout Started", values);
      segmentTrackService.trackEvent("New Booking Step Viewed", {
        step: 2,
        version: 2,
      });
    }, 2000);
  }, []);

  const _onTreatmentIdChanged = (i: number, value: any) => {
    resetTreatmentAddOn({ treatmentIndex: i, treatmentId: value });
    setTreatmentId(value);
    const newTreatmentDetails = [...treatmentDetails];
 
    if (!newTreatmentDetails[i]) return;

    newTreatmentDetails[i].treatmentId = value;
    newTreatmentDetails[i].treatmentTypeId = value;
    setTreatmentDetails(newTreatmentDetails);
    resetAnswersNotService(serviceId as number, value, i);
  };

  // remove backup date and time if present
  // reset latestTime to earliestTime
  const onCorporateServiceSelected = (treatment: any) => {
    setTreatmentId(treatment?.id);
    removeBackupDateAndTime();
    if (latestStartInMinutes) {
      setEarliestStartInMinutes(latestStartInMinutes);
    }
  };

  const _onServiceIdChange = (serviceId: number) => {
    setServiceId(serviceId);
    resetTreatmentLength();
    const isMassage = isMassageType(serviceId);
    if (isMassage) {
      const { prices: serviceMassageTypes = [] } = getMassageService();

      useBookingStore.setState({
        addOn: [],
      });
      useServicesStore.setState({
        answers: [],
        // questions: [],
      });
      resetNumOfRecipients();

      const treatment = serviceMassageTypes?.[0];

      if (treatment?.isCorporate) {
        onCorporateServiceSelected(treatment);
      }
      if (massageLength) {
        setMassageType1(treatment?.name);
        setMassageTreatments(
          {
            duration: massageLength,
            treatmentTypeId: treatment?.id,
            isMultipleAddonsAllowed: treatment?.isMultipleAddonsAllowed,
          },
          0
        );
      } else if (massageLength2) {
        setMassageType2(treatment?.name);
        setMassageTreatments(
          {
            duration: massageLength2,
            treatmentTypeId: treatment?.id,
            isMultipleAddonsAllowed: treatment?.isMultipleAddonsAllowed,
          },
          1
        );
      } else {
        resetMassageTreatmentDetails();
      }
      if (treatment?.id) resetAnswers(serviceId as number, [treatment.id]);
      return;
    } else {
      setSessionType(BOOKING_SESSION_TYPE.SINGLES);
    }

    const treatmentData = getTreatmentDataForService(serviceId);

    let treatDetails = [];
    // set treatment id
    if (treatmentData[0] && treatmentData[0].id) {
      const treatmentId = treatmentData[0].id;
      resetTreatmentAddOn({ treatmentIndex: 0, treatmentId });
      setTreatmentId(treatmentId);

      // set single treatment detail for hair and makeup booking
      const treatmentDetail = { ...treatmentDetails[0] };
      const treatments = [...Array(!isHairAndMakeupBooking() ? 1 : numOfRecipients || 1)].map(
        () => {
          const newTreatmentDetail = { ...treatmentDetail, treatmentId };
          if (isHairAndMakeupBooking()) {
            newTreatmentDetail.treatmentFor = treatmentDetail.treatmentFor || TreatmentFor.myself;
          }
          return newTreatmentDetail;
        }
      );

      setTreatmentDetails(treatments);
      treatDetails = treatments;
    }

    // set default answers
    const treatmentIds = treatDetails.map((treatment) => treatment.treatmentId);
    resetAnswers(serviceId as number, treatmentIds);
  };

  const _handleContinue = () => {
    setTimeout(() => {
      const treatmentId = getTreatmentId();
      const products = getProductsForSegmentTracking();
      segmentTrackService.trackNewBookingStep(
        isMassage ? serviceId : null,
        sessionType,
        treatmentId,
        products
      );
    }, 2000);

    if (!verifyFields()) {
      return;
    }

    if (updatingForBooking) {
      // Update...
      setSaving(true);

      updateBooking()
        .then(() => {
          setSaving(false);

          history.replace(`/bookings/${updatingForBooking.id}`);
        })
        .catch((error) => {
          setSaving(false);
          setErrorMessage(parseApiError(error));
        });
    } else {
      const next = get(state, "next");
      if (next) {
        history.replace(next, { preFetchPaymentMethod: false });
      } else if (!isEmpty(accessToken)) {
        history.replace(Paths.SavedRecipients);
      } else {
        history.replace(Paths.RecipientDetails);
      }
    }
  };

  const onServiceSelected = (option: DropdownOption) => {
    if (!updatingForBooking) {
      _onServiceIdChange(option.value);
      return;
    }
    const { rates } = useServicesStore.getState();

    // disable service change for corporate
    const currentService = rates.find((rate) => rate.id === serviceId);
    const newService = rates.find((rate) => rate.id === option.value);

    const isCorporate = getValue(currentService, "prices[0].isCorporate");
    const isNewCorporate = getValue(newService, "prices[0].isCorporate");

    if (isCorporate || isNewCorporate) {
      setServiceTypeErrorMessage(
        isCorporate ? ERROR_MESSAGES.fromCorporate : ERROR_MESSAGES.corporate
      );
      setServiceTypeErrorModalOpen(true);
      return;
    }

    if (sessionType === BOOKING_SESSION_TYPE.COUPLES) {
      setServiceTypeErrorMessage(ERROR_MESSAGES.couples);
      setServiceTypeErrorModalOpen(true);
      return;
    }

    _onServiceIdChange(option.value);
  };

  const handleNextObject = generateHandleNext(_handleContinue);

  return (
    <>
      <NewBookingWrapper
        overline="Step 2 of 7"
        title="Booking details"
        // subtitle="No cancellation fee within 24 hrs of your booking"
        footerLeftButton={
          <Button
            type={ButtonType.outlined}
            title={updatingForBooking ? "Back" : "Previous"}
            onClick={() => {
              if (!isEmpty(addresses)) {
                history.replace(Paths.SavedLocations);
              } else {
                history.replace(Paths.LocationDetails);
              }
            }}
            style={{ padding: "12px 24px" }}
          />
        }
        nextGeneratorObject={handleNextObject}
        footerRightButton={
          <Button
            loading={saving}
            type={updatingForBooking ? ButtonType.secondary : ButtonType.indigo}
            title={updatingForBooking ? "Save & Apply" : "Continue"}
            onClick={() => handleNextObject.next()}
            style={{ padding: "12px 24px" }}
          />
        }
        progress={STEP_PROGRESS[2]}
      >
        <Box marginTop="50px">
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Dropdown
              title="Service type"
              options={serviceTypeOptions}
              selectedOption={serviceTypeOptions.find((option) => option.value === serviceId)}
              onSelectedOption={(option) => onServiceSelected(option)}
            />
            <Box width="60px" />
            <Box width="100%" />
          </Box>
          {isMassage && !isCorporateType && (
            <MassageDetail
              onInfoModalChange={onInfoModalChange}
              onTreatmentChanged={_onTreatmentIdChanged}
              genderFallback={{
                message: genderFallbackMessage,
                visible: showGenderFallback,
                showGenderFallback: setShowGenderFallback,
              }}
            />
          )}
          {isHairAndMakeupBooking() && !isCorporateType && (
            <HairAndMakeupFlow
              treatments={treatmentData}
              onTreatmentChanged={_onTreatmentIdChanged}
              onInfoModalChange={onInfoModalChange}
            />
          )}

          {isCorporateType && (
            <CorporateFlow
              treatments={treatmentData}
              onTreatmentChanged={(treatmentId) => {
                resetTreatmentDetails();
                _onTreatmentIdChanged(0, treatmentId);
              }}
            />
          )}

          {!isMassage && !isHairAndMakeupBooking() && !isCorporateType && (
          
            <GeneralFlow
              treatments={treatmentData}
              selectedTreatment={treatmentId}
              onTreatmentChanged={(treatment) => {
                const treatmentId = getValue(treatment, "id");
                const treatmentDuration = getValue(treatment, "duration");

                resetTreatmentDetails();
                _onTreatmentIdChanged(0, treatmentId);
                if(treatmentDuration){
                  setMassageLength(treatmentDuration);
                  const newTreatmentDetails = [...(treatmentDetails || [])];
 
                  if (newTreatmentDetails[0]) {
                    newTreatmentDetails[0].serviceDuration = treatmentDuration;
                    newTreatmentDetails[0].treatmentDuration = treatmentDuration;
                    setTreatmentDetails(newTreatmentDetails);
                  }
                }
              }}
              onInfoModalChange={onInfoModalChange}
            />
          )}
        </Box>
      </NewBookingWrapper>
      <MassageTypeInfoModal
        type={MassageTypeInfoModalType.massageType1}
        open={infoModal[MassageTypeInfoModalType.massageType1]}
        onClose={() => onInfoModalChange(MassageTypeInfoModalType.massageType1, false)}
      />

      <MassageTypeInfoModal
        type={MassageTypeInfoModalType.massageType2}
        open={infoModal[MassageTypeInfoModalType.massageType2]}
        onClose={() => onInfoModalChange(MassageTypeInfoModalType.massageType1, false)}
      />

      <SessionTypeInfoModal
        open={infoModal["sessionType"]}
        onClose={() => onInfoModalChange("sessionType", false)}
      />

      <GenderFallbackInfoModal
        isOpen={infoModal["genderFallback"]}
        title={"Therapist Gender"}
        description={
          "To increase the speed of your booking confirmation, we suggest selecting ‘no preference’ or indicating you’re open the other genders if your preferred therapist gender is unavailable."
        }
        onClose={() => onInfoModalChange("genderFallback", false)}
      />

      <AlertModal
        open={serviceTypeErrorModalOpen}
        title="Service Type"
        message={serviceTypeErrorMessage}
        onClose={() => setServiceTypeErrorModalOpen(false)}
      />
    </>
  );
}
