import { Box, makeStyles } from "@material-ui/core";
import { getValue } from "../../../../utils/object";
import { AlignItems, Cursor, Display, FlexDirection, FontFamily, FontSize, FontWeight, JustifyContent, Spacing } from "../../../../components/v2/Styled/enum";
import { Colors } from "../../../../constants/colors";
import { formatDateWithTimezone } from "../../../../utils/date.util";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/date";
import EditIcon from "../../../../images/pen.svg";
import DeleteIcon from "../../../../images/trashbin.svg";

interface Props {
  pro: any;
  note: any;
  timezone: string;
  onEdit: () => unknown;
  onDelete: () => unknown;
  onClick?: () => unknown;
  showEditDeleteButtons: boolean;
};

const styles = makeStyles({
  lineClamp2: {
    "textOverflow": "ellipsis",
    "display": "-webkit-box !important",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    "overflow": "hidden",
  },
});

const TreatmentNoteCard = ({ note, timezone, pro, onEdit, onDelete, onClick = () => {}, showEditDeleteButtons=true }: Props ) => {
  
  const noteText = getValue(note, "data.note");
  const classes = styles();

  const getNoteMetaData = () => {
    const noteDate = getValue(note, "updatedAt");
    const formattedDate = formatDateWithTimezone(noteDate, timezone, DEFAULT_DATE_FORMAT );
    let metaData = `${formattedDate}`;
    if (pro && pro.firstName) {
      metaData += ` | ${pro.firstName}`;
    }
    return metaData;
  };

  const handleNoteEdit = (e: any) => {
    e.stopPropagation();
    onEdit();
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    onDelete();
  };

  const noteMeta = getNoteMetaData();

  return <Box 
    display={Display.Flex}
    flexDirection={FlexDirection.Column}
    gridGap={Spacing.S4}
    padding={Spacing.S4}
    style={{ backgroundColor: "#F8F9FA", borderRadius: "8px", cursor: Cursor.pointer }}
    onClick={onClick}
  >
    <Box display={Display.Flex} flexDirection={FlexDirection.Row} justifyContent={JustifyContent.spaceBetween} alignItems={AlignItems.center}>
      <Box
        fontFamily={FontFamily.SFPro}
        fontWeight={FontWeight.Medium}
        fontSize={FontSize.F12}
        color={Colors.Grey}
      >
        {noteMeta}
      </Box>
      {showEditDeleteButtons && (<Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S2}>
        <img onClick={(e) => handleNoteEdit(e)} src={EditIcon} alt="edit note" style={{ cursor: Cursor.pointer }}/>
        <img src={DeleteIcon} onClick={(e) => handleDelete(e)} alt="delete note" style={{ cursor: Cursor.pointer }}/>
      </Box>)}
    </Box>
    <Box
      fontSize={FontSize.F14}
      color={Colors.DarkSteelGrey}
      fontWeight={FontWeight.Regular}
      fontFamily={FontFamily.OpenSans}
      className={classes.lineClamp2}
    >
      {noteText}
    </Box>
  </Box>;
};

export default TreatmentNoteCard;
